import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class MemberLookupQuick extends Component {
     state = { id: 0, companys: [], insurances:[], suffixs: [], companyId: localStorage.getItem('companyId'), insuranceId: '', suffixId: '', memberNumber: '', msg: '', error: ''
    , firstname: '', surname: '', dobFormatted: '', idNumber: '',  beneficiaryType: '', beneficiaryStatusColor: '', beneficiaryStatus: '', enrolmentColor: '', enrolment: '', patientId: 0, newPatient: false, showSubmit: false, companyIdError: '', insuranceIdError: '', memberNumberError: '', suffixError: ''}
       getMemberLookup()
     {
        this.setState({ companys: [], companyId: localStorage.getItem('companyId'), insurances: [], suffixs: [], msg: '', error: '', firstname: '', surname: '', dobFormatted: '', idNumber: '',  beneficiaryType: '', beneficiaryStatusColor: '', beneficiaryStatus: '', enrolmentColor: '', enrolment: '', patientId: 0, newPatient: false , memberNumber:'', showSubmit: false, companyIdError: '', insuranceIdError: '', memberNumberError: '', suffixError: ''});
        axios.get(localStorage.getItem('host') + '/interchange/getmemberlookup/' + localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ companys: res.data.companys, insurances: res.data.insurances, suffixs: res.data.suffixs });
                this.props.setLoader(false)
                this.doValidate()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
     }
    memberLookupQuick() {
        var error = "";
         if(this.state.companyId === "")
            {
             error = "Company cannot be empty";
            }
         if(this.state.insuranceId === "" && error === "")
            {
             error = "Insurance cannot be empty";
            }
         if(this.state.suffixId === "" && error === "")
            {
             error = "Suffix cannot be empty";
            }
         if(this.state.memberNumber === "" && error === "")
            {
             error = "Member Number cannot be empty";
            }
        /* if(new RegExp(e.getAttribute("pattern")).test(this.state.memberNumber))
            {
             error = "Company cannot be empty";
            }*/
         if(this.state.memberNumber.length < 3 && error === "")
            {
             error = "Member Number should be more than 3 characters";
            }
        if(error === "")
        {
            this.props.setLoader(true)
       
        axios.get(localStorage.getItem('host') + '/interchange/lookup/'+this.state.memberNumber+'/'+this.state.suffixId+'/'+this.state.insuranceId+'/'+this.state.companyId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ firstname: res.data.firstname, surname: res.data.surname, dobFormatted: res.data.dobFormatted, idNumber: res.data.idNumber,  beneficiaryType: res.data.beneficiaryType, beneficiaryStatusColor: res.data.beneficiaryStatusColor, beneficiaryStatus: res.data.beneficiaryStatus, enrolmentColor: res.data.enrolmentColor, enrolment: res.data.enrolment, error: res.data.msg, patientId: res.data.patientId, newPatient: res.data.newPatient });
                this.props.setLoader(false)
               
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
        }
      else
         {
             this.setState({error: error})
         }  
    }
    closePopup() {
        document.getElementById('memberLookupQuickAction').style = 'display:none'
    }
    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }
    validate() {

        var error = false;
        if (this.state.companyId === null) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === "") {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === 0) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else {
            this.setState({ companyIdError: "" })
        }
        if (this.state.insuranceId === null) {
            this.setState({ insuranceIdError: "Patient Insurance cannot be empty" });
            error = true
        }
        else if (this.state.insuranceId === "") {
            this.setState({ insuranceIdError: "Patient Insurance cannot be empty" });
            error = true
        }
        else if (this.state.insuranceId === 0) {
            this.setState({ insuranceIdError: "Patient Insurance cannot be empty" });
            error = true
        }
        else {
            this.setState({ insuranceIdError: "" })
        }
        if (this.state.memberNumber === null) {
            this.setState({ memberNumberError: "Member Number cannot be empty" });
            error = true
        }
        else if (this.state.memberNumber === "") {
            this.setState({ memberNumberError: "Member Number cannot be empty" });
            error = true
        }
        else if (this.state.memberNumber === 0) {
            this.setState({ memberNumberError: "Member Number cannot be empty" });
            error = true
        }
        else {
            this.setState({ memberNumberError: "" })
        }
        if (this.state.suffixId === null) {
            this.setState({ suffixError: "Suffix cannot be empty" });
            error = true
        }
        else if (this.state.suffixId === "") {
            this.setState({ suffixError: "Suffix cannot be empty" });
            error = true
        }
        else if (this.state.suffixId === 0) {
            this.setState({ suffixError: "Suffix cannot be empty" });
            error = true
        }
        else {
            this.setState({ suffixError: "" })
        }
     if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ error: "", showSubmit: true })
        }
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        // v-bind:style="'height: '+heightMemberLookupQuick+'px;max-height: '+heightMemberLookupQuick+'px;overflow: auto;overflow-x: hidden;'"
        return (<div id="memberLookupQuickAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info">Member Lookup Action</div>
            <div className="card-body" >
                {divMsg}
                <div className="row">
                <div className="col"><div className="input-group">
                                                <span className="input-group-text">Company</span><select className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.companyId} onChange={e =>  {this.setState({companyId: e.target.value}); this.doValidate()} }  >
                        <option value="">Choose Company</option>
                        {this.state.companys.map((item, index) => (
                            <option value={item.id} key={index} >{item.name}</option>
                        ))}
                    </select>   <div className="invalid-feedback">{this.state.companyIdError}</div>  </div></div>
                    <div className="col"><div className="input-group">
                                                <span className="input-group-text">Insurance Details</span><select className={this.state.insuranceIdError !== "" ? "form-control is-invalid" : "form-control"} onChange={e =>  {this.setState({insuranceId: e.target.value}); this.doValidate()} } >
                        <option value="">Choose Insurance Record</option>
                        {this.state.insurances.map((item, index) => (
                            <option value={item.id} key={index} >{item.name}</option>
                        ))}
                    </select><div className="invalid-feedback">{this.state.insuranceIdError}</div></div></div>
                    <div className="col"><div className="input-group">
                                                <span className="input-group-text">Member Number</span><input type="text" className={this.state.memberNumberError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.memberNumber} onChange={e =>  {this.setState({memberNumber: e.target.value}); this.doValidate()} }  />
                                                <div className="invalid-feedback">{this.state.memberNumberError}</div></div></div>
                    <div className="col"><div className="input-group">
                                                <span className="input-group-text">Suffix</span><select className={this.state.suffixError !== "" ? "form-control is-invalid" : "form-control"} onChange={e => { this.setState({suffixId: e.target.value}); this.doValidate()} } >
                        <option value="">Choose Suffix</option>
                        {this.state.suffixs.map((item, index) => (
                            <option value={item.name} key={index} >{item.name}</option>
                        ))}
                    </select><div className="invalid-feedback">{this.state.suffixError}</div></div></div>
                    <div className="col">{this.state.showSubmit === true &&(<button onClick={()=>this.memberLookupQuick()} className="btn btn-success">Member Lookup</button>)}</div>
                </div>
                <div>
                    <div className="row">
                           <div className="col"><span className="fw-bold">Firstname:</span> {this.state.firstname}</div>
                           <div className="col"><span className="fw-bold">Surname:</span> {this.state.surname}</div>
                           <div className="col"><span className="fw-bold">Date of Birth:</span> {this.state.dobFormatted}</div>
                           </div>
                           <div className="row">    
                           <div className="col"><span className="fw-bold">ID Number:</span> {this.state.idNumber}</div>
                           <div className="col"><span className="fw-bold">Beneficiary Type:</span> {this.state.beneficiaryType}</div>
                           <div className="col"><span className="fw-bold">Dependent Code:</span> {this.state.dependantCode}</div>
                           </div>
                           <div className="row">
                           <div className="col"><span className="fw-bold">Beneficiary Status:</span> <span className={this.state.beneficiaryStatusColor}>{this.state.beneficiaryStatus}</span> </div>
                           <div className="col"><span className="fw-bold">Enrolment Status:</span> <span className={this.state.enrolmentColor}>{this.state.enrolment}</span></div>
                           <div className="col"></div>
                    </div>  
                    <br />
                    { this.state.newPatient === true && (<div className="row">
                        <div className="alert alert-info">Please make sure you edit the patient details to add the missing information like next of kin details and relationship to member</div>
                    </div>) }
                    { this.state.patientId > 0  && (
                            <div className="row">
                                            { this.state.enrolment === "NOT ENROLLED" && (<div className="col"><input type="button" onClick={() => this.props.getEnrol(this.state.patientId, 0)} className="btn btn-success" value="Enrol" /></div>)}
                                            <div className="col"><input type="button" value="Edit Patient" className="btn btn-success" onClick={() => this.props.openPatient(this.state.patientId)} /></div>
                                            <div className="col"><input type="button" value="Presign Bio" className="btn btn-success" onClick={() => this.props.getPresignPi(this.state.patientId, 0)} /></div>
                                            <div className="col"><input type="button" onClick={() => this.props.openVisitAction(0, this.state.patientId, '', 0)} className="btn btn-success" value="Create Visit" /></div>
                                         </div>
                            )}
                </div>
                <br />
                <div className="row">
                    <div className="col-lg-12" align="center"><button onClick={()=>this.closePopup()} className="btn btn-primary">Close Window</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}      