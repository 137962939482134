import React, { Component } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import StockAdjustment from "./stockadjustment";
import Cancel from "../patient/cancel";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}


export default class StockAdjustmentList extends Component {
    state = { loader: false, id: 0, firstname: '', surname: '', type: 'NEGATIVE', startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD, companyId: localStorage.getItem('companyId'), list: [], clusterCompanys: [] }
    constructor(props) {
        super(props);
        this.setLoader = this.setLoader.bind(this)
        this.getPrintOut = this.getPrintOut.bind(this)
        this.sendCancelRequest = this.sendCancelRequest.bind(this)
        this.getList = this.getList.bind(this)
        this.setMsg = this.setMsg.bind(this)
        this.printOutRef = React.createRef()
        this.stockAdjustmentRef = React.createRef()
        this.cancelRef = React.createRef()
    }

    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/'+localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({clusterCompanys: res.data});
                this.getList() 
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.setLoader(false)
            }
        )
    }
    getList() {
        this.setLoader(true)
        var data = { dateRange: this.state.startDate+","+this.state.endDate, companyId:  this.state.companyId , type: this.state.type}
        axios.post(localStorage.getItem('host') + '/stockmanagement/stockadjustmentlist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    } 

   
    openStockAdjustmentAction(id)
      {
       this.close()
       this.props.getForm(id, '')   
      }
      setLoader(loader) {
          this.setState({ loader: loader })
      }
      currencyFormat(amount) {
          let val = (amount / 1).toFixed(2)
          return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
      }
      getPrintOut(id, type, printouts, companyId) {
          this.setState({ loader: true })
          this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
          document.getElementById('printOutAction').style = 'display:block'
      }
      openReceiptAction(stockAdjustmentId) {
          this.setState({ loader: true })
          this.receiptRef.current.openReceiptAction(stockAdjustmentId)
          document.getElementById('receiptAction').style = 'display:block'
      }
      setMsg(msg)
       {
        this.setState({msg:msg})
       }
      processDate(date)
        {
          const now = new Date(date);
          const todayY = now.getFullYear();
          const todayM = checkZero(now.getMonth() + 1);
          const todayD = checkZero(now.getDate());
          var date1 = todayY + '-' + todayM + '-' + todayD;
          console.log(date1)
         return date1;
        }
        sendCancelRequest(id, reason)
         {
            this.setLoader(true)
            axios.get(localStorage.getItem('host') + '/stockmanagement/cancelstockadjustment/'+id+'/'+reason, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({msg: res.data, loader: false});
                    document.getElementById('cancelAction').style = 'display:none'
                    this.getList()
                }, err => {
                    console.log(".....")
                     this.setState({error: err+""})
                    this.setLoader(false)
                }
            )
         }  
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
           
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid" id="topDiv">
                        <div className="row">
                            <div className="col"><input type="button" className="btn btn-success" onClick={() => this.stockAdjustmentRef.current.openStockAdjustment(0)} value="Create New" /></div>
                        </div>
                        {divMsg}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card border-primary">
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">End Date</span> <DatePicker className="form-control" selected={new Date(this.state.endDate)}  minDate={new Date(this.state.startDate)} maxDate={new Date()}  onChange={d => { this.setState({ endDate: this.processDate(d) }); } } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                            {this.state.clusterCompanys.map((item, index) => (
                                                <option key={index} value={item.id} >{item.name}</option>
                                            ))}
                                        </select>  </div></div>
                                        <div className="col"><div className="input-group"><span className="input-group-text">Type</span><select className="form-control" onChange={(e) => this.setState({ type: e.target.value })}  >
                                                  <option value="NEGATIVE" >NEGATIVE</option>
                                                  <option value="POSITIVE" >POSITIVE</option>
                                        </select>  </div></div>
                                        <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getList()} value="Search" /></div>
                                    </div></div></div>
                        </div>
                    </div>
                    <div>
                        <table className="table table-striped table-hover">
                            <tr>
                                <th>Action</th>
                                <th>Number</th>
                                <th>Date</th>
                                <th>Location</th>
                            </tr>
                            <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr style={{ backgroundColor: item.color }} key={index}>
                                        {item.stockAdjustmentStatus !== 'CANCELLED'? (<td >
                                            <span className="link" onClick={() => this.getPrintOut(item.id, 'STOCK_ADJUSTMENT', [], item.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>
                                             </td>) : (<td ></td>)}
                                        <td>{item.number}</td>
                                        <td>{item.dateTime}</td>
                                        <td>{item.locationName}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
       
            {loaderDiv}
                        <StockAdjustment ref={this.stockAdjustmentRef} setLoader={this.setLoader} setMsg={this.setMsg} getPrintOut={this.getPrintOut} getList={this.getList} />
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} />
                        <Cancel ref={this.cancelRef} sendCancelRequest={this.sendCancelRequest} />
                    </div>
        )

    }



}