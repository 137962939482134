import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

let ws = null
export default class Enrol extends Component {
   
    state = { fingerImage: null, fingerFeature:'', authoCode: '', patientId: 0, billId: 0, id: 0, companys: [], patientInsurances:[], companyId: localStorage.getItem('companyId'), companyIdError: '', patientInsuranceId: '', patientInsuranceIdError: '', msg: '', error: '', type: ''
    , leftThumbImage: '',leftIndexImage: '', leftMiddleImage: '', leftRingImage:'', leftPinkyImage:'', rightThumbImage:'', rightIndexImage:'', rightMiddleImage:'', rightRingImage:'', rightPinkyImage:''
    , leftThumbFeature: '',leftIndexFeature: '', leftMiddleFeature: '', leftRingFeature:'', leftPinkyFeature:'', rightThumbFeature:'', rightIndexFeature:'', rightMiddleFeature:'', rightRingFeature:'', rightPinkyFeature:'', readerLoaded: false, isNeeded: true, qualityLevel: 0, color: "progress-bar bg-danger"}
    componentDidMount() {
       
    }
    getEnrol(patientId, billId)
     {
        this.setState({ fingerImage: null, authoCode: '', patientId: patientId, companyId: localStorage.getItem('companyId'), companyIdError: '', patientInsuranceId: '', patientInsuranceIdError: '', billId: billId, companys: [], patientInsurances: [], msg: '', error: '', firstname: '', surname: '', dobFormatted: '', idNumber: '',  beneficiaryType: '', beneficiaryStatusColor: '', beneficiaryStatus: '', enrolmentColor: '', enrolment: '', readerLoaded: false, isNeeded: true, type: ''
        , leftThumbImage: '',leftIndexImage: '', leftMiddleImage: '', leftRingImage:'', leftPinkyImage:'', rightThumbImage:'', rightIndexImage:'', rightMiddleImage:'', rightRingImage:'', rightPinkyImage:''
        , leftThumbFeature: '',leftIndexFeature: '', leftMiddleFeature: '', leftRingFeature:'', leftPinkyFeature:'', rightThumbFeature:'', rightIndexFeature:'', rightMiddleFeature:'', rightRingFeature:'', rightPinkyFeature:'' , qualityLevel: 0, color: "progress-bar bg-danger"});
        axios.get(localStorage.getItem('host') + '/interchange/getmemberlookup/' + localStorage.getItem('companyId')+'/'+patientId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ companys: res.data.companys, patientInsurances: res.data.patientInsurances });
                this.props.setLoader(false)
                this.handleReader()
                this.doValidate()
            }, err => {
                console.log(".....")
                this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
     }
     enrol() {

        var error = "";
         if(this.state.companyId === "")
            {
             error = "Company cannot be empty";
            }
         if(this.state.patientInsuranceId === "" && error === "")
            {
             error = "Insurance details cannot be empty";
            }
        if(error === "")
        {

            this.props.setLoader(true)
        var data = {leftThumbFeature: this.state.leftThumbFeature,leftIndexFeature: this.state.leftIndexFeature, leftMiddleFeature: this.state.leftMiddleFeature, leftRingFeature:this.state.leftRingFeature, leftPinkyFeature:this.state.leftPinkyFeature, rightThumbFeature:this.state.rightThumbFeature, rightIndexFeature:this.state.rightIndexFeature, rightMiddleFeature:this.state.rightMiddleFeature, rightRingFeature:this.state.rightRingFeature, rightPinkyFeature: this.state.rightPinkyFeature}
        axios.post(localStorage.getItem('host') + '/interchange/enrol/'+this.state.patientInsuranceId+'/'+this.state.companyId, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                if(res.data.error)
                   {
                    this.setState({  error: res.data.error })
                   }
                 else
                   {
                    this.setState({  msg: res.data.msg })
                   
                   }  
                
                this.props.setLoader(false)
               
               
            }, err => {
                console.log(".....")
                this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
        }
      else
         {
             this.setState({error: error})
         }  
    }
   /* getErrorMsg(errorcode) {
        var error;
        switch (errorcode) {
            case 1:
                error = "Creation failed (fingerprint reader not correctly installed or driver files error)";
                break;
            case 2:
                error = "Function failed (wrong type of fingerprint reader or not correctly installed)";
                break;
            case 3:
                error = "Internal (invalid parameters to sensor API)";
                break;
            case 5:
                error = "DLL load failed";
                break;
            case 6:
                error = "DLL load failed for driver";
                break;
            case 7:
                error = "DLL load failed for algorithm";
                break;
            case 51:
                error = "System file load failure";
                break;
            case 52:
                error = "Sensor chip initialization failed";
                break;
            case 53:
                error = "Sensor line dropped";
                break;
            case 54:
                error = "Timeout";
                break;
            case 55:
                error = "Device not found";
                break;
            case 56:
                error = "Driver load failed";
                break;
            case 57:
                error = "Wrong image";
                break;
            case 58:
                error = "Lack of bandwidth";
                break;
            case 59:
                error = "Device busy";
                break;
            case 60:
                error = "Cannot get serial number of the device";
                break;
            case 61:
                error = "Unsupported device";
                break;
            case 101:
                error = "Very low minutiae count";
                break;
            case 102:
                error = "Wrong template type";
                break;
            case 103:
                error = "Invalid template";
                break;
            case 104:
                error = "Invalid template";
                break;
            case 105:
                error = "Could not extract features";
                break;
            case 106:
                error = "Match failed";
                break;
            case 1000:
                error = "No memory";
                break;
            case 4000:
                error = "Invalid parameter passed to service";
                break;
            case 2000:
                error = "Internal error";
                break;
            case 3000:
                error = "Internal error extended";
                break;
            case 6000:
                error = "Certificate error cannot decode";
                break;
            case 10001:
                error = "License error";
                break;
            case 10002:
                error = "Invalid domain";
                break;
            case 10003:
                error = "License expired";
                break;
            case 10004:
                error = "WebAPI may not have received the origin header from the browser";
                break;
            default:
                error = "Unknown error code or Update code to reflect latest result";
                break;
        }
        return error;
    }*/
    closePopup() {
        if(ws !== null)
         {
            ws.close();
            ws = null;
         }
         this.setState({readerLoaded: false, isNeeded: false});
        document.getElementById('enrolAction').style = 'display:none'
    }
   scanFinger(type)
        {
            this.props.setLoader(true)
           axios.get(localStorage.getItem('websocket')+'/getfingerprint')
                    .then(res => {
                        this.props.setLoader(false)
                       var msg = JSON.parse(res.data);
            this.props.setLoader(false)
            switch (msg.type) {
            case "status":
             // setStatus(msg.statusMessage);
              break;
            case "data":
             console.log(msg.qualityLevel + "%")
             if(msg.error)
             {
              this.setState({error: msg.error});
             }
           else
            {  
              if (msg.qualityLevel >= 65) {
                switch (type) {
                    case "LEFT_THUMB":
                        this.setState({leftThumbFeature: msg.bioData, leftThumbImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "LEFT_INDEX":
                        this.setState({leftIndexFeature: msg.bioData, leftIndexImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "LEFT_MIDDLE":
                        this.setState({leftMiddleFeature: msg.bioData, leftMiddleImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "LEFT_RING":
                        this.setState({leftRingFeature: msg.bioData, leftRingImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "LEFT_PINKY":
                        this.setState({leftPinkyFeature: msg.bioData, leftPinkyImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "RIGHT_THUMB":
                        this.setState({rightThumbFeature: msg.bioData, rightThumbImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "RIGHT_INDEX":
                        this.setState({rightIndexFeature: msg.bioData, rightIndexImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "RIGHT_MIDDLE":
                        this.setState({rightMiddleFeature: msg.bioData, rightMiddleImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "RIGHT_RING":
                        this.setState({rightRingFeature: msg.bioData, rightRingImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "RIGHT_PINKY":
                        this.setState({rightPinkyFeature: msg.bioData, rightPinkyImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    default :
                        console.log("......");
                        break;
                }
                this.doValidate() 
              } else if (msg.qualityLevel > 50) {
                this.setState({qualityLevel: msg.qualityLevel, color: "progress-bar bg-warning"});
              } else {
                this.setState({qualityLevel: msg.qualityLevel, color: "progress-bar bg-warning"});
              }
            }
              break;
              default: 
              console.log(msg.qualityLevel + "%")
              break
            }
                      this.doValidate()
                    }).catch(err => {
                        this.props.setLoader(false)
                console.log(err)
                this.setState({error: err+""});
            });
        }
        doValidate() {
            setTimeout(() => {
                this.validate();
            }, 1000);
        }
        validate() {
    
            var error = false;
            if (this.state.companyId === null) {
                this.setState({ companyIdError: "Company cannot be empty" });
                error = true
            }
            else if (this.state.companyId === "") {
                this.setState({ companyIdError: "Company cannot be empty" });
                error = true
            }
            else if (this.state.companyId === 0) {
                this.setState({ companyIdError: "Company cannot be empty" });
                error = true
            }
            else {
                this.setState({ companyIdError: "" })
            }
            if (this.state.patientInsuranceId === null) {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" });
                error = true
            }
            else if (this.state.patientInsuranceId === "") {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" });
                error = true
            }
            else if (this.state.patientInsuranceId === 0) {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" });
                error = true
            }
            else {
                this.setState({ patientInsuranceIdError: "" })
            }
        
            var leftCount = 0;
            var rightCount = 0;
            if(this.state.leftThumbImage !== "")
               {
                leftCount++;
               }
            if(this.state.leftIndexImage !== "")
               {
                leftCount++;
               }
            if(this.state.leftMiddleImage !== "")
               {
                leftCount++;
               }
            if(this.state.leftRingImage !== "")
               {
                leftCount++;
               }
            if(this.state.leftPinkyImage !== "")
               {
                leftCount++;
               }
            if(this.state.rightThumbImage !== "")
               {
                rightCount++;
               }
            if(this.state.rightIndexImage !== "")
               {
                rightCount++;
               }
            if(this.state.rightMiddleImage !== "")
               {
                rightCount++;
               }
            if(this.state.rightRingImage !== "")
               {
                rightCount++;
               }
            if(this.state.rightPinkyImage !== "")
               {
                rightCount++;
               }
         if(!(leftCount > 2 && rightCount > 2))
               {
                error = true
               }     
            console.log(" error" + error)
            
        if(!(leftCount > 2 && rightCount > 2))
            {
            this.setState({ error: "At least three fingers from each hand is required to enrol", showSubmit: false })
            }
        else  if (error === true) {
                this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
            }
            else {
                this.setState({ error: "", showSubmit: true })
            }
        }
     
   /* handleReader() {
        if(this.state.isNeeded)
          {
        ws = new WebSocket(localStorage.getItem('websocket'));
        this.setState({msg:"Connecting to fingerprint reader..........", error: ""});
        
        console.log("Connecting to fingerprint reader..........");
        var timeout = setTimeout(function() {
            if (ws.readyState === 0) {
            console.log("Forcing timeout of connection attempt so we can retry");
            ws.close();
            }
        }, 10000); 
        
        ws.onopen = () => {
            clearTimeout(timeout);
            console.log("Connection to bio reader opened");
            this.setState({readerLoaded : true, msg: ""});
        };
        
        ws.onmessage = (evt) => { this.bioOnMessage(evt); };
        
        ws.onclose = () => {
            clearTimeout(timeout);
            console.log("Connection to bio reader closed");
            this.setState({readerLoaded : false});
        };
        
        ws.onerror = () => {
            clearTimeout(timeout);
            this.setState({error:"Unable to communicate with fingerprint reader. Is the fingerprint reader connected and application running?", msg: ""});
            ws = null;
            this.setState({readerLoaded : false});
            setTimeout(() => { this.handleReader() }, 3000);
        }
        
        window.onbeforeunload = (event) => {
            ws.close();
            ws = null;
            this.setState({readerLoaded: false});
        };
    }
        }  
     scanFinger(type)
        {
            this.props.setLoader(true)
            this.setState({type: type, error: "", msg: ""})
            ws.send("CAPTURE");   
            
        }
     
        bioOnMessage(evt) {
            var msg = JSON.parse(evt.data);
            this.props.setLoader(false)
            switch (msg.type) {
            case "status":
             // setStatus(msg.statusMessage);
              break;
            case "data":
             console.log(msg.qualityLevel + "%")
             if(msg.error)
             {
              this.setState({error: msg.error});
             }
           else
            {  
              if (msg.qualityLevel >= 65) {
                switch (this.state.type) {
                    case "LEFT_THUMB":
                        this.setState({leftThumbFeature: msg.bioData, leftThumbImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "LEFT_INDEX":
                        this.setState({leftIndexFeature: msg.bioData, leftIndexImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "LEFT_MIDDLE":
                        this.setState({leftMiddleFeature: msg.bioData, leftMiddleImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "LEFT_RING":
                        this.setState({leftRingFeature: msg.bioData, leftRingImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "LEFT_PINKY":
                        this.setState({leftPinkyFeature: msg.bioData, leftPinkyImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "RIGHT_THUMB":
                        this.setState({rightThumbFeature: msg.bioData, rightThumbImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "RIGHT_INDEX":
                        this.setState({rightIndexFeature: msg.bioData, rightIndexImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "RIGHT_MIDDLE":
                        this.setState({rightMiddleFeature: msg.bioData, rightMiddleImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "RIGHT_RING":
                        this.setState({rightRingFeature: msg.bioData, rightRingImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    case "RIGHT_PINKY":
                        this.setState({rightPinkyFeature: msg.bioData, rightPinkyImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                        break;
                    default :
                        console.log("......");
                        break;
                }
                this.doValidate() 
              } else if (msg.qualityLevel > 50) {
                this.setState({qualityLevel: msg.qualityLevel, color: "progress-bar bg-warning"});
              } else {
                this.setState({qualityLevel: msg.qualityLevel, color: "progress-bar bg-warning"});
              }
            }
              break;
            }
          }        */
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        // v-bind:style="'height: '+heightMemberLookupQuick+'px;max-height: '+heightMemberLookupQuick+'px;overflow: auto;overflow-x: hidden;'"
        return (<div id="enrolAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header bg-info">Enrol Action</div>
            <div className="card-body" >
                {divMsg}
                <div className="row">
                    <div className="col"><div className="input-group">
                                                <span className="input-group-text">Company</span><select className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.companyId} onChange={e =>  {this.setState({companyId: e.target.value}); this.doValidate();} }  >
                        <option value="">Choose Company</option>
                        {this.state.companys.map((item, index) => (
                            <option value={item.id} key={index} >{item.name}</option>
                        ))}
                    </select> <div className="invalid-feedback">{this.state.companyIdError}</div>  </div></div>
                    <div className="col"><div className="input-group">
                                                <span className="input-group-text">Insurance Details</span><select className={this.state.patientInsuranceIdError !== "" ? "form-control is-invalid" : "form-control"} onChange={e =>  {this.setState({patientInsuranceId: e.target.value}); this.doValidate();} } >
                        <option value="">Choose Insurance Record</option>
                        {this.state.patientInsurances.map((item, index) => (
                            <option value={item.id} key={index} >{item.name}</option>
                        ))}
                    </select><div className="invalid-feedback">{this.state.patientInsuranceIdError}</div></div></div>
                    <div className="col"></div>
                </div>
                <div className="row">
                    <div className="col"></div>
                    <div className="col"> <div class="progress">
  <div className={this.state.color} role="progressbar" style={{width: this.state.qualityLevel+"%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
</div></div>
                    <div className="col"></div>
                </div>
                <table className="table table-striped">
                <tr>
                    <td></td>
                  <th>Thumb</th>
                  <th>Index</th>
                  <th>Middle</th>
                  <th>Ring</th>
                  <th>Pinky</th>
                </tr>
                <tbody>
                <tr>
                    <td></td>
                    <td><button className="btn btn-success" onClick={()=> { this.scanFinger('LEFT_THUMB')}}>Scan Finger</button></td>
                    <td><button className="btn btn-success" onClick={()=> { this.scanFinger('LEFT_INDEX')}}>Scan Finger</button></td>
                    <td><button className="btn btn-success" onClick={()=> { this.scanFinger('LEFT_MIDDLE')}}>Scan Finger</button></td>
                    <td><button className="btn btn-success" onClick={()=> { this.scanFinger('LEFT_RING')}}>Scan Finger</button></td>
                    <td><button className="btn btn-success" onClick={()=> { this.scanFinger('LEFT_PINKY')}}>Scan Finger</button></td>
                </tr>
                <tr>
                    <th>LEFT</th>
                    <td>{this.state.leftThumbImage !== null && this.state.leftThumbImage !== ''?(<img style={{display :'block'}} alt="Finger Print" src={'data:image/bmp;base64,'+this.state.leftThumbImage} height="80" />):(<b >NOT AVAILABLE</b>)}</td>
                    <td>{this.state.leftIndexImage !== null && this.state.leftIndexImage !== ''?(<img style={{display :'block'}} alt="Finger Print" src={'data:image/bmp;base64,'+this.state.leftIndexImage} height="80" />):(<b >NOT AVAILABLE</b>)}</td>
                    <td>{this.state.leftMiddleImage !== null && this.state.leftMiddleImage !== ''?(<img style={{display :'block'}} alt="Finger Print" src={'data:image/bmp;base64,'+this.state.leftMiddleImage} height="80" />):(<b >NOT AVAILABLE</b>)}</td>
                    <td>{this.state.leftRingImage !== null && this.state.leftRingImage !== ''?(<img style={{display :'block'}} alt="Finger Print" src={'data:image/bmp;base64,'+this.state.leftRingImage} height="80" />):(<b >NOT AVAILABLE</b>)}</td>
                    <td>{this.state.leftPinkyImage !== null && this.state.leftPinkyImage !== ''?(<img style={{display :'block'}} alt="Finger Print" src={'data:image/bmp;base64,'+this.state.leftPinkyImage} height="80" />):(<b >NOT AVAILABLE</b>)}</td>
                </tr>
                <tr>
                    <td></td>
                    <td><button className="btn btn-success" onClick={()=> { this.scanFinger('RIGHT_THUMB')}}>Scan Finger</button></td>
                    <td><button className="btn btn-success" onClick={()=> { this.scanFinger('RIGHT_INDEX')}}>Scan Finger</button></td>
                    <td><button className="btn btn-success" onClick={()=> { this.scanFinger('RIGHT_MIDDLE')}}>Scan Finger</button></td>
                    <td><button className="btn btn-success" onClick={()=> { this.scanFinger('RIGHT_RING')}}>Scan Finger</button></td>
                    <td><button className="btn btn-success" onClick={()=> { this.scanFinger('RIGHT_PINKY')}}>Scan Finger</button></td>
                </tr>
                <tr>
                    <th>RIGHT</th>
                    <td>{this.state.rightThumbImage !== null && this.state.rightThumbImage !== ''?(<img style={{display :'block'}} alt="Finger Print" src={'data:image/bmp;base64,'+this.state.rightThumbImage} height="80" />):(<b >NOT AVAILABLE</b>)}</td>
                    <td>{this.state.rightIndexImage !== null && this.state.rightIndexImage !== ''?(<img style={{display :'block'}} alt="Finger Print" src={'data:image/bmp;base64,'+this.state.rightIndexImage} height="80" />):(<b >NOT AVAILABLE</b>)}</td>
                    <td>{this.state.rightMiddleImage !== null && this.state.rightMiddleImage !== ''?(<img style={{display :'block'}} alt="Finger Print" src={'data:image/bmp;base64,'+this.state.rightMiddleImage} height="80" />):(<b >NOT AVAILABLE</b>)}</td>
                    <td>{this.state.rightRingImage !== null && this.state.rightRingImage !== ''?(<img style={{display :'block'}} alt="Finger Print" src={'data:image/bmp;base64,'+this.state.rightRingImage} height="80" />):(<b >NOT AVAILABLE</b>)}</td>
                    <td>{this.state.rightPinkyImage !== null && this.state.rightPinkyImage !== ''?(<img style={{display :'block'}} alt="Finger Print" src={'data:image/bmp;base64,'+this.state.rightPinkyImage} height="80" />):(<b >NOT AVAILABLE</b>)}</td>
                </tr>
                </tbody>
           </table> 
                <div className="row">
                    <div className="col" align="center">{this.state.showSubmit === true &&(<button onClick={()=>this.enrol()} className="btn btn-primary">Enrol Bio</button>)}</div>
                    <div className="col" align="center"><button onClick={()=>this.closePopup()} className="btn btn-primary">Close Window</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}      