import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


export default class Users extends Component {
    state = { list: [], id: 0, firstName: '', lastName: '', username: '', password: '', activeStatus: 1, loginFailCount: 0, consultant: 0, email: '', roles: [], businessUnits: [], msg: '', error: '', userRoleCompanys: [], companys: [], specialitys: [], timeoutInMinutes: 10 }
    componentDidMount() {
        this.getList()

    }
    userAction(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, firstName: '', lastName: '', username: '', password: '', activeStatus: 1, loginFailCount: 0, consultant: 0, email: '', canLogin: '0', admin: '0', cellphone: '', qualifications: '', nurse: 0, userRoleCompanys: [], companys: [], roles: [], specialitys: [], timeoutInMinutes: 10 })
        
            axios.get(localStorage.getItem('host') + '/auth/useraction/' + id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    let roles = []
                    for(let i in res.data.roles)
                       {
                        if(res.data.roles[i].id === 1 )
                           {
                             if(localStorage.getItem("userId") === "1" || localStorage.getItem("userId") === 1)
                                {
                                    roles.push(res.data.roles[i])
                                }

                           }
                        else
                           {
                            roles.push(res.data.roles[i])
                           }   
                       }
                     let companys = []
                     let specialitys = []
                       if(localStorage.getItem("userId") === "1" || localStorage.getItem("userId") === 1)
                        {
                          companys = res.data.companys
                          specialitys = res.data.specialitys
                        }
                    else{
                        for(let i in res.data.companys)
                            {
                                if(Number(localStorage.getItem("companyId")) === res.data.companys[i].id)
                                    {    
                                     companys.push(res.data.companys[i])
                                    }
                            }
                            specialitys.push(localStorage.getItem("speciality"))
                        }
                    this.setState({ id: res.data.id, firstName: res.data.firstName, lastName: res.data.lastName, username: res.data.username, activeStatus: res.data.activeStatus, loginFailCount: res.data.loginFailCount, consultant: res.data.consultant, email: res.data.email, canLogin: res.data.canLogin, admin: res.data.admin, cellphone: res.data.cellphone, qualifications: res.data.qualifications, nurse: res.data.nurse, userRoleCompanys: res.data.userRoleCompanys, companys: companys, roles: roles, specialitys: specialitys, timeoutInMinutes: res.data.timeoutInMinutes });

                }, err => {
                    console.log(".....")
                }
            )
        document.querySelector('#userAction').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#userAction').style = 'display:none'
        console.log('......close');
    }
    addRoleCompany() {
        let list = this.state.userRoleCompanys.slice();
        list.push({ id: 0, companyId: '', roleId: '', speciality: '' , activeStatus: 1 });
        this.setState({ userRoleCompanys: list })
    }
    saveUser() {
        var data = { id: this.state.id, firstName: this.state.firstName, lastName: this.state.lastName, username: this.state.username, password: this.state.password, activeStatus: this.state.activeStatus, loginFailCount: this.state.loginFailCount, consultant: this.state.consultant, email: this.state.email, canLogin: this.state.canLogin, admin: this.state.admin, cellphone: this.state.cellphone, qualifications: this.state.qualifications, nurse: this.state.nurse, userRoleCompanys: this.state.userRoleCompanys , imageType: '', imageTypeName: '', timeoutInMinutes: this.state.timeoutInMinutes }
        axios.post(localStorage.getItem('host') + '/auth/userform', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                if (res.data.includes('Error')) {
                    this.setState({ error: res.data });
                } else {
                    this.setState({ msg: res.data });
                    this.getList();
                    this.closePopup();
                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }
            }, err => {
                console.log(".....")
            }
        )
    }

    setRowValue(index, e, name) {
        let list = this.state.userRoleCompanys.slice();
        switch (name) {
            case "companyId":
                list[index].companyId = e.target.value;
                break;
            case "roleId":
                list[index].roleId = e.target.value;
                break;
            case "speciality":
                list[index].speciality = e.target.value;
                break;
            case "activeStatus":
                list[index].activeStatus = e.target.value;
                break;
            default:
                console.log("default")
                break;
        }
        this.setState({ userRoleCompanys: list })
    }

    getList() {
        axios.get(localStorage.getItem('host') + '/auth/user/' + localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span ><span className="link" title="Edit User" id={res.data[index].id} onClick={e => this.userAction(e)}><i className="bi bi-pencil-square"></i></span>
                    <span className="link" title="Upload Signature" id={res.data[index].id} onClick={e => this.uploadImage(e, "signature_")}><i class="bi bi-cloud-upload-fill"></i></span></span>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }
    uploadImage(e, imageType)
      {
        var id = e.currentTarget.id;
        this.setState({id:id, imageFile: '', file: '', imageType: imageType, imageTypeName: imageType === "stamp_"?"Stamp":"Signature"})
        axios.get(localStorage.getItem('host') + '/auth/getimagecompany/'+id+"/"+imageType, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                 this.setState({ imageFile: res.data });
                 document.getElementById('imageAction').style = 'display:block'
            }, err => {
                console.log(".....")
            }
        )
        
      }
      closeImage() {
          document.getElementById('imageAction').style = 'display:none'
      }
      saveImage()
      {
        var data = new FormData();
        data.append("file", this.state.file)
        axios.post(localStorage.getItem('host') + '/auth/uploadimage/'+this.state.id+"/"+this.state.imageType, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'multipart/form-data'
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.closeImage();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
      }
    render() {
        const data = {
            columns: [
                
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Fullname',
                    field: 'fullname',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Username',
                    field: 'username',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'Status',
                    field: 'stringStatus',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: this.state.list
        };
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let divError = ''
        if (this.state.error) {
            divError = (<div className='alert alert-error'>{this.state.error}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                    <div className="row">
                        <div className="col"><a href="/file/BioReaderApp.zip" target="_blank" download>Download Bio Reader Driver</a></div>
                    </div>
                        <button className="btn btn-primary" onClick={() => this.showForm(0)} title="Add User" ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                        <MDBDataTable
                            button="true"
                            striped
                            bordered
                            small
                            data={data}
                        />    
                        <div className="page-popup page-popup-overlay" id="imageAction">
                               <div className="card">
                                   <div className="card-header bg-info">
                                       {this.state.imageTypeName} Action
                                   </div>
                                   <div className="card-body">
                                       <div className="row">
                                           <div className="col"><img alt="Signature or Stamp" src={this.state.imageFile} style={{width: 300+'px'}} /></div>
                                           <div className="col">
                                                <div className="input-group"><span className="input-group-text">Image</span>
                                               <input type="file" accept=".png" className="form-control"  onChange={e => { this.setState({ file: e.target.files[0] }) }} /> </div>
                                           </div>
                                           </div>
                                       <div className="row">
                                           <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.saveImage()} value="Save" /></div>
                                           <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closeImage()} value="Close" /></div>
                                       </div>
                                    </div>
                                </div>     
                                </div> 
                        <div className="page-popup page-popup-overlay" id="userAction">
                            <form  >
                                <div className="card">
                                    <div className="card-header bg-info ">
                                        User Action
                                    </div>
                                    <div className="card-body">
                                        {divError}
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Firstname</span>
                                                <input type="text" className="form-control" value={this.state.firstName} onChange={e => { this.setState({ firstName: e.target.value }) }} /></div> 
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Lastname</span>
                                                <input type="text" className="form-control" value={this.state.lastName} onChange={e => { this.setState({ lastName: e.target.value }) }} /></div> 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Email</span>
                                                <input type="text" className="form-control" value={this.state.email} onChange={e => { this.setState({ email: e.target.value }) }} /></div> 
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Username</span>
                                                <input type="text" className="form-control" value={this.state.username} onChange={e => { this.setState({ username: e.target.value }) }} /></div> 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Password</span>
                                                <input type="password" className="form-control" value={this.state.password} onChange={e => { this.setState({ password: e.target.value }) }} /></div> 
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Login Fail Count</span>
                                                <input type="text" className="form-control" value={this.state.loginFailCount} onChange={e => { this.setState({ loginFailCount: e.target.value }) }} /></div> 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Cellphone</span>
                                                <input type="text" className="form-control" value={this.state.cellphone} onChange={e => { this.setState({ cellphone: e.target.value }); }} /></div> 
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Qualifications</span>
                                                <input type="text" className="form-control" value={this.state.qualifications} onChange={e => { this.setState({ qualifications: e.target.value }) }} /></div> 
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Consultant</span>
                                                <select className="form-control" value={this.state.consultant} onChange={e => { this.setState({ consultant: e.target.value }); }}  >
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Disable</option>
                                                </select></div> 
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e => { this.setState({ activeStatus: e.target.value }) }}  >
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Disable</option>
                                                </select></div> 
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Nurse</span>
                                                <select className="form-control" value={this.state.nurse} onChange={e => { this.setState({ nurse: e.target.value }); }}  >
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Disable</option>
                                                </select></div> 
                                            </div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Timeout In Minutes</span><input type="text" disabled={localStorage.getItem('admin') !== "1"} className="form-control" value={this.state.timeoutInMinutes} onChange={e => { this.setState({ timeoutInMinutes: e.target.value }) }} /></div> 
                                           
                                            </div></div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Can Login</span>
                                                <select className="form-control" value={this.state.canLogin} onChange={e => { this.setState({ canLogin: e.target.value }); }}  >
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Disable</option>
                                                </select></div> 
                                            </div>
                                            <div className="col">
                                            { (localStorage.getItem("userId") === "1" || localStorage.getItem("userId") === 1 ) && <div className="input-group"><span className="input-group-text">Admin</span>
                                                <select className="form-control" value={this.state.admin} onChange={e => { this.setState({ admin: e.target.value }); }}  >
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Disable</option>
                                                </select></div> }
                                            </div>
                                        </div>
                                        <div className="card card-green">
                                            <div className="card-header">Role Company</div>
                                            <div className="card-body">
                                                <table  className="table table-striped">
                                                    <tr>
                                                        <th>Company</th>
                                                        <th>Role</th>
                                                        <th>Speciality</th>
                                                        <th>Status</th>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4"> </td>
                                                        <td><button type="button" className="btn btn-success" title="Insert Role Company" onClick={() => { this.addRoleCompany() }} ><i className="bi bi-plus-square"></i></button></td>
                                                    </tr>
                                                    <tbody>
                                                    {this.state.userRoleCompanys.map((userRoleCompany, index) => (
                                                        <tr>
                                                            <td><select className="form-control" value={userRoleCompany.companyId} onChange={e => { this.setRowValue(index, e, "companyId") }}  >
                                                            <option value="">Choose</option>
                                                            {this.state.companys.map((company, index) => (
                                                        <option key={index} value={company.id} >{company.name}</option>
                                                    ))}
                                                            </select></td>
                                                            <td><select className="form-control" value={userRoleCompany.roleId} onChange={e => { this.setRowValue(index, e, "roleId") }}  >
                                                            <option value="">Choose</option>
                                                            {this.state.roles.map((role, index) => 
                                                     <option key={index} value={role.id} >{role.name}</option>
                                                    )}
                                                            </select></td>
                                                            <td><select className="form-control" value={userRoleCompany.speciality} onChange={e => { this.setRowValue(index, e, "speciality") }}  >
                                                            <option value="">Choose</option>
                                                            {this.state.specialitys.map((speciality, index) => (
                                                        <option key={index} value={speciality} >{speciality}</option>
                                                    ))}
                                                            </select></td>
                                                            <td><select className="form-control" value={userRoleCompany.activeStatus} onChange={e => { this.setRowValue(index, e, "activeStatus") }}  >
                                                                <option value="1" >Active</option>
                                                                <option value="0" >Disable</option>
                                                            </select></td>
                                                        </tr>
                                                    ))}</tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary"  onClick={() => this.saveUser()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary"  onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                            </form>
                        </div>
                    </div>
                </div></div>
        )

    }
}    