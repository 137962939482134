import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

let ws = null
export default class Presignbio extends Component {
   
    state = { fingerImage: "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=", fingerFeature:'', fingerFeatureError: '', authoCode: '', patientId: 0, billId: 0, id: 0, companys: [], patientInsurances:[], companyId: localStorage.getItem('companyId'), companyIdError: '', patientInsuranceId: '', patientInsuranceIdError: '', msg: '', error: '', readerLoaded: false
    , firstname: '', surname: '', dobFormatted: '', idNumber: '',  beneficiaryType: '', beneficiaryStatusColor: '', beneficiaryStatus: '', enrolmentColor: '', enrolment: '', isNeeded: true, qualityLevel: 0, color: "progress-bar bg-danger"}
    getPresignPi(patientId, billId)
     {
        this.setState({ fingerImage: "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=", fingerFeature: '', fingerFeatureError: '', companyId: localStorage.getItem('companyId'), companyIdError: '', patientInsuranceId: '', patientInsuranceIdError: '', authoCode: '', patientId: patientId, billId: billId, companys: [], patientInsurances: [], msg: '', error: '', firstname: '', surname: '', dobFormatted: '', idNumber: '',  beneficiaryType: '', beneficiaryStatusColor: '', beneficiaryStatus: '', enrolmentColor: '', enrolment: '' , isNeeded: true, qualityLevel: 0, color: "progress-bar bg-danger"});
        axios.get(localStorage.getItem('host') + '/interchange/getmemberlookup/' + localStorage.getItem('companyId')+'/'+patientId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ companys: res.data.companys, patientInsurances: res.data.patientInsurances });
                this.props.setLoader(false)
                this.doValidate()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
     }
     presign() {

        var error = "";
         if(this.state.companyId === "")
            {
             error = "Company cannot be empty";
            }
         if(this.state.patientInsuranceId === "" && error === "")
            {
             error = "Insurance details cannot be empty";
            }
         if((this.state.fingerImage === null || this.state.fingerImage === "") && error === "")
            {
             error = "Finger Print cannot be empty";
            }
        if(error === "")
        {
         var insuranceId= '', suffixId = '', memberNumber = ''   
         for(var i in this.state.patientInsurances)
             {
             if(Number(this.state.patientInsurances[i].id) === Number(this.state.patientInsuranceId))
                {
                 var patientInsurance = this.state.patientInsurances[i];
                 insuranceId = patientInsurance.insuranceId 
                 suffixId = patientInsurance.suffixId
                 memberNumber = patientInsurance.memberNumber
                }    
             }

            this.props.setLoader(true)
       
        axios.get(localStorage.getItem('host') + '/interchange/presignbio/'+memberNumber+'/'+suffixId+'/'+insuranceId+'/RT/'+this.state.companyId+'?fpData='+this.state.fingerFeature, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                if(res.data.success)
                   {
                    this.props.updateVisitAction(this.state.billId)
                    this.setState({ authoCode: res.data.authCode});
                    setTimeout(() => { this.closePopup() }, 5000);
                   }
                 else
                   {
                    this.setState({  error: res.data.msg })
                   }  
                
                this.props.setLoader(false)
               
               
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
        }
      else
         {
             this.setState({error: error})
         }  
    }
    closePopup() {
        if(ws !== null)
         {
            ws.close();
            ws = null;
         }
        this.setState({readerLoaded: false, isNeeded: false});
        document.getElementById('presignBioAction').style = 'display:none'
    }
    scanFingerSingle()
        {
            this.props.setLoader(true)
            this.setState({error: '', msg: ''})
            axios.get(localStorage.getItem('websocket')+'/getfingerprint')
                        .then(res => {
                        this.props.setLoader(false)
                       // var data = res.data;
                        var msg = JSON.parse(res.data);
                        /*if (data.ErrorCode === 0 || data.ErrorCode === "0") {
                            if (data !== null && data.BMPBase64.length > 0) {
                                this.setState({fingerFeature: data.ISOTemplateBase64, fingerImage: data.BMPBase64});
                                this.doValidate()
                            }

                        } else {
                            this.setState({error: this.getErrorMsg(data.ErrorCode), fingerFeature: '', fingerImage: ''});
                        }*/
                        switch (msg.type) {
                            case "status":
                             // setStatus(msg.statusMessage);
                          
                             
                              break;
                            case "data":
                            if(msg.error)
                              {
                               this.setState({error: msg.error});
                              }
                            else
                             {  
                             console.log(msg.qualityLevel + "%")
                          
                              if (msg.qualityLevel >= 65) {
                                this.setState({fingerFeature: msg.bioData, fingerImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                                this.presign()
                              } else if (msg.qualityLevel > 50) {
                                this.setState({qualityLevel: msg.qualityLevel, color: "progress-bar bg-warning"});
                              } else {            
                                this.setState({qualityLevel: msg.qualityLevel, color: "progress-bar bg-warning"});
                              }
                            }
                              break;
                              default:
                                console.log("...............")
                                break;
                            }
                    }).catch(err => {
                        this.props.setLoader(false)
                console.log(err)
                this.setState({error: err+""});
            });
        }

   /* handleReader() {
        if(this.state.isNeeded)
          {
        ws = new WebSocket(localStorage.getItem('websocket'));
        this.setState({msg:"Connecting to fingerprint reader..........", error: ""});
        
        console.log("Connecting to fingerprint reader..........");
        var timeout = setTimeout(function() {
            if (ws.readyState === 0) {
            console.log("Forcing timeout of connection attempt so we can retry");
            ws.close();
            }
        }, 10000); 
        
        ws.onopen = () => {
            clearTimeout(timeout);
            console.log("Connection to bio reader opened");
            this.setState({readerLoaded : true, msg: ""});
        };
        
        ws.onmessage = (evt) => { this.bioOnMessage(evt); };
        
        ws.onclose = () => {
            clearTimeout(timeout);
            console.log("Connection to bio reader closed");
            this.setState({readerLoaded : false});
        };
        
        ws.onerror = () => {
            clearTimeout(timeout);
            this.setState({error:"Unable to communicate with fingerprint reader. Is the fingerprint reader connected and application running?", msg: ""});
            ws = null;
            this.setState({readerLoaded : false});
            setTimeout(() => { this.handleReader() }, 3000);
        }
        
        window.onbeforeunload = (event) => {
            ws.close();
            ws = null;
            this.setState({readerLoaded: false});
        };
    }
        }
      setReaderStatus(msg)
       {
        this.setState({readerMsg: msg})
       }
       scanFingerSingle()
        {
            this.props.setLoader(true)
            this.setState({readerLoaded: true, fingerFeature: null, fingerImage: "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=" , error: "", msg: ""});
            ws.send("CAPTURE");  
             
        }   
        bioOnMessage(evt) {
            this.props.setLoader(false)
            var msg = JSON.parse(evt.data);
            switch (msg.type) {
            case "status":
             // setStatus(msg.statusMessage);
          
             
              break;
            case "data":
            if(msg.error)
              {
               this.setState({error: msg.error});
              }
            else
             {  
             console.log(msg.qualityLevel + "%")
          
              if (msg.qualityLevel >= 65) {
                this.setState({fingerFeature: msg.bioData, fingerImage: msg.imageData, qualityLevel: msg.qualityLevel, color: "progress-bar bg-success"});
                this.presign()
              } else if (msg.qualityLevel > 50) {
                this.setState({qualityLevel: msg.qualityLevel, color: "progress-bar bg-warning"});
              } else {            
                this.setState({qualityLevel: msg.qualityLevel, color: "progress-bar bg-warning"});
              }
            }
              break;
            }
          }     */
    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }
    validate() {

        var error = false;
        if (this.state.companyId === null) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === "") {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === 0) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else {
            this.setState({ companyIdError: "" })
        }
        if (this.state.patientInsuranceId === null) {
            this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" });
            error = true
        }
        else if (this.state.patientInsuranceId === "") {
            this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" });
            error = true
        }
        else if (this.state.patientInsuranceId === 0) {
            this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" });
            error = true
        }
        else {
            this.setState({ patientInsuranceIdError: "" })
        }
        if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
          //  this.handleReader();
            this.setState({ error: "", showSubmit: true, readerLoaded: true })
        }
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        // v-bind:style="'height: '+heightMemberLookupQuick+'px;max-height: '+heightMemberLookupQuick+'px;overflow: auto;overflow-x: hidden;'"
        return (<div id="presignBioAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header bg-info">Presign Action</div>
            <div className="card-body" >
                {divMsg}
                <div className="row">
                    <div className="col"><div className="input-group">
                                                <span className="input-group-text">Company</span><select className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.companyId} onChange={e =>  {this.setState({companyId: e.target.value}); this.doValidate()} }  >
                        <option value="">Choose Company</option>
                        {this.state.companys.map((item, index) => (
                            <option value={item.id} key={index} >{item.name}</option>
                        ))}
                    </select>   <div className="invalid-feedback">{this.state.companyIdError}</div>  </div></div>
                    <div className="col"><div className="input-group">
                                                <span className="input-group-text">Insurance Details</span><select className={this.state.patientInsuranceIdError !== "" ? "form-control is-invalid" : "form-control"} onChange={e =>  {this.setState({patientInsuranceId: e.target.value}); this.doValidate()} } >
                        <option value="">Choose Insurance Record</option>
                        {this.state.patientInsurances.map((item, index) => (
                            <option value={item.id} key={index} >{item.name}</option>
                        ))}
                    </select><div className="invalid-feedback">{this.state.patientInsuranceIdError}</div></div></div>
                    </div>
                    <div className="row">
                    <div className="col"></div>
                    <div className="col" align="center">{ this.state.readerLoaded && (<button onClick={()=>this.scanFingerSingle()} className="btn btn-success">Scan Finger</button>)}
                     <img style={{display :'block'}} alt="Finger Print" src={'data:image/png;base64,'+this.state.fingerImage} height="80px" />
                     <div class="progress">
  <div className={this.state.color} role="progressbar" style={{width: this.state.qualityLevel+"%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
</div>
                     <div className="input-group"><input type="hidden" className={this.state.fingerFeatureError !== "" ? "form-control is-invalid" : "form-control"} /> <div className="invalid-feedback">{this.state.fingerFeatureError}</div></div>
                 </div>
                    <div className="col"></div>
                   
                </div>
                <div className="row">
                           <div className="col"><span className="fw-bold">AuthCode:</span> {this.state.authoCode}</div>
                    </div>
                <div className="row">
                    <div className="col" align="center"><button onClick={()=>this.closePopup()} className="btn btn-primary">Close Window</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}      