import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class StockReceive extends Component {
    state = {id: 0,  number: '', issuingLocationName: '', locationId: '', locationName: '', remarks: '', stockMovementItems: [], msg: '', error: '' , showSubmit: false}
  
   
    openStockReceive(item) {
        document.getElementById('stockReceiveAction').style = 'display:block' 
        this.setState({ id: item.id, number: item.number, locationName: item.locationName, issuingLocationName: item.issuingLocationName, locationId: item.locationId,  stockMovementItems: [], msg: '', error: '', showSubmit: false });
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/stockmanagement/getstockreceiveitems/' + item.id , {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                var count = 0;
                for(var i in res.data)
                   {
                    res.data[i].receivedQuantityError  = ""; 
                    res.data[i].receivedQuantity = Number(res.data[i].issuedQuantity) === 0?0:""   
                    if(res.data[i].receivedQuantity !== "" && Number(res.data[i].receivedQuantity) >= 0)
                       {
                        count++;
                       }                    
                   }
                this.setState({  stockMovementItems: res.data , showSubmit: (count === res.data.length)});
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    save() {
        this.props.setLoader(true)
        var data = { id: this.state.id, locationId: this.state.locationId, stockMovementItems: this.state.stockMovementItems}
        axios.post(localStorage.getItem('host') + '/stockmanagement/savestockreceive', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.close()
                this.props.getList()
                this.props.setMsg(res.data)
            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    close() {
        document.getElementById('stockReceiveAction').style = 'display:none'
    } 
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    setReceivedQuantity(index, e) {
        var value = e.target.value
        this.setState({showSubmit: false})   
        var count = 0;
        var list = this.state.stockMovementItems 
      if(value !== "" && Number(value) > 0 && Number(value) <= list[index].issuedQuantity)
         {
            list[index].receivedQuantity = value
            list[index].receivedQuantityError = "";
         }
      else
         {
            list[index].receivedQuantity = ""
            list[index].receivedQuantityError = "Wrong quantity value";
         }  
         console.log(list[index].receivedQuantity)
      for(var i in list)
         {
           if(list[i].receivedQuantity !== "" && Number(list[i].receivedQuantity) >= 0)
                 {
                  count++;
                 }                    
         }   
      this.setState({stockMovementItems: list, showSubmit: (count === list.length)})    
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (
                        <div id="stockReceiveAction" className="page-popup page-popup-overlay"   >
                        <div className="card" >
                            <div className="card-header bg-info">Stock Receive Action</div>
                            <div className="card-body" >
                                {divMsg}
                        <div className="card card-green">
                            <div className="card-body">
                            <div className="row">
                                    <div className="col"><span className="fw-bold">Number:</span> {this.state.number}</div>
                                    <div className="col"><span className="fw-bold">Requesting Location:</span> {this.state.locationName}</div>
                                </div>
                            <div className="row">
                                    <div className="col"><span className="fw-bold">Issuing Location:</span> {this.state.issuingLocationName}</div>
                                    <div className="col"><span className="fw-bold">Remarks:</span> {this.state.remarks}</div>
                                </div>
                            </div></div>   
                        <br />
                        <div className="card card-green">
                            <div className="card-header">Items</div>
                            <div className="card-body">
                            
                                <table className="table table-striped" >
                                <tr>
                                                         <th>Code</th>
                                                        <th>Name</th>
                                                        <th>Category</th>
                                                        <th>Type</th>
                                                        <th>Request Quantity</th>
                                                        <th>Issued Quantity</th>
                                                        <th>Received Quantity</th>
                                                    </tr>
                                                    <tbody>                                               
                                                     {this.state.stockMovementItems.map((item, index) => (
                                                        <tr>
                                                           <td>{item.code}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.category}</td>
                                                            <td>{item.type}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{item.issuedQuantity}</td>
                                                            <td>{ item.issuedQuantity === 0 ?(<span>{item.receivedQuantity}</span>):(<div className="input-group has-validation"><input type="text" className={item.receivedQuantityError !== "" ? "form-control is-invalid" : "form-control"} value={item.receivedQuantity} onChange={e => { this.setReceivedQuantity(index, e) }} />
                                                                <div className="invalid-feedback">{item.receivedQuantityError}</div></div>)}</td>
                                                          
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                </table>
                                <div className="row">
                                    <div className="input-group">
                                        <input type="hidden" className={this.state.stockReceiveItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                        <div className="invalid-feedback">{this.state.stockReceiveItemsError}</div> </div></div>  </div></div>
                        <div className="row">
                            <div className="col" align="right">{this.state.showSubmit === true && (<input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" />)}</div>
                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.close()} value="Cancel" /></div>
                        </div>
                        </div>
                </div ></div >
        )

    }
}    