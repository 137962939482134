import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


export default class SmsTemplate extends Component {
    state = { id: 0, template: '', smsType: '', companyId: 0, smsTypes: [], companys: [], activeStatus: 1, list: []}
    componentDidMount() {
        this.getList()
    }
    action(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, template: '', smsType: '', companyId: 0, smsTypes: [], companys: [], activeStatus: 1})
            axios.get(localStorage.getItem('host') + '/master/smstemplateaction/' + id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                     this.setState({ template: res.data.template, id: res.data.id, smsType: res.data.smsType, companyId: res.data.companyId, smsTypes: res.data.smsTypes, companys: res.data.companys, activeStatus: res.data.activeStatus});
                     }, err => {
                    console.log(".....")
                }
            )
        document.querySelector('#action').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
    }
    save() {
        var data = { id: this.state.id, activeStatus: this.state.activeStatus, smsType: this.state.smsType, companyId: this.state.companyId, template: this.state.template}
        axios.post(localStorage.getItem('host') + '/master/smstemplateform', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/master/smstemplate/'+localStorage.getItem('companyId') , {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit" id={res.data[index].id} onClick={e => this.action(e)}><i className="bi bi-pencil-square"></i></span>
                    console.log("..... smsType"+res.data[index].smsType)
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }


    render() {
        const data = {
            columns: [
                
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },               
                {
                    label: 'Sms Type',
                    field: 'smsType',
                    sort: 'asc',
                    width: 150
                },{
                    label: 'Template',
                    field: 'template',
                    sort: 'asc',
                    width: 150
                }, 
                {
                    label: 'Action Status',
                    field: 'stringStatus',
                    sort: 'asc',
                    width: 150
                }
            ],
            rows: this.state.list
        };
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                    <button className="btn btn-primary" onClick={() => this.showForm(0)} ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                        <MDBDataTable
                            button="true"
                            striped
                            bordered
                            small
                            data={data}
                        />
                        <div className="page-popup page-popup-overlay" id="action">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Action 
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Name</span>
                                                <textarea className="form-control" value={this.state.template} onChange={e => { this.setState({ template: e.target.value }) }} ></textarea></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Active Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e =>  this.setState({ activeStatus: e.target.value })}  >
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Sms Type</span>
                                                <select className="form-control" value={this.state.smsType} onChange={e =>  this.setState({ smsType: e.target.value })}  >
                                                <option value="0">Choose Type</option>
                                                    {this.state.smsTypes.map((smsType, index) => (
                                                        <option key={index} value={smsType} >{smsType}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                            <div className="col">
                                             <div className="input-group"><span className="input-group-text">Company</span>
                                            <select className="form-control" value={this.state.companyId} onChange={e =>  this.setState({ companyId: e.target.value })}  >
                                            <option value="0">Choose Company</option>
                                                    {this.state.companys.map((company, index) => (
                                                        <option key={index} value={company.id} >{company.name}</option>
                                                    ))}
                                                    </select></div>
                                                    </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                        </div>
                    </div>
                </div ></div >
        )

    }
}    