import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const now = new Date();
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}
export default class CalendarReview extends Component {
  
     state={number: '', id : '', reason: '', titles:[], timeTables: [], dates: [], clusterCompanys:[], startDate: now.getFullYear() + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()), companyId: localStorage.getItem('companyId')}
    calendar()
    {    
     document.getElementById('calendarReviewAction').style = 'display:block'
     this.props.setLoader(true)
     this.getTimeTableListDelayed()
    }  
    getTimeTableList() {
        this.setState({ list: [], msg: '' });
        axios.get(localStorage.getItem('host') + '/patient/getcalendar/'+localStorage.getItem('companyId')+'/'+this.state.startDate+"/0",  {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ titles: res.data.titles, dates: res.data.dates, timeTables: res.data.timeTables });
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    closePopup() {
        document.getElementById('calendarReviewAction').style = 'display:none'
    }
    getTimeTableListDelayed(){
        this.props.setLoader(true)
        setTimeout(() => {
            this.getTimeTableList();
        }, 1000);
    }
    cancelReminder(number, appointmentId) {
        console.log("...... cancel entry")
        this.setState({number: number, id: appointmentId})
        document.getElementById('cancelReminderAction').style = 'display:block' 
    }
    sendCancelAppointment() {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/cancelschedule/' + this.state.id, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ msg: res.data });
                this.props.setLoader(true)
                this.closeCancelPopup()
                this.getTimeTableList()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    closeCancelPopup() {
        document.getElementById('cancelReminderAction').style = 'display:none'
    } 
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
    openReminderEntry(companyId, schuduleTimeId, scheduleDate)
     {
      this.setState({companyId: companyId, schuduleTimeId: schuduleTimeId, scheduleDate: scheduleDate})
      document.getElementById('addReminderAction').style = 'display:block'
     } 
     closeReminderPopup() {
         document.getElementById('addReminderAction').style = 'display:none'
     }  
     sendReminder()
     {
        this.props.setLoader(true)
        var data = { companyId: this.state.companyId, schuduleTimeId: this.state.schuduleTimeId, scheduleDate: this.state.scheduleDate, remarks: this.state.remarks}
        axios.post(localStorage.getItem('host') + '/patientmanagement/remindersave',data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                  this.setState({ msg: res.data });  
                  this.closeReminderPopup();
                  this.getTimeTableList()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
     }
    render() {
        return (<div id="calendarReviewAction" className="page-popup page-popup-overlay" >
        <div className="card" >
            <div className="card-header bg-info">Calendar Action</div>
            <div className="card-body" >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                             <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });  this.getTimeTableListDelayed()} } dateFormat="yyyy-MM-dd" /></div></div>
                                             <div className="col"><b>Note</b> Double click to choose a date and time for your review</div>
                                             </div></div></div>
                            </div>
                        </div>
                 <table className="table table-striped" >
                    <thead>
                        <tr>
                            <th>Time</th>
                            {this.state.titles.map((item, index) => (
                            <th key={index}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                {this.state.timeTables.map((item, index) => (
                        <tr key={index}>
                            <th>{item.time}</th>
                           {item.entry1 === ""?<td className="link" onDoubleClick={()=> this.props.setCalenderReview( this.state.dates[0], item.timeId)}></td> :<td className=" bg-info"><span dangerouslySetInnerHTML={{ __html: item.entry1 }}></span></td>}
                           {item.entry2 === ""?<td className="link" style={{ backgroundColor: '#cccccc' }}  onDoubleClick={()=> this.props.setCalenderReview(this.state.dates[1], item.timeId)}></td> : <td className=" bg-info"><span dangerouslySetInnerHTML={{ __html: item.entry2 }}></span></td>}
                           {item.entry3 === ""?<td className="link" onDoubleClick={()=> this.props.setCalenderReview(this.state.dates[2], item.timeId)}></td> : <td className=" bg-info"><span dangerouslySetInnerHTML={{ __html: item.entry3 }}></span></td>}
                           {item.entry4 === ""?<td className="link" style={{ backgroundColor: '#cccccc' }}  onDoubleClick={()=> this.props.setCalenderReview(this.state.dates[3], item.timeId)}></td> : <td className=" bg-info"><span dangerouslySetInnerHTML={{ __html: item.entry4 }}></span></td>}
                           {item.entry5 === ""?<td className="link" onDoubleClick={()=> this.props.setCalenderReview(this.state.dates[4], item.timeId)}></td> : <td className=" bg-info"><span dangerouslySetInnerHTML={{ __html: item.entry5 }}></span></td>}
                           {item.entry6 === ""?<td className="link" style={{ backgroundColor: '#cccccc' }}  onDoubleClick={()=> this.props.setCalenderReview(this.state.dates[5], item.timeId)}></td> : <td className=" bg-info"><span dangerouslySetInnerHTML={{ __html: item.entry6 }}></span></td>}
                           {item.entry7 === ""?<td className="link" onDoubleClick={()=> this.props.setCalenderReview(this.state.dates[6], item.timeId)}></td> : <td className=" bg-info"><span dangerouslySetInnerHTML={{ __html: item.entry7 }}></span></td>}
                        </tr>
                         ))}
                    </tbody>
                 </table>
                <div className="row">
                <div className="col" align="center" ><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div> 
                </div>
            </div>
        </div>
    </div>)

    }
}    