import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class PrintOut extends Component {
    state = { printIndex: [] , printoutDtos: [], url: false }
     toggleTabs(index) {
        let list = this.state.printIndex.slice();
        for(var i  in list)
          {
            list[i] = Number(i) === Number(index)?true:false;  
            //console.log((i == index?true:false)+'...'+i+'....'+index) 
          }
        this.setState({ printIndex: list })
        
    }
    getPrintOut(id, type, printoutDtos, companyId) {
        this.setState({ printIndex: [] , printoutDtos: [] , url: false})
        if(id > 0)
         {
            axios.get(localStorage.getItem('host') + '/pdf/getprintout/'+id+'/'+type+'/'+companyId+'/'+localStorage.getItem('speciality'), {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    let list = [];
                    for(var i  in res.data.slice())
                    {
                      list[i] = false;   
                    }
                    this.setState({ printIndex: list, printoutDtos: res.data })
                    this.props.setLoader(false)
                }, err => {
                     this.setState({error: err+""})
                    this.props.setLoader(false)
                }
            )
         }
       else
        {  
        let list = [];
        for(var i  in printoutDtos.slice())
        {
            list[i] = false;   
        }
        this.setState({ printIndex: list, printoutDtos: printoutDtos })
        console.log("......."+JSON.stringify(list))
        this.props.setLoader(false)
        }
    }
    getPrintOutUrl(id, type, printoutDtos, companyId) {
        this.setState({ printIndex: [] , printoutDtos: [], url: true })
        if(id > 0)
         {
            axios.get(localStorage.getItem('host') + '/pdf/getprintouturl/'+id+'/'+type+'/'+companyId+'/'+localStorage.getItem('speciality'), {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    let list = [];
                    for(var i  in res.data.slice())
                    {
                      list[i] = false;   
                    }
                    this.setState({ printIndex: list, printoutDtos: res.data })
                    this.props.setLoader(false)
                }, err => {
                     this.setState({error: err+""})
                    this.props.setLoader(false)
                }
            )
         }
       else
        {  
        let list = [];
        for(var i  in printoutDtos.slice())
        {
            list[i] = false;   
        }
        this.setState({ printIndex: list, printoutDtos: printoutDtos })
        console.log("......."+JSON.stringify(list))
        this.props.setLoader(false)
        }
    }
    closePopup() {
        document.getElementById('printOutAction').style = 'display:none'
    }
    render() {
        return (<div id="printOutAction" className="page-popup page-popup-overlay" >
        <div className="card" >
            <div className="card-header bg-info">PrintOut</div>
            <div className="card-body" >
                <div className="row"> 
                    <div className="col-lg-12">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">	
                        {this.state.printoutDtos.map((item, index) => (
                            <li className="nav-item" key={index}><span className={this.state.printIndex[index] ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs(index) }}>{ item.name }</span>	  </li>
                            ))}	
                        </ul>	
                         <div >
                        <div className="tab-content" id="pills-tabContent">	  
                        {this.state.printoutDtos.map((item, index) => (
                            <div  key={index} className={this.state.printIndex[index] ? "tab-pane fade show active" : "tab-pane fade"}>
                                { item.name === "Signed Claim Form" && (<a href={localStorage.getItem("host")+item.url} target="_blank">Link</a>)}
                         {this.state.url === false ?(   <iframe title="PDF View" src={'data:application/pdf;base64,'+item.data} type="application/pdf" style={{width: 100+'%', height: 500+'px'}}></iframe>):(<a href={localStorage.getItem("host")+'/pdf/view/'+item.data} target="_blank">Link</a>)}
   
                                 </div>	
                                    ))}	
                        </div></div></div>
                </div>
                <div className="row">
                <div className="col-lg-12" align="center" ><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div> 
                </div>
            </div>
        </div>
    </div>)

    }
}    