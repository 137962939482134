import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'


export default class Price extends Component {
    state = { loader: false,institutionId: 0, billCategory: 'CASH', institutions: [], list: []}
   
    getInstitutions(billCategory) {
        this.setState({ institutions: [] , institutionId: 0})
        if (billCategory === "INSURANCE" || billCategory === "SPECIAL_CONTRACT") {
            this.setLoader(true)
            axios.get(localStorage.getItem('host') + '/master/institutionlist/' + billCategory+'/'+localStorage.getItem('companyId') , {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ institutions: res.data});
                    this.setLoader(false)
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#action').style = 'display:block'
    }
    save() {
        this.setLoader(true)
         axios.post(localStorage.getItem('host') + '/master/saveprice', this.state.list, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data,list: [] });
                this.setLoader(false)
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    setRowValue(index, e, name) {
        let list = this.state.list.slice();
        switch (name) {
         case "markUp":
                        list[index].markUp = e.target.value;
                        break;
         case "tariffCode":
                        list[index].tariffCode = e.target.value;
                        break;
         case "sellingPrice":
                        list[index].sellingPrice = e.target.value;
                        break;
         case "allowEdit":
                        list[index].allowEdit = e.target.value;
                        break;
         case "cashOnly":
                        list[index].cashOnly = e.target.value;
                        break;
         case "ratePrice":
                        list[index].ratePrice = e.target.value;
                        break;
         case "activeStatus":
                        list[index].activeStatus = e.target.value;
                        break;
               default:
                       console.log("default")
                        break;
                      }       
        this.setState({ list: list })
      }
    getList() {
        this.setState({ list: [] });
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/master/price/' + localStorage.getItem('companyId')+'/'+this.state.billCategory+'/'+this.state.institutionId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
            }
        )
    }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }


    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        {divMsg}
                        <div className="card card-green">
                                    <div className="card-header">
                                        Search
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Bill Category</span>
                                                <select className="form-control" value={this.state.billCategory} onChange={e => {this.setState({ billCategory: e.target.value }); this.getInstitutions(e.target.value)}}  >
                                                    <option value="CASH">CASH</option>
                                                    <option value="INSURANCE">INSURANCE</option>
                                                    <option value="SPECIAL_CONTRACT">SPECIAL CONTRACT</option>
                                                </select></div>
                                            </div>
                                            <div className="col">
                                               { this.state.billCategory !== 'CASH' && <div className="input-group"><span className="input-group-text">Institutions</span>
                                                <select className="form-control" value={this.state.institutionId} onChange={e => this.setState({ institutionId: e.target.value })}  >
                                                    <option value="0">Choose Institution</option>
                                                    {this.state.institutions.map((institution, index) => (
                                                        <option key={index} value={institution.id} >{institution.name}</option>
                                                    ))}
                                                </select></div>}
                                            </div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.getList()} value="Search" /></div>
                                        </div>
                                    </div></div>
                                    <br  />
                                        <div className="card">
                                            <div className="card-header">Service Cost</div>
                                            <div className="card-body">    
                                            <div className="table-responsive-lg"> 
                                            <table className="table table-striped" >
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Pack Size</th>
                                                    <th>Uom</th>
                                                    <th>Category</th>
                                                    <th>Type</th>
                                                    <th>Buying Price</th>
                                                    <th>Mark-up</th>
                                                    <th>Selling price</th>
                                                    <th>Dispensing Fee</th>
                                                    <th>Tariff Code</th>
                                                    <th>Allow Edit</th>
                                                    <th>Rate</th>
                                                    <th>Active Status</th>
                                                </tr><tbody>
                                                {this.state.list.map((l, index) => (
                                                    <tr>
                                                        <td>{l.name}</td>
                                                        <td>{l.packSize}</td>
                                                        <td>{l.uom}</td>
                                                        <td>{l.form}</td>
                                                        <td>{l.type}</td>
                                                        <td>{l.buyingPrice}</td>
                                                        <td><input type="text" className="form-control" value={l.markUp} onChange={e => { this.setRowValue(index, e, "markUp") }} /></td>
                                                        <td><input type="text" className="form-control" value={l.sellingPrice} onChange={e => { this.setRowValue(index, e, "sellingPrice") }} /></td>
                                                        <td><input type="text" className="form-control" value={l.dispensingFee} onChange={e => { this.setRowValue(index, e, "dispensingFee") }} /></td>
                                                        <td><input type="text" className="form-control" value={l.tariffCode} onChange={e => { this.setRowValue(index, e, "tariffCode") }} /></td>
                                                        <td><select className="form-control" value={l.allowEdit} onChange={e => { this.setRowValue(index, e, "allowEdit") }}  >
                                                        <option value="1" >Yes</option>
                                                            <option value="0" >No</option>
                                                        </select></td>
                                                        <td><select className="form-control" value={l.ratePrice} onChange={e => { this.setRowValue(index, e, "ratePrice") }}  >
                                                        <option value="1" >Yes</option>
                                                            <option value="0" >No</option>
                                                        </select></td>
                                                        <td><select className="form-control" value={l.activeStatus} onChange={e => { this.setRowValue(index, e, "activeStatus") }}  >
                                                        <option value="1" >Yes</option>
                                                            <option value="0" >No</option>
                                                        </select></td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table> </div>  </div></div>
                                        <div className="row">
                                            <div className="col" align="center"><input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" /></div>
                                            
                                        </div>
                        {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    