import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const now = new Date();
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}
export default class CalendarNurseView extends Component {
  
     state={number: '', id : '', reason: '', titles:[], timeTables: [], dates: [], clusterCompanys:[], startDate: now.getFullYear() + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()), companyId: localStorage.getItem('companyId')}
    calendar()
    {    
     document.getElementById('calendarNurseViewAction').style = 'display:block'
     this.props.setLoader(true)
     axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/' + localStorage.getItem('companyId'), {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.setState({ clusterCompanys: res.data });
            this.getTimeTableList()
        }, err => {
            this.setState({ error: err + "" })
            this.props.setLoader(false)
        }
    )
    }  
    getTimeTableList() {
        this.setState({ list: [], msg: '' });
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/getnursecalendar/'+this.state.companyId+'/'+this.state.startDate,  {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ titles: res.data.titles, dates: res.data.dates, timeTables: res.data.timeTables });
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    closePopup() {
        document.getElementById('calendarNurseViewAction').style = 'display:none'
    }
    cancelReminder(number, appointmentId) {
        console.log("...... cancel entry")
        this.setState({number: number, id: appointmentId})
        document.getElementById('cancelReminderAction').style = 'display:block' 
    }
    sendCancelAppointment() {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/cancelschedule/' + this.state.id, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ msg: res.data });
                this.props.setLoader(true)
                this.closeCancelPopup()
                this.getTimeTableList()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    closeCancelPopup() {
        document.getElementById('cancelReminderAction').style = 'display:none'
    } 
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
    openReminderEntry(companyId, schuduleTimeId, scheduleDate)
     {
      this.setState({companyId: companyId, schuduleTimeId: schuduleTimeId, scheduleDate: scheduleDate})
      document.getElementById('addNurseReminderAction').style = 'display:block'
     } 
     closeReminderPopup() {
         document.getElementById('addNurseReminderAction').style = 'display:none'
     }  
     sendReminder()
     {
        this.props.setLoader(true)
        var data = { companyId: this.state.companyId, schuduleTimeId: this.state.schuduleTimeId, scheduleDate: this.state.scheduleDate, remarks: this.state.remarks}
        axios.post(localStorage.getItem('host') + '/patientmanagement/remindersave',data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                  this.setState({ msg: res.data });  
                  this.closeReminderPopup();
                  this.getTimeTableList()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
     }
    render() {
        return (<div id="calendarNurseViewAction" className="page-popup page-popup-overlay" >
        <div className="card" >
            <div className="card-header bg-info">Calendar Action</div>
            <div className="card-body" >
                    <div id="cancelReminderAction" className="page-popup page-popup-overlay" >
                        <div className="card" >
                            <div className="card-header bg-info">Cancel Reminder Action</div>
                            <div className="card-body" >
                            <p>Are you sure you want to cancel ?</p> 
                                <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.sendCancelAppointment()} className="btn btn-primary">Yes</button></div> 
                                <div className="col" align="center" ><button onClick={() => this.closeCancelPopup()} className="btn btn-primary">No</button></div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="addNurseReminderAction" className="page-popup page-popup-overlay" >
                        <div className="card" >
                            <div className="card-header bg-info">Reminder Action</div>
                            <div className="card-body" >
                            <div className="row">
                    <div className="col"><div className="input-group">
                        <span className="input-group-text">Detail</span>
                        <textarea className="form-control" value={this.state.remarks} onChange={e => { this.setState({ remarks: e.target.value }); this.doValidate()} } ></textarea>
                    </div></div>
                </div>
                                <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.sendReminder()} className="btn btn-primary">Save</button></div> 
                                <div className="col" align="center" ><button onClick={() => this.closeReminderPopup()} className="btn btn-primary">Close</button></div> 
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                             <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                                             <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                                {this.state.clusterCompanys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                            <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getTimeTableList()} value="Search" /></div>
                                        </div></div></div>
                            </div>
                        </div>
                 <table className="table table-striped" >
                    <thead>
                        <tr>
                            <th>Time</th>
                            {this.state.titles.map((item, index) => (
                            <th key={index}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                {this.state.timeTables.map((item, index) => (
                        <tr key={index}>
                            <th>{item.time}</th>
                           {item.entry1 === ""?<td className="link" onClick={()=> this.openReminderEntry(this.state.companyId, item.timeId, this.state.dates[0])}></td> :<td className=" bg-info">{item.entry1} <span className="link-red" onClick={() => this.cancelReminder(item.entry1, item.entryId1)} title="Cancel Appointment"><i className="bi bi-x-square-fill"></i></span></td>}
                           {item.entry2 === ""?<td className="link" onClick={()=> this.openReminderEntry(this.state.companyId, item.timeId, this.state.dates[1])}></td> : <td className=" bg-info">{item.entry2} <span className="link-red" onClick={() => this.cancelReminder(item.entry2, item.entryId2)} title="Cancel Appointment"><i className="bi bi-x-square-fill"></i></span></td>}
                           {item.entry3 === ""?<td className="link" onClick={()=> this.openReminderEntry(this.state.companyId, item.timeId, this.state.dates[2])}></td> : <td className=" bg-info">{item.entry3} <span className="link-red" onClick={() => this.cancelReminder(item.entry3, item.entryId3)} title="Cancel Appointment"><i className="bi bi-x-square-fill"></i></span></td>}
                           {item.entry4 === ""?<td className="link" onClick={()=> this.openReminderEntry(this.state.companyId, item.timeId, this.state.dates[3])}></td> : <td className=" bg-info">{item.entry4} <span className="link-red" onClick={() => this.cancelReminder(item.entry4, item.entryId4)} title="Cancel Appointment"><i className="bi bi-x-square-fill"></i></span></td>}
                           {item.entry5 === ""?<td className="link" onClick={()=> this.openReminderEntry(this.state.companyId, item.timeId, this.state.dates[4])}></td> : <td className=" bg-info">{item.entry5} <span className="link-red" onClick={() => this.cancelReminder(item.entry5, item.entryId5)} title="Cancel Appointment"><i className="bi bi-x-square-fill"></i></span></td>}
                           {item.entry6 === ""?<td className="link" onClick={()=> this.openReminderEntry(this.state.companyId, item.timeId, this.state.dates[5])}></td> : <td className=" bg-info">{item.entry6} <span className="link-red" onClick={() => this.cancelReminder(item.entry6, item.entryId6)} title="Cancel Appointment"><i className="bi bi-x-square-fill"></i></span></td>}
                           {item.entry7 === ""?<td className="link" onClick={()=> this.openReminderEntry(this.state.companyId, item.timeId, this.state.dates[6])}></td> : <td className=" bg-info">{item.entry7} <span className="link-red" onClick={() => this.cancelReminder(item.entry7, item.entryId7)} title="Cancel Appointment"><i className="bi bi-x-square-fill"></i></span></td>}
                        </tr>
                         ))}
                    </tbody>
                 </table>
                <div className="row">
                <div className="col" align="center" ><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div> 
                </div>
            </div>
        </div>
    </div>)

    }
}    