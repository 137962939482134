import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import RisReport from './risreport'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class RisReportList extends Component {
    constructor(props) {
        super(props);
    this.setLoader = this.setLoader.bind(this)
    this.getPrintOut = this.getPrintOut.bind(this)
    this.getList = this.getList.bind(this)
    this.printOutRef = React.createRef()
    this.risReportRef = React.createRef()
    }
    state = { id: 0, firstname: '', surname: '', startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD , companyId: localStorage.getItem('companyId'), list: [], clusterCompanys: [], departments: [], departmentId:0 }
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/is/getlistparams/' + localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                var departments = [];
                var departmentId = 0;
                for(var i in res.data)
                  { 
                   if(res.data[i].id === Number(localStorage.getItem('companyId')))
                      {
                        departments = res.data[i].departments
                        departmentId = departments[0].id;
                      }
                  }
                this.setState({clusterCompanys: res.data, departments: departments,  departmentId: departmentId });
                this.getList()
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        this.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname, dateRange: this.state.startDate+","+this.state.endDate, companyId:  localStorage.getItem('companyId'), departmentId: this.state.departmentId , risStatus: 'PENDING'}
        axios.post(localStorage.getItem('host') + '/is/rislist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    } 
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    openRisReportAction(ris)
    {
    this.setState({loader: true})    
    this.risReportRef.current.openRisReportAction(ris)
    document.getElementById('risReport').style = 'display:block'   
    }
     processDate(date)
       {
         const now = new Date(date);
         const todayY = now.getFullYear();
         const todayM = checkZero(now.getMonth() + 1);
         const todayD = checkZero(now.getDate());
         var date1 = todayY + '-' + todayM + '-' + todayD;
         console.log(date1)
        return date1;
       }
    getDepartments(id)  
     {
        var departments = [];
        var departmentId = 0;
        for(var i in this.state.clusterCompanys)
          { 
           if(this.state.clusterCompanys[i].id === Number(id))
              {
                departments = this.state.clusterCompanys[i].departments
                departmentId = departments[0].id;
              }
          }
        this.setState({ departments: departments,  departmentId: departmentId });

     } 
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                       {divMsg}
                     
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname</span><input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">End Date</span> <DatePicker className="form-control" selected={new Date(this.state.endDate)}  minDate={new Date(this.state.startDate)} maxDate={new Date()}  onChange={d => { this.setState({ endDate: this.processDate(d) }); } } dateFormat="yyyy-MM-dd" /></div></div>
                                                               <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getList()} value="Search" /></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                            <table className="table table-striped table-hover">
                                <tr>
                                    <th>Action</th>
                                    <th>Number</th>
                                    <th>Name</th>
                                    <th>Patient Name</th>
                                    <th>Date of Birth</th>
                                    <th>Gender</th>
                                    <th>Date</th>
                                    <th>Reported By</th>
                                    <th>Rejected By</th>
                                    <th>Reject Reason</th>
                                    <th>Status</th>
                                </tr>
                                <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr key={index} style={{backgroundColor:item.color}}>
                                        <td >
                                       <span className="link" onClick={() => this.openRisReportAction(item)} title="Edit"><i className="bi bi-pencil-square"></i></span>
                                            {item.risStatus === "REPORTED" && (<span className="link" onClick={() => this.getPrintOut(item.id, 'RISREPORT', [], item.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>)}
                                           </td>
                                        <td>{item.number}</td>
                                        <td>{item.name}</td>
                                        <td>{item.fullname}</td>
                                        <td>{item.dateOfBirth}</td>
                                        <td>{item.gender}</td>
                                        <td>{item.date}</td>
                                        <td>{item.reportedBy}</td>
                                        <td>{item.rejectedBy}</td>
                                        <td>{item.rejectReason}</td>
                                        <td>{item.risStatus}</td>
                                       </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <RisReport ref={this.risReportRef} setLoader={this.setLoader} getPrintOut={this.getPrintOut} getList={this.getList} />
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} /> 
                       {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    