import React, { Component } from "react";
import axios from "axios";
import '../../App.css';



export default class WhatsappAction extends Component {
   state = {error: '', message: '', visitId: 0, whatsappNumber: '', contactNumber: '', companyId: 0}
    openWhatsappAction(visitId) {
        this.setState({error: '', message: '', visitId: visitId, fullname: '', whatsappNumber: '' , contactNumber: '', companyId: 0})
        axios.get(localStorage.getItem('host') + '/interchange/getwhatsappdetails/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                var message = 'Good day. Results for '+res.data.fullname+' are out may you please make an appointment through our reception on '+res.data.contactNumber+' for review. Thank you.'
                this.setState({ message: message, contactNumber: res.data.contactNumber, whatsappNumber: res.data.whatsappNumber, companyId: res.data.companyId, fullname: res.data.fullname});
                this.props.setLoader(false)

            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('whatsappAction').style = 'display:none'
    }

    save() {
        if(!(/^(2637)\d{8}$/.test(this.state.whatsappNumber)) && !(/^(2609)\d{8}$/.test(this.state.mobileNumber)) && !(/^(2607)\d{8}$/.test(this.state.mobileNumber)))
            {
            this.setState({error: "Whatsapp should be in the format 2637XXXXXXXX or 2607XXXXXXXX or 2609XXXXXXXX"})
            }
        else
            {    
        this.props.setLoader(true)
        var data = {companyId: this.state.companyId, whatsappNumber: this.state.whatsappNumber, fullname: this.state.fullname, contactNumber: this.state.contactNumber}
        axios.post(localStorage.getItem('host') + '/interchange/whatsappresult', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {this.props.setLoader(false)
                this.setState({ msg: res.data });
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                this.setState({ error: err+""})
                this.props.setLoader(false)
            }
        )
        }
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (
            <div id="whatsappAction" className="page-popup page-popup-overlay"   >
                <div className="card" >
                    <div className="card-header bg-info">Whatsapp Action</div>
                    <div className="card-body" >
                        {divMsg}
                        <div className="row">
                            <div className="col"><div className="input-group"><span className="input-group-text">Whatsapp Number</span> <input type="text"  value={this.state.whatsappNumber} onChange={e => this.setState({whatsappNumber: e.target.value})} className="form-control" /></div></div>
                            <div className="col">{this.state.message}</div>
                        </div>
                        <div className="row">
                            <div className="col" align="right"> <input type="submit" value="Send" onClick={() => this.save()} className="btn btn-primary" /></div>
                            <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Cancel</button></div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}    