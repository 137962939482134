import React, { Component } from "react";
import axios from "axios";
import '../../App.css';



export default class ResultAction extends Component {
     state = { fileData: '', showSubmit: false, visitId: 0, firstname: '', surname: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', list: [] , file: null, resultText: '' }
    getResultAction(visitId) {
        this.setState({fileData: '', visitId: visitId, firstname: '', surname: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', list: [] , file: null, resultText: '' })
        axios.get(localStorage.getItem('host') + '/file/getresult/' + visitId , {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, list: res.data.results });
                this.props.setLoader(false)

            }, err => {
                console.log(".....")
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('resultAction').style = 'display:none'  
        this.props.updateAfterSave(this.state.visitId, this.state.list)
    }

    doValidate()
    {
      setTimeout(() => {
          this.validate();
      }, 1000);   
    }
    validate()
      {
        this.setState({showSubmit: false}) 
        var error = false;
        var error1 = false;
        if (this.state.file === null) {
            error = true
        }
        else if (this.state.file === "") {
            error = true
        }

        
        if (this.state.resultText === null) {
            error1 = true
        }
        else if (this.state.resultText === "") {
            error1 = true
        }
        if(error === false || error1 === false)
           {
            this.setState({showSubmit: true}) 
           }  
      }
    save() {
        this.props.setLoader(true)
        var data = new FormData();
        if(this.state.file !== null)
          {
           data.append("file", this.state.file)
          }
        data.append("remarks", this.state.resultText)
        data.append("visitId", this.state.visitId)
        axios.post(localStorage.getItem('host') + '/file/resultsave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'multipart/form-data'

            }
        }).then(
            res => {
                this.props.setLoader(false)
                console.log(res.data)
                this.setState({ list: res.data, msg: 'Successfully saved Result', resultText: '' , file: null});
                this.props.updateAfterSave(this.state.visitId, res.data)
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patient/nurselist/'+this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    deleteResult(id, fileStorageId, fileExtension)
     {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/delete/'+id+'/'+fileStorageId+'/'+(fileExtension === ""?".pdf":fileExtension)+'/'+this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data , msg: 'Successfully deleted Result'});
                this.props.updateAfterSave(this.state.visitId, res.data)
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err+""})
                this.props.setLoader(false)
            }
        )
     }
     showFile(id, fileExtension)
      {
        this.setState({ fileData: '' });  
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/getfile/'+id+'/'+fileExtension, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ fileData: res.data });
                document.getElementById('showFile').style = 'display:block'  
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err+""})
                this.props.setLoader(false)
            }
        )

      }
    fileClosePopup()
      {
        this.setState({ fileData: '' });  
        document.getElementById('showFile').style = 'display:none'  
      }   
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div id="resultAction" className="page-popup page-popup-overlay"   >
                <div className="card" >
                    <div className="card-header bg-info">Result Action</div>
                    <div className="card-body" >
                        {divMsg}
                        <div id="showFile" className="page-popup page-popup-overlay" >
                <div className="card" >
            <div className="card-header  bg-info">File View</div>
            <div className="card-body" >
            <iframe title="PDF File View" src={'data:application/pdf;base64,'+this.state.fileData} type="application/pdf" style={{width: 100+'%', height: 500+'px'}}></iframe>   
            <div className="row">
                <div className="col" align="center" ><button onClick={() => this.fileClosePopup()} className="btn btn-primary">Close</button></div> 
                </div>
                    </div>
                    </div>
                 </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-success">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                            <div className="col"><span className="fw-bold">ID No:</span> {this.state.idNumber}</div>
                                            <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                            <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                            <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                        </div>
                                    </div></div>
                                    <br  />
                                <div className="card card-blue" >
                                    <div className="card-header  text-dark">
                                        Results
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Upload Result [15MB]</span> <input type="file"  accept=".pdf" onChange={e => {this.setState({file :e.target.files[0]}); this.doValidate();}} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Results Text </span> <textarea name="resultText" onChange={e => {this.setState({resultText :e.target.value}); this.doValidate();}} className="form-control" value={this.state.resultText} ></textarea></div></div>
                                        </div>
                                        <div className="row">
                                            {this.state.showSubmit === true?(<div className="col" align="center"><input type="button" onClick={() => this.save()} value="Save" className="btn btn-success" /></div>):(<div></div>)}
                                        </div>
                                        <br />
                                        <table className="table table-striped">
                                            <tr>
                                                <th>Action</th>
                                                <th>File</th>
                                                <th>Remarks</th>
                                            </tr>
                                            <tbody>
                                                {this.state.list.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><span className="link-red" onClick={() => this.deleteResult(item.id, item.fileStorageId, item.fileExtension)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                        {item.fileStorageId > 0 ? (<td><span className="link" onClick={() => this.showFile(item.fileStorageId, item.fileExtension)}>{item.fileName}</span></td>) : (<td></td>)}
                                                        <td>{item.remarks}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div></div>
                                <div className="row">
                                    <div className="col" align="center"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                                </div></div >
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}    