import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import DispenseList from "./dispenselist";
import Receipt from "../patient/receipt";
import Select from 'react-select';
import PatientAction from '../patient/patientaction'
import PatientList from './patientlist'
import DuplicateRecords from "../patient/duplicaterecords";
import MemberLookup from "../patient/memberlookup";
import MemberLookupQuick from "../patient/memberlookupquick";
import Presignbio from "../patient/presignbio";
import Enrol from "../patient/enrol";
import ClaimResponse from "../patient/claimresponse";
import ViewProducts from "./viewproducts";
import ViewCollected from "./viewcollected";
import PrescriptionList from "./prescriptionlist";

function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
export default class Dispense extends Component {
    state = { loader: false, duplicateCount: 0,name: '', rate: 1, id : 0, visitId: 0, companyId: localStorage.getItem('companyId'), companys: [], currencyName: '', locationId: 0, locations: [], treatments:[], productOptions: [], productItemLocationId:'', status: 'CREATED', productItems: [], dispenseItems: [], dispenseItemsError: '', billCategorys:[], referringConsultants: [], billId: 0
    , firstnameError: '', firstname: '', surnameError: '', surname: '', dateOfBirthError: '', dateOfBirth: '', companyIdError: '', billCategoryError: '', billCategory: '', patientInsuranceIdError: '', patientInsuranceId: 0, specialContractIdError: '', specialContractId: 0,  currencyIdError: '', currencyId: 0, referringConsultantIdError: '', referringConsultantId: 0
    , quantity: '' , dosage: '', repeats: 0, patientInsurances: [], institutions: [], currencys: [],institutionId: 0, showSearch: false
    , cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0
    , cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0 
    , cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0
    ,  health263Claim: false, canSendToHealth263: false, canSendToHealth263WithOverride: false, canCancelHealth263WithBio: false, canCancelHealth263: false, patientEnrolled: false, health263MessageShow: false, combinedMessages: ''
    , cipherStrings: []}
    constructor(props) {
        super(props);
    this.setLoader = this.setLoader.bind(this)
    this.getPrintOut = this.getPrintOut.bind(this)
    this.getDispense = this.getDispense.bind(this)
    this.openPatient = this.openPatient.bind(this)
    this.openReceiptAction = this.openReceiptAction.bind(this)
    this.setSellPatient = this.setSellPatient.bind(this)
    this.viewCollected = this.viewCollected.bind(this)
    this.printOutRef = React.createRef()
    this.dispenseListRef = React.createRef()
    this.patientListRef = React.createRef()
    this.patientRef = React.createRef()
    this.receiptRef = React.createRef()
    this.duplicateRecordsRef = React.createRef()
    this.prescriptionListRef = React.createRef()
    this.memberLookupQuickRef = React.createRef()
    this.memberLookupRef = React.createRef()
    this.presignbioRef = React.createRef()
    this.enrolRef = React.createRef()
    this.claimResponseRef = React.createRef()
    this.viewProductsRef = React.createRef()
    this.viewCollectedRef = React.createRef()
    }
    
    componentDidMount() {
        this.getPatientRecordsCount();
          }
          getPatientRecordsCount()
           {
              axios.get(localStorage.getItem('host') + '/sell/getduplicatescount', {
                  headers: {
                      'Authorization': `Bearer ${localStorage.getItem('token')}`
                  }
              }).then(
                  res => {
                      this.setState({ duplicateCount: res.data});
                  }, err => {
                      console.log(".....")
                       this.setState({error: err+""})
                      this.props.setLoader(false)
                  }
              )      
           }
    getDispense(id, patientId, visitId, msg) {
        this.setState({id: id, patientId: patientId, rate: 1, currencyId: 0, visitId: 0, companyId: '', companys: [], currencyName: '', locations: [], status: 'CREATED', dispenseItems: [], msg: msg, error: ''
        , cipherStrings: [], firstnameError: '', firstname: '', surnameError: '', surname: '', dateOfBirthError: '', dateOfBirth: '', companyIdError: '', billCategoryError: '', billCategory: '', patientInsuranceIdError: '', patientInsuranceId: 0, specialContractIdError: '', specialContractId: 0,  currencyIdError: '', referringConsultantIdError: '', referringConsultantId: 0
        , quantity: '' , dosage: '', repeats: 0, patientInsurances: [], institutions: [], treatments:[], currencys: [],institutionId: 0, showSearch: false
        , cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0
        , cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0 
        , cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0
        ,  health263Claim: false, canSendToHealth263: false, canSendToHealth263WithOverride: false, canCancelHealth263WithBio: false, canCancelHealth263: false, patientEnrolled: false, health263MessageShow: false, combinedMessages: ''});
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/sell/dispense/' + id+'/'+patientId+'/'+visitId+'/'+localStorage.getItem('companyId')+'/'+localStorage.getItem('roleId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                var patientInsurances = []
                var cipherStrings = []
                var institutions = []
                var currencys = []
                var locations = []
                var showSearch = false
                if(res.data.companyId > 0)
                   {
                    showSearch = true
                   for(var index in res.data.companys)
                       {
                        if(res.data.companys[index].id === res.data.companyId)    
                            {  
                                    patientInsurances = res.data.companys[index].patientInsurances
                                    cipherStrings = res.data.companys[index].cipherStrings
                                    institutions = res.data.companys[index].institutions
                                    currencys = res.data.companys[index].currencys
                                    locations = res.data.companys[index].locations
                            }
                       }
                   }
                this.setState({visitId: visitId, treatments: res.data.treatments, rate: res.data.rate, companyId: res.data.companyId, companys: res.data.companys, billCategorys: res.data.billCategorys, referringConsultants: res.data.referringConsultants, referringConsultantId: res.data.referringConsultantId, patientInsuranceId: res.data.patientInsuranceId, specialContractId: res.data.specialContractId, billCategory: res.data.billCategory, currencyId: res.data.currencyId, currencyName: res.data.currencyName
                , cashPayable: res.data.cashPayable, insurancePayable: res.data.insurancePayable, shortfallPayable: res.data.shortfallPayable, specialContractPayable: res.data.specialContractPayable, totalPayable: res.data.totalPayable
                , cashPaid: res.data.cashPaid, insurancePaid: res.data.insurancePaid, shortfallPaid: res.data.shortfallPaid, specialContractPaid: res.data.specialContractPaid, totalPaid: res.data.totalPaid 
                , cashOwing: res.data.cashOwing, insuranceOwing: res.data.insuranceOwing, shortfallOwing: res.data.shortfallOwing, specialContractOwing: res.data.specialContractOwing, totalOwing: res.data.totalOwing
                , billId: this.state.billId, institutionId: this.state.institutionId
                , patientInsurances: patientInsurances, cipherStrings: cipherStrings, institutions: institutions, currencys: currencys, locations: locations, firstname: res.data.firstname, surname: res.data.surname, gender: res.data.gender, dateOfBirth: res.data.dateOfBirth, patientNumber: res.data.patientNumber, mobile: res.data.mobile, age: res.data.age
                , status: res.data.status, dispenseItems: res.data.dispenseItems, showSearch: showSearch 
                , health263Claim: res.data.health263Claim, canSendToHealth263: res.data.canSendToHealth263, canSendToHealth263WithOverride: res.data.canSendToHealth263WithOverride, canCancelHealth263WithBio: res.data.canCancelHealth263WithBio, canCancelHealth263: res.data.canCancelHealth263, patientEnrolled: res.data.patientEnrolled, health263MessageShow: res.data.health263MessageShow, combinedMessages: res.data.combinedMessages});
                this.setLoader(false)
                this.doValidate()
            }, err => {
                console.log(".....")
                this.setState({error: err+""})
                this.setLoader(false)
            }
        )
    }
    save() {
        this.setLoader(true)
        var data = {id: this.state.id, rate: this.state.rate, currencyId: this.state.currencyId, visitId: this.state.visitId, companyId: this.state.companyId, billId: this.state.billId
            , patientInsuranceId: this.state.patientInsuranceId, specialContractId: this.state.specialContractId, billCategory: this.state.billCategory
            , referringConsultantId: this.state.referringConsultantId, patientId: this.state.patientId
            , cashPayable: this.state.cashPayable, insurancePayable: this.state.insurancePayable, shortfallPayable: this.state.shortfallPayable, specialContractPayable: this.state.specialContractPayable, totalPayable: this.state.totalPayable
            , cashPaid: this.state.cashPaid, insurancePaid: this.state.insurancePaid, shortfallPaid: this.state.shortfallPaid, specialContractPaid: this.state.specialContractPaid, totalPaid: this.state.totalPaid 
            , cashOwing: this.state.cashOwing, insuranceOwing: this.state.insuranceOwing, shortfallOwing: this.state.shortfallOwing, specialContractOwing: this.state.specialContractOwing, totalOwing: this.state.totalOwing
            , status: this.state.status, dispenseItems: this.state.dispenseItems }
         axios.post(localStorage.getItem('host') + '/sell/savedispense', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setLoader(false)
                document.getElementById('topDiv').scrollIntoView()
                this.getDispense(res.data, this.state.patientId, 0, 'Successfully saved Sell')
            }, err => {
                console.log(".....")
                this.setState({error: err+""})
                this.setLoader(false)
            }
        )
    }
   
      setDispense(name, value) {
        switch (name) {
            case "companyId":
                this.setState({showSearch: false})
                for (var i in this.state.companys) {
                    if (Number(this.state.companys[i].id) === Number(value)) {
                        var companyId = this.state.companys[i].id;
                        var patientInsurances = this.state.companys[i].patientInsurances;
                        var institutions = this.state.companys[i].institutions;
                        var currencys = this.state.companys[i].currencys;
                        var zwlStyle = this.state.companys[i].zwlStyle;
                        var usdStyle = this.state.companys[i].usdStyle;
                        var owingAmountZwl = this.state.companys[i].owingAmountZwl;
                        var owingAmountUsd = this.state.companys[i].owingAmountUsd;
                        var cipherStrings = this.state.companys[i].cipherStrings;
                        this.setState({ companyId: companyId, patientInsurances: patientInsurances, institutions: institutions, currencys: currencys, patientInsuranceId: 0, specialContractId: 0, institutionId: 0, currencyId: 0, rate: 0, billCategory: '', referringConsultantId: '', zwlStyle: zwlStyle, usdStyle: usdStyle, owingAmountZwl:owingAmountZwl, owingAmountUsd: owingAmountUsd, cipherStrings: cipherStrings })
                    }
                }
                break;
            case "billCategory":
                this.setState({ billCategory: value, patientInsuranceId: 0, specialContractId: 0, institutionId: 0, currencyId: 0, rate: 0 , showSearch: false})
                break;
            case "patientInsuranceId":
                this.setState({showSearch: false})
                for (var j in this.state.patientInsurances) {
                    if (this.state.patientInsurances[j].id === Number(value)) {
                        var patientInsuranceId = this.state.patientInsurances[j].id;
                        var institutionId = this.state.patientInsurances[j].institutionId;
                        var currencyId = this.state.patientInsurances[j].currencyId;
                        var rate = this.state.patientInsurances[j].rate;
                        this.setState({ patientInsuranceId: patientInsuranceId, institutionId: institutionId, currencyId: currencyId, rate: rate , showSearch: true})
                    }
                }
                break;
            case "specialContractId":
                this.setState({showSearch: false})
                for (var v in this.state.institutions) {
                    if (this.state.institutions[v].id === Number(value)) {
                        var specialContractId = this.state.institutions[v].id;
                        institutionId = this.state.institutions[v].id;
                        currencyId = this.state.institutions[v].currencyId;
                        rate = this.state.institutions[v].rate;
                        this.setState({ specialContractId: specialContractId, institutionId: institutionId, currencyId: currencyId, rate: rate  , showSearch: true})
                    }
                }
                break;
            case "currencyId":
                this.setState({showSearch: false})
                for (var b in this.state.currencys) {
                    if (this.state.currencys[b].id === Number(value)) {
                         currencyId = this.state.currencys[b].id;
                         institutionId = 0;
                         rate = this.state.currencys[b].rate;
                        this.setState({ currencyId: currencyId, institutionId: 0, rate: rate , showSearch: true })
                    }
                }
                break;
            case "referringConsultantId":
                this.setState({ referringConsultantId: value })
                break;
             default: 
                     console.log("....")
                     break;   
        }
    }
    getDispenseProducts() {
        this.setState({ productItems: [] });
        this.setLoader(true)
        var data = {companyId:  this.state.companyId, locationId: this.state.locationId, billCategory: this.state.billCategory, institutionId: this.state.institutionId, name: this.state.name, rate: this.state.rate}
        axios.post(localStorage.getItem('host') + '/master/getproductitems', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                var options = [];
                for(var i in res.data)
                    {
                        options.push({value: res.data[i].productItemLocationId, label: res.data[i].name+" ["+res.data[i].quantity+"] - "+res.data[i].batchNumber+" - "+res.data[i].expiryDate})
                    } 
                this.setState({productItems: res.data, productOptions: options});
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({error: err+""})
                this.setLoader(false)
            }
        )
    }
    closePopup() {
        this.setState({ fileData: '' });
        document.getElementById('productList').style = 'display:none'
    }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    setProductItem(item)
    {
        let list = this.state.dispenseItems.slice();
        list.push({
            id: 0, productItemId: item.id, productItemLocationId: item.productItemLocationId, name: item.name, form: item.form, type: item.type, tariffCode: item.tariffCode, packSize: item.packSize, maxQuantity: item.quantity, quantity: 0, quantityError: '',
            uom: item.uom, cost: item.sellingPrice, dispensingFee: item.dispensingFee, totalCost: 0.00, instruction: '', storedQuantity: 0.0, collected: item.quantity, activeStatus: 1, locationId: this.state.locationId });
            this.closePopup()
        this.calcBill(list)    
        this.doValidate()
    }   
    calcBill(list)
        {
            var cashPayable = 0;
            var insurancePayable = 0;
            var shortfallPayable = 0;
            var specialContractPayable = 0;
            for (var index in list)
            {
                if (list[index].activeStatus === "1" || list[index].activeStatus === 1)
                {

                    if (list[index].cashOnly)
                    {
                       cashPayable += list[index].totalCost;
                    } else
                    {
                        switch (this.state.billCategory)
                        {
                            case 'CASH':
                                cashPayable += list[index].totalCost;
                                break;
                            case 'INSURANCE':
                                insurancePayable += list[index].totalCost;
                                break;
                            case 'SPECIAL_CONTRACT':
                                specialContractPayable += list[index].totalCost;
                                break;
                                default: 
                                        console.log("....")
                                        break;   
                        }
                    }
                }
            }
            if (this.state.authorisationNumber !== null && this.state.authorisationNumber !== '' && this.state.authorisationAmount > 0)
            {
                shortfallPayable = Number(insurancePayable) - Number(this.state.authorisationAmount);
                insurancePayable = this.state.authorisationAmount;
            }
            var cashOwing = Number(cashPayable) - Number(this.state.cashPaid);
            var insuranceOwing = Number(insurancePayable) - Number(this.state.insurancePaid);
            var shortfallOwing = Number(shortfallPayable) - Number(this.state.shortfallPaid);
            var specialContractOwing = Number(specialContractPayable) - Number(this.state.specialContractPaid);
            var totalPayable = Number(cashPayable) + Number(insurancePayable) + Number(shortfallPayable) + Number(specialContractPayable);
            var totalOwing = Number(cashOwing) + Number(insuranceOwing) + Number(shortfallOwing) + Number(specialContractOwing);
            this.setState({cashPayable: cashPayable, insurancePayable: insurancePayable, shortfallPayable: shortfallPayable, specialContractPayable: specialContractPayable, totalPayable: totalPayable
                , cashOwing: cashOwing, insuranceOwing: insuranceOwing, shortfallOwing: shortfallOwing, specialContractOwing: specialContractOwing, totalOwing: totalOwing})
        }
    setRowValue(index, e, name) {
        let list = this.state.dispenseItems.slice();
        switch (name) {
            case "quantity":
                list[index].quantity = e.target.value;
                break;
            case "instruction":
                list[index].instruction = e.target.value;
                break;
            case "activeStatus":
                list[index].activeStatus = 0;
                break;
            default:
                console.log("default")
                break;
        }
        this.calcBill(list)
        this.doValidate()
    }
    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }
    validate() {
        var error = false;
        if (this.state.companyId === null) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === "") {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === 0) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else {
            this.setState({ companyIdError: "" })
        }

        if (this.state.billCategory === null) {
            this.setState({ billCategoryError: "Bill Category cannot be empty" })
            error = true
        }
        else if (this.state.billCategory === "") {
            this.setState({ billCategoryError: "Bill Category cannot be empty" })
            error = true
        }
        else {
            this.setState({ billCategoryError: "" })
        }
        if (this.state.billCategory === 'CASH') {
            if (this.state.currencyId === null) {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else if (this.state.currencyId === 0) {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else if (this.state.currencyId === "") {
                this.setState({ currencyIdError: "Currency cannot be empty" })
                error = true
            }
            else {
                this.setState({ currencyIdError: "" })
            }
        }
        if (this.state.billCategory === 'INSURANCE') {
            if (this.state.patientInsuranceId === null) {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else if (this.state.patientInsuranceId === 0) {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else if (this.state.patientInsuranceId === "") {
                this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" })
                error = true
            }
            else {
                this.setState({ patientInsuranceIdError: "" })
            }
        }
        if (this.state.billCategory === 'SPECIAL_CONTRACT') {
            if (this.state.specialContractId === null) {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else if (this.state.specialContractId === 0) {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else if (this.state.specialContractId === "") {
                this.setState({ specialContractIdError: "Special Contract cannot be empty" })
                error = true
            }
            else {
                this.setState({ specialContractIdError: "" })
            }
        }
        if (this.state.referringConsultantId === null) {
            this.setState({ referringConsultantIdError: "Referring Consultant cannot be empty" })
            error = true
        }
        else if (this.state.referringConsultantId === 0) {
            this.setState({ referringConsultantIdError: "Referring Consultant cannot be empty" })
            error = true
        }
        else if (this.state.referringConsultantId === "") {
            this.setState({ referringConsultantIdError: "Referring Consultant cannot be empty" })
            error = true
        }
        else {
            this.setState({ referringConsultantIdError: "" })
        }
        var dispenseItems = this.state.dispenseItems
        for (var index in this.state.dispenseItems) {
            if( dispenseItems[index].activeStatus === 1)
                    {
            if (dispenseItems[index].quantity === "") {
                dispenseItems[index].quantityError = "Quantity cannot be empty"
                dispenseItems[index].quantity= 0;
                error = true
            }
            else if(dispenseItems[index].quantity > dispenseItems[index].maxQuantity){
                dispenseItems[index].quantityError = "Quantity cannot be a number greater than System Quantity "+dispenseItems[index].maxQuantity
                dispenseItems[index].quantity= 0;
                error = true
            }
            else if (dispenseItems[index].quantity > 0) {
                dispenseItems[index].quantityError = "";              
                   
            }
            else {
                dispenseItems[index].quantityError = "Quantity should be a number greater than zero"
                dispenseItems[index].quantity= 0;
                error = true
            }
        }
               
        }

            
        if (this.state.dispenseItems === null) {
            this.setState({ dispenseItemsError: "OTC Items cannot be empty" })
            error = true
        }
        else if (this.state.dispenseItems.length === 0) {
            this.setState({ dispenseItemsError: "OTC Items cannot be empty" })
            error = true
        }
        else {
            this.setState({ dispenseItemsError: "" })
        }
        console.log(" error" + error)
        
        this.setState({ dispenseItems: dispenseItems })
        if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ error: "", showSubmit: true })
        }
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
      viewDispenseList()
        {
          this.setState({loader: true})  
          this.dispenseListRef.current.getList()
          document.getElementById('dispenseListAction').style = 'display:block'
        }
    openReceiptAction(dispenseId)
          {
            this.setState({loader: true})
            this.receiptRef.current.openReceiptAction(dispenseId)
            document.getElementById('receiptAction').style = 'display:block'       
          }
    setLocations(id)
         {
            for (var i in this.state.companys) {
                if (Number(this.state.companys[i].id) === Number(id)) {
                    var locations = this.state.companys[i].locations;
                    this.setState({ locations: locations})
                }
            }
         } 
         addProductItem()
            {
             if(this.state.productItemLocationId.value > 0)
                {  console.log('......... service cost id '+this.state.productItemLocationId.value) 
                 const now = new Date();
                 var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
                 let list = this.state.dispenseItems.slice();
                 var s = '';
                 for (var i in this.state.productItems) {
                     if (this.state.productItems[i].productItemLocationId === Number(this.state.productItemLocationId.value)) {
                         s = this.state.productItems[i];
                     }
                 }
              if(this.state.quantity <= s.quantity)
                  {   
                 list.push({id: 0, serviceId: 0, name: s.name, type: s.type, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: s.type
                 , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: this.state.quantity, collected: this.state.quantity, unitCost: s.sellingPrice, cost: (s.sellingPrice * this.state.quantity), totalCost: (s.sellingPrice * this.state.quantity), cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: s.buyingPrice, activeStatus: 1, productItemId: s.id, productItemLocationId: s.productItemLocationId, repeats: this.state.repeats, instruction: this.state.dosage, locationId: this.state.locationId});
                 this.setState({ dispenseItems: list, productItemId: '', productItemLocationId: '', quantity: 0, repeats: 0,  dosage: '', name: ''});
                 this.calcBill(list)
                 this.doValidate()
                  }
                else
                   {
                     window.scrollTo({top: 0, behavior: 'smooth'})
                     this.setState({error:"Entered quantity cannot be greater than available quantity of "+s.quantity})  
                   }  
                }
            } 
    getPatientList()
      {
        this.setState({loader: true})
        document.getElementById('patientList').style = 'display:block'
        this.patientListRef.current.openPatientList(this.state.firstname, this.state.surname, this.state.dateOfBirth)
      }   
      
    openPatient(id) {
        this.setLoader(true)
        this.setState({ patientId: id , loader: true})
        console.log(".... Patient Id " + id)
        this.patientRef.current.getPatient(id)
        document.getElementById('action').style = 'display:block'
    }  
    setSellPatient(id)
     {       
       this.getDispense(0, id, 0, '')
       document.getElementById('patientList').style = 'display:none'
     }    
     setDosage()
     {
      var value = this.state.dosage.toLocaleUpperCase()  
      for(var i in this.state.cipherStrings)
         { console.log('....'+this.state.cipherStrings[i].code+' -> '+this.state.cipherStrings[i].desc)
         value = value.replace(this.state.cipherStrings[i].code, this.state.cipherStrings[i].desc)
         }
       this.setState({dosage: value})
     }      
     reload()
      {
        window.location = './dispenseaction'
      }
      openMergeRecords() {
          this.setState({loader: true})
          this.duplicateRecordsRef.current.getDuplicateRecords();
          document.getElementById('duplicateRecordAction').style = 'display:block'
      }
      openMemberLookupQuickAction()
      {
          this.setState({loader: true})
          this.memberLookupQuickRef.current.getMemberLookup()
          document.getElementById('memberLookupQuickAction').style = 'display:block' 
      }
      getMemberLookuPi(patientId, visitId)
       { 
          this.setState({loader: true})
          this.memberLookupRef.current.getMemberLookuPi(patientId, visitId)
          document.getElementById('memberLookupAction').style = 'display:block'    
       }
       getPresignPi(patientId, visitId)
         {
           this.setState({loader: true})
           this.presignbioRef.current.getPresignPi(patientId, visitId)
           document.getElementById('presignBioAction').style = 'display:block'    
         }  
       getEnrol(patientId, visitId)
         {
           this.setState({loader: true})
           this.enrolRef.current.getEnrol(patientId, visitId)
           document.getElementById('enrolAction').style = 'display:block'    
         }  
       getClaimDetails(billId)
         {
           this.setState({loader: true})
           this.claimResponseRef.current.getClaimDetails(billId)
           document.getElementById('h263ResponseMsg').style = 'display:block'       
         }  
    viewProducts()
         {
            this.setState({loader: true})
            this.viewProductsRef.current.getList(this.state.patientId)
            document.getElementById('viewProductsAction').style = 'display:block'
         }     
    viewCollected(dispenseItemId, available)
              {
                 this.setState({loader: true})
                 this.viewCollectedRef.current.getList(dispenseItemId, available)
                 document.getElementById('viewProductsAction').style = 'display:none'
                 document.getElementById('viewCollectedAction').style = 'display:block'
              }  
    openPrescriptionList()
      {
        this.setState({loader: true})  
        this.prescriptionListRef.current.getList()
        document.getElementById('prescriptionListAction').style = 'display:block'
      }            
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid" id="topDiv">
                        <div className="row">
                            <div className="col">{ this.state.patientId > 0 && (<button onClick={() => this.viewProducts()} className="btn btn-success">View Patient's Products</button>)}</div>
                            <div className="col"><input type="button" className="btn btn-success" onClick={() => this.viewDispenseList()} value="View Dispense List" /></div>
                            <div className="col"><button onClick={() => this.openMergeRecords()} className="btn btn-success">Duplicate Records ({this.state.duplicateCount})</button></div>
                            <div className="col"><button onClick={() => this.openMemberLookupQuickAction()} className="btn btn-success">Member Lookup</button></div>
                            <div className="col"><button onClick={() => this.openPrescriptionList()} className="btn btn-success">Prescription List</button></div>
                      </div>
                        {divMsg}
                        <div className="card card-green">
                                    <div className="card-body">
                                    <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Firstname</span> <input type="text" className={this.state.firstnameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.firstname} onChange={e => { this.setState({ firstname: e.target.value }) }} /><div className="invalid-feedback">{this.state.firstnameError}</div></div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Surname</span> <input type="text" className={this.state.surnameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.surname} onChange={e => { this.setState({ surname: e.target.value }) }} /></div><div className="invalid-feedback">{this.state.surnameError}</div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Date Of Birth</span> <input type="text" className={this.state.dateOfBirthError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.dateOfBirth} onChange={e => { this.setState({ dateOfBirth: e.target.value }) }} /><div className="invalid-feedback">{this.state.dateOfBirthError}</div></div></div>
                                    <div className="col"><button className="btn btn-success" onClick={() => this.getPatientList()} >Search</button></div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">Mobile:</span> {this.state.mobile}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                                <div className="row">
                                    <div className="col">{ this.state.id > 0 && (<span className="link" onClick={() => this.getPrintOut(this.state.id, 'DISPENSE', [], this.state.companyId)} title="Print" style={{fontSize: 24+'px'}} ><i className="bi bi-printer-fill"></i></span>)}</div>
                                </div>
                                        <div className="row">
                                        <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Company</span>
                                                <select  className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.companyId} onChange={e => {this.setDispense("companyId", e.target.value ); this.setLocations(e.target.value); }}  >
                                                    <option value="0">Choose Company</option>
                                                    {this.state.companys.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select><div className="invalid-feedback">{this.state.companyIdError}</div></div>
                                            </div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Bill Category</span><select className={this.state.billCategoryError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.billCategory} onChange={e => { this.setDispense("billCategory", e.target.value); this.doValidate() }} >
                                                        <option value="">Choose Bill Category</option>
                                                        {this.state.billCategorys.map((item, index) => (
                                                            <option key={index} value={item} >{item}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.billCategoryError}</div>
                                                </div></div>
                                                <div className="col">{this.state.billCategory === "INSURANCE" ? (<div className="input-group has-validation">
                                                        <span className="input-group-text">{this.state.billCategory}</span><select className={this.state.patientInsuranceIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.patientInsuranceId} onChange={e => { this.setDispense("patientInsuranceId", e.target.value); this.doValidate() }}>
                                                            <option value="0">Choose {this.state.billCategory}</option>
                                                            {this.state.patientInsurances.map((item, index) => (
                                                                <option key={index} value={item.id} >{item.name} - {item.currencyName}</option>
                                                            ))}
                                                        </select><div className="invalid-feedback">{this.state.patientInsuranceIdError}</div></div>) : (<span></span>)}
                                                        {this.state.billCategory === "SPECIAL_CONTRACT" ? (<div className="input-group has-validation">
                                                            <span className="input-group-text">{this.state.billCategory}</span> <select className={this.state.specialContractIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.specialContractId} onChange={e => { this.setDispense("specialContractId", e.target.value); this.doValidate() }} >
                                                                <option value="0">Choose Special Contract</option>
                                                                {this.state.institutions.map((item, index) => (
                                                                    <option key={index} value={item.id} >{item.name} - {item.currencyName}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{this.state.specialContractIdError}</div></div>) : (<span></span>)}
                                                        {this.state.billCategory === "CASH" ? (<div className="input-group has-validation">
                                                            <span className="input-group-text">{this.state.billCategory}</span><select className={this.state.currencyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.currencyId} onChange={e => { this.setDispense("currencyId", e.target.value); this.doValidate() }}>
                                                                <option value="0">Choose {this.state.billCategory}</option>
                                                                {this.state.currencys.map((item, index) => (
                                                                    <option key={index} value={item.id} >{item.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{this.state.currencyIdError}</div></div>) : (<span></span>)}
                                                    </div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Referring Doctor</span> <select className={this.state.referringConsultantIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.referringConsultantId} onChange={e => { this.setDispense("referringConsultantId", e.target.value); this.doValidate() }} >
                                                        <option value="" >Choose Referring Doctor</option>
                                                        {this.state.referringConsultants.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.referringConsultantIdError}</div>
                                                </div></div>
                                        </div>
    </div></div>
    <div className="card card-green">
                                    <div className="card-body">
                                        <div className="row">
                                        <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Location</span>
                                                <select className="form-control" value={this.state.locationId} onChange={e => this.setState({ locationId: e.target.value })}  >
                                                    <option value="0">Choose Location</option>
                                                    {this.state.locations.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Product</span>
                                                 <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({name: e.target.value}) }} />
                                              { this.state.locationId > 0 && this.state.showSearch === true && (<input type="button" className="btn btn-primary" onClick={() => this.getDispenseProducts()} value="Search" />)}</div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Products</span>
                                                 <Select
                                                    value={this.state.productItemLocationId}
                                                    className="form-control"
                                                    onChange={e => this.setState({ productItemLocationId: e })}
                                                    options={this.state.productOptions}
                                                /></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Quantity</span>
                                                 <input type="text" className="form-control" value={this.state.quantity} onChange={e => { this.setState({quantity: e.target.value}) }} /></div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Dosage</span>
                                                 <input type="text" className="form-control" value={this.state.dosage} onChange={e => { this.setState({dosage: e.target.value}) }} />
                                                 <button  onClick={() => this.setDosage()}  className="btn btn-success" ><i class="bi bi-magic"></i></button></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Repeats</span>
                                                 <input type="text" className="form-control" value={this.state.repeats} onChange={e => { this.setState({repeats: e.target.value}) }} /></div>
                                            </div>
                                            <div className="col"><input type="button"  onClick={() => this.addProductItem()} value="Add" className="btn btn-success" /></div>
                                        </div>
                                    </div></div>
                                    <br  />
                                    {this.state.health263Claim === true && (<div>
                                        <div className="row">
                                            <div className="col"><input type="button" onClick={() => this.props.getMemberLookuPi(this.state.patientId, this.state.billId)} className="btn btn-info" value="Member Lookup" /></div>
                                            <div className="col"><input type="button" onClick={() => this.props.getEnrol(this.state.patientId, this.state.billId)} className="btn btn-info" value="Enrol" /></div>
                                            <div className="col"><input type="button" value="Presign Bio" className="btn btn-info" onClick={() => this.props.getPresignPi(this.state.patientId, this.state.billId)} /></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12" align="center">{this.state.combinedMessages !== '' ? (<div className="alert alert-danger">{this.state.combinedMessages}</div>) : (<span></span>)}  </div>
                                        </div>
                                        <div className="row">
                                            {this.state.canSendToHealth263 && this.state.patientDiagnosis.length > 0?(<div align="center" className="col"><input type="button" id="submitHealth263" className="btn btn-success" onClick={() => this.claimSubmission('false', 'false', 'true')} value="CLAIM WITH BIO" /> </div>):(<span ></span>)}
                                            {this.state.canSendToHealth263WithOverride && this.state.patientDiagnosis.length > 0?(<div align="center" className="col"><input type="button" id="submitHealth263" className="btn btn-success" onClick={() => this.claimSubmission('false', 'true', 'false')} value="CLAIM BY-PASS" /> </div>):(<span></span>)}
                                            {this.state.canSendToHealth263WithOverride && this.state.patientDiagnosis.length > 0?(<div align="center" className="col"><input type="button" id="submitHealth263" className="btn btn-success" onClick={() => this.claimSubmission('false', 'false', 'false')} value="CLAIM WITH NO BIO" /> </div>):(<span></span>)}
                                            {this.state.canCancelHealth263WithBio && this.state.patientDiagnosis.length > 0?(<div align="center" className="col"><input type="button" id="reverseHealth263" className="btn btn-danger" onClick={() => this.claimSubmission('true', 'false', 'true')} value="REVERSE CLAIM WITH BIO" /></div>):(<span></span>)}
                                            {this.state.canCancelHealth263 && this.state.patientDiagnosis.length > 0?(<div align="center" className="col"><input type="button" id="reverseHealth263" className="btn btn-danger" onClick={() => this.claimSubmission('true', 'false', 'false')} value="REVERSE CLAIM WITH NO BIO" /></div>):(<span></span>)}
                                            <div align="center" className="col"><input type="button" id="responseHealth263" className="btn btn-success" onClick={() => this.props.getClaimDetails(this.state.billId)} value="View Claim Response" /></div>
                                        </div>
                                    </div>) }
                                    <div className="card  card-blue">
                                    <div className="card-header">Prescription</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Drug</th>
                                                <th>Dosage</th>
                                                <th>Frequency</th>
                                                <th>Route</th>
                                                <th>Duration</th>
                                                <th>Formulation</th>
                                                <th>Repeats</th>
                                            </tr>
                                            <tbody>
                                                {this.state.treatments.map((item, index) =>(
                                                    <tr key={index}>
                                                        <td>{item.drugName} </td>
                                                        <td>{item.dosage}</td>
                                                        <td>{item.frequencyName}</td>
                                                        <td>{item.routeName} </td>
                                                        <td>{item.durationName}</td>
                                                        <td>{item.formulationName} </td>
                                                        <td>{item.repeats}</td>
                                                         </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                    </div></div>
                                        <div className="card card-green">
                                            <div className="card-header">Items</div>
                                            <div className="card-body">    
                                            <table className="table table-striped" >
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Type</th>
                                                    <th>Tariff Code</th>
                                                    <th>Quantity</th>
                                                    <th>Cost</th>
                                                    <th>Dispensing Fee</th>
                                                    <th>Total Cost</th>
                                                    <th>Instruction</th>
                                                    <th>Collection</th>
                                                    <th>Active Status</th>
                                                </tr><tbody>
                                                {this.state.dispenseItems.map((l, index) => ( l.activeStatus === 1 && (
                                                    <tr>
                                                        <td>{l.name}</td>
                                                        <td>{l.type}</td>
                                                        <td>{l.tariffCode}</td>
                                                        <td><div className="input-group has-validation"><input type="text" className={l.quantityError !== "" ? "form-control is-invalid" : "form-control"} value={l.quantity} onChange={e => { this.setRowValue(index, e, "quantity") }} />
                                                                <div className="invalid-feedback">{l.quantityError}</div></div></td>
                                                        <td>{this.currencyFormat(l.cost)}</td>
                                                        <td>{this.currencyFormat(l.dispensingFee)}</td>
                                                        <td>{this.currencyFormat(l.totalCost)}</td>
                                                        <td><input type="text" className="form-control" value={l.instruction} onChange={e => { this.setRowValue(index, e, "instruction") }} /></td>
                                                        <td><input type="text" className="form-control" value={l.collected} onChange={e => { this.setRowValue(index, e, "collected") }} /></td>
                                                        <td><input type="button" className="btn btn-danger" value="Delete" onClick={() => this.setRowValue(index, 0,  "activeStatus")} /></td>
                                                    </tr>
                                                )))}
                                                </tbody>
                                            </table> 
                                            <div className="row">
                                            <div className="input-group">
                                                        <input type="hidden" className={this.state.dispenseItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                                        <div className="invalid-feedback">{this.state.dispenseItemsError}</div> </div></div> 
                                            
                                                        <table className="table table-striped">
                                                <tr>
                                                    <th></th>
                                                    <th>Cash</th>
                                                    <th>Insurance</th>
                                                    <th>Shortfall</th>
                                                    <th>Special Contract</th>
                                                    <th>Total</th>
                                                </tr>
                                                <tbody>
                                                <tr>
                                                    <th>Payable</th>
                                                    <td>{this.currencyFormat(this.state.cashPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePayable)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPayable)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Paid</th>
                                                    <td>{this.currencyFormat(this.state.cashPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePaid)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPaid)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Owing</th>
                                                    <td>{this.currencyFormat(this.state.cashOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.insuranceOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.totalOwing)}</td>
                                                </tr></tbody>

                                            </table>            
                                             </div></div>
                                            <div className="row">
                                            <div className="col" align="right">{this.state.showSubmit === true && (<input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" />)}</div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.reload()} value="Close" /></div>
                                        </div>
                        {loaderDiv}
                        <DispenseList ref={this.dispenseListRef} setLoader={this.setLoader} getDispense={this.getDispense} getPrintOut={this.getPrintOut} openReceiptAction={this.openReceiptAction} /> 
                        <Receipt ref={this.receiptRef} setLoader={this.setLoader} /> 
                        <PatientList ref={this.patientListRef} setLoader={this.setLoader} openPatient={this.openPatient} setSellPatient={this.setSellPatient} />
                        <PrescriptionList ref={this.prescriptionListRef} setLoader={this.setLoader} getDispense={this.getDispense} />
                       <PatientAction patientId={this.state.patientId} actionSavePatient={this.actionSavePatient} ref={this.patientRef} setLoader={this.setLoader} />
                       <DuplicateRecords getPatientRecordsCount={this.getPatientRecordsCount} ref={this.duplicateRecordsRef} setLoader={this.setLoader} />
                         <MemberLookup ref={this.memberLookupRef} setLoader={this.setLoader} updateVisitAction={this.updateVisitAction} />
                        <MemberLookupQuick ref={this.memberLookupQuickRef} setLoader={this.setLoader} />
                        <Presignbio ref={this.presignbioRef} setLoader={this.setLoader} updateVisitAction={this.updateVisitAction} />
                        <Enrol ref={this.enrolRef} setLoader={this.setLoader} updateVisitAction={this.updateVisitAction} />
                        <ClaimResponse ref={this.claimResponseRef} setLoader={this.setLoader} />
                        <ViewProducts  ref={this.viewProductsRef} setLoader={this.setLoader} viewCollected={this.viewCollected} />
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} /> 
                       <ViewCollected  ref={this.viewCollectedRef} setLoader={this.setLoader} />
                       </div>
                </div ></div >
        )

    }
}    