import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class JobOrderCreate extends Component {
       state = {showSubmit: false, companyId: '', companyIdError: '', jobOrderStatusId: '', jobOrderStatusIdError: '', patientId: 0, companys: [], statuses: [], fullname: '', gender: '', dateOfBirth: ''
    ,tint: '', bifocalType: '', prismsRight: '', prismsLeft: '', segmentsRight: '', segmentsLeft: '', opticalCentresD: '', sphRight: '', cylRight: '', axisRight: '', addRight: '', sphLeft: '', cylLeft: '', axisLeft: '', addLeft: '', specialInstructions: '', data: ''}
    componentDidMount () {
        window.scrollTo(0, 0);
      }
      createJobOrder(patient) {
        this.setState({patientId: patient.id, fullname: patient.fullname, gender: patient.gender, dateOfBirth: patient.dateOfBirth})
        axios.get(localStorage.getItem('host') + '/is/getjoborderstatus/' + localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.doValidate()
                this.setState({companys: res.data});
                this.props.setLoader(false)
                window.scrollTo(0, 0);
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )
    }
    saveNext(jobOrderStatusId) {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/is/updatejobordernext/'+this.state.id+'/'+jobOrderStatusId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                this.setState({ msg: res.data });
                window.scrollTo(0, 0);
                this.props.getList()
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('jobOrderCreateAction').style = 'display:none';
        window.scrollTo(0, 0);
    }

    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }
    validate() {

        var error = false;
        if (this.state.companyId === null) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === "") {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === 0) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else {
            this.setState({ companyIdError: "" })
        }
        if (this.state.jobOrderStatusId === null) {
            this.setState({ jobOrderStatusIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.jobOrderStatusId === "") {
            this.setState({ jobOrderStatusIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.jobOrderStatusId === 0) {
            this.setState({ jobOrderStatusIdError: "Company cannot be empty" });
            error = true
        }
        else {
            this.setState({ jobOrderStatusIdError: "" })
        }

        console.log(" error" + error)
        if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ error: "", showSubmit: true })
        }
    }
    close() {
        document.getElementById('visitAction').style = 'display:none'
    }
    save()
      {
          this.props.setLoader(true)
          var data = {companyId: this.state.companyId, patientId: this.state.patientId, jobOrderStatusId: this.state.jobOrderStatusId
              , optomPrescription: { tint: this.state.tint, bifocalType: this.state.bifocalType, prismsRight: this.state.prismsRight, prismsLeft: this.state.prismsLeft, segmentsRight: this.state.segmentsRight, segmentsLeft: this.state.segmentsLeft, opticalCentresD: this.state.opticalCentresD, sphRight: this.state.sphRight, cylRight: this.state.cylRight, axisRight: this.state.axisRight, addRight: this.state.addRight, sphLeft: this.state.sphLeft, cylLeft: this.state.cylLeft, axisLeft: this.state.axisLeft, addLeft: this.state.addLeft, specialInstructions: this.state.specialInstructions, data: this.state.data }}
          axios.post(localStorage.getItem('host') + '/is/createjoborder', data, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
          }).then(
              res => {
                  this.props.setLoader(false)
                  console.log(res.data)
                  if(res.data.includes('Error'))
                    {
                      this.setState({ error: res.data });  
                      window.scrollTo(0, 0)
                    }
                    else
                    {
                     window.scrollTo(0, 0)
                     this.setState({msg: res.data});
                    }
              }, err => {
                   this.setState({error: err+""})
                   window.scrollTo(0, 0)
                  this.props.setLoader(false)
              }
          )   
      }
      setCompany(id)
        {
         this.setState({statuses:[]})
         for(var i in this.state.companys)
            {
             if(this.state.companys[i].id === Number(id))
                {
                 this.setState({statuses: this.state.companys[i].statuses, companyId:Number(id)})
                }
            }
        }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="jobOrderCreateAction" className="page-popup page-popup-overlay"   >
            <div className="card" >
            <div className="card-header  bg-info">Job Order Action</div>
            <div className="card-body" >
                {divMsg}
                <div className="row">
                    <div className="col">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                 </div>
                            </div>
                        </div>
                    <div className="row">
                        <div className="col-lg-6" align="center">{this.state.showSubmit === true ? (<button onClick={() => this.save()} id="submitButton2" className="btn btn-primary" >Submit</button>) : (<span></span>)}</div>
                        <div className="col-lg-6" align="center"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                    </div>
                    <div className="row">
                    <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Company</span><select className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.companyId} onChange={e => { this.setCompany(e.target.value); this.doValidate() }} >
                                                        <option value="">Choose Company</option>
                                                        {this.state.companys.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.companyIdError}</div>
                                                </div></div>
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Status</span><select className={this.state.jobOrderStatusIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.jobOrderStatusId} onChange={e => { this.setState({jobOrderStatusId: e.target.value}); this.doValidate() }} >
                                                        <option value="">Choose Status</option>
                                                        {this.state.statuses.map((item, index) => (
                                                            <option key={index} value={item.id} >{item.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.jobOrderStatusIdError}</div>
                                                </div></div>
                                                <div className="col"></div>
                                  </div>              
                        <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Optom Prescription
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-striped">
                                    <tr>
            <td></td>
            <th>SPH</th>
            <th>CYL</th>
            <th>AXIS</th>
            <th>ADD</th>
        </tr>        
        <tbody>
        <tr>
            <th>R</th>
            <td><input type="text" class="form-control" value={this.state.sphRight} onChange={e => this.setState({ sphRight: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.cylRight} onChange={e => this.setState({ cylRight: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.axisRight} onChange={e => this.setState({ axisRight: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.addRight} onChange={e => this.setState({ addRight: e.target.value })} /></td>
        </tr>
        <tr>
            <th>L</th>
            <td><input type="text" class="form-control" value={this.state.sphLeft} onChange={e => this.setState({ sphLeft: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.cylLeft} onChange={e => this.setState({ cylLeft: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.axisLeft} onChange={e => this.setState({ axisLeft: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.addLeft} onChange={e => this.setState({ addLeft: e.target.value })} /></td>
        </tr>
        <tr>
            <td></td>
            <th>Prisms</th>
            <th>Segments</th>
            <td></td>
            <td></td>
        </tr>       
        <tr>
            <th>R</th>
            <td><input type="text" class="form-control" value={this.state.prismsRight} onChange={e => this.setState({ prismsRight: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.segmentsRight} onChange={e => this.setState({ segmentsRight: e.target.value })} /></td>  
            <th>Tint</th>   
            <td><input type="text" class="form-control" value={this.state.tint} onChange={e => this.setState({ tint: e.target.value })} /></td>  
        </tr> 
        <tr>
            <th>L</th>
            <td><input type="text" class="form-control" value={this.state.prismsLeft} onChange={e => this.setState({ prismsLeft: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.segmentsLeft} onChange={e => this.setState({ segmentsLeft: e.target.value })} /></td>   
            <th>Bifocal Type</th>
            <td><input type="text" class="form-control" value={this.state.bifocalType} onChange={e => this.setState({ bifocalType: e.target.value })} /></td>   
        </tr> 
        <tr>
            <td>Optical Centres D</td>
            <td colspan="4"><input type="text" class="form-control" value={this.state.opticalCentresD} onChange={e => this.setState({ opticalCentresD: e.target.value })} /></td>
        </tr>
        <tr>
            <td>Special Instructions</td>
            <td colspan="4"><textarea class="form-control" value={this.state.specialInstructions} onChange={e => this.setState({ specialInstructions: e.target.value })}></textarea></td>
        </tr>
        </tbody>
    </table>
                                    </div>
                                </div>
                        <br />
                        
                            
                               
            </div >  </div>
            </div >
        </div >
        </div >)

    }
}      