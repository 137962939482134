import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());

function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class BroadcastList extends Component {
    state = { id: 0,  startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD , reason: '', companyId: localStorage.getItem('companyId'), list: [], message: '', to: '', name: '', totalCount: '', status: '' }
    componentDidMount() {
        this.getList()
    }
   
    getList() {
        this.setLoader(true)
        var data = { dateRange: this.state.startDate+","+this.state.endDate, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/sms/broadcastlist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.setLoader(false)
            }
        )
    }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    openBroadcastAction(id, companyId) {
            this.setState({ id: 0, message: '', to: '', name: '', totalCount: '', status: '' })
           
                axios.get(localStorage.getItem('host') + '/sms/broadcastaction/' + id+'/'+companyId, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }).then(
                    res => {
                        this.setState({ name: res.data.name, id: res.data.id, message: res.data.message , to: res.data.to , totalCount: res.data.totalCount , status: res.data.status });
                    }, err => {
                        console.log(".....")
                    }
                )
            document.querySelector('#action').style = 'display:block'
        }
        closePopup() {
            document.querySelector('#action').style = 'display:none'
            console.log('......close');
        }
        save() {
            var data = { id: this.state.id, name: this.state.name, message: this.state.message, to: this.state.to, totalCount: this.state.totalCount, companyId: localStorage.getItem('companyId') }
            axios.post(localStorage.getItem('host') + '/sms/broadcastsave', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    console.log(res.data)
                    if(res.data.includes("Error"))
                        {
                            this.setState({ error: res.data }); 
                        }
                     else
                        {
                            this.setState({ msg: res.data });
                        }   
                    
                    this.getList();
                    setTimeout(() => {
                        this.setState({ msg: '' });
                        this.closePopup();
                        
                    }, 3000);
                }, err => {
                    console.log(".....")
                }
            )
        }
 openBroadcastAprrove(broadcast) {
        this.setState({message: broadcast.message, id: broadcast.id})
        document.getElementById('approveBroadcastAction').style = 'display:block' 
    }
    sendApproveBroadcast() {
        axios.get(localStorage.getItem('host') + '/sms/broadcastapprove/' + this.state.id, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ msg: res.data });
                this.setLoader(false)
                this.getList()
                setTimeout(() => {
                    this.setState({ msg: '' });
                    this.closeApprovePopup();
                    
                }, 3000);
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    closeApprovePopup() {
        document.getElementById('approveBroadcastAction').style = 'display:none'
    } 
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                    <div id="approveBroadcastAction" className="page-popup page-popup-overlay" >
                        <div className="card" >
                            <div className="card-header bg-info">Approve Action</div>
                            <div className="card-body" >
                       {divMsg}
                            <div className="row">
                                    <div className="col"><span className="fw-bold">Message:</span> {this.state.message}</div>
                                </div>
                                <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.sendApproveBroadcast()} className="btn btn-primary">Yes</button></div> 
                                <div className="col" align="center" ><button onClick={() => this.closeApprovePopup()} className="btn btn-primary">No</button></div> 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="page-popup page-popup-overlay" id="action">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Action
                                    </div>
                                    <div className="card-body">
                       {divMsg}
                                    <div className="row">
                                    <div className="col"><span className="fw-bold">Contact Count:</span> {this.state.totalCount}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">To:</span><textarea className="form-control" value={this.state.to} rows="10" ></textarea> </div>
                                </div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Name</span>
                                                <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Message</span>
                                                <textarea className="form-control" value={this.state.message} onChange={e => { if(e.target.value.length <= 160){ this.setState({ message: e.target.value })} } } rows="4" ></textarea>
                                               </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col"></div>
                                            <div className="col"><span className="fw-bold">Character Count:</span> { this.state.message !== null?(<span>{this.state.message.length}</span>):(<span>0</span>)}</div>
                                            </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                        </div>
                        <button className="btn btn-primary" onClick={() => this.openBroadcastAction(0, this.state.companyId) } ><i className="bi bi-plus-square"></i></button>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">End Date</span> <DatePicker className="form-control" selected={new Date(this.state.endDate)}  minDate={new Date(this.state.startDate)} maxDate={new Date()}  onChange={d => { this.setState({ endDate: this.processDate(d) }); } } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getBroadcastList()} value="Search" /></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                            <table className="table table-striped table-hover">
                                <tr>
                                    <th>Action</th>
                                    <th>Name</th>
                                    <th>Contact Count</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                </tr>
                                <tbody>
                                {this.state.list.map((broadcast, index) => (
                                    <tr key={index} style={{backgroundColor:broadcast.color}}>
                                      <td >
                                      {broadcast.status === 'CREATED' && (<span className="link" onClick={() => this.openBroadcastAction(broadcast.id, broadcast.companyId)} title="Edit broadcast"><i className="bi bi-pencil-square"></i></span>)}
                                      {broadcast.status === 'CREATED' && (<span className="link" onClick={() => this.openBroadcastAprrove(broadcast)} title="Approve" ><i class="bi bi-check-square-fill"></i></span>)}
                                                </td>
                                        <td>{broadcast.name}</td>
                                        <td>{broadcast.totalCount}</td>
                                        <td>{broadcast.broadcastDate}</td>
                                        <td>{broadcast.status}</td>
                                       </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}      