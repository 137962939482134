import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class GpAction2 extends Component {
    state = {
        visitStatus:'', loader: false, htmlText: '', id: 0, billId: 0, patientInsuranceId: 0, companyId: 0, patientId: 0, patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], rehabilitations: [], referrals: [], treatments: [], drugs: [], frequencys: [], routes: [], durations: [], formulations: [], services: [], investigations: [], findings: [], emrOlds: [], oldResults: [], investigationRequests: [], treatmentOther: '',appointmentTimes: [],
        fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: 0.0, height: 0.0, waist: 0.0, headCircumference: 0.0, muac: 0, bmi: 0.0, waistHeightRatio: 0.0, nurseNotes: '', vitalColors: [], notes: [], referralLetters: [], oldPatient: '', managementData: '', historyVisits: [], visitId: 0, msg: '', error: '',
        presentingComplaintList: [], historyOfPresentingComplaints: '', backgroundHistory: '', systemicReview: '', pastMedicalHistory: '', familyHistory: '', socialHistory: '',
        appearanceChoice: '', appearanceText: '', behaviourChoice: '', behaviourText: '', dehydrationChoice: '', dehydrationText: '', wastingChoice: '', wastingText: '', hirsutismChoice: '', hirsutismText: '', thyroidChoice: '', thyroidText: '', palpableChoice: '', palpableText: '', clubbingChoice: '', clubbingText: '', pallorChoice: '', pallorText: '', icterusChoice: '', icterusText: '', cyanosisChoice: '', cyanosisText: '', edemaChoice: '', edemaText: '', lossphyChoice: '', lossphyText: '', herniaChoice: '', herniaText: '', examinationNotes: '',
        hBA1C: '', cholesterol: '', otherName: '', otherValue: '', treatmentRecords: [], results: [],
        historyOfPresentingComplaintsT: false, presentingComplaintsT: false,backgroundHistoryT: true, systemicReviewT: false, pastMedicalHistoryT: false, familyHistoryT: false, socialHistoryT: false, allergies: false,
        patientHistory: false, history: true, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: false, graphical: false, bill: false,
        health263Claim: false, canSendToHealth263: false, canSendToHealth263WithOverride: false, canCancelHealth263WithBio: false, canCancelHealth263: false, patientEnrolled: false, health263MessageShow: false, combinedMessages: '',
        cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0, drugAllergy: '', otherAllergy: '',
        cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0,
        cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0, authorisationNumber: '', authorisationAmount: 0,
        speciality: '',  rate: 0, institutionId: 0, billItems: [], billCategory: '', billServices: [], options: [], drugAllergies: '', otherAllergies: ''
 }
    componentDidMount() {

    }
    openGpAction(visitId, msg) {
        if(msg === '')
           {
            this.setLoader(true) 
           } 
        this.setState({
            visitStatus:'', htmlText: '', id: 0, billId: 0, patientInsuranceId: 0, companyId: 0, patientId: 0, patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], rehabilitations: [], referrals: [], treatments: [], drugs: [], frequencys: [], routes: [], durations: [], formulations: [], services: [], investigations: [], findings: [], emrOlds: [], oldResults: [], investigationRequests: [], treatmentOther: '',appointmentTimes: [],
            fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: 0.0, height: 0.0, waist: 0.0, headCircumference: 0.0, muac: 0, bmi: 0.0, waistHeightRatio: 0.0, nurseNotes: '', vitalColors: [], notes: [], referralLetters: [], oldPatient: '', managementData: '', historyVisits: [], visitId: visitId, msg: '', error: '',
            presentingComplaintList: [], historyOfPresentingComplaints: '', backgroundHistory: '', systemicReview: '', pastMedicalHistory: '', familyHistory: '', socialHistory: '',
            appearanceChoice: '', appearanceText: '', behaviourChoice: '', behaviourText: '', dehydrationChoice: '', dehydrationText: '', wastingChoice: '', wastingText: '', hirsutismChoice: '', hirsutismText: '', thyroidChoice: '', thyroidText: '', palpableChoice: '', palpableText: '', clubbingChoice: '', clubbingText: '', pallorChoice: '', pallorText: '', icterusChoice: '', icterusText: '', cyanosisChoice: '', cyanosisText: '', edemaChoice: '', edemaText: '', lossphyChoice: '', lossphyText: '', herniaChoice: '', herniaText: '', examinationNotes: '',
            hBA1C: '', cholesterol: '', otherName: '', otherValue: '', treatmentRecords: [], results: [],
            historyOfPresentingComplaintsT: false, presentingComplaintsT: false,backgroundHistoryT: true, systemicReviewT: false, pastMedicalHistoryT: false, familyHistoryT: false, socialHistoryT: false, allergies: false,
            patientHistory: false, history: true, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: false, graphical: false, bill: false,
            health263Claim: false, canSendToHealth263: false, canSendToHealth263WithOverride: false, canCancelHealth263WithBio: false, canCancelHealth263: false, patientEnrolled: false, health263MessageShow: false, combinedMessages: '',
            cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0, drugAllergy: '', otherAllergy: '',
            cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0,
            cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0, authorisationNumber: '', authorisationAmount: 0,
            speciality: '', rate: 0, institutionId: 0, billItems: [], billCategory: '', billServices: [], options: [], drugAllergies: '', otherAllergies: ''
    })
        axios.get(localStorage.getItem('host') + '/patientmanagement/gp/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for(var j in res.data.vitalColors)
                    {
                    res.data.vitalColors[j].temperature = res.data.vitalColors[j].temperature === 0?"":res.data.vitalColors[j].temperature
                    res.data.vitalColors[j].pulse = res.data.vitalColors[j].pulse === 0?"":res.data.vitalColors[j].pulse
                    res.data.vitalColors[j].glucometer = res.data.vitalColors[j].glucometer === 0?"":res.data.vitalColors[j].glucometer
                    res.data.vitalColors[j].spO2 = res.data.vitalColors[j].spO2 === 0?"":res.data.vitalColors[j].spO2
                    res.data.vitalColors[j].diastolic = res.data.vitalColors[j].diastolic === 0?"":res.data.vitalColors[j].diastolic
                    res.data.vitalColors[j].systolic = res.data.vitalColors[j].systolic === 0?"":res.data.vitalColors[j].systolic
                    }
                    res.data.weight = res.data.weight === 0?"":res.data.weight
                    res.data.height = res.data.height === 0?"":res.data.height
                    res.data.headCircumference = res.data.headCircumference === 0?"":res.data.headCircumference
                    res.data.muac = res.data.muac === 0?"":res.data.muac
                    res.data.bmi = res.data.bmi === 0?"":res.data.bmi
                    res.data.waistHeightRatio = res.data.waistHeightRatio === 0?"":res.data.waistHeightRatio
                    var options = [];
                    for(var i in res.data.billServices)
                        {
                            options.push({value: res.data.billServices[i].id, label: res.data.billServices[i].name})
                        }  
                this.setState({
                    visitStatus: res.data.visitStatus, id: res.data.id, billId: res.data.billId, patientInsuranceId: res.data.patientInsuranceId, companyId: res.data.companyId, patientId: res.data.patientId, patientDiagnosis: res.data.patientDiagnosis, diagnosisText: res.data.diagnosisText, reviews: res.data.reviews, medicalCertificateTypes: res.data.medicalCertificateTypes, medicalCertificates: res.data.medicalCertificates, rehabilitations: res.data.rehabilitations, referrals: res.data.referrals, treatments: res.data.treatments, drugs: res.data.drugs, frequencys: res.data.frequencys, routes: res.data.routes, durations: res.data.durations, formulations: res.data.formulations, services: res.data.services, investigations: res.data.investigations, findings: res.data.findings, emrOlds: res.data.emrOlds, oldResults: res.data.oldResults, msg: msg, investigationRequests: res.data.investigationRequests , treatmentOther: res.data.treatmentOther, appointmentTimes: res.data.appointmentTimes,
            /* HISTORY  */   presentingComplaintList: res.data.history.presentingComplaintList, historyOfPresentingComplaints: res.data.history.historyOfPresentingComplaints, backgroundHistory: res.data.history.backgroundHistory, systemicReview: res.data.history.systemicReview, pastMedicalHistory: res.data.history.pastMedicalHistory, familyHistory: res.data.history.familyHistory, socialHistory: res.data.history.socialHistory,
         /* EXAMINATION */ appearanceChoice: res.data.examination.appearanceChoice, appearanceText: res.data.examination.appearanceText, behaviourChoice: res.data.examination.behaviourChoice, behaviourText: res.data.examination.behaviourText, dehydrationChoice: res.data.examination.dehydrationChoice, dehydrationText: res.data.examination.dehydrationText, wastingChoice: res.data.examination.wastingChoice, wastingText: res.data.examination.wastingText, hirsutismChoice: res.data.examination.hirsutismChoice, hirsutismText: res.data.examination.hirsutismText, thyroidChoice: res.data.examination.thyroidChoice, thyroidText: res.data.examination.thyroidText, palpableChoice: res.data.examination.palpableChoice, palpableText: res.data.examination.palpableText, clubbingChoice: res.data.examination.clubbingChoice, clubbingText: res.data.examination.clubbingText, pallorChoice: res.data.examination.pallorChoice, pallorText: res.data.examination.pallorText, icterusChoice: res.data.examination.icterusChoice, icterusText: res.data.examination.icterusText, cyanosisChoice: res.data.examination.cyanosisChoice, cyanosisText: res.data.examination.cyanosisText, edemaChoice: res.data.examination.edemaChoice, edemaText: res.data.examination.edemaText, lossphyChoice: res.data.examination.lossphyChoice, lossphyText: res.data.examination.lossphyText, herniaChoice: res.data.examination.herniaChoice, herniaText: res.data.examination.herniaText, examinationNotes: res.data.examination.notes,
                    hBA1C: res.data.hBA1C, cholesterol: res.data.cholesterol, otherName: res.data.otherName, otherValue: res.data.otherValue, results: res.data.results,
                    fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, vitalColors: res.data.vitalColors, notes: res.data.notes, referralLetters: res.data.referralLetters, oldPatient: res.data.oldPatient, managementData: res.data.managementData, historyVisits: res.data.historyVisits,
                    cashPayable: res.data.cashPayable, insurancePayable: res.data.insurancePayable, shortfallPayable: res.data.shortfallPayable, specialContractPayable: res.data.specialContractPayable, totalPayable: res.data.totalPayable, drugAllergy: res.data.drugAllergy, otherAllergy: res.data.otherAllergy,
                    cashPaid: res.data.cashPaid, insurancePaid: res.data.insurancePaid, shortfallPaid: res.data.shortfallPaid, specialContractPaid: res.data.specialContractPaid, totalPaid: res.data.totalPaid, 
                    cashOwing: res.data.cashOwing, insuranceOwing: res.data.insuranceOwing, shortfallOwing: res.data.shortfallOwing, specialContractOwing: res.data.specialContractOwing, totalOwing: res.data.totalOwing, authorisationNumber: res.data.authorisationNumber, authorisationAmount: res.data.authorisationAmount,
                    speciality: res.data.speciality, rate: res.data.rate, institutionId: res.data.institutionId, billItems: res.data.billItems, billCategory: res.data.billCategory, billServices: res.data.billServices, options: options, drugAllergies: res.data.drugAllergies, otherAllergies: res.data.otherAllergies,
          health263Claim: res.data.health263Claim, canSendToHealth263: res.data.canSendToHealth263, canSendToHealth263WithOverride: res.data.canSendToHealth263WithOverride, canCancelHealth263WithBio: res.data.canCancelHealth263WithBio, canCancelHealth263: res.data.canCancelHealth263, patientEnrolled: res.data.patientEnrolled, health263MessageShow: res.data.health263MessageShow, combinedMessages: res.data.combinedMessages
                });
                setTimeout(() => { this.setLoader(false)  }, 2000);
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    save() {
        this.setLoader(true)
        var billItems = this.state.billItems
        for(var i in this.state.billItems)
           {
            billItems[i].billStartDate = this.processDateTime(billItems[i].billStartDate)
            billItems[i].billEndDate = this.processDateTime(billItems[i].billEndDate)
           }
        var data = {
                visitStatus: this.state.visitStatus, patientId: this.state.patientId, visitId: this.state.visitId, managementData: this.state.managementData, investigationRequests: this.state.investigationRequests, treatmentOther: this.state.treatmentOther, history: {presentingComplaintList: this.state.presentingComplaintList, historyOfPresentingComplaints: this.state.historyOfPresentingComplaints, backgroundHistory: this.state.backgroundHistory, systemicReview: this.state.systemicReview, pastMedicalHistory: this.state.pastMedicalHistory, familyHistory: this.state.familyHistory, socialHistory: this.state.socialHistory }
            , examination: { appearanceChoice: this.state.appearanceChoice, appearanceText: this.state.appearanceText, behaviourChoice: this.state.behaviourChoice, behaviourText: this.state.behaviourText, dehydrationChoice: this.state.dehydrationChoice, dehydrationText: this.state.dehydrationText, wastingChoice: this.state.wastingChoice, wastingText: this.state.wastingText, hirsutismChoice: this.state.hirsutismChoice, hirsutismText: this.state.hirsutismText, thyroidChoice: this.state.thyroidChoice, thyroidText: this.state.thyroidText, palpableChoice: this.state.palpableChoice, palpableText: this.state.palpableText, clubbingChoice: this.state.clubbingChoice, clubbingText: this.state.clubbingText, pallorChoice: this.state.pallorChoice, pallorText: this.state.pallorText, icterusChoice: this.state.icterusChoice, icterusText: this.state.icterusText, cyanosisChoice: this.state.cyanosisChoice, cyanosisText: this.state.cyanosisText, edemaChoice: this.state.edemaChoice, edemaText: this.state.edemaText, lossphyChoice: this.state.lossphyChoice, lossphyText: this.state.lossphyText, herniaChoice: this.state.herniaChoice, herniaText: this.state.herniaText, notes: this.state.examinationNotes }
            , patientDiagnosis: this.state.patientDiagnosis, diagnosisText: this.state.diagnosisText, reviews: this.state.reviews, medicalCertificates: this.state.medicalCertificates, rehabilitations: this.state.rehabilitations, referrals: this.state.referrals, treatments: this.state.treatments, investigations: this.state.investigations, findings: this.state.findings
            , patientHistory: {drugAllergiesText: this.state.drugAllergies, otherAllergiesText: this.state.otherAllergies}
            , billItems: billItems, billId: this.state.billId, speciality: this.state.speciality
            , cashPayable: this.state.cashPayable, insurancePayable: this.state.insurancePayable, shortfallPayable: this.state.shortfallPayable, specialContractPayable: this.state.specialContractPayable, totalPayable: this.state.totalPayable
            , cashOwing: this.state.cashOwing, insuranceOwing: this.state.insuranceOwing, shortfallOwing: this.state.shortfallOwing, specialContractOwing: this.state.specialContractOwing, totalOwing: this.state.totalOwing, authorisationNumber: this.state.authorisationNumber, authorisationAmount: this.state.authorisationAmount
       }
        axios.post(localStorage.getItem('host') + '/patientmanagement/gpsave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.getList()
                document.getElementById('topDiv').scrollIntoView()
                this.openGpAction(this.state.visitId,  res.data )
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('gpAction').style = 'display:none'
    }
    setNurseDetails(name, value) {
        switch (name) {
            case "weight":
                var bmi = 0;
                value = value > 0 && value < 150 ? value : 0
                if (value > 0 && this.state.height > 0) {
                    bmi = Number(((value * 10000) / (this.state.height * this.state.height)).toFixed("2"))
                }
                this.setState({ weight: value, bmi: bmi })
                break;
            case "height":
                bmi = 0;
                var waistHeightRatio = 0;
                value = value > 0 && value < 250 ? value : 0
                if (value > 0 && this.state.weight > 0) {
                    bmi = Number(((this.state.weight * 10000) / (value * value)).toFixed("2"))
                }
                if (value > 0 && this.state.waist > 0) {
                    waistHeightRatio = Number((this.state.waist / value).toFixed("2"))
                }
                this.setState({ height: value, bmi: bmi, waistHeightRatio: waistHeightRatio })
                break;
            default:
                waistHeightRatio = 0;
                value = value > 0 && value < 150 ? value : 0
                if (value > 0 && this.state.height > 0) {
                    waistHeightRatio = Number((value / this.state.height).toFixed("2"))
                }
                this.setState({ waist: value, waistHeightRatio: waistHeightRatio })
                break;
        }
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    }
    setVitalValue(index, name, value) {
        var list = this.state.vitals;
        switch (name) {
            case "vitalTime":
                list[index].vitalTime = value;
                break;
            case "temperature":
                list[index].temperature = value > 0 && value < 45 ? value : 0;
                break;
            case "pulse":
                list[index].pulse = value > 0 && value < 120 ? value : 0;
                break;
            case "glucometer":
                list[index].glucometer = value > 0 && value < 30 ? value : 0;
                break;
            case "systolic":
                list[index].systolic = value > 0 && value < 140 ? value : 0;
                break;
            case "diastolic":
                list[index].diastolic = value > 0 && value < 140 ? value : 0;
                break;
            default:
                list[index].spO2 = value > 0 && value < 100 ? value : 0;
                break;

        }
        this.setState({ vitals: list })
    }
    addVital() {
        let list = this.state.vitals.slice();
        const now = new Date();
        list.push({ id: 0, vitalTime: checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()), temperature: 0, pulse: 0, glucometer: 0, systolic: 0, diastolic: 0, spO2: 0 });
        this.setState({ vitals: list })
    }
    viewHistory(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/summaryview/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    viewOldHistory(id, date)
      {
        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/old/view/' + id+'/'+date, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )   
      }
    viewConsolidated(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/summaryviewconsolidated/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor Consolidated' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    viewPatientHistory(patientId){

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/patienthistorysummary/' + patientId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Patient History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    showTreatmentRecord(patientId) {

        this.setState({ treatmentRecords: [] })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/treatmentlistview/' + patientId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ treatmentRecords: res.data });
                document.getElementById('treatmentRecord').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    genericClosePopup() {
        document.getElementById('genericPopup').style = 'display:none'
    }

    treatmentClosePopup() {
        document.getElementById('treatmentRecord').style = 'display:none'
    }
    viewResults() {
        document.getElementById('resultPopup').style = 'display:block'
    }
    resultClosePopup() {
        document.getElementById('resultPopup').style = 'display:none'
    }
    uploadFile(fileType, file) {
        if (file !== null || file !== '') {
            var data = new FormData();
            data.append("file", file)
            data.append("fileType", fileType)
            data.append("visitId", this.state.visitId)
            axios.post(localStorage.getItem('host') + '/file/filesave', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data'

                }
            }).then(
                res => {
                    console.log(res.data)
                    if (fileType === "REFERRAL_LETTERS") {
                        this.setState({ referralLetters: res.data, msg: 'Successfully uploaded Referral Letter' });
                    }
                    else {
                        this.setState({ notes: res.data, msg: 'Successfully uploaded Notes' });
                    }

                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }, err => {
                    console.log(".....")
                }
            )

        }
    }
    deleteFile(id, fileExtension, fileType) {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/deletefile/' + id + '/' + fileType + '/' + fileExtension + '/' + this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                if (fileType === "REFERRAL_LETTERS") {
                    this.setState({ referralLetters: res.data, msg: 'Successfully deleted Referral Letter' });
                }
                else {
                    this.setState({ notes: res.data, msg: 'Successfully deleted Notes' });
                }
                this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    }
    showFile(id, fileExtension) {
        this.setState({ fileData: localStorage.getItem('host') + '/file/pdfview/' + id + '/' + fileExtension });
        document.getElementById('showFileNurseAction').style = 'display:block'
        //'data:application/pdf;base64,' +

    }
    fileNurseClosePopup() {
        this.setState({ fileData: '' });
        document.getElementById('showFileNurseAction').style = 'display:none'
    }
    showOldFile(id) {
        this.setState({ fileData: localStorage.getItem('host') + '/old/emrfileresultoutput/' + id  });
        document.getElementById('showFileNurseAction').style = 'display:block'
    }
    showOldResults(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/old/resultlistview/' + oldPatient , {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Nurse History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    toggleTabs(name) {
        switch (name) {
            case "history":
                this.setState({ patientHistory: false, history: true, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: false, graphical: false, bill: false, historyOfPresentingComplaintsT: false, presentingComplaintsT: false,backgroundHistoryT: true, systemicReviewT: false, pastMedicalHistoryT: false, familyHistoryT: false, socialHistoryT: false, allergies: false })
                break
            case "examination":
                this.setState({ patientHistory: false, history: false, examination: true, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: false, graphical: false, bill: false })
                break
            case "investigation":
                this.setState({ patientHistory: false, history: false, examination: false, investigation: true, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: false, graphical: false, bill: false })
                break
            case "treatment":
                this.setState({ patientHistory: false, history: false, examination: false, investigation: false, treatment: true, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: false, graphical: false, bill: false })
                break
            case "rehabilitation":
                this.setState({ patientHistory: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: true, referral: false, medicalCertificate: false, review: false, management: false, graphical: false, bill: false })
                break
            case "referral":
                this.setState({ patientHistory: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: true, medicalCertificate: false, review: false, management: false, graphical: false, bill: false })
                break
            case "medicalCertificate":
                this.setState({ patientHistory: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: true, review: false, management: false, graphical: false, bill: false })
                break
            case "review":
                this.setState({ patientHistory: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: true, management: false, graphical: false, bill: false })
                break
            case "management":
                this.setState({ patientHistory: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: true, graphical: false, bill: false })
                break
            case "graphical":
                this.setState({ patientHistory: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: false, graphical: true, bill: false })
                break
            case "bill":
                this.setState({ patientHistory: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: false, graphical: false, bill: true })
                break
            default:
                this.setState({ patientHistory: true, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: false, graphical: false, bill: false })
                break

        }
    }
    addReviewTable(reviewDate, reviewTime) {
        let list = this.state.reviews.slice();
        list.push({ id: 0, reviewDate: reviewDate, reviewTime: reviewTime, remarks: '', activeStatus: 1 });
        this.setState({ reviews: list });
    }

    addMedicalCertificateTable() {
        let list = this.state.medicalCertificates.slice();
        list.push({ id: 0, name: '', startDate: date1, endDate: date1, activeStatus: 1 });
        this.setState({ medicalCertificates: list });
    }
    addRehabilitationTable() {
        let list = this.state.rehabilitations.slice();
        list.push({ id: 0, type: '', remarks: '', activeStatus: 1 });
        this.setState({ rehabilitations: list });
    }
    addInvestigationTable() {
        let list = this.state.investigationRequests.slice();
        list.push({ id: 0, name: '', activeStatus: 1 });
        this.setState({ investigationRequests: list });
    }
    addReferralTable() {
        let list = this.state.referrals.slice();
        list.push({ id: 0, consultant: '', referralNotes: '', activeStatus: 1 });
        this.setState({ referrals: list });
    }
    addTreatmentTable() {
        let list = this.state.treatments.slice();
        list.push({ id: 0, dosage: '', drugName: '', formulationName: '', frequencyName: '', routeName: '', durationName: '', repeats: '', activeStatus: 1 });
        this.setState({ treatments: list });
    }
    deleteReview(index) {
        let list = this.state.reviews.slice();
        list[index].activeStatus = 0
        this.setState({ reviews: list });
    }

    deleteMedicalCertificate(index) {
        let list = this.state.medicalCertificates.slice();
        list[index].activeStatus = 0
        this.setState({ medicalCertificates: list });
    }
    deleteRehabilitation(index) {
        let list = this.state.rehabilitations.slice();
        list[index].activeStatus = 0
        this.setState({ rehabilitations: list });
    }
    deleteInvestigation(index) {
        let list = this.state.investigationRequests.slice();
        list.splice(index, 1); 
        this.setState({ investigationRequests: list });
    }
    deleteReferral(index) {
        let list = this.state.referrals.slice();
        list[index].activeStatus = 0
        this.setState({ referrals: list });
    }
    deleteTreatment(index) {
        let list = this.state.treatments.slice();
        list[index].activeStatus = 0
        this.setState({ treatments: list });
    }
    setReview(index, name, value) {
        let list = this.state.reviews.slice();
        switch (name) {
            case "reviewDate":
                list[index].reviewDate = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ reviews: list });
    }

    setMedicalCertificate(index, name, value) {
        let list = this.state.medicalCertificates.slice();
        switch (name) {
            case "name":
                list[index].name = value
                break;
            case "startDate":
                list[index].startDate = value
                break;
            default:
                list[index].endDate = value
                break;
        }
        this.setState({ medicalCertificates: list });
    }
    setRehabilitation(index, name, value) {
        let list = this.state.rehabilitations.slice();
        switch (name) {
            case "type":
                list[index].type = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ rehabilitations: list });
    }
    setInvestigation(index, name, value) {
        let list = this.state.investigationRequests.slice();
        switch (name) {
            case "clinicalData":
                list[index].clinicalData = value
                break;
            case "unitRequested":
                list[index].unitRequested = value
                break;
            default:
                list[index].examinationRequested = value
                break;
        }

        this.setState({ investigationRequests: list });
    }
    setReferral(index, name, value) {
        let list = this.state.referrals.slice();
        switch (name) {
            case "consultant":
                list[index].consultant = value
                break;
            default:
                list[index].referralNotes = value
                break;
        }
        this.setState({ referrals: list });
    }
    setTreatment(index, name, value) {
        let list = this.state.treatments.slice();
        switch (name) {
            case "dosage":
                list[index].dosage = value
                break;
            case "drugName":
                list[index].drugName = value
                break;
            case "formulationName":
                list[index].formulationName = value
                break;
            case "frequencyName":
                list[index].frequencyName = value
                break;
            case "routeName":
                list[index].routeName = value
                break;
            case "durationName":
                list[index].durationName = value
                break;
            default:
                list[index].repeats = value
                break;
        }
        this.setState({ treatments: list });
    }

    toggleHistoryTabs(name) {
        switch (name) {
            case "historyOfPresentingComplaintsT":
                this.setState({ historyOfPresentingComplaintsT: true, presentingComplaintsT: false,backgroundHistoryT: false, systemicReviewT: false, pastMedicalHistoryT: false, familyHistoryT: false, socialHistoryT: false, allergies: false })
                break
            case "presentingComplaintsT":
                this.setState({ historyOfPresentingComplaintsT: false, presentingComplaintsT: true,backgroundHistoryT: false, systemicReviewT: false, pastMedicalHistoryT: false, familyHistoryT: false, socialHistoryT: false, allergies: false })
                break
            case "backgroundHistoryT":
                this.setState({historyOfPresentingComplaintsT: false, presentingComplaintsT: false,backgroundHistoryT: true, systemicReviewT: false, pastMedicalHistoryT: false, familyHistoryT: false, socialHistoryT: false, allergies: false})
                break
            case "systemicReviewT":
                this.setState({historyOfPresentingComplaintsT: false, presentingComplaintsT: false,backgroundHistoryT: false, systemicReviewT: true, pastMedicalHistoryT: false, familyHistoryT: false, socialHistoryT: false, allergies: false })
                break
            case "pastMedicalHistoryT":
                this.setState({ historyOfPresentingComplaintsT: false, presentingComplaintsT: false,backgroundHistoryT: false, systemicReviewT: false, pastMedicalHistoryT: true, familyHistoryT: false, socialHistoryT: false, allergies: false })
                break
            case "familyHistoryT":
                this.setState({ historyOfPresentingComplaintsT: false, presentingComplaintsT: false,backgroundHistoryT: false, systemicReviewT: false, pastMedicalHistoryT: false, familyHistoryT: true, socialHistoryT: false, allergies: false})
                break 
            case "socialHistoryT":
                this.setState({ historyOfPresentingComplaintsT: false, presentingComplaintsT: false,backgroundHistoryT: false, systemicReviewT: false, pastMedicalHistoryT: false, familyHistoryT: false, socialHistoryT: true, allergies: false })
                break
            case "allergies":
                this.setState({ historyOfPresentingComplaintsT: false, presentingComplaintsT: false,backgroundHistoryT: false, systemicReviewT: false, pastMedicalHistoryT: false, familyHistoryT: false, socialHistoryT: false, allergies: true })
                break
           
            default:
                this.setState({  historyOfPresentingComplaintsT: false, presentingComplaintsT: false,backgroundHistoryT: false, systemicReviewT: false, pastMedicalHistoryT: false, familyHistoryT: false, socialHistoryT: true, allergies: false  })
                break

        }
    }
    getDiagnosisList() {
        this.setState({ diagnosisList: [], diagnosis: '' })
        console.log('..... i am ')
        if (this.state.searchDiagnosis !== null && this.state.searchDiagnosis !== "") {
            console.log('..... i am ')
            this.setLoader(true)
            axios.get(localStorage.getItem('host') + '/master/diagnosislist?text=' + this.state.searchDiagnosis, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ diagnosisList: res.data });
                    this.setLoader(false)
                }, err => {
                    this.setState({ error: err + "" })
                    this.setLoader(false)
                }
            )

        }
    }
    insertDiagnosis() {
        if (this.state.diagnosis !== null && this.state.diagnosis !== '') {
            let list = this.state.patientDiagnosis.slice();
            for (var i in this.state.diagnosisList) {
                if(this.state.diagnosisList[i].code === this.state.diagnosis)
                   {
                    list.push({ id: 0, code: this.state.diagnosis, description: this.state.diagnosis+" - "+this.state.diagnosisList[i].description, activeStatus: 1 });
                   }
            }
            this.setState({ patientDiagnosis: list });
        }

    }
    deleteDiagnosis(index) {
        let list = this.state.patientDiagnosis.slice();
        list[index].activeStatus = 0
        this.setState({ patientDiagnosis: list });
    }
    insertPresentingComplaintList()
    {
        let list = this.state.presentingComplaintList.slice();
        list.push({complaint: '', duration: ''})
        this.setState({ presentingComplaintList: list });  
    }
    setPresentingComplaintList(index, name, value) {
        let list = this.state.presentingComplaintList.slice();
        switch (name) {
            case "complaint":
                list[index].complaint = value
                break;
            default:
                list[index].duration = value
                break;
        }
        this.setState({ presentingComplaintList: list });
    }
    deletePresentingComplaint(i)
    {
    let list = this.state.presentingComplaintList.slice();   
    list.splice(i, 1);
    this.setState({ presentingComplaintList: list }); 
    }
    updateAfterSave(visitId, results)
     {
     if(results.length > 0)
        {
        this.setState({results: results})
        }
      else
        {
            axios.get(localStorage.getItem('host') + '/patientmanagement/nursetrimmed/' + visitId, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, vitalColors: res.data.vitalColors});
                }, err => {
                    this.setState({ error: err + "" })
                }
            )
        }  
     }
     setLoader(loader)
       {
        this.setState({loader: loader})
       }
       claimSubmission(bool, overrideClaim, sendAuthoCode) {
         /* if (bool === 'false')
          {
              document.getElementById("submitHealth263").disabled = true;
          } else
          {
              document.getElementById("reverseHealth263").disabled = true;
          }*/
          this.props.setLoader(true)
          axios.get(localStorage.getItem('host') + '/interchange/sendtohealth263/OUTPATIENT/' + this.state.billId + '/' + this.state.patientInsuranceId + '/' + this.state.companyId + '/' + bool + "/" + overrideClaim + "/" + sendAuthoCode, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
          })
                  .then(res => {
                      if(res.data.error)
                         {
                          this.setState({error: res.data.error})
                          this.updateHealth263(this.state.billId)
                         }
                      else
                         {   
                      this.updateHealth263(this.state.billId)
                      this.props.getClaimDetails(this.state.billId);
                         }

                      this.props.setLoader(false)
                  })
      }
      updateHealth263(billId)
        {
          this.props.setLoader(true)
          this.setState({health263Claim: false, canSendToHealth263: false, canSendToHealth263WithOverride: false, canCancelHealth263WithBio: false, canCancelHealth263: false, patientEnrolled: false, health263MessageShow: false, combinedMessages: ''})
          axios.get(localStorage.getItem('host') + '/patient/gethealth263update/' + billId, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
          }).then(
              res => {
                  this.setState({health263Claim: res.data.health263Claim, canSendToHealth263: res.data.canSendToHealth263, canSendToHealth263WithOverride: res.data.canSendToHealth263WithOverride, canCancelHealth263WithBio: res.data.canCancelHealth263WithBio, canCancelHealth263: res.data.canCancelHealth263, patientEnrolled: res.data.patientEnrolled, health263MessageShow: res.data.health263MessageShow, combinedMessages: res.data.combinedMessages});
                  window.scrollTo(0, 0);
                     this.props.setLoader(false)
              }, err => {
                   this.setState({error: err+""})
                   window.scrollTo(0, 0);
                  this.props.setLoader(false)
              }
          )
  
        }
        getReviewTime(reviewTime)
           {
            var time = ''
            for(var j in this.state.appointmentTimes)
            {
             if(Number(reviewTime) === Number(this.state.appointmentTimes[j].id))
                {
                 time = this.state.appointmentTimes[j].name
                }
            }
            return time
           }   

           currencyFormat(amount) {
            let val = (amount / 1).toFixed(2)
            return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
        }
        processDateTime(date)
          {
            const now = new Date(date);
            const todayY = now.getFullYear();
            const todayM = checkZero(now.getMonth() + 1);
            const todayD = checkZero(now.getDate());
            var date1 = todayY + '-' + checkZero(todayM) + '-' + checkZero(todayD)+ ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());;
            console.log(date1)
           return date1;
          }
    
    addServices()
    {  console.log('......... service cost id '+JSON.stringify(this.state.serviceCostId)) 
     if(this.state.serviceCostId.value > 0)
        {  console.log('......... service cost id '+this.state.serviceCostId.value) 
         const now = new Date();
         var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
         let list = this.state.billItems.slice();
         var s = '';
         for (var i in this.state.billServices) {
             if (this.state.billServices[i].id === Number(this.state.serviceCostId.value)) {
                 s = this.state.billServices[i];
             }
         }
         if(this.state.cashOnly === 1 || this.state.cashOnly === "1")
         {
          s.cashOnly = 1;
         }
         list.push({id: 0, serviceId: s.id, name: s.name, billItemType: 'SERVICE', serviceType: s.serviceType, serviceClass: s.serviceClass
         , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: s.quantity, unitCost: s.fixedCost, min: (Number(s.fixedCost) - this.getMargin(s)), max: (Number(s.fixedCost) + this.getMargin(s)), cost: s.fixedCost, totalCost: s.fixedCost, cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
             for (var index in s.services)
             {
                 var ss = s.services[index];
                 list.push({id: 0, serviceId: ss.id, name: ss.name, billItemType: 'SERVICE', serviceType: ss.serviceType, serviceClass: ss.serviceClass
                     , tariffCode: ss.tariffCode, billStartDate: date1, billEndDate: date1, quantity: ss.quantity, unitCost: ss.fixedCost, min: (Number(ss.fixedCost) - this.getMargin(ss)), max: (Number(ss.fixedCost) + this.getMargin(ss)), cost: ss.fixedCost, totalCost: ss.fixedCost, cashOnly: ss.cashOnly, allowEdit: ss.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: ss.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
             }
         this.setState({ billItems: list });
         this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
         this.doValidate()
        }
    }  

    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }
    validate() {

        var error = false;
        var billItems = this.state.billItems;
        if (billItems === null) {
            this.setState({ billItemsError: "Bill Items cannot be empty" })
            error = true
        }
        else if (billItems.length === 0) {
            this.setState({ billItemsError: "Bill Items cannot be empty" })
            error = true
        }
        else {
           
            
            for(var i in billItems)
            {
             billItems[i].unitCostError =  "" 
             if(billItems[i].allowEdit)
               {
                if (billItems[i].unitCost === null) {
                    billItems[i].unitCostError =  "Unit Cost cannot be empty"
                    error = true
                }
                else if (billItems[i].unitCost === "") {
                    billItems[i].unitCostError =  "Unit Cost cannot be empty"
                    error = true
                }
                else if (billItems[i].unitCost > billItems[i].max) {
                    billItems[i].unitCostError =  "Unit Cost cannot be greater than "+billItems[i].max
                    error = true
                }
                else if (billItems[i].unitCost < billItems[i].min) {
                    billItems[i].unitCostError =  "Unit Cost cannot be less than "+billItems[i].min
                    error = true
                }
                else {
                    billItems[i].unitCostError =  "" 
                }

                console.log(billItems[i].unitCostError)
               }
          this.setState({ billItemsError: "", billItems: billItems })   
            }
        }
        console.log(" error" + error)
        if(this.state.allowSave === false)
          {
            this.setState({ error: "The visit for this patient cannot be saved. Please contact accounts department for Help", showSubmit: false })
          }
        else  if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ error: "", showSubmit: true })
        }
    } 

 getMargin(service)
    {
        return Math.ceil(service.fixedCost * (service.margin / 100));
    }   
 setBillItem(name, index, value)   
    {
     var list = this.state.billItems;   
    switch(name)
       {    
          case "billStartDate":
             list[index].billStartDate = value
             break;
         case "billEndDate":
             list[index].billEndDate = value
             break;   
         case "quantity":
             if(list[index].billItemType === 'PRODUCT')
             {
               if(Number(list[index].quantity) > Number(value))
                  {
                     list[index].quantity = value  
                  }
             }
             else
               {
                 list[index].quantity = value
                 this.calcQuantity(index, list[index].quantity)
               }
             break;   
         case "unitCost":
             list[index].unitCost = value
             this.calcQuantity(index, list[index].quantity)
             break;   
         case "cashOnly":
             list[index].cashOnly = Number(value)
             this.calcQuantity(index, list[index].quantity)
             break;     
         case "authoAmount":
             list[index].authoAmount = value 
             this.calcQuantity(index, list[index].quantity)
             break;  
          default: 
                  console.log("....")
                  break;   
       }
   this.setState({ billItems: list })    
   this.doValidate() 
    }
    calcBill(list, authorisationNumber, authorisationAmount)
     {
         var cashPayable = 0;
         var insurancePayable = 0;
         var shortfallPayable = 0;
         var specialContractPayable = 0;
         var authoAmount = 0;
         for (var index in list)
         {
             if (list[index].activeStatus === "1" || list[index].activeStatus === 1)
             {

                 if (list[index].cashOnly)
                 {
                    cashPayable += list[index].totalCost;
                 } else
                 {
                     switch (this.state.billCategory)
                     {
                         case 'CASH':
                             cashPayable += list[index].totalCost;
                             break;
                         case 'INSURANCE':
                             insurancePayable += list[index].totalCost;
                             authoAmount += Number(list[index].authoAmount);
                             break;
                         case 'SPECIAL_CONTRACT':
                             specialContractPayable += list[index].totalCost;
                             break;
                             default: 
                                     console.log("....")
                                     break;   
                     }
                 }
             }
         }
         if (authorisationNumber !== null && authorisationNumber !== '' && authorisationAmount > 0)
         {
             cashPayable += Number(insurancePayable) - Number(authorisationAmount);
             insurancePayable = authorisationAmount;
         }
         if(authoAmount > 0)
          {
             shortfallPayable = Number(insurancePayable) - Number(authoAmount);
             insurancePayable = authoAmount;
          }
         var cashOwing = Number(cashPayable) - Number(this.state.cashPaid);
         var insuranceOwing = Number(insurancePayable) - Number(this.state.insurancePaid);
         var shortfallOwing = Number(shortfallPayable) - Number(this.state.shortfallPaid);
         var specialContractOwing = Number(specialContractPayable) - Number(this.state.specialContractPaid);
         var totalPayable = Number(cashPayable) + Number(insurancePayable) + Number(shortfallPayable) + Number(specialContractPayable);
         var totalOwing = Number(cashOwing) + Number(insuranceOwing) + Number(shortfallOwing) + Number(specialContractOwing);
         this.setState({cashPayable: cashPayable, insurancePayable: insurancePayable, shortfallPayable: shortfallPayable, specialContractPayable: specialContractPayable, totalPayable: totalPayable
             , cashOwing: cashOwing, insuranceOwing: insuranceOwing, shortfallOwing: shortfallOwing, specialContractOwing: specialContractOwing, totalOwing: totalOwing})
     }
     calcQuantity(index, value)
     {
         var list = this.state.billItems;  
         list[index].cost = Number(list[index].unitCost) * Number(value);
         list[index].totalCost = list[index].cost;
         this.setState({ billItems: list })  
         this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount);
     }
          hideEntry(index)
          {
             let list = this.state.billItems.slice();
             list[index].activeStatus = 0
             this.setState({ billItems: list }); 
             this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount);
          }  
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (<div id="gpAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info" id="topDiv">Gp Action</div>
            <div className="card-body" >
                {divMsg}
                {loaderDiv}
                <div id="genericPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">{this.state.popupName}</div>
                        <div className="card-body" >
                            <span dangerouslySetInnerHTML={{ __html: this.state.htmlText }}></span>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.genericClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="treatmentRecord" class="page-popup page-popup-overlay"><div class="card" >
                    <div class="card-header   bg-info">Treatment Record</div>
                    <div class="card-body" >
                        {this.state.treatmentRecords.map((item, index) => (
                            <div class="row" key={index} >
                                <div class="col-xs-12 col-md-12 col-lg-12">
                                    <div class="card border-success">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col"><span className="fw-bold">Date:</span> {item.treatmentDateTime}</div>
                                                <div className="col"><span className="fw-bold"></span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table table-striped" >
                                        <tr>
                                            <th>Drug</th>
                                            <th>Dosage</th>
                                            <th>Frequency</th>
                                            <th>Route</th>
                                            <th>Duration</th>
                                            <th>Formulation</th>
                                            <th>Repeats</th>
                                        </tr>
                                        <tbody>
                                            {item.treatments.map((treatment, i) => (
                                                <tr key={i} >
                                                    <td>{treatment.drugName}</td>
                                                    <td>{treatment.dosage}</td>
                                                    <td>{treatment.frequencyName}</td>
                                                    <td>{treatment.routeName}</td>
                                                    <td> {treatment.durationName}</td>
                                                    <td>{treatment.formulationName}</td>
                                                    <td>{treatment.repeats}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div class="row">
                        <div class="col" align="center" ><input class="btn btn-primary" type='button' onClick={() => this.treatmentClosePopup()} value="Close" /></div>
                    </div>
                </div>
                </div>
                <div id="resultPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">Result View</div>
                        <div className="card-body" >
                            <table className="table table-striped">
                                <tr>
                                    <th>Treatment Date</th>
                                    <th>File</th>
                                    <th>Remarks</th>
                                </tr>
                                <tbody>
                                    {this.state.results.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.visitDate}</td>
                                            {item.fileStorageId > 0 ? (<td><span className="link" onClick={() => this.showFile(item.fileStorageId, item.fileExtension)}>{item.fileName}</span></td>) : (<td></td>)}
                                            <td>{item.remarks}</td>
                                        </tr>
                                    ))}
                                    {this.state.oldResults.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.visitDate}</td>
                                            {item.resultFileName !== "" ? (<td><span className="link" onClick={() => this.showOldFile(item.resultFileId)}>{item.resultFileName}</span></td>) : (<td></td>)}
                                            <td>{item.resultText}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.resultClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="showFileNurseAction" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                            <iframe title="File View" src={this.state.fileData} type="application/pdf" style={{ width: 100 + '%', height: 500 + 'px' }}></iframe>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-2">
                        {this.state.id > 0 && (<div ><span className="link" onClick={() => this.props.getPrintOut(this.state.visitId, 'DOCTOR', [], this.state.companyId)} title="Print" ><i className="bi bi-printer-fill" style={{ fontSize: 3 + 'em' }}></i></span></div>)}
                        {this.state.id > 0 && (<div className="card card-green">
                            <div className="card-header text-dark">Current</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewHistory(this.state.visitId)} >Current</span></li>
                                </ul>
                            </div>
                        </div>)}
                        <div className="card card-green">
                            <div className="card-header  text-dark">Consolidated</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewConsolidated(this.state.visitId)}>Consolidated</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="card card-green">
                            <div className="card-header  text-dark">Patient History</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewPatientHistory(this.state.patientId)}>Summary</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                            <ul className="nav nav-pills  d-flex flex-column" role="tablist">
                            <li className="nav-item"><span className={this.state.history ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("history") }}>History</span></li>
                            <li className="nav-item"><span className={this.state.examination ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("examination") }}>Examination</span></li>
                            <li className="nav-item"><span className={this.state.investigation ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("investigation") }}>Investigations</span></li>
                            <li className="nav-item"><span className={this.state.treatment ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("treatment") }}>Treatment</span></li>
                            <li className="nav-item"><span className={this.state.rehabilitation ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("rehabilitation") }}>Rehabilitation</span></li>
                            <li className="nav-item"><span className={this.state.referral ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("referral") }}>Referral</span></li>
                            <li className="nav-item"><span className={this.state.medicalCertificate ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("medicalCertificate") }}>Medical Certificate</span></li>
                            <li className="nav-item"><span className={this.state.review ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("review") }}>Review</span></li>
                            <li className="nav-item"><span className={this.state.management ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("management") }}>Management</span></li>
                            <li className="nav-item"><span className={this.state.bill ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("bill") }}>Bill</span></li>
                           </ul>
                            </div>
                        </div>
                        {this.state.historyVisits.length > 0 && (<div className="card card-green" >
                            <div className="card-header  text-dark">History</div>
                            <div className="card-body"> 
                                <ul>
                                    {this.state.historyVisits.map((item, index) => (
                                        <li key={index}><span className="link" onClick={() => this.viewHistory(item.id)} >{item.name}</span></li>
                                    ))}
                                    {this.state.emrOlds.map((item, index) => (
                                        <li key={index}><span className="link" onClick={() => this.viewOldHistory(item.id, item.date)} >{item.dateOnly}</span></li>
                                    ))}
                                </ul>
                            </div>
                        </div>)}
                        <div className="card  card-green" >
                            <div className="card-header  text-dark">Other Info</div>
                            <div className="card-body">
                                <ul>
                                    {(this.state.results.length > 0 || this.state.oldResults.length > 0) && (<li><span className="link" onClick={() => this.viewResults()}>View Results</span></li>)}
                                    <li><span className="link" onClick={() => this.showTreatmentRecord(this.state.patientId)}>View Prescription Records</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Notes</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('NOTES', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.notes.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Notes</div>
                            <div className="card-body">
                                <table >
                                    {this.state.notes.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'NOTES')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                    </div>
                    <div className="col-lg-10 col-md-10">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">Bill:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Weight (Kg):</span> {this.state.weight}</div>
                                    <div className="col"><span className="fw-bold">Height (cm):</span> {this.state.height}</div>
                                    <div className="col"><span className="fw-bold">Waist (cm):</span> {this.state.waist}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">BMI:</span> {this.state.bmi}</div>
                                    <div className="col"><span className="fw-bold">Waist Height Ratio:</span> {this.state.waistHeightRatio}</div>
                                    <div className="col"><span className="fw-bold">Notes:</span> <span >{this.state.nurseNotes}</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="card card-info">
                            <div className="card-body">
                                <table className="table" id="tableNurse">
                                    <tr>
                                        <th>Time</th>
                                        <th>Temperature</th>
                                        <th>Bp</th>
                                        <th>Pulse (bmp)</th>
                                        <th>Glucometer (mmol/l)</th>
                                        <th>SpO2 (%)</th>
                                    </tr>
                                    <tbody>
                                        {this.state.vitalColors.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.vitalTime}</td>
                                                <td style={{ color: item.temperatureColor }}>{item.temperature}</td>
                                                <td><span style={{ color: item.systolicColor }}>{item.systolic}</span>/<span style={{ color: item.diastolicColor }}>{item.diastolic}</span></td>
                                                <td style={{ color: item.pulseColor }}>{item.pulse}</td>
                                                <td style={{ color: item.glucometerColor }}>{item.glucometer}</td>
                                                <td style={{ color: item.spO2Color }}>{item.spO2}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        { (this.state.drugAllergies || this.state.otherAllergies) &&
                        <div className="card border-danger">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Drug Allergies:</span> {this.state.drugAllergies}</div>
                                    <div className="col"><span className="fw-bold">Other Allergies:</span> {this.state.otherAllergies}</div>
                                    <div className="col"></div>
                                </div>
                            </div>
                        </div>}
                        {this.state.health263Claim === true && (<div>
                                        <div className="row">
                                            <div className="col"><input type="button" value="Presign Bio" className="btn btn-info" onClick={() => this.props.getPresignPi(this.state.patientId, this.state.billId)} /></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12" align="center">{this.state.combinedMessages !== '' && (<div className="alert alert-danger">{this.state.combinedMessages}</div>)}  </div>
                                        </div>
                                        <div className="row">
                                            {this.state.canSendToHealth263 && (<div align="center" className="col"><input type="button" id="submitHealth263" className="btn btn-success" onClick={() => this.claimSubmission('false', 'false', 'true')} value="CLAIM WITH BIO" /> </div>)}
                                            {this.state.canSendToHealth263WithOverride && true === false && (<div align="center" className="col"><input type="button" id="submitHealth263" className="btn btn-success" onClick={() => this.claimSubmission('false', 'true', 'false')} value="CLAIM BY-PASS" /> </div>)}
                                            {this.state.canSendToHealth263WithOverride && (<div align="center" className="col"><input type="button" id="submitHealth263" className="btn btn-success" onClick={() => this.claimSubmission('false', 'false', 'false')} value="CLAIM WITH NO BIO" /> </div>)}
                                            {this.state.canCancelHealth263WithBio &&(<div align="center" className="col"><input type="button" id="reverseHealth263" className="btn btn-danger" onClick={() => this.claimSubmission('true', 'false', 'true')} value="REVERSE CLAIM WITH BIO" /></div>)}
                                            {this.state.canCancelHealth263 &&(<div align="center" className="col"><input type="button" id="reverseHealth263" className="btn btn-danger" onClick={() => this.claimSubmission('true', 'false', 'false')} value="REVERSE CLAIM WITH NO BIO" /></div>)}
                                            <div align="center" className="col"><input type="button" id="responseHealth263" className="btn btn-success" onClick={() => this.props.getClaimDetails(this.state.billId)} value="View Claim Response" /></div>
                                        </div>
                                    </div>) }
                        <div className="row">
                            <div className="col" align="right"><button onClick={() => this.save()} className="btn btn-primary">Save</button></div>
                            <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                            <div className="col" align="right"><button onClick={() => this.props.openNurseAction(this.state.visitId)} className="btn btn-primary">Edit Nurse Details</button></div>
                            <div className="col" align="left"><button onClick={() => this.props.getResultAction(this.state.visitId)} className="btn btn-primary">Upload Results</button></div>
                        </div>
                        <br />
                        
                        <div className="tab-content" id="myTabContent">
                            <div className={this.state.history ? "tab-pane fade show active" : "tab-pane fade"} >
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        History
                                    </div>
                                    <div className="card-body">
                                        <ul className="nav nav-tabs">
                                           <li className="nav-item"><span className={this.state.backgroundHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("backgroundHistoryT") }}>BACKGROUND HISTORY</span></li>
                                        <li className="nav-item"><span className={this.state.presentingComplaintsT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("presentingComplaintsT") }}>PRESENTING COMPLAINTS</span></li>
                                            <li className="nav-item"><span className={this.state.historyOfPresentingComplaintsT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("historyOfPresentingComplaintsT") }}>HISTORY OF PRESENTING COMPLAINTS</span></li>
                                            <li className="nav-item"><span className={this.state.systemicReviewT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("systemicReviewT") }}>SYSTEM REVIEW</span></li>
                                            <li className="nav-item"><span className={this.state.pastMedicalHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("pastMedicalHistoryT") }}>PAST MEDICAL HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.familyHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("familyHistoryT") }}>FAMILY HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.socialHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("socialHistoryT") }}>SOCIAL HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.allergies ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("allergies") }}>ALLERGIES</span></li>
                                       </ul>
                                        <br />
                                        <div className="tab-content">
                                            <div className={this.state.historyOfPresentingComplaintsT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.historyOfPresentingComplaints} onChange={e => { this.setState({ historyOfPresentingComplaints: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.backgroundHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.backgroundHistory} onChange={e => { this.setState({ backgroundHistory: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.systemicReviewT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.systemicReview} onChange={e => { this.setState({ systemicReview: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.pastMedicalHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.pastMedicalHistory} onChange={e => { this.setState({ pastMedicalHistory: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.familyHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.familyHistory} onChange={e => { this.setState({ familyHistory: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.socialHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.socialHistory} onChange={e => { this.setState({ socialHistory: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.presentingComplaintsT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <table class="table" >
                                                    <tr>
                                                        <th>Complaint</th>
                                                        <th>Duration</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tbody>
                                                        {this.state.presentingComplaintList.map((item, index) => (
                                                            <tr key={index}>
                                                                <td><input type="text" className="form-control" value={item.complaint} onChange={e => { this.setPresentingComplaintList(index, 'complaint', e.target.value); }} /></td>
                                                                <td><input type="text" className="form-control" value={item.duration} onChange={e => { this.setPresentingComplaintList(index, 'duration', e.target.value); }} /></td>
                                                                <td><span className="link-red" onClick={() => this.deletePresentingComplaint(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div class="row">
                                                    <div class="col-lg-12 form-inline"><input type="button" onClick={() => this.insertPresentingComplaintList()} class="btn btn-success" value="Insert New Line" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={this.state.examination ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Physical and General Examination
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.appearanceChoice === "1"} onChange={e => this.setState({ appearanceChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Appearance</span> <input type="text" value={this.state.appearanceText} onChange={e => this.setState({ appearanceText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.behaviourChoice === "1"} onChange={e => this.setState({ behaviourChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Behavior</span><input type="text" value={this.state.behaviourText} onChange={e => this.setState({ behaviourText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.dehydrationChoice === "1"} onChange={e => this.setState({ dehydrationChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Dehydration</span><input type="text" value={this.state.dehydrationText} onChange={e => this.setState({ dehydrationText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.wastingChoice === "1"} onChange={e => this.setState({ wastingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Wasting </span><input type="text" value={this.state.wastingText} onChange={e => this.setState({ wastingText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.hirsutismChoice === "1"} onChange={e => this.setState({ hirsutismChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Hirsutism </span><input type="text" value={this.state.hirsutismText} onChange={e => this.setState({ hirsutismText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.thyroidChoice === "1"} onChange={e => this.setState({ thyroidChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Thyroid Enlargement </span><input type="text" value={this.state.thyroidText} onChange={e => this.setState({ thyroidText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.palpableChoice === "1"} onChange={e => this.setState({ palpableChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Palpable Lymph Nodes</span><input type="text" value={this.state.palpableText} onChange={e => this.setState({ palpableText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.clubbingChoice === "1"} onChange={e => this.setState({ clubbingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Clubbing</span> <input type="text" value={this.state.clubbingText} onChange={e => this.setState({ clubbingText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.pallorChoice === "1"} onChange={e => this.setState({ pallorChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Pallor</span><input type="text" value={this.state.pallorText} onChange={e => this.setState({ pallorText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.icterusChoice === "1"} onChange={e => this.setState({ icterusChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Icterus</span><input type="text" value={this.state.icterusText} onChange={e => this.setState({ icterusText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.cyanosisChoice === "1"} onChange={e => this.setState({ cyanosisChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Cyanosis</span> <input type="text" value={this.state.cyanosisText} onChange={e => this.setState({ cyanosisText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.edemaChoice === "1"} onChange={e => this.setState({ edemaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Edema</span><input type="text" value={this.state.edemaText} onChange={e => this.setState({ edemaText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.lossphyChoice === "1"} onChange={e => this.setState({ lossphyChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Loss  of Weight </span><input type="text" value={this.state.lossphyText} onChange={e => this.setState({ lossphyText: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.herniaChoice === "1"} onChange={e => this.setState({ herniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Hernia</span><input type="text" value={this.state.herniaText} onChange={e => this.setState({ herniaText: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col" align="right"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.examinationNotes} onChange={e => this.setState({ examinationNotes: e.target.value })} className="form-control" rows="5" ></textarea></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Upload Findings [15MB]</span> <input type="file" accept=".pdf" onChange={e => { this.setState({ file: e.target.files[0] }); this.doValidate(); }} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Findings Text </span> <textarea name="resultText" onChange={e => { this.setState({ resultText: e.target.value }); this.doValidate(); }} className="form-control" ></textarea></div></div>
                                        </div>
                                        <div className="row">
                                            {this.state.showSubmit === true ? (<div className="col" align="center"><input type="button" onClick={() => this.save()} value="Save" className="btn btn-success" /></div>) : (<div></div>)}
                                        </div>
                                        <br />
                                        <table className="table table-striped">
                                            <tr>
                                                <th>Action</th>
                                                <th>File</th>
                                                <th>Findings</th>
                                            </tr>
                                            <tbody>
                                                {this.state.findings.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><span className="link-red" onClick={() => this.deleteResult(item.id, item.fileStorageId, item.fileExtension)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                        {item.fileStorageId > 0 ? (<td><span className="link" onClick={() => this.showFile(item.fileStorageId, item.fileExtension)}>{item.fileName}</span></td>) : (<td></td>)}
                                                        <td>{item.remarks}</td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div></div>
                            </div>
                            <div className={this.state.management ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Management</div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Detail</span>
                                                <textarea className="form-control" value={this.state.managementData} onChange={e => this.setState({ managementData: e.target.value })} ></textarea></div></div>
                                        </div>
                                    </div></div>
                            </div>
                            <div className={this.state.review ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Review</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Comments</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.reviews.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                          <td>{item.reviewDate}</td>
                                                    <td> {this.getReviewTime(item.reviewTime)} </td>
                                                        <td><textarea className="form-control" value={item.remarks} onChange={e => { this.setReview(index, 'remarks', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReview(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.props.showCalenderReview()} className="btn btn-primary" >Insert New Review Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.medicalCertificate ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Medical Certificate</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.medicalCertificates.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><select className="form-control" value={item.name} onChange={e => { this.setMedicalCertificate(index, 'name', e.target.value); }} >
                                                            <option value="">Choose Type</option>
                                                            {this.state.medicalCertificateTypes.map((m, index) => (
                                                                <option key={index} value={m} >{m}</option>
                                                            ))}
                                                        </select></td>
                                                        <td><DatePicker className="form-control" selected={new Date(item.startDate)} onChange={d => { this.setMedicalCertificate(index, "startDate", this.processDate(d)) }} dateFormat="yyyy-MM-dd" /></td>
                                                        <td><DatePicker className="form-control" selected={new Date(item.endDate)} onChange={d => { this.setMedicalCertificate(index, "endDate", this.processDate(d)) }} dateFormat="yyyy-MM-dd" /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteMedicalCertificate(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addMedicalCertificateTable()} className="btn btn-primary" >Insert New Medical Certificate Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.rehabilitation ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Rehabilitation</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Remarks</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.rehabilitations.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><select className="form-control" value={item.type} onChange={e => { this.setRehabilitation(index, 'type', e.target.value); }} >
                                                            <option value="">Choose Type</option>
                                                            <option value="PHYSIO THERAPY">PHYSIO_THERAPY</option>
                                                            <option value="OCCUPATIONAL THERAPY">OCCUPATIONAL_THERAPY</option>
                                                        </select></td>
                                                        <td><textarea className="form-control" value={item.remarks} onChange={e => { this.setRehabilitation(index, 'remarks', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteRehabilitation(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addRehabilitationTable()} className="btn btn-primary" >Insert New Rehabilitation Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.referral ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Referral</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Remarks</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.referrals.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><input type="text" className="form-control" value={item.consultant} onChange={e => { this.setReferral(index, 'consultant', e.target.value); }} /></td>
                                                        <td><textarea className="form-control" value={item.referralNotes} onChange={e => { this.setReferral(index, 'referralNotes', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReferral(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addReferralTable()} className="btn btn-primary" >Insert New Referral Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.treatment ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Treatment</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Drug</th>
                                                <th>Dosage</th>
                                                <th>Frequency</th>
                                                <th>Route</th>
                                                <th>Duration</th>
                                                <th>Formulation</th>
                                                <th>Repeats</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.treatments.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><input type="text" list={'drugList' + index} className="form-control" value={item.drugName} onChange={e => { this.setTreatment(index, 'drugName', e.target.value); }} />
                                                            <datalist id={'drugList' + index}>
                                                                {this.state.drugs.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" className="form-control" value={item.dosage} onChange={e => { this.setTreatment(index, 'dosage', e.target.value); }} /></td>
                                                        <td><input type="text" list={'frequencyList' + index} className="form-control" value={item.frequencyName} onChange={e => { this.setTreatment(index, 'frequencyName', e.target.value); }} />
                                                            <datalist id={'frequencyList' + index}>
                                                                {this.state.frequencys.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'routeList' + index} className="form-control" value={item.routeName} onChange={e => { this.setTreatment(index, 'routeName', e.target.value); }} />
                                                            <datalist id={'routeList' + index}>
                                                                {this.state.routes.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'durationList' + index} className="form-control" value={item.durationName} onChange={e => { this.setTreatment(index, 'durationName', e.target.value); }} />
                                                            <datalist id={'durationList' + index}>
                                                                {this.state.durations.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'formulationList' + index} className="form-control" value={item.formulationName} onChange={e => { this.setTreatment(index, 'formulationName', e.target.value); }} />
                                                            <datalist id={'formulationList' + index}>
                                                                {this.state.formulations.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" className="form-control" value={item.repeats} onChange={e => { this.setTreatment(index, 'repeats', e.target.value); }} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteTreatment(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addTreatmentTable()} className="btn btn-primary" >Insert New Treatment Line</button>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Treatment</span>
                                                <textarea className="form-control" value={this.state.treatmentOther} onChange={e => this.setState({ treatmentOther: e.target.value })} ></textarea></div></div>
                                        </div>
                                    </div></div>
                            </div><div className={this.state.investigation ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Investigation</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Clinical Data</th>
                                                <th>Unit Requested</th>
                                                <th>Examination Requested</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.investigationRequests.map((item, index) =>  (
                                                    <tr key={index}>
                                                        <td><input type="text" className="form-control" value={item.clinicalData} onChange={e => { this.setInvestigation(index, 'clinicalData', e.target.value); }} /></td>
                                                        <td><input type="text" className="form-control" value={item.unitRequested} onChange={e => { this.setInvestigation(index, 'unitRequested', e.target.value); }} /></td>
                                                        <td><textarea className="form-control" value={item.examinationRequested} onChange={e => { this.setInvestigation(index, 'examinationRequested', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteInvestigation(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addInvestigationTable()} className="btn btn-primary" >Insert New Investigation Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.graphical ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Graphical
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">HBA1C</span><input type="number" step="any" value={this.state.hBA1C} onChange={e => this.setState({ hBA1C: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Cholesterol</span><input type="number" step="any" value={this.state.cholesterol} onChange={e => this.setState({ cholesterol: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Other Name</span><input type="text" step="any" value={this.state.otherName} onChange={e => this.setState({ otherName: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Other Value</span><input type="number" step="any" value={this.state.otherValue} onChange={e => this.setState({ otherValue: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={this.state.allergies ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Allergies
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Drug Allergies</span><textarea value={this.state.drugAllergies} onChange={e => this.setState({ drugAllergies: e.target.value })} className="form-control" ></textarea></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Other Allergies</span><textarea value={this.state.otherAllergies} onChange={e => this.setState({ otherAllergies: e.target.value })} className="form-control" ></textarea></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

<div className={this.state.bill ? "tab-pane fade show active" : "tab-pane fade"}>
                  <div className="card"><div className="card-body">
                        <div className="row">
                            <div className="col "><div className="input-group">
                                <span className="input-group-text">Service</span>
                                <Select
                                value={this.state.serviceCostId}
                                className="form-control"
                                onChange={e => this.setState({ serviceCostId: e })}
                                options={this.state.options}
                            /></div></div>
                            <div className="col "><div className="input-group">
                                <span className="input-group-text">Cash Only</span><select className="form-control" data-live-search="true" value={this.state.cashOnly} onChange={e => this.setState({ cashOnly: e.target.value })}>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </select></div></div>
                            <div className="col" align="left">{ this.state.serviceCostId !== "" && (<input type="button" onClick={() => this.addServices()} value="Add" className="btn btn-success" />)}</div>
                        </div>
                        </div></div>
                        <br />
                        <br />
                        <table className="table table-striped">
                            <tr>
                                <th>Line</th>
                                <th>Name</th>
                                <th>Tariff</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Quantity</th>
                                <th>Unit Cost</th>
                                <th>Cost</th>
                                <th>Autho Amount</th>
                                <th>Total Cost</th>
                                <th>Cash Only</th>
                                <th>Action</th>
                            </tr>
                            <tbody>
                            {this.state.billItems.map((billItem, index) => (
                                billItem.activeStatus === 1 &&(<tr >
                                    <td >{index + 1}</td>
                                    <td >{billItem.name}</td>
                                    <td>{billItem.tariffCode}</td>
                                    <td><DatePicker selected={new Date(billItem.billStartDate)} onChange={e => this.setBillItem("billStartDate", index, e)} timeInputLabel="Time:" showTimeInput dateFormat="yyyy-MM-dd HH:mm:ss" /></td>
                                    <td><DatePicker selected={new Date(billItem.billEndDate)} onChange={e => this.setBillItem("billEndDate", index, e)} timeInputLabel="Time:" showTimeInput dateFormat="yyyy-MM-dd HH:mm:ss" /></td>
                                    {billItem.serviceType === 'MULTIPLE_QUANTITY' || billItem.billItemType === "PRODUCT"?(<td><input type="text" className="form-control" value={billItem.quantity} onChange={e => {this.setBillItem("quantity", index, e.target.value); this.calcQuantity(index, e.target.value)}} /></td>):(<td>{billItem.quantity}</td>)}
                                    {billItem.allowEdit === 1 ?(<td><div className="input-group has-validation"><input type="text" className={billItem.unitCostError !== "" ? "form-control is-invalid" : "form-control"} v-model="billItem.unitCost" value={billItem.unitCost} onChange={e => this.setBillItem("unitCost", index, e.target.value)} /><div className="invalid-feedback">{billItem.unitCostError}</div></div></td>):(<td>{this.currencyFormat(billItem.unitCost)}</td>)}
                                    <td>{this.currencyFormat(billItem.cost)}</td>
                                    <td>{billItem.authoAmount}</td>
                                    <td>{this.currencyFormat(billItem.totalCost)}</td>
                                    <td><select className="form-control" value={billItem.cashOnly} onChange={e => { this.setBillItem("cashOnly",index, e.target.value) }}  >
                                    <option value="1" >Yes</option>
                                        <option value="0" >No</option>
                                    </select></td>
                                    <td><span className="link-red" onClick={() => this.hideEntry(index)}><i className="bi bi-trash-fill"></i></span></td>
                                </tr>)
                            ))}
                            </tbody>
                        </table>
                        <div className="row">
                        <div className="input-group">
                                    <input type="hidden" className={this.state.billItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                    <div className="invalid-feedback">{this.state.billItemsError}</div> </div></div>    
                     <div className="row">
                            <div className="col"><div className="input-group"><span className="input-group-text">Authorisation Number</span><input type="text" className="form-control" data-live-search="true" onChange={e => {this.setState({ authorisationNumber: e.target.value }); this.calcBill(this.state.billItems, e.target.value, this.state.authorisationAmount);}} value={ this.state.authorisationNumber} /></div></div>
                            <div className="col"><div className="input-group"><span className="input-group-text">Authorisation Amount</span><input type="text" className="form-control" data-live-search="true" onChange={e => {this.setState({ authorisationAmount: e.target.value }); this.calcBill(this.state.billItems, this.state.authorisationNumber, e.target.value);}} value={ this.state.authorisationAmount} /></div></div>
                            </div>            
                     
                        <table className="table table-striped">
                            <tr>
                                <th></th>
                                <th>Cash</th>
                                <th>Insurance</th>
                                <th>Shortfall</th>
                                <th>Special Contract</th>
                                <th>Total</th>
                            </tr>
                            <tbody>
                            <tr>
                                <th>Payable</th>
                                <td>{this.currencyFormat(this.state.cashPayable)}</td>
                                <td>{this.currencyFormat(this.state.insurancePayable)}</td>
                                <td>{this.currencyFormat(this.state.shortfallPayable)}</td>
                                <td>{this.currencyFormat(this.state.specialContractPayable)}</td>
                                <td>{this.currencyFormat(this.state.totalPayable)}</td>
                            </tr>
                            <tr>
                                <th>Paid</th>
                                <td>{this.currencyFormat(this.state.cashPaid)}</td>
                                <td>{this.currencyFormat(this.state.insurancePaid)}</td>
                                <td>{this.currencyFormat(this.state.shortfallPaid)}</td>
                                <td>{this.currencyFormat(this.state.specialContractPaid)}</td>
                                <td>{this.currencyFormat(this.state.totalPaid)}</td>
                            </tr>
                            <tr>
                                <th>Owing</th>
                                <td>{this.currencyFormat(this.state.cashOwing)}</td>
                                <td>{this.currencyFormat(this.state.insuranceOwing)}</td>
                                <td>{this.currencyFormat(this.state.shortfallOwing)}</td>
                                <td>{this.currencyFormat(this.state.specialContractOwing)}</td>
                                <td>{this.currencyFormat(this.state.totalOwing)}</td>
                            </tr></tbody>

                        </table>
                        </div>
                        </div>

                        <div >
                            <div className="card card-blue">
                                <div className="card-header  text-dark">
                                    Diagnosis
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-12 col-lg-12">
                                            <a href="https://icd.who.int/browse10/2019/en" target="_blank" rel="noreferrer">Check Online</a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col"><div className="input-group">
                                            <span className="input-group-text">Diagnosis Text</span><textarea className="form-control" value={this.state.diagnosisText} onChange={e => { this.setState({ diagnosisText: e.target.value }) }} ></textarea> </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col"><div className="input-group">
                                            <input type="text" value={this.state.searchDiagnosis} onChange={e => { this.setState({ searchDiagnosis: e.target.value }) }} className="form-control" placeholder="Search ICD10 Diagnosis Here" />
                                            
                                            <button className="btn btn-success" onClick={() => this.getDiagnosisList()} ><i class="bi bi-search"></i></button>
                                        </div>
                                        </div>
                                        </div>
                                    { this.state.diagnosisList.length > 0 &&(<div className="row">
                                        <div className="col"><div className="input-group"> 
                                        <select class="form-select" size={this.state.diagnosisList.length}  onChange={e => this.setState({ diagnosis: e.target.value })} aria-label="size 3 select example">
                                        <option value="">Choose Diagnosis</option>
                                       {this.state.diagnosisList.map((item, index) =>(  
  <option key={index} value={item.code}>{item.code} - {item.description}</option>
  ))}
</select><button onClick={() => this.insertDiagnosis()} className="btn btn-success" ><i class="bi bi-plus-circle-fill"></i></button></div></div>
                                    </div> )}
                                    <table className="table">
                                        <tr>
                                            <th>Description</th>
                                            <th>Action</th>
                                        </tr>
                                        {this.state.patientDiagnosis.map((item, index) =>  (item.activeStatus === 1 &&(
                                            <tr key={index}>
                                                <td>{item.description}</td>
                                                <td><span className="link-red" onClick={() => this.deleteDiagnosis(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                            </tr>)))}
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
             
               
            </div >
        </div >
        </div >)

    }
}      