import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class MemberLookup extends Component {
     state = { patientId: 0, billId: 0, id: 0, companys: [], patientInsurances:[], companyId: localStorage.getItem('companyId'), companyIdError: '', patientInsuranceId: '', patientInsuranceIdError: '', msg: '', error: ''
    , firstname: '', surname: '', dobFormatted: '', idNumber: '',  beneficiaryType: '', beneficiaryStatusColor: '', beneficiaryStatus: '', enrolmentColor: '', enrolment: ''}
   
    getMemberLookuPi(patientId, billId)
     {
        this.setState({ patientId: patientId, companyId: localStorage.getItem('companyId'), companyIdError: '', billId: billId, companys: [], patientInsuranceId: '', patientInsurances: [], patientInsuranceIdError: '', msg: '', error: '', firstname: '', surname: '', dobFormatted: '', idNumber: '',  beneficiaryType: '', beneficiaryStatusColor: '', beneficiaryStatus: '', enrolmentColor: '', enrolment: '' });
        axios.get(localStorage.getItem('host') + '/interchange/getmemberlookup/' + localStorage.getItem('companyId')+'/'+patientId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ companys: res.data.companys, patientInsurances: res.data.patientInsurances });
                this.props.setLoader(false)
                this.doValidate()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
     }
    memberLookup() {

        var error = "";
         if(this.state.companyId === "")
            {
             error = "Company cannot be empty";
            }
         if(this.state.patientInsuranceId === "" && error === "")
            {
             error = "Insurance details cannot be empty";
            }
        if(error === "")
        {
         var insuranceId= '', suffixId = '', memberNumber = ''   
         for(var i in this.state.patientInsurances)
             {
             if(Number(this.state.patientInsurances[i].id) === Number(this.state.patientInsuranceId))
                {
                 var patientInsurance = this.state.patientInsurances[i];
                 insuranceId = patientInsurance.insuranceId 
                 suffixId = patientInsurance.suffixId
                 memberNumber = patientInsurance.memberNumber
                }    
             }

            this.props.setLoader(true)
       
        axios.get(localStorage.getItem('host') + '/interchange/lookupsimple/'+memberNumber+'/'+suffixId+'/'+insuranceId+'/'+this.state.companyId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ firstname: res.data.firstname, surname: res.data.surname, dobFormatted: res.data.dobFormatted, idNumber: res.data.idNumber,  beneficiaryType: res.data.beneficiaryType, beneficiaryStatusColor: res.data.beneficiaryStatusColor, beneficiaryStatus: res.data.beneficiaryStatus, enrolmentColor: res.data.enrolmentColor, enrolment: res.data.enrolment, error: res.data.msg });
                this.props.setLoader(false)
                this.props.updateVisitAction(this.state.billId)
               
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
        }
      else
         {
             this.setState({error: error})
         }  
    }
    closePopup() {
        document.getElementById('memberLookupAction').style = 'display:none'
    }
    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }
    validate() {

        var error = false;
        if (this.state.companyId === null) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === "") {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else if (this.state.companyId === 0) {
            this.setState({ companyIdError: "Company cannot be empty" });
            error = true
        }
        else {
            this.setState({ companyIdError: "" })
        }
        if (this.state.patientInsuranceId === null) {
            this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" });
            error = true
        }
        else if (this.state.patientInsuranceId === "") {
            this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" });
            error = true
        }
        else if (this.state.patientInsuranceId === 0) {
            this.setState({ patientInsuranceIdError: "Patient Insurance cannot be empty" });
            error = true
        }
        else {
            this.setState({ patientInsuranceIdError: "" })
        }
     if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ error: "", showSubmit: true })
        }
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        // v-bind:style="'height: '+heightMemberLookupQuick+'px;max-height: '+heightMemberLookupQuick+'px;overflow: auto;overflow-x: hidden;'"
        return (<div id="memberLookupAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header bg-info">Member Lookup Action</div>
            <div className="card-body" >
                {divMsg}
                <div className="row">
                    <div className="col"><div className="input-group">
                                                <span className="input-group-text">Company</span><select className={this.state.companyIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.companyId} onChange={e =>  {this.setState({companyId: e.target.value}); this.doValidate()} }  >
                        <option value="">Choose Company</option>
                        {this.state.companys.map((item, index) => (
                            <option value={item.id} key={index} >{item.name}</option>
                        ))}
                    </select>  <div className="invalid-feedback">{this.state.companyIdError}</div>  </div></div>
                    <div className="col"><div className="input-group">
                                                <span className="input-group-text">Insurance Details</span><select className={this.state.patientInsuranceIdError !== "" ? "form-control is-invalid" : "form-control"} onChange={e => { this.setState({patientInsuranceId: e.target.value}); this.doValidate()} } >
                        <option value="">Choose Insurance Record</option>
                        {this.state.patientInsurances.map((item, index) => (
                            <option value={item.id} key={index} >{item.name}</option>
                        ))}
                    </select><div className="invalid-feedback">{this.state.patientInsuranceIdError}</div></div></div>
                    <div className="col">{this.state.showSubmit === true &&(<button onClick={()=>this.memberLookup()} className="btn btn-success">Member Lookup</button>)}</div>
                </div>
                <div>
                <div className="row">
                           <div className="col"><span className="fw-bold">Firstname:</span> {this.state.firstname}</div>
                           <div className="col"><span className="fw-bold">Surname:</span> {this.state.surname}</div>
                           <div className="col"><span className="fw-bold">Date of Birth:</span> {this.state.dobFormatted}</div>
                           </div>
                           <div className="row">    
                           <div className="col"><span className="fw-bold">ID Number:</span> {this.state.idNumber}</div>
                           <div className="col"><span className="fw-bold">Beneficiary Type:</span> {this.state.beneficiaryType}</div>
                           <div className="col"><span className="fw-bold">Dependent Code:</span> {this.state.dependantCode}</div>
                           </div>
                           <div className="row">
                           <div className="col"><span className="fw-bold">Beneficiary Status:</span> <span className={this.state.beneficiaryStatusColor}>{this.state.beneficiaryStatus}</span> </div>
                           <div className="col"><span className="fw-bold">Enrolment Status:</span> <span className={this.state.enrolmentColor}>{this.state.enrolment}</span></div>
                           <div className="col"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12" align="center"><button onClick={()=>this.closePopup()} className="btn btn-info">Close Window</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}      