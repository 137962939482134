import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


export default class ExportManager extends Component {
    state = { list: [], id: 0, name: '', sqlText: '', titles: '', activeStatus: 1, exportFilters: [], menus: [], menu: '', msg: '', error: '' }
    componentDidMount() {
        this.getList()
    }
    reportAction(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.target.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, name: '', sqlText: '', titles: '', activeStatus: 1, msg: '', exportFilters: [] })
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/interchange/getexportmanager/' + id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ id: res.data.id, name: res.data.name, sqlText: res.data.sqlText, titles: res.data.titles, activeStatus: res.data.activeStatus,exportFilters: res.data.exportFilters });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#reportAction').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#reportAction').style = 'display:none'
        console.log('......close');
    }
    saveReport() {
        var data = { id: this.state.id, name: this.state.name, sqlText: this.state.sqlText, titles: this.state.titles, activeStatus: this.state.activeStatus, exportFilters: this.state.exportFilters}
        axios.post(localStorage.getItem('host') + '/interchange/saveexportmanager', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/interchange/getexportmanagerlist', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit Export" id={res.data[index].id} onClick={e => this.reportAction(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }
    addFilter() {
        let list = this.state.exportFilters.slice();
        list.push({ id: 0, filterType: '', parameterMapper: '', label1: '', label2: '', columnName: '', selectSql: '', selectColumn: '', required: 0, activeStatus: 1 });
        this.setState({ exportFilters: list })
    }
    setRowValue(index, e, name) {
        let list = this.state.exportFilters.slice();
        switch (name) {
         case "columnName":
                        list[index].columnName = e.target.value;
                        break;
         case "label1":
                        list[index].label1 = e.target.value;
                        break;
         case "label2":
                        list[index].label2 = e.target.value;
                        break;
         case "filterType":
                        list[index].filterType = e.target.value;
                        break;
         case "parameterMapper":
                        list[index].parameterMapper = e.target.value;
                        break;
         case "selectSql":
                        list[index].selectSql = e.target.value;
                        break;
         case "selectColumn":
                        list[index].selectColumn = e.target.value;
                        break;
         case "required":
                        list[index].required = e.target.value;
                        break;
         case "activeStatus":
                        list[index].activeStatus = e.target.value;
                        break;
                 default:
                         console.log("default")
                          break;

                      }       
        this.setState({ exportFilters: list })
      }
render() {
    const data = {
        columns: [
            {
                label: 'Action',
                field: 'action',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Name',
                field: 'name',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Status',
                field: 'activeStatusString',
                sort: 'asc',
                width: 100
            }
        ],
        rows: this.state.list
    };
    let divMsg = ''
    if (this.state.msg) {
        divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
    }
    return (
        <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
            <Top />

            <div className="container-fluid">
                    <button className="btn btn-primary" onClick={() => this.showForm(0)} ><i className="bi bi-plus-square"></i></button>
                    {divMsg}
                    <MDBDataTable
                        button="true"
                        striped
                        bordered
                        small
                        data={data}
                    />
                    <div className="page-popup page-popup-overlay" id="reportAction">
                        <form  >
                            <div className="card">
                                <div className="card-header bg-info">
                                    Report Action {this.props.idFromParent}
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <label>Name</label>
                                            <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} />
                                        </div>
                                        <div className="col">
                                            <label>Status</label>
                                            <select className="form-control" value={this.state.activeStatus} onChange={e => { this.setState({ activeStatus: e.target.value }) }}  >
                                                <option value="1" >Active</option>
                                                <option value="0" >Deactivate</option>
                                            </select>
                                        </div></div>
                                    <div className="row">
                                        <div className="col">
                                            <label>Titles</label>
                                            <textarea className="form-control" value={this.state.titles} onChange={e => { this.setState({ titles: e.target.value }) }} ></textarea>
                                        </div>
                                        <div className="col">
                                            <label>SQL</label>
                                            <textarea className="form-control" value={this.state.sqlText} onChange={e => { this.setState({ sqlText: e.target.value }) }} ></textarea>
                                        </div></div>
                                    <div className="card">
                                        <div className="card-header">Filters</div>
                                        <div className="card-body">                                        <table style={{ width: 100 + '%' }} >
                                            <tr>
                                                <th>Column Name</th>
                                                <th>Label 1</th>
                                                <th>Label 2</th>
                                                <th>Filter Type</th>
                                                <th>Parameter</th>
                                                <th>Select Sql</th>
                                                <th>Select Column</th>
                                                <th>Required</th>
                                                <th>Status</th>
                                            </tr>
                                            <tr>
                                                <td colSpan={8}> </td>
                                                <td><input type="button" className="btn btn-success" value="Insert Filter" onClick={() => { this.addFilter() }} /></td>
                                            </tr>
                                            {this.state.exportFilters.map((reportFilter, index) => (
                                                <tr>
                                                    <td><input type="text" className="form-control" value={reportFilter.columnName} onChange={e => { this.setRowValue(index, e, "columnName")  }} /></td>
                                                    <td><input type="text" className="form-control" value={reportFilter.label1} onChange={e => { this.setRowValue(index, e, "label1")  }} /></td>
                                                    <td><input type="text" className="form-control" value={reportFilter.label2} onChange={e => {this.setRowValue(index, e, "label2")  }} /></td>
                                                    <td><select className="form-control" value={reportFilter.filterType} onChange={e => { this.setRowValue(index, e, "filterType") }}  >
                                                        <option value="BOOLEAN" >BOOLEAN</option>
                                                        <option value="DATE_RANGE" >DATE RANGE</option>
                                                        <option value="NUMERIC" >NUMERIC</option>
                                                        <option value="SELECT_STATEMENT" >SELECT STATEMENT</option>
                                                        <option value="SINGLE_DATE" >SINGLE DATE</option>
                                                        <option value="STRING_LIKE" >STRING LIKE</option>
                                                    </select></td>
                                                    <td><select className="form-control" value={reportFilter.parameterMapper} onChange={e => { this.setRowValue(index, e, "parameterMapper")  }}  >
                                                        <option value="parameter1" >Parameter 1</option>
                                                        <option value="parameter2" >Parameter 2</option>
                                                        <option value="parameter3" >Parameter 3</option>
                                                        <option value="parameter4" >Parameter 4</option>
                                                        <option value="parameter5" >Parameter 5</option>
                                                        <option value="parameter6" >Parameter 6</option>
                                                    </select></td>
                                                    <td><input type="text" className="form-control" value={reportFilter.selectSql} onChange={e => { this.setRowValue(index, e, "selectSql")  }} /></td>
                                                    <td><input type="text" className="form-control" value={reportFilter.selectColumn} onChange={e => { this.setRowValue(index, e, "selectColumn")  }} /></td>
                                                    <td><select className="form-control" value={reportFilter.required} onChange={e => { this.setRowValue(index, e, "required")  }}  >
                                                        <option value="1" >Yes</option>
                                                        <option value="0" >No</option>
                                                    </select></td>
                                                    <td><select className="form-control" value={reportFilter.activeStatus} onChange={e => { this.setRowValue(index, e, "activeStatus")  }}  >
                                                        <option value="1" >Active</option>
                                                        <option value="0" >Disable</option>
                                                    </select></td>
                                                </tr>
                                            ))}
                                        </table>   </div></div>
                                    <div className="row">
                                        <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.saveReport()} value="Save" /></div>
                                        <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                    </div>
                                </div></div>
                        </form>
                    </div>
                </div>
            </div></div>
    )

}
}    