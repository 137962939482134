import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


export default class Parameter extends Component {
    state = { id: 0, name: '', printName: '', activeStatus: 1, list: [], predefineds: [] }
    componentDidMount() {
        this.getList()
    }
    action(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, name: '', printName: '', activeStatus: 1 , predefineds: []})
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/master/parameteraction/' + id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ name: res.data.name, printName: res.data.printName, id: res.data.id, activeStatus: res.data.activeStatus, precision: res.data.precision, parameterRange: res.data.parameterRange, maxRange: res.data.maxRange, minRange: res.data.minRange, unit: res.data.unit, parameterType: res.data.parameterType, predefineds: res.data.predefineds });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#action').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
        console.log('......close');
    }
    save() {
        var data = { id: this.state.id, activeStatus: this.state.activeStatus, name: this.state.name, printName: this.state.printName, precision: this.state.precision, parameterRange: this.state.parameterRange, maxRange: this.state.maxRange, minRange: this.state.minRange, unit: this.state.unit, parameterType: this.state.parameterType, predefineds: this.state.predefineds, companyId: localStorage.getItem('companyId') }
        axios.post(localStorage.getItem('host') + '/master/parameterform', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }

    add() {
        let list = this.state.predefineds.slice();
        list.push({ id: 0, name: '', printName: '', activeStatus: 1 });
        this.setState({ predefineds: list })
    }
    setRowValue(index, e, name) {
        let list = this.state.predefineds.slice();
        switch (name) {
            case "name":
                list[index].name = e.target.value;
                break;
            case "printName":
                list[index].printName = e.target.value;
                break;
            case "activeStatus":
                list[index].activeStatus = e.target.value;
                break;
            default:
                console.log("default")
                break;
        }
        this.setState({ predefineds: list })
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/master/parameter/' + localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit Cluster" id={res.data[index].id}  onClick={e => this.action(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }

    render() {
        const data = {
            columns: [
                
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Active Status',
                    field: 'stringStatus',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: this.state.list
        };
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        <button className="btn btn-primary" onClick={() => this.showForm(0)} ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                        <MDBDataTable
                            button="true"
                            striped
                            bordered
                            small
                            data={data}
                        />
                        <div className="page-popup page-popup-overlay" id="action">
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Action
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Name</span>
                                                <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Active Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e => this.setState({ activeStatus: e.target.value })}  >
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Print Name</span>
                                                <input type="text" className="form-control" value={this.state.printName} onChange={e => { this.setState({ printName: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Precision</span>
                                                <input type="text" className="form-control" value={this.state.precision} onChange={e => { this.setState({ precision: e.target.value }) }} /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Parameter Range</span>
                                                <input type="text" className="form-control" value={this.state.parameterRange} onChange={e => { this.setState({ parameterRange: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Unit</span>
                                                <input type="text" className="form-control" value={this.state.unit} onChange={e => { this.setState({ unit: e.target.value }) }} /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Min Range</span>
                                                <input type="text" className="form-control" value={this.state.minRange} onChange={e => { this.setState({ minRange: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Max Range</span>
                                                <input type="text" className="form-control" value={this.state.maxRange} onChange={e => { this.setState({ maxRange: e.target.value }) }} /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Parameter Type</span>
                                            <select className="form-control" value={this.state.parameterType} onChange={e => this.setState({ parameterType: e.target.value })}  >
                                                    <option value="">Choose</option>
                                                    <option value="NUMERIC">NUMERIC</option>
                                                    <option value="TEXT">TEXT</option>
                                                    <option value="PREDEFINED">PREDEFINED</option>
                                                    <option value="HEADER">HEADER</option>
                                                </select></div>
                                            </div>
                                            <div className="col"></div>
                                        </div>
                                        <div className="card card-green">
                                            <div className="card-header">Predefined</div>
                                            <div className="card-body">
                                                <table className="table table-striped"  >
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Print Name</th>
                                                        <th>Status</th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4"> </td>
                                                        <td><button type="button" className="btn btn-success" title="Insert" onClick={() => { this.add() }}  ><i className="bi bi-plus-square"></i></button></td>
                                                    </tr>
                                                <tbody>
                                                    {this.state.predefineds.map((item, index) => (
                                                        <tr>
                                                            <td><input type="text" className="form-control" value={item.name} onChange={e => { this.setRowValue(index, e, "name") }} /></td>
                                                            <td><input type="text" className="form-control" value={item.printName} onChange={e => { this.setRowValue(index, e, "printName") }} /></td>
                                                            <td><select className="form-control" value={item.activeStatus} onChange={e => { this.setRowValue(index, e, "activeStatus") }}  >
                                                                <option value="1" >Active</option>
                                                                <option value="0" >Disable</option>
                                                            </select></td>
                                                            <td><span className="link-red" id={item.id} onClick={e => this.delete(e)} title="Cancel"><i className="bi bi-x-square-fill"></i></span></td>
                                                        </tr>
                                                    ))} </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                        </div>
                    </div>
                </div ></div >
        )

    }
}    