import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import "react-datepicker/dist/react-datepicker.css";
import fileDownload from 'js-file-download'

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class CurrentStock extends Component {
    constructor(props) {
        super(props);
    this.setLoader = this.setLoader.bind(this)
    this.printOutRef = React.createRef()
    }
    state = { name: '', id: 0, locations: [], locationId: '', startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD , companyId: localStorage.getItem('companyId'), list: [], clusterCompanys: [] }
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/stockmanagement/getcompanys/'+localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({clusterCompanys: res.data});
                this.getLocations(this.state.companyId)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
            }
        )
    }
    action(e) {
        var id = e.target.id;
        console.log("......." + id + " .... " + e.target.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, name: '', activeStatus: 1 })
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/master/appointmenttimeaction/' + id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ name: res.data.name, id: res.data.id, activeStatus: res.data.activeStatus });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#action').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
        console.log('......close');
    }
    save() {
        var data = { id: this.state.id, activeStatus: this.state.activeStatus, name: this.state.name, companyId: localStorage.getItem('companyId') }
        axios.post(localStorage.getItem('host') + '/master/appointmenttimeform', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        this.setLoader(true)
        var data = { name: this.state.name, locationId: this.state.locationId, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/stockmanagement/currentstock', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    } 
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    getLocations(companyId)
     {
      this.setState({locations: [], locationId: ''})
      for(var i in this.state.clusterCompanys)
         {
           if(this.state.clusterCompanys[i].id === Number(companyId))
              {
               this.setState({locations: this.state.clusterCompanys[i].locations })
              } 
         }
     }
     processDate(date)
       {
         const now = new Date(date);
         const todayY = now.getFullYear();
         const todayM = checkZero(now.getMonth() + 1);
         const todayD = checkZero(now.getDate());
         var date1 = todayY + '-' + todayM + '-' + todayD;
         console.log(date1)
        return date1;
       }
    downloadExcel() {
          
          axios.get(localStorage.getItem('host') + '/stockmanagement/exportcurrentstock/' + this.state.locationId+'/'+this.state.companyId, { responseType: 'blob', 
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
          }})
         .then((res) => {
           fileDownload(res.data, "Current Stock.xlsx")
         })
       }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                       {divMsg}
                     
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                        <div className="col"><div className="input-group"><span className="input-group-text">Name / Code </span> <input type="text" className="form-control" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} /></div></div>
                                          <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" value={this.state.companyId} onChange={(e) => {this.setState({ companyId: e.target.value }); this.getLocations(e.target.value)}}  >
                                                {this.state.clusterCompanys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Locations</span><select className="form-control" value={this.state.locationId} onChange={(e) => this.setState({ locationId: e.target.value })}  >
                                                <option value="">Choose Location</option>
                                                {this.state.locations.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                            <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getList()} value="Search" /></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                            <div className="col">{this.state.list.length > 0 &&(<span className="link" style={{backgroundColor: '#36BEEE', color: '#FFF', fontSize: 2+'em', paddingTop: 10}} title="Download Excel" onClick={() => { this.downloadExcel(); }} ><i class="bi bi-file-earmark-spreadsheet-fill"></i></span>)}</div> 
                            <div className="col"></div>
                            <div className="col"></div>               
                            </div>
                            <table className="table table-striped table-hover">
                                <tr>
                                    <th>Code</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Category</th>
                                    <th>Quantity</th>
                                    <th>Levels</th>
                                    <th>Buying Price</th>
                                    <th>Batch Number</th>
                                    <th>Expiry Date</th>
                                    <th>Manufacturer</th>
                                    <th>Supplier</th>
                                </tr>
                                <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr key={index} style={{backgroundColor:item.color}}>
                                    <td>{item.code}</td>
                                         <td>{item.name}</td>
                                        <td>{item.type}</td>
                                        <td>{item.category}</td>
                                        <td>{item.quantity}</td>
                                        <td><i class="bi bi-square-fill" style={{color: item.reorderLevel}}></i></td>
                                        <td>{item.buyingPrice}</td>
                                        <td>{item.batchNumber}</td>
                                        <td>{item.expiryDate}</td>
                                        <td>{item.manufacturer}</td>
                                        <td>{item.supplier}</td>
                                       </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} />
                       {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    