import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InstitutionInvoiceAction from './institutioninvoiceaction'

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class InstitutionInvoice extends Component {
    constructor(props) {
        super(props);
        this.setLoader = this.setLoader.bind(this)
        this.setMsg = this.setMsg.bind(this)
        this.getList = this.getList.bind(this)
        this.institutioninvoiceActionRef = React.createRef()
        this.allocateAdvancedRceiptRef = React.createRef()
        this.printOutRef = React.createRef()
    }
    state = { id: 0, firstname: '', surname: '', startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD, institutionId: '' , companyId: localStorage.getItem('companyId'), list: [], institutions: [] }
    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/bill/getinstitutions/' + localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ institutions: res.data });
                this.getList()
            }, err => {
                console.log(".....")
                this.setState({ error: err.Error })
            }
        )
    }
    openInstitutionInvoiceAction() {
        this.setState({ loader: true })
        this.institutioninvoiceActionRef.current.openInstitutionInvoiceAction(this.state.institutions)
        document.getElementById('institutioninvoiceAction').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
        console.log('......close');
    }
    getList() {
        this.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname,  startDate: this.state.startDate, endDate:this.state.endDate, institutionId: this.state.institutionId, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/bill/institutioninvoicelist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    }
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
    cancelInstitutionInvoice(item) {
        this.setState({ loader: true })
        axios.get(localStorage.getItem('host') + '/bill/institutioninvoicecancel/' + item.id+'/'+item.cashPaid+'/'+item.shortfallPaid, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ msg: res.data });
                this.getList()
            }, err => {
                console.log(".....")
                this.setState({ error: err.Error })
            }
        )
    }
    setLoader(loader) {
        this.setState({ loader: loader })
    }
    setMsg(msg) {
        this.setState({ msg: msg })
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        let loaderDiv = ''
        if (this.state.loader) {
            loaderDiv = (<div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        {divMsg}

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                    <div class="row">
                    <div class="col"><button onClick={() => this.openInstitutionInvoiceAction()} class="btn btn-success">Add New</button></div>
                </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Firstname </span> <input type="text" className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Surname</span><input type="text" className="form-control" onChange={(e) => this.setState({ surname: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">End Date</span> <DatePicker className="form-control" selected={new Date(this.state.endDate)}  minDate={new Date(this.state.startDate)} maxDate={new Date()}  onChange={d => { this.setState({ endDate: this.processDate(d) }); } } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Institution</span><select className="form-control" onChange={(e) => this.setState({ institutionId: e.target.value })}  >
                                                {this.state.institutions.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                             <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getList()} value="Search" /></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                            <table className="table table-striped table-hover">
                                <tr>
                                    <th>Action</th>
                                    <th>Number</th>
                                    <th>Date</th>
                                    <th>Total Amount</th>
                                </tr>
                                <tbody>
                                    {this.state.list.map((item, index) => (
                                        <tr key={index} >
                                            <td >
                                            <span className="link" onClick={() => this.getPrintOut(item.id, 'INSTITUTIONINVOICE', [], item.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>
                                               </td>
                                            <td>{item.number}</td>
                                            <td>{item.creationDateTime}</td>
                                           <td align="right">{this.currencyFormat(item.totalAmount)}</td>
                                             </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <InstitutionInvoiceAction ref={this.institutioninvoiceActionRef} setLoader={this.setLoader} setMsg={this.setMsg} getList={this.getList} />
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} /> 
                        {loaderDiv}
                    </div>
                </div ></div >
        )

    }
}    