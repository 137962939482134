import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class CancelAdvancedRceipt extends Component {
    state = { id: 0, remarks: '', msg: '', error: '', fullname: '', number: '', paymentType: '', paidAmount: 0.00, availableAmount: 0.00, usedAmount: 0.00 }

    getCancelAdvancedRceipt(advancedReceipt) {
        this.props.setLoader(false)
        this.setState({ id: advancedReceipt.id, remarks: '', fullname: advancedReceipt.fullname, number: advancedReceipt.number, paymentType: advancedReceipt.paymentType, paidAmount: advancedReceipt.paidAmount, availableAmount: advancedReceipt.availableAmount, usedAmount: advancedReceipt.usedAmount })
    }
    save() {
        var error = "";
        if (error === "") {
            this.props.setLoader(true)

            axios.get(localStorage.getItem('host') + "/bill/advancedreceiptcancel/"+this.state.id+"?remarks="+this.state.remarks, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then(res => {
                    this.props.setMsg(res.data);
                    this.closePopup();
                    this.props.getList();
                    this.props.setLoader(false)
                })
        } else {
            this.setState({ error: error })
        }
    }
    setRate(index, value) {
        var list = this.state.allocationAdvancedReceipts;
        list[index].rate = value
        this.setState({ allocationAdvancedReceipts: list })
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    closePopup() {
        document.getElementById('advancedReceiptCancelAction').style = 'display:none'
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="advancedReceiptCancelAction" class="page-popup page-popup-overlay"   ><div class="card" >
            <div class="card-header bg-info">Cancel Advanced Receipt</div>
            <div class="card-body" >
                <div class="card border-success">
                    <div class="card-body">
                        {divMsg}
                        <div class="row">
                            <div class="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                            <div class="col"><span className="fw-bold">Number:</span> {this.state.number}</div>
                            <div class="col"><span className="fw-bold">Payment Type:</span> {this.state.paymentType}</div>
                        </div>
                        <div class="row">
                            <div class="col"><span className="fw-bold">Paid Amount:</span> {this.currencyFormat(this.state.paidAmount)}</div>
                            <div class="col"><span className="fw-bold">Available Amount:</span> {this.currencyFormat(this.state.availableAmount)}</div>
                            <div class="col"><span className="fw-bold">Used Amount:</span> {this.currencyFormat(this.state.usedAmount)}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col" align="center"><div className="input-group"><span className="input-group-text">Cancel Reason:</span><textarea cols="5" value={this.state.remarks} onChange={e => this.setState({ remarks: e.target.value })} class="form-control" ></textarea></div> </div>

                </div>
                <div class="row">
                    <div class="col" align="center">{this.state.remarks !== '' && this.state.remarks.length > 4 ? (<button onClick={() => this.save()} id="submitButton2" class="btn btn-primary" >Submit</button>) : (<span></span>)}</div>
                    <div class="col" align="center"><button onClick={() => this.closePopup()} class="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}    