import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class OpdNurseAction extends Component {
    
    state = { barcode: '',billId: '', companyId: '',locationId: '', speciality: '', plocationId: '', rate: 0, institutionId: '', nurseDetails: true, billingDetails: false, fileData: '', billCategory: '', results: [], locations: [], services: [], options:[], billItems: [], treatments: [], htmlText: '', popupName: '', fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', employer: '', weight: '', height: '', waist: '', headCircumference: '', muac: '', bmi: '', bedDetails: '', nurseNotes: '', vitals: [], notes: [], referralLetters: [], oldPatient: '', managementLists: [], historyVisits: [], visitId: 0, msg: '', error: '', diagnosisText: '', patientDiagnosis: [] 
    , cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0, procedures: [], drugAllergy: '', otherAllergy: '', medicationGivens: [], procedure: ''
    , cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0 
    , cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0, authorisationNumber: '', authorisationAmount: ''
    , ph: '', ketones: '', sg: '', proteins: '', leucocytes: '', urobilinogen: '', blood: '', nitrate: '', glucose: '', otherUrineAnaysis: '', cashOnlyPcp: 0 , cashOnlyDs: 0, cashOnly: 0, showButton: true}
    componentDidMount() {

    }
    openNurseAction(visitId) {
        this.setState({barcode: '', billId: '', speciality: '', companyId: '', locationId: '', rate: 0, institutionId: '', nurseDetails: true, billingDetails: false, billCategory: '', fileData: '', results: [], locations: [], options:[], services: [], billItems: [], treatments: [], htmlText: '', popupName: '', fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', employer: '', weight: '', height: '', waist: '', headCircumference: '', muac: '', bmi: '', bedDetails: '', nurseNotes: '', vitals: [], notes: [], referralLetters: [], oldPatient: '', managementLists: [], historyVisits: [], visitId: visitId, msg: '', error: '', diagnosisText: '', patientDiagnosis: []
        , cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0, procedures: [], drugAllergy: '', otherAllergy: '', medicationGivens: [], procedure: ''
        , cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0 
        , cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0, authorisationNumber: '', authorisationAmount: ''
        , ph: '', ketones: '', sg: '', proteins: '', leucocytes: '', urobilinogen: '', blood: '', nitrate: '', glucose: '', otherUrineAnaysis: '', cashOnlyPcp: 0, cashOnlyDs: 0, cashOnly: 0, showButton: true})
        axios.get(localStorage.getItem('host') + '/patientmanagement/hospitalnurse/' + visitId+'/'+localStorage.getItem('roleId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                var options = [];
                for(var i in res.data.services)
                    {
                        options.push({value: res.data.services[i].id, label: res.data.services[i].name})
                    }  
                this.setState({ billId: res.data.billId, speciality: res.data.speciality, companyId: res.data.companyId, rate: res.data.rate, institutionId: res.data.institutionId, billItems: res.data.billItems, billCategory: res.data.billCategory, results: res.data.results,locations: res.data.locations, services: res.data.services, options: options, fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, employer: res.data.employer, weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, bedDetails: res.data.bedDetails, nurseNotes: res.data.nurseNotes, vitals: res.data.vitals, notes: res.data.notes, referralLetters: res.data.referralLetters, oldPatient: res.data.oldPatient, managementLists: res.data.managementLists, historyVisits: res.data.historyVisits, diagnosisText: res.data.diagnosisText, patientDiagnosis: res.data.patientDiagnosis
                    , cashPayable: res.data.cashPayable, insurancePayable: res.data.insurancePayable, shortfallPayable: res.data.shortfallPayable, specialContractPayable: res.data.specialContractPayable, totalPayable: res.data.totalPayable, drugAllergy: res.data.drugAllergy, otherAllergy: res.data.otherAllergy, medicationGivens: res.data.medicationGivens
                    , cashPaid: res.data.cashPaid, insurancePaid: res.data.insurancePaid, shortfallPaid: res.data.shortfallPaid, specialContractPaid: res.data.specialContractPaid, totalPaid: res.data.totalPaid
                    , cashOwing: res.data.cashOwing, insuranceOwing: res.data.insuranceOwing, shortfallOwing: res.data.shortfallOwing, specialContractOwing: res.data.specialContractOwing, totalOwing: res.data.totalOwing, authorisationNumber: res.data.authorisationNumber, authorisationAmount: res.data.authorisationAmount
                    , ph: res.data.ph, ketones: res.data.ketones, sg: res.data.sg, proteins: res.data.proteins, leucocytes: res.data.leucocytes, urobilinogen: res.data.urobilinogen, blood: res.data.blood, nitrate: res.data.nitrate, glucose: res.data.glucose, otherUrineAnaysis: res.data.otherUrineAnaysis, treatments: res.data.treatments
                   });
                this.props.setLoader(false)
                this.doValidate();
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    save() {
        this.setState({showButton: false})
        this.props.setLoader(true)
        var billItems = this.state.billItems
        for(var i in this.state.billItems)
           {
            billItems[i].billStartDate = this.processDateTime(billItems[i].billStartDate)
            billItems[i].billEndDate = this.processDateTime(billItems[i].billEndDate)
           }
        var data = { visitId: this.state.visitId, weight: this.state.weight, height: this.state.height, drugAllergy: this.state.drugAllergy, otherAllergy: this.state.otherAllergy, muac: this.state.muac, bmi: this.state.bmi, bedDetails: this.state.bedDetails, nurseNotes: this.state.nurseNotes, vitals: this.state.vitals, medicationGivens: this.state.medicationGivens
            
            , billItems: billItems, billId: this.state.billId, speciality: this.state.speciality
            , currencyId: this.state.currencyId, cashPayable: this.state.cashPayable, insurancePayable: this.state.insurancePayable, shortfallPayable: this.state.shortfallPayable, specialContractPayable: this.state.specialContractPayable, totalPayable: this.state.totalPayable
            , cashOwing: this.state.cashOwing, insuranceOwing: this.state.insuranceOwing, shortfallOwing: this.state.shortfallOwing, specialContractOwing: this.state.specialContractOwing, totalOwing: this.state.totalOwing, authorisationNumber: this.state.authorisationNumber, authorisationAmount: this.state.authorisationAmount
            , ph: this.state.ph, ketones: this.state.ketones, sg: this.state.sg, proteins: this.state.proteins, leucocytes: this.state.leucocytes, urobilinogen: this.state.urobilinogen, blood: this.state.blood, nitrate: this.state.nitrate, glucose: this.state.glucose, otherUrineAnaysis: this.state.otherUrineAnaysis}
        axios.post(localStorage.getItem('host') + '/patientmanagement/hospitalnursesave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                this.setState({ msg: res.data, showButton: true }); 
                document.getElementById('topDiv').scrollIntoView()
                this.props.updateAfterSave(this.state.visitId, [])
            }, err => {
                console.log(".....")
                this.setState({ error: err , showButton: true})
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('nurseAction').style = 'display:none'
        this.props.getList();
    }
    setNurseDetails(name, value) {
        switch (name) {
            case "weight":
                var bmi = 0;
                value = value > 0 && value < 150 ? value : ''
                if (value > 0 && this.state.height > 0) {
                    bmi = Number(((value * 10000) / (this.state.height * this.state.height)).toFixed("2"))
                }
                this.setState({ weight: value, bmi: bmi })
                break;
            case "height":
                bmi = 0;
                value = value > 0 && value < 250 ? value : ''
                if (value > 0 && this.state.weight > 0) {
                    bmi = Number(((this.state.weight * 10000) / (value * value)).toFixed("2"))
                }
                this.setState({ height: value, bmi: bmi })
                break;
            default:
               console.log("")
                break;
        }
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    }
    setVitalValue(index, name, value) {
        var list = this.state.vitals;
        switch (name) {
            case "vitalTime":
                list[index].vitalTime = value;
                break;
            case "temperature":
                list[index].temperature = value > 0 && value < 45 ? value : '';
                break;
            case "pulse":
                list[index].pulse = value > 0 && value < 180 ? value : '';
                break;
            case "glucometer":
                list[index].glucometer = value > 0 && value < 30 ? value : '';
                break;
            case "systolic":
                list[index].systolic = value > 0 && value < 300 ? value : '';
                break;
            case "diastolic":
                list[index].diastolic = value > 0 && value < 140 ? value : '';
                break;
            case "gcs":
                list[index].gcs = value > 0 && value < 16 ? value : '';
                break;
            default:
                list[index].spO2 = value > 0 && value < 100 ? value : '';
                break;

        }
        this.setState({ vitals: list })
    }
    setMedicationGivenValue(index, name, value) {
        var list = this.state.medicationGivens;
        switch (name) {
            case "drugName":
                list[index].drugName = value;
                break;
            case "route":
                list[index].route = value;
                break;
            case "dose":
                list[index].dose = value;
                break;
            default:
                list[index].time = value;
                break;

        }
        this.setState({ medicationGivens: list })
    }
    addVital() {
        let list = this.state.vitals.slice();
        const now = new Date();
        list.push({ id: 0, vitalTime: checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()), temperature:'', pulse: '', glucometer:'', systolic: '', diastolic:'', spO2: '' });
        this.setState({ vitals: list })
    }
    addMedicationGiven() {
        let list = this.state.medicationGivens.slice();
        const now = new Date();
        list.push({ time: checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()), drugName:'', route: '', dose:'', sign: localStorage.getItem('fullname') });
        this.setState({ medicationGivens: list })
    }
    deleteRow(index)
     {
        let list = this.state.vitals.slice();
        list.splice(index, 1); 
        this.setState({ vitals: list }) 
     }
     deleteRowMedicationGiven(index)
      {
         let list = this.state.medicationGivens.slice();
         list.splice(index, 1); 
         this.setState({ medicationGivens: list }) 
      }
    showNurseHistory(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/nursehistory/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Nurse History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    genericClosePopup() {
        document.getElementById('genericPopup').style = 'display:none'
    }
    uploadFile(fileType, file) {
        if (file !== null || file !== '') {
            var data = new FormData();
            data.append("file", file)
            data.append("fileType", fileType)
            data.append("visitId", this.state.visitId)
            axios.post(localStorage.getItem('host') + '/file/filesave', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data'

                }
            }).then(
                res => {
                    console.log(res.data)
                    if (fileType === "REFERRAL_LETTERS") {
                        this.setState({ referralLetters: res.data, msg: 'Successfully uploaded Referral Letter' });
                    }
                    else {
                        this.setState({ notes: res.data, msg: 'Successfully uploaded Notes' });
                    }

                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }, err => {
                    console.log(".....")
                }
            )

        }
    }
    deleteFile(id, fileExtension, fileType) {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/deletefile/' + id + '/' + fileType + '/' + fileExtension + '/' + this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                if (fileType === "REFERRAL_LETTERS") {
                    this.setState({ referralLetters: res.data, msg: 'Successfully deleted Referral Letter' });
                }
                else {
                    this.setState({ notes: res.data, msg: 'Successfully deleted Notes' });
                }
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    showFile(id, fileExtension) {
        this.setState({ fileData: '' });
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/getfile/' + id + '/' + fileExtension, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ fileData: res.data });
                document.getElementById('showFileNurseAction1').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )

    }
    fileNurseClosePopup() {
        this.setState({ fileData: '' });
        document.getElementById('showFileNurseAction1').style = 'display:none'
    }
    showOldNurse(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.props.setLoader(true)
        axios.get('https://ucare.kimbah.net/old/nurselistview/' + oldPatient + '/500', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                //   this.setState({ htmlText: res.data, popupName: 'Nurse History'});
                document.getElementById('genericPopup').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    showOldResults(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.props.setLoader(true)
        axios.get('https://ucare.kimbah.net/old/nurselistview/' + oldPatient + '/500', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Nurse History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
            }
        )
    }
    viewResults() {
        document.getElementById('resultPopup1').style = 'display:block'
    }
    resultClosePopup() {
        document.getElementById('resultPopup1').style = 'display:none'
    }
    toggleMainTabs(name) {
        switch (name) {
            case "billingDetails":
                this.setState({ nurseDetails: false, billingDetails: true})
                break
            default:
                this.setState({nurseDetails: true, billingDetails: false})
                break

        }
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }

    addServices()
       {  console.log('......... service cost id '+JSON.stringify(this.state.serviceCostId)) 
        if(this.state.serviceCostId.value > 0)
           {  console.log('......... service cost id '+this.state.serviceCostId.value) 
            const now = new Date();
            var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
            let list = this.state.billItems.slice();
            var s = '';
            for (var i in this.state.services) {
                if (this.state.services[i].id === Number(this.state.serviceCostId.value)) {
                    s = this.state.services[i];
                }
            }
            if(this.state.cashOnly === 1 || this.state.cashOnly === "1")
            {
             s.cashOnly = 1;
            }
            list.push({id: 0, serviceId: s.id, name: s.name, billItemType: 'SERVICE', serviceType: s.serviceType, serviceClass: s.serviceClass
            , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: s.quantity, unitCost: s.fixedCost, min: this.getMargin(s.fixedCost, s.margin, 'MIN'), max: this.getMargin(s.fixedCost, s.margin, 'MAX'), cost: s.fixedCost, totalCost: s.fixedCost, cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
                for (var index in s.services)
                {
                    var ss = s.services[index];
                    list.push({id: 0, serviceId: ss.id, name: ss.name, billItemType: 'SERVICE', serviceType: ss.serviceType, serviceClass: ss.serviceClass
                        , tariffCode: ss.tariffCode, billStartDate: date1, billEndDate: date1, quantity: ss.quantity, unitCost: ss.fixedCost, min: this.getMargin(ss.fixedCost, ss.margin, 'MIN'), max: this.getMargin(ss.fixedCost, ss.margin, 'MAX'), cost: ss.fixedCost, totalCost: ss.fixedCost, cashOnly: ss.cashOnly, allowEdit: ss.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: ss.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
                }
            this.setState({ billItems: list });
            this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
            this.doValidate()
           }
       }
       getMargin(unitCost, margin, type)
          {
           let val = 0
           if(type === 'MIN')
               {
                 if(unitCost < 0)
                   {
                   val = unitCost +  Math.ceil(unitCost * (margin / 100));  
                   }  
                else
                   {
                   val = unitCost -  Math.ceil(unitCost * (margin / 100));    
                   val = val < 0?0:val
                   }   
               }
           else{
               if(unitCost < 0)
                   {
                   val = unitCost -  Math.ceil(unitCost * (margin / 100));  
                   val = val > 0?0:val
                   }  
                else
                   {
                   val = unitCost +  Math.ceil(unitCost * (margin / 100));    
                   }   
               
               }    
   
          return val;    
          }    
    setBillItem(name, index, value)   
       {
        var list = this.state.billItems;   
       switch(name)
          {    
             case "billStartDate":
                list[index].billStartDate = value
                break;
            case "billEndDate":
                list[index].billEndDate = value
                break;   
            case "quantity":
                if(list[index].billItemType === 'PRODUCT')
                {
                  if(Number(list[index].quantity) > Number(value))
                     {
                        list[index].quantity = value  
                     }
                }
                else
                  {
                    list[index].quantity = value
                    this.calcQuantity(index, list[index].quantity)
                  }
                break;   
            case "unitCost":
                list[index].unitCost = value
                this.calcQuantity(index, list[index].quantity)
                break;   
            case "cashOnly":
                list[index].cashOnly = Number(value)
                this.calcQuantity(index, list[index].quantity)
                break;     
            case "authoAmount":
                list[index].authoAmount = value 
                this.calcQuantity(index, list[index].quantity)
                break;  
             default: 
                     console.log("....")
                     break;   
          }
      this.setState({ billItems: list })    
      this.doValidate() 
       }
       calcBill(list, authorisationNumber, authorisationAmount)
        {
            var cashPayable = 0;
            var insurancePayable = 0;
            var shortfallPayable = 0;
            var specialContractPayable = 0;
            var authoAmount = 0;
            for (var index in list)
            {
                if (list[index].activeStatus === "1" || list[index].activeStatus === 1)
                {

                    if (list[index].cashOnly)
                    {
                       cashPayable += list[index].totalCost;
                    } else
                    {
                        switch (this.state.billCategory)
                        {
                            case 'CASH':
                                cashPayable += list[index].totalCost;
                                break;
                            case 'INSURANCE':
                                insurancePayable += list[index].totalCost;
                                authoAmount += Number(list[index].authoAmount);
                                break;
                            case 'SPECIAL_CONTRACT':
                                specialContractPayable += list[index].totalCost;
                                break;
                                default: 
                                        console.log("....")
                                        break;   
                        }
                    }
                }
            }
            if (authorisationNumber !== null && authorisationNumber !== '' && authorisationAmount > 0)
            {
                cashPayable += Number(insurancePayable) - Number(authorisationAmount);
                insurancePayable = authorisationAmount;
            }
            if(authoAmount > 0)
             {
                shortfallPayable = Number(insurancePayable) - Number(authoAmount);
                insurancePayable = authoAmount;
             }
            var cashOwing = Number(cashPayable) - Number(this.state.cashPaid);
            var insuranceOwing = Number(insurancePayable) - Number(this.state.insurancePaid);
            var shortfallOwing = Number(shortfallPayable) - Number(this.state.shortfallPaid);
            var specialContractOwing = Number(specialContractPayable) - Number(this.state.specialContractPaid);
            var totalPayable = Number(cashPayable) + Number(insurancePayable) + Number(shortfallPayable) + Number(specialContractPayable);
            var totalOwing = Number(cashOwing) + Number(insuranceOwing) + Number(shortfallOwing) + Number(specialContractOwing);
            this.setState({cashPayable: cashPayable, insurancePayable: insurancePayable, shortfallPayable: shortfallPayable, specialContractPayable: specialContractPayable, totalPayable: totalPayable
                , cashOwing: cashOwing, insuranceOwing: insuranceOwing, shortfallOwing: shortfallOwing, specialContractOwing: specialContractOwing, totalOwing: totalOwing})
        }
        calcQuantity(index, value)
        {
            var list = this.state.billItems;  
            list[index].cost = Number(list[index].unitCost) * Number(value);
            list[index].totalCost = list[index].cost;
            this.setState({ billItems: list })  
            this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount);
        }
          hideEntry(index)
           {
              let list = this.state.billItems.slice();
              list[index].activeStatus = 0
              this.setState({ billItems: list }); 
              this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount);
           } 
           getProductItems() {
               this.setState({ productItems: [] });
               this.props.setLoader(true)
               var data = {companyId:  this.state.companyId, locationId: this.state.locationId, institutionId: this.state.institutionId, name: this.state.name, rate: this.state.rate, billCategory: this.state.billCategory}
               axios.post(localStorage.getItem('host') + '/patientmanagement/getproductitems', data, {
                   headers: {
                       'Authorization': `Bearer ${localStorage.getItem('token')}`
                   }
               }).then(
                   res => {
                    var options = [];
                    for(var i in res.data)
                        {
                            options.push({value: res.data[i].productItemLocationId, label: res.data[i].name+" ["+res.data[i].quantity+"] - "+res.data[i].batchNumber+" - "+res.data[i].expiryDate})
                        } 
                    this.setState({productItems: res.data, productOptions: options});
                    this.props.setLoader(false)
                   }, err => {
                       console.log(".....")
                       this.setState({error: err+""})
                       this.props.setLoader(false)
                   }
               )
           }
        addProductItem()
           {
            if(this.state.productItemLocationId.value > 0)
               {  console.log('......... service cost id '+this.state.productItemLocationId.value) 
                const now = new Date();
                var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
                let list = this.state.billItems.slice();
                var s = '';
                for (var i in this.state.productItems) {
                    if (this.state.productItems[i].productItemLocationId === Number(this.state.productItemLocationId.value)) {
                        s = this.state.productItems[i];
                        s.cashOnly = this.state.cashOnlyDs === 1 || this.state.cashOnlyDs === "1"?1: s.cashOnly;
                    }
                }
             if(this.state.quantity <= s.quantity)
                 {   
                list.push({id: 0, serviceId: 0, name: s.name, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: 'HOSPITAL', productType:s.type
                , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: this.state.quantity, unitCost: s.sellingPrice, min: this.getMargin(s.sellingPrice, s.margin, 'MIN'), max: this.getMargin(s.sellingPrice, s.margin, 'MAX'), cost: (s.sellingPrice * this.state.quantity), totalCost: (s.sellingPrice * this.state.quantity), cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: s.buyingPrice, activeStatus: 1, productItemId: s.id, productItemLocationId: s.productItemLocationId, locationId: this.state.locationId});
                this.setState({ billItems: list, cashOnlyDs: 0});
                this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                this.doValidate()
                 }
               else
                  {
                    window.scrollTo({top: 0, behavior: 'smooth'})
                    this.setState({error:"Entered quantity cannot be greater than available quantity of "+s.quantity})  
                  }  
               }
           } 
           doValidate() {
               setTimeout(() => {
                   this.validate();
               }, 1000);
           }
           validate() {
       
               var error = false;
               var billItems = this.state.billItems;
               if (billItems === null) {
                   this.setState({ billItemsError: "Bill Items cannot be empty" })
                   error = true
               }
               else if (billItems.length === 0) {
                   this.setState({ billItemsError: "Bill Items cannot be empty" })
                   error = true
               }
               else {
                  
                   
                   for(var i in billItems)
                   {
                    billItems[i].unitCostError =  "" 
                    if(billItems[i].allowEdit)
                      {
                       if (billItems[i].unitCost === null) {
                           billItems[i].unitCostError =  "Unit Cost cannot be empty"
                           error = true
                       }
                       else if (billItems[i].unitCost === "") {
                           billItems[i].unitCostError =  "Unit Cost cannot be empty"
                           error = true
                       }
                       else if (billItems[i].unitCost >= 0 && billItems[i].unitCost > billItems[i].max) {
                        billItems[i].unitCostError =  "Unit Cost cannot be greater than "+billItems[i].max
                        error = true
                    }
                    else if (billItems[i].unitCost >= 0 && billItems[i].unitCost < billItems[i].min) {
                        billItems[i].unitCostError =  "Unit Cost cannot be less than "+billItems[i].min
                        error = true
                    }
                    else if (billItems[i].unitCost < 0 && billItems[i].unitCost > 0) {
                        billItems[i].unitCostError =  "Unit Cost cannot be greater than 0"
                        error = true
                    }
                    else if (billItems[i].unitCost < 0 && billItems[i].unitCost < billItems[i].min) {
                        billItems[i].unitCostError =  "Unit Cost cannot be less than "+billItems[i].min
                        error = true
                    }
                       else {
                           billItems[i].unitCostError =  "" 
                       }
       
                       console.log(billItems[i].unitCostError)
                      }
                 this.setState({ billItemsError: "", billItems: billItems })   
                   }
               }
               console.log(" error" + error)
               if(this.state.allowSave === false)
                 {
                   this.setState({ error: "The visit for this patient cannot be saved. Please contact accounts department for Help", showSubmit: false })
                 }
               else  if (error === true) {
                   this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
               }
               else {
                   this.setState({ error: "", showSubmit: true })
               }
           } 
           doProductItemFromBarCode(val) {
               this.props.setLoader(true)
               setTimeout(() => {
                   if(this.state.barcode === val && this.state.barcode !== "")
                     {
                      this.setProductItemFromBarCode();
                     }
               }, 2000);
           }
           setProductItemFromBarCode()
                 {
                   const now = new Date();
                    var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
                    var data = {companyId:  this.state.companyId, locationId: this.state.locationId, institutionId: this.state.institutionId, barcode: this.state.barcode, rate: this.state.rate, billCategory: this.state.billCategory}
                    axios.post(localStorage.getItem('host') + '/patientmanagement/getproductitemsbarcode', data, {
                       headers: {
                           'Authorization': `Bearer ${localStorage.getItem('token')}`
                       }
                   }).then(
                       res => {
                           this.setState({barcode: ""})
                           if(res.data.quantity > 0)
                             {
                           var quantity = 0;
                           var index = 0;
                           var list = this.state.billItems;
                           for(var i in list)
                              {
                               if(list[i].productItemLocationId === res.data.productItemLocationId)
                                   {
                                    quantity++;
                                    index = i;
                                   }
                              }
                           if(quantity === 0)
                              {
                                res.data.cashOnly = this.state.cashOnlyDs === 1 || this.state.cashOnlyDs === "1"?1: res.data.cashOnly;
                                list.push({id: 0, serviceId: 0, name: res.data.name, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: 'HOSPITAL', productType:res.data.type
                                , tariffCode: res.data.tariffCode, billStartDate: date1, billEndDate: date1, quantity: 1, unitCost: res.data.sellingPrice, min: this.getMargin(res.data.sellingPrice, res.data.margin, 'MIN'), max: this.getMargin(res.data.sellingPrice, res.data.margin, 'MAX'), cost: res.data.sellingPrice , totalCost: res.data.sellingPrice, cashOnly: res.data.cashOnly, allowEdit: res.data.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: res.data.authoAmount, wac: 0.0, lastReceiptCost: res.data.buyingPrice, activeStatus: 1, productItemId: res.data.id, productItemLocationId: res.data.productItemLocationId, locationId: this.state.locationId});
                                this.setState({ billItems: list , cashOnlyDs: 0});
                                 this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                               this.doValidate()
                              }   
                           else if(quantity <= res.data.quantity)
                           {   
                           list[index].quantity = list[index].quantity + 1
                           list[index].cost =  list[index].quantity * list[index].unitCost
                           list[index].totalCost =  list[index].quantity * list[index].unitCost
                          this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                          this.doValidate()
                           }
                         else
                            {                              
                              document.getElementById('topDiv').scrollIntoView()
                              this.setState({error:"Entered quantity cannot be greater than available quantity of "+res.data.quantity})  
                            }
                          
                             } 
                          else
                             {
                                document.getElementById('topDiv').scrollIntoView()
                              this.setState({error: "Product was not found."}) 
                             }   
                             this.props.setLoader(false)
                       }, err => {
                           console.log(".....")
                           this.setState({error: err+"", barcode: ""})
                           document.getElementById('topDiv').scrollIntoView()
                           this.props.setLoader(false)
                       }
                   )
                   
                 } 
           processDateTime(date)
             {
               const now = new Date(date);
               const todayY = now.getFullYear();
               const todayM = checkZero(now.getMonth() + 1);
               const todayD = checkZero(now.getDate());
               var date1 = todayY + '-' + checkZero(todayM) + '-' + checkZero(todayD)+ ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());;
               console.log(date1)
              return date1;
             }
    getPreConfigured(locationId)
        {
            this.setState({ procedures: [], locationId: locationId });
               this.props.setLoader(true)
              axios.get(localStorage.getItem('host') + '/patientmanagement/getprocedures/'+locationId+"/"+this.state.visitId,  {
                   headers: {
                       'Authorization': `Bearer ${localStorage.getItem('token')}`
                   }
               }).then(
                   res => {
                    this.setState({procedures: res.data});
                    this.props.setLoader(false)
                   }, err => {
                       console.log(".....")
                       this.setState({error: err+""})
                       this.props.setLoader(false)
                   }
               )  
        }    
        viewHistory(visitId) {
    
            this.setState({ htmlText: '', popupName: '' })
             this.props.setLoader(true)
            axios.get(localStorage.getItem('host') + '/hospital/casualtysummaryview/' + visitId, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ htmlText: res.data, popupName: 'Doctor History' });
                    document.getElementById('genericPopup').style = 'display:block'
                    this.props.setLoader(false)
                }, err => {
                    console.log(".....")
                    this.setState({ error: err })
                    this.props.setLoader(false)
                }
            )
        }
  addPreconfigs()
        {
         if(this.state.procedure >= 0)
            {
             var procedures = this.state.procedures[this.state.procedure];
             let list = this.state.billItems.slice();
             for (var i in procedures.productItems) {
                var s = procedures.productItems[i];
                s.cashOnly = this.state.cashOnlyPcp === 1 || this.state.cashOnlyPcp === "1"?1: s.cashOnly
                list.push({id: 0, serviceId: 0, name: s.name, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: 'HOSPITAL', productType:s.type
                , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: s.quantity, unitCost: s.sellingPrice, min: this.getMargin(s.sellingPrice, s.margin, 'MIN'), max: this.getMargin(s.sellingPrice, s.margin, 'MAX'), cost: (s.sellingPrice * s.quantity), totalCost: (s.sellingPrice * s.quantity), cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: s.buyingPrice, activeStatus: 1, productItemId: s.id, productItemLocationId: s.productItemLocationId, locationId: this.state.locationId});
               }
               for (i in procedures.services) {
                s = procedures.services[i];
                s.cashOnly = this.state.cashOnlyPcp === 1 || this.state.cashOnlyPcp === "1"?1: s.cashOnly
                list.push({id: 0, serviceId: s.id, name: s.name, billItemType: 'SERVICE', serviceType: s.serviceType, serviceClass: s.serviceClass
                , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: s.quantity, unitCost: s.fixedCost, min: this.getMargin(s.fixedCost, s.margin, 'MIN'), max: this.getMargin(s.fixedCost, s.margin, 'MAX'), cost: s.fixedCost, totalCost: s.fixedCost, cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
                 }
           
                this.setState({ billItems: list , cashOnlyPcp: 0});
                this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
                this.doValidate()
            }
        }         
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="nurseAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info" id="topDiv">Nurse Action</div>
            <div className="card-body" >
                {divMsg}
                <div id="resultPopup1" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">Result View</div>
                        <div className="card-body" >
                            <table className="table table-striped">
                                <tr>
                                    <th>File</th>
                                    <th>Remarks</th>
                                </tr>
                                <tbody>
                                    {this.state.results.map((item, index) => (
                                        <tr key={index}>
                                            {item.fileStorageId > 0 ? (<td><span className="link" onClick={() => this.showFile(item.fileStorageId, item.fileExtension)}>{item.fileName}</span></td>) : (<td></td>)}
                                            <td>{item.remarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.resultClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="showFileNurseAction1" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                            <iframe title="PDF File View" src={'data:application/pdf;base64,' + this.state.fileData} type="application/pdf" style={{ width: 100 + '%', height: 500 + 'px' }}></iframe>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="genericPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">{this.state.popupName}</div>
                        <div className="card-body" >
                            <span dangerouslySetInnerHTML={{ __html: this.state.htmlText }}></span>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.genericClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">

                        {this.state.historyVisits.length > 0 && (<div className="card card-green" >
                            <div className="card-header  text-dark">History</div>
                            <div className="card-body">
                                <ul>
                                    {this.state.historyVisits.map((item, index) => (
                                        <li key={index}><span className="link" onClick={() => this.showNurseHistory(item.id)} >{item.name}</span></li>
                                    ))}
                                </ul>
                            </div>
                        </div>)}
                        <div className="card  card-green">
                            <div className="card-header  text-dark">Doctor Notes</div>
                            <div className="card-body">
                                <ul>
                                <li ><span className="link" onClick={() => this.viewHistory(this.state.visitId)} >Current Notes</span></li>
                                </ul>
                            </div>
                        </div>
                        {this.state.results.length > 0 && (<div className="card  card-green" >
                            <div className="card-header  text-dark">Other Info</div>
                            <div className="card-body">
                                <ul>
                                    <li><span className="link" onClick={() => this.viewResults()}>View Results</span></li>
                                </ul>
                            </div>
                        </div>)}
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Notes</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('NOTES', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.notes.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Notes</div>
                            <div className="card-body">
                                <table >
                                    {this.state.notes.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'NOTES')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Referral Letter</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('REFERRAL_LETTERS', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.referralLetters.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Referral Letters</div>
                            <div className="card-body">
                                <table >
                                    {this.state.referralLetters.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'REFERRAL_LETTERS')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">ID No:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Employer:</span> {this.state.employer}</div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    </div>
                            </div>
                        </div>
                        <div id="110"></div>
                        <br />

                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item">
                                <a className={this.state.nurseDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleMainTabs("nurseDetails") }} href={false} >Nurse Details</a>
                            </li>
                           <li className="nav-item">
                                <a className={this.state.billingDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleMainTabs("billingDetails") }} href={false}>Billing</a>
                            </li>
                        </ul>
                        <div className="tab-content pt-2 pl-1" id="pills-tabContent">
                                <div className={this.state.nurseDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                <div className="card  card-blue">
                            <div className="card-header">
                                Vitals
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col" align="right"><input type="button" onClick={() => this.addVital()} value="Insert Line" className="btn btn-success" /></div>

                                </div>
                                <br />
                                <table className="table" id="tableNurse">
                                    <tr>
                                        <th>Time</th>
                                        <th>Temperature</th>
                                        <th>Pulse (bmp)</th>
                                        <th>Glucometer (mmol/l)</th>
                                        <th>Systolic</th>
                                        <th>Diastolic</th>
                                        <th>SpO2 (%)</th>
                                        <th>GCS / 15</th>
                                        <th>Action</th>
                                    </tr>
                                    <tbody>
                                        {this.state.vitals.map((item, index) => (
                                            <tr key={index}>
                                                <td><input type="text" className="form-control" value={item.vitalTime} onChange={e => { this.setVitalValue(index, 'vitalTime', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.temperature} onChange={e => { this.setVitalValue(index, 'temperature', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.pulse} onChange={e => { this.setVitalValue(index, 'pulse', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.glucometer} onChange={e => { this.setVitalValue(index, 'glucometer', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.systolic} onChange={e => { this.setVitalValue(index, 'systolic', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.diastolic} onChange={e => { this.setVitalValue(index, 'diastolic', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.spO2} onChange={e => { this.setVitalValue(index, 'spO2', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.gcs} onChange={e => { this.setVitalValue(index, 'gcs', e.target.value); }} /></td>
                                               <td ><span className="link-red" onClick={() => this.deleteRow(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card card-blue"><div className="card-header">Diagnosis</div><div className="card-body">
                         <div className="row">
                              <div className="col"><b>Text:</b> {this.state.diagnosisText}</div>
                         </div>
                         <div className="row">
                            <div className="col">
                                <table className="table table-striped">
                                    <head>
                                        <tr>
                                            <th>ICD 10 Description</th>
                                        </tr>
                                    </head>
                                    <tbody>
                                    {this.state.patientDiagnosis.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item}</td>
                                            </tr>
                                    ))}    
                                    </tbody>
                                </table>
                            </div>
                         </div>
                        </div></div>    
                        <br />
                        <div className="card  card-blue">
                            <div className="card-header">
                                Nurse Details
                            </div>
                           
                        
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"> <div className="input-group"><span className="input-group-text">Weight (Kg)</span>
                                        <div><input type="text" className="form-control" value={this.state.weight} onChange={e => this.setNurseDetails("weight", e.target.value)} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Height (cm)</span>
                                        <div ><input type="text" className="form-control" value={this.state.height} onChange={e => this.setNurseDetails("height", e.target.value)} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">BMI</span>
                                        <div><input type="text" readonly="readonly" className="form-control" value={this.state.bmi} /></div>
                                    </div></div>
                                </div>
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Drug Allergies</span><textarea className="form-control" value={this.state.drugAllergy} onChange={e => this.setState({ drugAllergy: e.target.value })}></textarea></div></div>
                                </div>
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Other Allergies</span><textarea className="form-control" value={this.state.otherAllergy} onChange={e => this.setState({ otherAllergy: e.target.value })}></textarea></div></div>
                                </div>
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Bed Details</span><input type="text" className="form-control" value={this.state.bedDetails} onChange={e => this.setState({ bedDetails: e.target.value })} /></div></div>
                                </div>
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Notes</span><textarea className="form-control" value={this.state.nurseNotes} onChange={e => this.setState({ nurseNotes: e.target.value })}></textarea></div></div>
                                </div>
                            </div>
                        </div>
                        {this.state.managementLists.length > 0 && ( <div className="card  card-blue">
                                    <div className="card-header">Management</div>
                                    <div className="card-body">
                                    <table className="table" id="table">
                                            <tr>
                                                <th>Time</th>
                                                <th>Procedure</th>
                                            </tr>
                                            <tbody>
                                                {this.state.managementLists.map((item, index) =>  (
                                                    <tr key={index}>
                                                        <td>{item.time}</td>
                                                        <td>{item.procedure}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div></div>) }
                        <div className="card  card-blue">
                            <div className="card-header">
                                Medication Given
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col" align="right"><input type="button" onClick={() => this.addMedicationGiven()} value="Insert Line" className="btn btn-success" /></div>

                                </div>
                                <br />
                                <table className="table" id="tableNurse">
                                    <tr>
                                        <th>Time</th>
                                        <th>Drug</th>
                                        <th>Route</th>
                                        <th>Dose</th>
                                        <th>Sign</th>
                                        <th>Action</th>
                                    </tr>
                                    <tbody>
                                        {this.state.medicationGivens.map((item, index) => (
                                            <tr key={index}>
                                                <td><input type="text" className="form-control" value={item.time} onChange={e => { this.setMedicationGivenValue(index, 'time', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.drugName} onChange={e => { this.setMedicationGivenValue(index, 'drugName', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.route} onChange={e => { this.setMedicationGivenValue(index, 'route', e.target.value); }} /></td>
                                                <td><input type="text" className="form-control" value={item.dose} onChange={e => { this.setMedicationGivenValue(index, 'dose', e.target.value); }} /></td>
                                                <td>{ item.sign}</td>
                                                 <td ><span className="link-red" onClick={() => this.deleteRowMedicationGiven(index)} ><i className="bi bi-trash-fill"></i></span></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card"><div className="card-header">Urine Analysis</div><div className="card-body">
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Ph</span>
                                        <div><input type="text"  className="form-control" value={this.state.ph} onChange={e => this.setState({ ph: e.target.value })} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Ketones</span>
                                        <div ><input type="text"  className="form-control" value={this.state.ketones} onChange={e => this.setState({ ketones: e.target.value })} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Sg</span>
                                        <div ><input type="text" className="form-control" value={this.state.sg} onChange={e => this.setState({ sg: e.target.value })} /></div>
                                    </div></div>
                                </div>
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Proteins</span>
                                        <div><input type="text"  className="form-control" value={this.state.proteins} onChange={e => this.setState({ proteins: e.target.value })} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Leucocytes</span>
                                        <div ><input type="text"  className="form-control" value={this.state.leucocytes} onChange={e => this.setState({ leucocytes: e.target.value })} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Urobilinogen</span>
                                        <div ><input type="text" className="form-control" value={this.state.urobilinogen} onChange={e => this.setState({ urobilinogen: e.target.value })} /></div>
                                    </div></div>
                                </div>
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Blood</span>
                                        <div><input type="text"  className="form-control" value={this.state.blood} onChange={e => this.setState({ blood: e.target.value })} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Nitrate</span>
                                        <div ><input type="text"  className="form-control" value={this.state.nitrate} onChange={e => this.setState({ nitrate: e.target.value })} /></div>
                                    </div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Glucose</span>
                                        <div ><input type="text" className="form-control" value={this.state.glucose} onChange={e => this.setState({ glucose: e.target.value })} /></div>
                                    </div></div>
                                </div>
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Other</span><textarea className="form-control" value={this.state.otherUrineAnaysis} onChange={e => this.setState({ otherUrineAnaysis: e.target.value })}></textarea></div></div>
                                </div>
                                </div></div>
                    </div>
                    <div className={this.state.billingDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                    <div className="card"><div className="card-body">
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Location</span>
                                                <select className="form-control" onChange={e => this.getPreConfigured(e.target.value)}  >
                                                    <option value="0">Choose Location</option>
                                                    {this.state.locations.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Pre Configured Procedures</span>
                                                    <select className="form-control" value={this.state.procedure} onChange={e => this.setState({ procedure: e.target.value })}  >
                                                    <option value="">Choose Pre Configured Procedures</option>
                                                    {this.state.procedures.map((item, index) => (
                                                        <option key={index} value={index} >{item.name}</option>
                                                    ))}
                                                </select></div></div>
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Cash Only</span><select className="form-control" data-live-search="true" value={this.state.cashOnlyPcp} onChange={e => this.setState({ cashOnlyPcp: e.target.value })}>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select></div></div>
                                                <div className="col" align="left">{ this.state.procedure >= 0 && (<input type="button" onClick={() => this.addPreconfigs()} value="Add" className="btn btn-success" />)}</div>
                                            </div>
                                            </div></div>
                                            <br />
                                      <div className="card"><div className="card-body">
                                            <div className="row">
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Service</span>
                                                    <Select
                                                    value={this.state.serviceCostId}
                                                    className="form-control"
                                                    onChange={e => this.setState({ serviceCostId: e })}
                                                    options={this.state.options}
                                                /></div></div>
                                                <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Cash Only</span><select className="form-control" data-live-search="true" value={this.state.cashOnly} onChange={e => this.setState({ cashOnly: e.target.value })}>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select></div></div>
                                                <div className="col" align="left">{ this.state.serviceCostId !== "" && (<input type="button" onClick={() => this.addServices()} value="Add" className="btn btn-success" />)}</div>
                                            </div>
                                            </div></div>
                                            <br />
                                            <div className="card"><div className="card-body">
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Location</span>
                                                <select className="form-control" value={this.state.locationId} onChange={e => this.setState({ locationId: e.target.value })}  >
                                                    <option value="0">Choose Location</option>
                                                    {this.state.locations.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Name</span>
                                                 <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({name: e.target.value}) }} /></div>
                                            </div>
                                            <div className="col"><button className="btn btn-success" onClick={() => this.getProductItems()} ><i class="bi bi-search"></i></button></div>
                                            <div className="col"> <div className="input-group"><span className="input-group-text">Barcode</span><input type="text" className="form-control" readOnly={this.state.locationId === ''?"readOnly":""} value={this.state.barcode} onChange={e => { this.setState({barcode: e.target.value}); this.doProductItemFromBarCode(e.target.value) }} /></div></div>
                                        </div>
                                            <div className="row">
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Products</span>
                                                 <Select
                                                    value={this.state.productItemLocationId}
                                                    className="form-control"
                                                    onChange={e => this.setState({ productItemLocationId: e })}
                                                    options={this.state.productOptions}
                                                /></div>
                                            </div>
                                            <div className="col">
                                                 <div className="input-group"><span className="input-group-text">Quantity</span>
                                                 <input type="text" className="form-control" value={this.state.quantity} onChange={e => { this.setState({quantity: e.target.value}) }} /></div>
                                            </div>
                                            
                                            <div className="col "><div className="input-group">
                                                    <span className="input-group-text">Cash Only</span><select className="form-control" data-live-search="true" value={this.state.cashOnlyDs} onChange={e => this.setState({ cashOnlyDs: e.target.value })}>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </select></div></div>
                                             <div className="col">{ this.state.productItemLocationId !== "" && this.state.quantity > 0 && (<input type="button"  onClick={() => this.addProductItem()} value="Add" className="btn btn-success" />)}</div>

                                        </div>
                                        </div></div>
                                            <br />
                                    <div className="card  card-blue">
                                    <div className="card-header">Prescription</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Drug</th>
                                                <th>Dosage</th>
                                                <th>Frequency</th>
                                                <th>Route</th>
                                                <th>Duration</th>
                                                <th>Formulation</th>
                                                <th>Repeats</th>
                                            </tr>
                                            <tbody>
                                                {this.state.treatments.map((item, index) =>(
                                                    <tr key={index}>
                                                        <td>{item.drugName} </td>
                                                        <td>{item.dosage}</td>
                                                        <td>{item.frequencyName}</td>
                                                        <td>{item.routeName} </td>
                                                        <td>{item.durationName}</td>
                                                        <td>{item.formulationName} </td>
                                                        <td>{item.repeats}</td>
                                                         </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                    </div></div>
                                            <br />
                                            <table className="table table-striped">
                                                <tr>
                                                    <th>Line</th>
                                                    <th>Name</th>
                                                    <th>Tariff</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Quantity</th>
                                                    <th>Unit Cost</th>
                                                    <th>Cost</th>
                                                    <th>Autho Amount</th>
                                                    <th>Total Cost</th>
                                                    <th>Cash Only</th>
                                                    <th>Action</th>
                                                </tr>
                                                <tbody>
                                                {this.state.billItems.map((billItem, index) => (
                                                    billItem.activeStatus === 1 &&(<tr >
                                                        <td >{index + 1}</td>
                                                        <td >{billItem.name}</td>
                                                        <td>{billItem.tariffCode}</td>
                                                        <td><DatePicker selected={new Date(billItem.billStartDate)} onChange={e => this.setBillItem("billStartDate", index, e)} timeInputLabel="Time:" showTimeInput dateFormat="yyyy-MM-dd HH:mm:ss" /></td>
                                                        <td><DatePicker selected={new Date(billItem.billEndDate)} onChange={e => this.setBillItem("billEndDate", index, e)} timeInputLabel="Time:" showTimeInput dateFormat="yyyy-MM-dd HH:mm:ss" /></td>
                                                        {billItem.serviceType === 'MULTIPLE_QUANTITY' || billItem.billItemType === "PRODUCT"?(<td><input type="text" className="form-control" value={billItem.quantity} onChange={e => {this.setBillItem("quantity", index, e.target.value); this.calcQuantity(index, e.target.value)}} /></td>):(<td>{billItem.quantity}</td>)}
                                                        {billItem.allowEdit === 1 ?(<td><div className="input-group has-validation"><input type="text" className={billItem.unitCostError !== "" ? "form-control is-invalid" : "form-control"} v-model="billItem.unitCost" value={billItem.unitCost} onChange={e => this.setBillItem("unitCost", index, e.target.value)} /><div className="invalid-feedback">{billItem.unitCostError}</div></div></td>):(<td>{this.currencyFormat(billItem.unitCost)}</td>)}
                                                        <td>{this.currencyFormat(billItem.cost)}</td>
                                                        <td>{billItem.authoAmount}</td>
                                                        <td>{this.currencyFormat(billItem.totalCost)}</td>
                                                        <td><select className="form-control" value={billItem.cashOnly} onChange={e => { this.setBillItem("cashOnly",index, e.target.value) }}  >
                                                        <option value="1" >Yes</option>
                                                            <option value="0" >No</option>
                                                        </select></td>
                                                        <td><span className="link-red" onClick={() => this.hideEntry(index)}><i className="bi bi-trash-fill"></i></span></td>
                                                    </tr>)
                                                ))}
                                                </tbody>
                                            </table>
                                            <div className="row">
                                            <div className="input-group">
                                                        <input type="hidden" className={this.state.billItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                                        <div className="invalid-feedback">{this.state.billItemsError}</div> </div></div>    
                                         <div className="row">
                                                <div className="col"><div className="input-group"><span className="input-group-text">Authorisation Number</span><input type="text" className="form-control" data-live-search="true" onChange={e => {this.setState({ authorisationNumber: e.target.value }); this.calcBill(this.state.billItems, e.target.value, this.state.authorisationAmount);}} value={ this.state.authorisationNumber} /></div></div>
                                                <div className="col"><div className="input-group"><span className="input-group-text">Authorisation Amount</span><input type="text" className="form-control" data-live-search="true" onChange={e => {this.setState({ authorisationAmount: e.target.value }); this.calcBill(this.state.billItems, this.state.authorisationNumber, e.target.value);}} value={ this.state.authorisationAmount} /></div></div>
                                                </div>            
                                         
                                            <table className="table table-striped">
                                                <tr>
                                                    <th></th>
                                                    <th>Cash</th>
                                                    <th>Insurance</th>
                                                    <th>Shortfall</th>
                                                    <th>Special Contract</th>
                                                    <th>Total</th>
                                                </tr>
                                                <tbody>
                                                <tr>
                                                    <th>Payable</th>
                                                    <td>{this.currencyFormat(this.state.cashPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePayable)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPayable)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPayable)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Paid</th>
                                                    <td>{this.currencyFormat(this.state.cashPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.insurancePaid)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractPaid)}</td>
                                                    <td>{this.currencyFormat(this.state.totalPaid)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Owing</th>
                                                    <td>{this.currencyFormat(this.state.cashOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.insuranceOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.shortfallOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.specialContractOwing)}</td>
                                                    <td>{this.currencyFormat(this.state.totalOwing)}</td>
                                                </tr></tbody>

                                            </table>
                                            </div>
                                            </div>
                                            </div>
                </div>
                <div className="row">
                    <div className="col" align="right">{ this.state.showButton && <button onClick={() => this.save()} className="btn btn-primary">Save</button>}</div>
                    <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}      