import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import OtcList from "./otclist";
import Receipt from "./receipt";
import Select from 'react-select';


function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
export default class Otc extends Component {
    state = { loader: false,name: '', rate: 1, companyId: localStorage.getItem('companyId'), companys: [], currencyId: 0, currencyName: '', locationId: 0, locations: [], productOptions: [], productItemId:0, productItemLocationId: '', cashPayable: 0.00, cashPaid: 0.00, cashOwing: 0.00, status: 'CREATED', productItems: [], otcItems: [], otcItemsError: '', id:0, billId: 0}
    constructor(props) {
        super(props);
    this.setLoader = this.setLoader.bind(this)
    this.getPrintOut = this.getPrintOut.bind(this)
    this.getForm = this.getForm.bind(this)
    this.getList = this.getList.bind(this)
    this.openReceiptAction = this.openReceiptAction.bind(this)
    this.printOutRef = React.createRef()
    this.otcListRef = React.createRef()
    this.receiptRef = React.createRef()
    }
    
    componentDidMount() {
      this.getForm(0);
    }
    getForm(id, msg) {
        this.setState({id: id, billId: 0, rate: 1, currencyId: 0, companyId: localStorage.getItem('companyId'), companys: [], currencyName: '', locations: [], cashPayable: 0.00, cashPaid: 0.00, cashOwing: 0.00, status: 'CREATED', otcItems: [], msg: msg, error: ''});
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/sell/otc/' + id+'/'+localStorage.getItem('companyId')+'/'+localStorage.getItem('roleId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({rate: res.data.rate, currencyId: res.data.currencyId, companyId: res.data.companyId, companys: res.data.companys, currencyName: res.data.currencyName, cashPayable: res.data.cashPayable, cashPaid: res.data.cashPaid, cashOwing: res.data.cashOwing, status: res.data.status, otcItems: res.data.otcItems, billId: res.data.billId });
                this.setLoader(false)
                this.doValidate()
            }, err => {
                console.log(".....")
                this.setState({error: err+""})
                this.setLoader(false)
            }
        )
    }
    save() {
        this.setLoader(true)
        var data = {id: this.state.id, rate: this.state.rate, currencyId: this.state.currencyId, companyId: this.state.companyId, cashPayable: this.state.cashPayable, cashPaid: this.state.cashPaid, cashOwing: this.state.cashOwing, status: this.state.status, otcItems: this.state.otcItems }
         axios.post(localStorage.getItem('host') + '/sell/saveotc', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                document.getElementById('topDiv').scrollIntoView()
                this.getForm(res.data, 'Successfully saved Sell') 
                this.setLoader(false)
                this.setState({ error: "", showSubmit: false })
            }, err => {
                console.log(".....")
                this.setState({error: err+""})
                this.setLoader(false)
            }
        )
    }
      
    getOtcProducts() {
        this.setState({ productItems: [] });
        this.setLoader(true)
        var data = {companyId:  this.state.companyId, locationId: this.state.locationId, name: this.state.name, rate: this.state.rate}
        axios.post(localStorage.getItem('host') + '/master/getotcproductitems', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                var options = [];
                for(var i in res.data)
                    {
                        options.push({value: res.data[i].productItemLocationId, label: res.data[i].name+"-"+res.data[i].category+" ["+res.data[i].quantity+"] - "+res.data[i].batchNumber+" - "+res.data[i].expiryDate})
                    } 
                this.setState({productItems: res.data, productOptions: options, productItemLocationId: ''});
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({error: err+""})
                this.setLoader(false)
            }
        )
    }
    closePopup() {
        this.setState({ fileData: '' });
        document.getElementById('productList').style = 'display:none'
    }
    setLoader(loader)
    {
     this.setState({loader: loader})
    }
    setProductItem(item)
    {
        let list = this.state.otcItems.slice();
        list.push({
            id: 0, productItemId: item.id, productItemLocationId: item.productItemLocationId, name: item.name, category: item.category, type: item.type, tariffCode: item.tariffCode, maxQuantity: item.quantity, quantity: 0, quantityError: '',
            cost: item.sellingPrice, unitCostError: '', allowEdit: item.allowEdit, dispensingFee: item.dispensingFee, totalCost: 0.00, instruction: '', storedQuantity: 0.0, activeStatus: 1 , locationId: this.state.locationId});
            this.closePopup()
        this.validate(list)
    }
   
    setRowValue(index, e, name) {
        let list = this.state.otcItems.slice();
        switch (name) {
            case "quantity":
                list[index].quantity = e.target.value;
                break;
            case "unitCost":
                list[index].unitCost = e.target.value;
                break;
            case "instruction":
                list[index].instruction = e.target.value;
                break;
            case "activeStatus":
                list[index].activeStatus = 0;
                break;
            default:
                console.log("default")
                break;
        }
        this.calcBill(list)
        this.doValidate()
    }
    doValidate() {
        setTimeout(() => {
            this.validate();
        }, 1000);
    }
    validate() {
         var totalCost = 0;
        var error = false;
        var otcItems = this.state.otcItems
        for (var index in this.state.otcItems) {
            if( otcItems[index].activeStatus === 1)
                    {
            if (otcItems[index].quantity === "") {
                otcItems[index].quantityError = "Quantity cannot be empty"
                otcItems[index].quantity= 0;
                error = true
            }
            else if(otcItems[index].quantity > otcItems[index].maxQuantity){
                otcItems[index].quantityError = "Quantity cannot be a number greater than System Quantity "+otcItems[index].maxQuantity
                otcItems[index].quantity= 0;
                error = true
            }
            else if (otcItems[index].quantity > 0) {
                otcItems[index].quantityError = "";
                
                     otcItems[index].totalCost = (Number(otcItems[index].unitCost) * Number(otcItems[index].quantity)) +  Number(otcItems[index].dispensingFee)
            }
            else {
                otcItems[index].quantityError = "Quantity should be a number greater than zero"
                otcItems[index].quantity= 0;
                error = true
            }
            if (otcItems[index].unitCost === "") {
                otcItems[index].unitCostError = "Unit Cost cannot be empty"
                otcItems[index].unitCost= '';
                error = true
            }
            else if (otcItems[index].unitCost > 0) {
                otcItems[index].unitCostError = "";
                
                     otcItems[index].totalCost = (Number(otcItems[index].unitCost) * Number(otcItems[index].quantity)) +  Number(otcItems[index].dispensingFee)
            }
            else {
                otcItems[index].unitCostError = "Unit Cost should be a number greater than zero"
                otcItems[index].unitCost= '';
                error = true
            }

            totalCost += Number(otcItems[index].totalCost)
        }
        
        }

            
        if (this.state.otcItems === null) {
            this.setState({ otcItemsError: "OTC Items cannot be empty" })
            error = true
        }
        else if (this.state.otcItems.length === 0) {
            this.setState({ otcItemsError: "OTC Items cannot be empty" })
            error = true
        }
        else {
            this.setState({ otcItemsError: "" })
        }
        console.log(" error" + error)
        this.setState({ otcItems: otcItems, cashPayable: totalCost, cashOwing: (totalCost - this.state.cashPaid) })
        if (error === true) {
            this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
        }
        else {
            this.setState({ error: "", showSubmit: true })
        }
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    getPrintOut(id, type, printouts, companyId)
      {
        this.setState({loader: true})  
        this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
        document.getElementById('printOutAction').style = 'display:block'
      }
      viewOtcList()
        {
          this.setState({loader: true})  
          this.otcListRef.current.getList()
          document.getElementById('otcListAction').style = 'display:block'
        }
    openReceiptAction(billId, action, otcId)
          {
            this.setState({loader: true})
            this.receiptRef.current.openReceiptAction(billId, action, otcId)
            document.getElementById('receiptAction').style = 'display:block'       
          }
    setLocations(id)
         {
            for (var i in this.state.companys) {
                if (Number(this.state.companys[i].id) === Number(id)) {
                    var locations = this.state.companys[i].locations;
                    this.setState({ locations: locations})
                }
            }
         } 
         addProductItem()
            {
             if(this.state.productItemLocationId.value > 0)
                {  console.log('......... service cost id '+this.state.productItemLocationId.value) 
                 const now = new Date();
                 var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
                 let list = this.state.otcItems.slice();
                 var s = '';
                 for (var i in this.state.productItems) {
                     if (this.state.productItems[i].productItemLocationId === Number(this.state.productItemLocationId.value)) {
                         s = this.state.productItems[i];
                     }
                 }
              if(this.state.quantity <= s.quantity)
                  {   
                 list.push({id: 0, serviceId: 0, name: s.name, category: s.category, type: s.type, packSize: s.packSize, uom: s.uom, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: s.type
                 , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: this.state.quantity, unitCost: s.sellingPrice, unitCostError:'', cost: (s.sellingPrice * this.state.quantity), totalCost: (s.sellingPrice * this.state.quantity), cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: s.buyingPrice, activeStatus: 1, productItemId: s.id, productItemLocationId: s.productItemLocationId, locationId: this.state.locationId});
                 this.setState({ otcItems: list });
                 this.calcBill(list)
                 this.doValidate()
                  }
                else
                   {
                     
                    document.getElementById('topDiv').scrollIntoView()
                     this.setState({error:"Entered quantity cannot be greater than available quantity of "+s.quantity})  
                   }  
                }
            } 
            doProductItemFromBarCode(val) {
                this.setLoader(true)
                setTimeout(() => {
                    if(this.state.barcode === val && this.state.barcode !== "")
                      {
                       this.setProductItemFromBarCode();
                      }
                }, 2000);
            }
        setProductItemFromBarCode()
              {
                const now = new Date();
                 var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
                 var data = {companyId:  this.state.companyId, locationId: this.state.locationId, barcode: this.state.barcode, rate: this.state.rate}
                axios.post(localStorage.getItem('host') + '/master/getotcproductitemsbarcode', data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }).then(
                    res => {
                        this.setState({barcode: ""})
                        if(res.data.quantity > 0)
                          {
                        var quantity = 0;
                        var index = 0;
                        var list = this.state.otcItems;
                        for(var i in list)
                           {
                            if(list[i].productItemLocationId === res.data.productItemLocationId)
                                {
                                 quantity++;
                                 index = i;
                                }
                           }
                        if(quantity === 0)
                           {
                            list.push({id: 0, serviceId: 0, name: res.data.name, category: res.data.category, type: res.data.type, packSize: res.data.packSize, uom: res.data.uom, billItemType: 'PRODUCT', serviceType: 'MULTIPLE_QUANTITY_SUM_COST', serviceClass: res.data.type
                            , tariffCode: res.data.tariffCode, billStartDate: date1, billEndDate: date1, quantity: 1, unitCost: res.data.sellingPrice, cost: res.data.sellingPrice, unitCostError: '', totalCost: res.data.sellingPrice, cashOnly: res.data.cashOnly, allowEdit: res.data.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: res.data.authoAmount, wac: 0.0, lastReceiptCost: res.data.buyingPrice, activeStatus: 1, productItemId: res.data.id, productItemLocationId: res.data.productItemLocationId, locationId: this.state.locationId});
                            this.setState({ otcItems: list });
                            this.calcBill(list)
                            this.doValidate()
                           }   
                        else if(quantity <= res.data.quantity)
                        {   
                        list[index].quantity = list[index].quantity + 1
                        list[index].cost =  list[index].quantity * list[index].unitCost
                        list[index].totalCost =  list[index].quantity * list[index].unitCost
                       this.calcBill(list)
                       this.doValidate()
                        }
                      else
                         {
                           window.scrollTo({top: 0, behavior: 'smooth'})
                           this.setState({error:"Entered quantity cannot be greater than available quantity of "+res.data.quantity})  
                         }
                       
                          } 
                       else
                          {
                           this.setState({error: "Product was not found."}) 
                          }   
                          this.setLoader(false)
                    }, err => {
                        console.log(".....")
                        this.setState({error: err+""})
                        this.setLoader(false)
                    }
                )
                
              } 
            calcBill(list)
                {
                    var cashPayable = 0;
                    for (var index in list)
                    {
                        if (list[index].activeStatus === "1" || list[index].activeStatus === 1)
                        {
        
                            cashPayable += list[index].totalCost;
                        }
                    }
                    var cashOwing = Number(cashPayable) - Number(this.state.cashPaid);
                    this.setState({otcItems: list, cashPayable: cashPayable , cashOwing: cashOwing})
                } 
    getList()
       {
        this.otcListRef.current.getList()
       }            
    reload()
          {
            window.location = './otcaction'
          }                 
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid" id="topDiv">
                        <div className="row">
                            <div className="col"><input type="button" className="btn btn-success" onClick={() => this.viewOtcList()} value="View Otc List" /></div>
                        </div>
                        {divMsg}
                        { this.state.id > 0 && ( <span className="link" onClick={() => this.getPrintOut(this.state.id, 'OTC', [], this.state.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>)}
                        {this.state.id > 0 && this.state.cashOwing > 0 && (<span className="link" onClick={() => this.openReceiptAction(this.state.billId, 2, this.state.id)} title="Receipt Bill"><i className="bi bi-clipboard-check-fill"></i></span>)}
                        <div className="card card-green">
                                    <div className="card-body">
                                        <div className="row">
                                        <div className="col-3">
                                                 <div className="input-group"><span className="input-group-text">Company</span>
                                                <select className="form-control" value={this.state.companyId} onChange={e => {this.setState({ companyId: e.target.value }); this.setLocations(e.target.value); }}  >
                                                    <option value="0">Choose Company</option>
                                                    {this.state.companys.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                        <div className="col-3">
                                                 <div className="input-group"><span className="input-group-text">Location</span>
                                                <select className="form-control" value={this.state.locationId} onChange={e => this.setState({ locationId: e.target.value })}  >
                                                    <option value="0">Choose Location</option>
                                                    {this.state.locations.map((item, index) => (
                                                        <option key={index} value={item.id} >{item.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                            <div className="col-3">
                                                 <div className="input-group"><span className="input-group-text">Product</span>
                                                 <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({name: e.target.value}) }} /></div>
                                            </div>
                                            <div className="col-1"><input type="button" className="btn btn-primary" onClick={() => this.getOtcProducts()} value="Search" /></div>
                                        <div className="col-2">  <div className="input-group"><span className="input-group-text">Barcode</span><input type="text" className="form-control" readOnly={this.state.locationId === 0?"readOnly":""} value={this.state.barcode} onChange={e => { this.setState({barcode: e.target.value}); this.doProductItemFromBarCode(e.target.value) }} /></div></div>
                                        </div>

                                        <div className="row">
                                            <div className="col-9">
                                                 <div className="input-group"><span className="input-group-text">Products</span>
                                                 <Select
                                                    value={this.state.productItemLocationId}
                                                    className="form-control"
                                                    onChange={e => this.setState({ productItemLocationId: e })}
                                                    options={this.state.productOptions}
                                                /></div>
                                            </div>
                                            <div className="col-2">
                                                 <div className="input-group"><span className="input-group-text">Quantity</span>
                                                 <input type="text" className="form-control" value={this.state.quantity} onChange={e => { this.setState({quantity: e.target.value}) }} /></div>
                                            </div>
                                            <div className="col-1"><input type="button"  onClick={() => this.addProductItem()} value="Add" className="btn btn-success" /></div>
                                        </div>
                                    </div></div>
                                    <div className="row">
                                    <div className="col"><span className="fw-bold">Total:</span> {this.currencyFormat(this.state.cashPayable)}</div>
                                    <div className="col"><span className="fw-bold">Paid:</span> {this.currencyFormat(this.state.cashPaid)}</div>
                                    <div className="col"><span className="fw-bold">Owing:</span> {this.currencyFormat(this.state.cashOwing)}</div>
                                </div>
                                    <br  />
                                        <div className="card card-green">
                                            <div className="card-header">Items</div>
                                            <div className="card-body">    
                                            <table className="table table-striped" >
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Category</th>
                                                    <th>Type</th>
                                                    <th>Tariff Code</th>
                                                    <th>Quantity</th>
                                                    <th>Unit Cost</th>
                                                    <th>Dispensing Fee</th>
                                                    <th>Total Cost</th>
                                                    <th>Instruction</th>
                                                    <th>Active Status</th>
                                                </tr><tbody>
                                                {this.state.otcItems.map((l, index) => ( l.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td>{l.name}</td>
                                                        <td>{l.category}</td>
                                                        <td>{l.type}</td>
                                                        <td>{l.tariffCode}</td>
                                                        <td><div className="input-group has-validation"><input type="text" className={l.quantityError !== "" ? "form-control is-invalid" : "form-control"} value={l.quantity} onChange={e => { this.setRowValue(index, e, "quantity") }} />
                                                                <div className="invalid-feedback">{l.quantityError}</div></div></td>
                                                        <td>{ l.allowEdit === 1?<div className="input-group has-validation"><input type="text" className={l.unitCostError !== "" ? "form-control is-invalid" : "form-control"} value={l.unitCost} onChange={e => { this.setRowValue(index, e, "unitCost") }} /><div className="invalid-feedback">{l.unitCostError}</div></div>:<span>{this.currencyFormat(l.cost)}</span>}</td>
                                                        <td>{this.currencyFormat(l.dispensingFee)}</td>
                                                        <td>{this.currencyFormat(l.totalCost)}</td>
                                                        <td><input type="text" className="form-control" value={l.instruction} onChange={e => { this.setRowValue(index, e, "instruction") }} /></td>
                                                        <td><input type="button" className="btn btn-danger" value="Delete" onClick={() => this.setRowValue(index, 0,  "activeStatus")} /></td>
                                                    </tr>
                                                )))}
                                                </tbody>
                                            </table> 
                                            <div className="row">
                                            <div className="input-group">
                                                        <input type="hidden" className={this.state.otcItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                                        <div className="invalid-feedback">{this.state.otcItemsError}</div> </div></div>  </div></div>
                                            <div className="row">
                                            <div className="col" align="right">{this.state.showSubmit === true && this.state.id === 0 && (<input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" />)}</div>
                                            <div className="col"><input type="button" className="btn btn-primary" onClick={() => this.reload()} value="Cancel" /></div>
                                        </div>
                        {loaderDiv}
                        <OtcList ref={this.otcListRef} setLoader={this.setLoader} getForm={this.getForm} getPrintOut={this.getPrintOut} openReceiptAction={this.openReceiptAction} /> 
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} /> 
                        <Receipt ref={this.receiptRef} setLoader={this.setLoader} getPrintOut={this.getPrintOut} getForm={this.getForm} getList={this.getList} /> 
                    </div>
                </div ></div >
        )

    }
}    