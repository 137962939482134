import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactPainter } from 'react-painter';
import Select from 'react-select';

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class DentistAction extends Component {
    state = {
        visitStatus:'', loader: false, billCategoryString: '', htmlText: '', id: 0, billId: 0, patientInsuranceId: 0, companyId: 0, patientId: 0, patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], referrals: [], treatments: [], drugs: [], frequencys: [], routes: [], durations: [], formulations: [], services: [], investigations: [], findings: [], emrOlds: [], remainderDates: [],
        fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', employer: '', weight: 0.0, height: 0.0, waist: 0.0, headCircumference: 0.0, muac: 0, bmi: 0.0, waistHeightRatio: 0.0, nurseNotes: '', vitalColors: [], notes: [], referralLetters: [], oldPatient: '', managementData: '', historyVisits: [], visitId: 0, msg: '', error: '', treatmentRecords: [], results: [],treatmentPlanData: '',
        ph: '', ketones: '', sg: '', proteins: '', leucocytes: '', urobilinogen: '', blood: '', nitrate: '', glucose: '', otherUrineAnaysis: '', showUrinalysis: false, showNurse: false,
        chiefComplaints: [], medicalHistorys: [], dentalHistorys: [], personalHistorys: [], appointmentTimes: [], toothSurfaces: [{id:'BU', name: 'Buccal'}, {id:'DI', name: 'Distal'}, {id:'OC', name: 'Occlusal'}, {id:'LA', name: 'Labial'}, {id:'LI', name: 'Lingual'}, {id:'ME', name: 'Mesial'}, {id: 'PA', name: 'Palatal'}], toothInfos: [], index: 0,
            sinuses: '', parotidGland: '', lymphNodes: '', tmj: '', facialSymmetry: '', examinationRemarks: '', lips: '', oroppharynx: '', mucobuccalFold: '', floorOfMouth: '', buccalmucosa: '', salivaryGland: '', tongue: '', gingiva: '', hardPalate: '', residualRidge: '', softPalate: '', periodontium: '', intraOralSoftTissueExaminationRemarks: '', intraOralHardTissueExaminations: [], occulsion: '', examinationDone: '', otherIntraOralHardTissueExaminationNotes: '',
        chiefComplaint: true, medicalHistory: false, dentalHistory: false, personalHistory: false,
        extraOralExamination: true, intraOralHardTissueExamination: false, intraOralSoftTissueExamination: false,
         history: true, examination: false, investigation: false, treatment: false, treatmentPlan: false, referral: false, medicalCertificate: false, review: false, management: false, remainder: false, patientChart: false, bill: false,
         health263Claim: false, canSendToHealth263: false, canSendToHealth263WithOverride: false, canCancelHealth263WithBio: false, canCancelHealth263: false, patientEnrolled: false, health263MessageShow: false, combinedMessages: '',
         cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0, drugAllergy: '', otherAllergy: '',
         cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0,
         cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0, authorisationNumber: '', authorisationAmount: 0,
         speciality: '',  rate: 0, institutionId: 0, billItems: [], billCategory: '', billServices: [], options: []
    }
    componentDidMount() {

    }
    openDentistAction(visitId, msg) {
        if(msg === '')
           {
            this.setLoader(true) 
           } 
        this.setState({
            visitStatus:'', htmlText: '',billCategoryString: '', id: 0, billId: 0, patientInsuranceId: 0, companyId: 0, patientId: 0, patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], referrals: [], treatments: [], drugs: [], frequencys: [], routes: [], durations: [], formulations: [], services: [], investigations: [], findings: [], emrOlds: [], oldResults: [], remainderDates: [],
            fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', employer: '', weight: 0.0, height: 0.0, waist: 0.0, headCircumference: 0.0, muac: 0, bmi: 0.0, waistHeightRatio: 0.0, nurseNotes: '', vitalColors: [], notes: [], referralLetters: [], oldPatient: '', managementData: '', historyVisits: [], visitId: visitId, msg: '', error: '', treatmentRecords: [], results: [],treatmentPlanData: '',
            ph: '', ketones: '', sg: '', proteins: '', leucocytes: '', urobilinogen: '', blood: '', nitrate: '', glucose: '', otherUrineAnaysis: '', showUrinalysis: false, showNurse: false,
        chiefComplaints: [], medicalHistorys: [], dentalHistorys: [], personalHistorys: [], appointmentTimes: [],
            sinuses: '', parotidGland: '', lymphNodes: '', tmj: '', facialSymmetry: '', examinationRemarks: '', lips: '', oroppharynx: '', mucobuccalFold: '', floorOfMouth: '', buccalmucosa: '', salivaryGland: '', tongue: '', gingiva: '', hardPalate: '', residualRidge: '', softPalate: '', periodontium: '', intraOralSoftTissueExaminationRemarks: '', intraOralHardTissueExaminations: [], occulsion: '', examinationDone: '', otherIntraOralHardTissueExaminationNotes: '',
            chiefComplaint: true, medicalHistory: false, dentalHistory: false, personalHistory: false,
            extraOralExamination: true, intraOralHardTissueExamination: false, intraOralSoftTissueExamination: false,
            history: true, examination: false, investigation: false, treatment: false, treatmentPlan: false, referral: false, medicalCertificate: false, review: false, management: false, remainder: false, patientChart: false, bill: false,
            health263Claim: false, canSendToHealth263: false, canSendToHealth263WithOverride: false, canCancelHealth263WithBio: false, canCancelHealth263: false, patientEnrolled: false, health263MessageShow: false, combinedMessages: '',
            cashPayable: 0, insurancePayable: 0, shortfallPayable: 0, specialContractPayable: 0, totalPayable: 0, drugAllergy: '', otherAllergy: '',
            cashPaid: 0, insurancePaid: 0, shortfallPaid: 0, specialContractPaid: 0, totalPaid: 0,
            cashOwing: 0, insuranceOwing: 0, shortfallOwing: 0, specialContractOwing: 0, totalOwing: 0, authorisationNumber: '', authorisationAmount: 0,
            speciality: '',  rate: 0, institutionId: 0, billItems: [], billCategory: '', billServices: [], options: []
        })
        axios.get(localStorage.getItem('host') + '/patientmanagement/dental/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                var options = [];
                for(var i in res.data.billServices)
                    {
                        options.push({value: res.data.billServices[i].id, label: res.data.billServices[i].name})
                    }  

                for (var j in res.data.vitalColors) {
                    res.data.vitalColors[j].temperature = res.data.vitalColors[j].temperature === 0 ? "" : res.data.vitalColors[j].temperature
                    res.data.vitalColors[j].pulse = res.data.vitalColors[j].pulse === 0 ? "" : res.data.vitalColors[j].pulse
                    res.data.vitalColors[j].glucometer = res.data.vitalColors[j].glucometer === 0 ? "" : res.data.vitalColors[j].glucometer
                    res.data.vitalColors[j].spO2 = res.data.vitalColors[j].spO2 === 0 ? "" : res.data.vitalColors[j].spO2
                    res.data.vitalColors[j].diastolic = res.data.vitalColors[j].diastolic === 0 ? "" : res.data.vitalColors[j].diastolic
                    res.data.vitalColors[j].systolic = res.data.vitalColors[j].systolic === 0 ? "" : res.data.vitalColors[j].systolic
                    res.data.vitalColors[j].gsc = res.data.vitalColors[j].gsc === 0 ? "" : res.data.vitalColors[j].gsc
                }
                res.data.weight = res.data.weight === 0 ? "" : res.data.weight
                res.data.height = res.data.height === 0 ? "" : res.data.height
                res.data.bmi = res.data.bmi === 0 ? "" : res.data.bmi
                this.setState({
                    visitStatus: res.data.visitStatus, billCategoryString: res.data.billCategoryString, id: res.data.id, billId: res.data.billId, patientInsuranceId: res.data.patientInsuranceId, companyId: res.data.companyId, patientId: res.data.patientId, patientDiagnosis: res.data.patientDiagnosis, diagnosisText: res.data.diagnosisText, reviews: res.data.reviews, medicalCertificateTypes: res.data.medicalCertificateTypes, medicalCertificates: res.data.medicalCertificates, treatmentPlan: res.data.treatmentPlan, referrals: res.data.referrals, treatments: res.data.treatments, drugs: res.data.drugs, frequencys: res.data.frequencys, routes: res.data.routes, durations: res.data.durations, formulations: res.data.formulations, services: res.data.services, investigations: res.data.investigations, findings: res.data.findings, emrOlds: res.data.emrOlds, oldResults: res.data.oldResults, msg: msg , results: res.data.results, remainderDates: res.data.remainderDates,
                    ph: res.data.ph, ketones: res.data.ketones, sg: res.data.sg, proteins: res.data.proteins, leucocytes: res.data.leucocytes, urobilinogen: res.data.urobilinogen, blood: res.data.blood, nitrate: res.data.nitrate, glucose: res.data.glucose, otherUrineAnaysis: res.data.otherUrineAnaysis, showUrinalysis: res.data.showUrinalysis, showNurse: res.data.showNurse,
                    /* HISTORY  */  chiefComplaints: res.data.history.chiefComplaints, medicalHistorys: res.data.history.medicalHistorys, dentalHistorys: res.data.history.dentalHistorys, personalHistorys: res.data.history.personalHistorys, appointmentTimes: res.data.appointmentTimes,
          /*EXAMINATION */  sinuses: res.data.examination.sinuses, parotidGland: res.data.examination.parotidGland, lymphNodes: res.data.examination.lymphNodes, tmj: res.data.examination.tmj, facialSymmetry: res.data.examination.facialSymmetry, examinationRemarks: res.data.examination.examinationRemarks, lips: res.data.examination.lips, oroppharynx: res.data.examination.oroppharynx, mucobuccalFold: res.data.examination.mucobuccalFold, floorOfMouth: res.data.examination.floorOfMouth, buccalmucosa: res.data.examination.buccalmucosa, salivaryGland: res.data.examination.salivaryGland, tongue: res.data.examination.tongue, gingiva: res.data.examination.gingiva, hardPalate: res.data.examination.hardPalate, residualRidge: res.data.examination.residualRidge, softPalate: res.data.examination.softPalate, periodontium: res.data.examination.periodontium, intraOralSoftTissueExaminationRemarks: res.data.examination.intraOralSoftTissueExaminationRemarks, intraOralHardTissueExaminations: res.data.examination.intraOralHardTissueExaminations, occulsion: res.data.examination.occulsion, examinationDone: res.data.examination.examinationDone, otherIntraOralHardTissueExaminationNotes: res.data.examination.otherIntraOralHardTissueExaminationNotes,
                    fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, employer: res.data.employer, treatmentPlanData: res.data.treatmentPlanData, weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, vitalColors: res.data.vitalColors, notes: res.data.notes, referralLetters: res.data.referralLetters, oldPatient: res.data.oldPatient, managementData: res.data.managementData, historyVisits: res.data.historyVisits,
                    cashPayable: res.data.cashPayable, insurancePayable: res.data.insurancePayable, shortfallPayable: res.data.shortfallPayable, specialContractPayable: res.data.specialContractPayable, totalPayable: res.data.totalPayable, drugAllergy: res.data.drugAllergy, otherAllergy: res.data.otherAllergy,
                    cashPaid: res.data.cashPaid, insurancePaid: res.data.insurancePaid, shortfallPaid: res.data.shortfallPaid, specialContractPaid: res.data.specialContractPaid, totalPaid: res.data.totalPaid, 
                    cashOwing: res.data.cashOwing, insuranceOwing: res.data.insuranceOwing, shortfallOwing: res.data.shortfallOwing, specialContractOwing: res.data.specialContractOwing, totalOwing: res.data.totalOwing, authorisationNumber: res.data.authorisationNumber, authorisationAmount: res.data.authorisationAmount,
                    speciality: res.data.speciality, rate: res.data.rate, institutionId: res.data.institutionId, billItems: res.data.billItems, billCategory: res.data.billCategory, billServices: res.data.billServices, options: options,
          health263Claim: res.data.health263Claim, canSendToHealth263: res.data.canSendToHealth263, canSendToHealth263WithOverride: res.data.canSendToHealth263WithOverride, canCancelHealth263WithBio: res.data.canCancelHealth263WithBio, canCancelHealth263: res.data.canCancelHealth263, patientEnrolled: res.data.patientEnrolled, health263MessageShow: res.data.health263MessageShow, combinedMessages: res.data.combinedMessages
                });
                this.doValidate()
                setTimeout(() => { this.setLoader(false)  }, 2000);
                
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    save() {
        this.setLoader(true)
        var billItems = this.state.billItems
        for(var i in this.state.billItems)
           {
            billItems[i].billStartDate = this.processDateTime(billItems[i].billStartDate)
            billItems[i].billEndDate = this.processDateTime(billItems[i].billEndDate)
           }
        var data = {
                visitStatus: this.state.visitStatus, patientId: this.state.patientId, companyId: this.state.companyId, visitId: this.state.visitId, managementData: this.state.managementData, remainderDates: this.state.remainderDates, history: { chiefComplaints: this.state.chiefComplaints, medicalHistorys: this.state.medicalHistorys, dentalHistorys: this.state.dentalHistorys, personalHistorys: this.state.personalHistorys }
            , examination: {sinuses: this.state.sinuses, parotidGland: this.state.parotidGland, lymphNodes: this.state.lymphNodes, tmj: this.state.tmj, facialSymmetry: this.state.facialSymmetry, examinationRemarks: this.state.examinationRemarks, lips: this.state.lips, oroppharynx: this.state.oroppharynx, mucobuccalFold: this.state.mucobuccalFold, floorOfMouth: this.state.floorOfMouth, buccalmucosa: this.state.buccalmucosa, salivaryGland: this.state.salivaryGland, tongue: this.state.tongue, gingiva: this.state.gingiva, hardPalate: this.state.hardPalate, residualRidge: this.state.residualRidge, softPalate: this.state.softPalate, periodontium: this.state.periodontium, intraOralSoftTissueExaminationRemarks: this.state.intraOralSoftTissueExaminationRemarks, intraOralHardTissueExaminations: this.state.intraOralHardTissueExaminations, occulsion: this.state.occulsion, examinationDone: this.state.examinationDone, otherIntraOralHardTissueExaminationNotes: this.state.otherIntraOralHardTissueExaminationNotes }
             , patientDiagnosis: this.state.patientDiagnosis, diagnosisText: this.state.diagnosisText, reviews: this.state.reviews, medicalCertificates: this.state.medicalCertificates, treatmentPlanData: this.state.treatmentPlanData, referrals: this.state.referrals, treatments: this.state.treatments, investigations: this.state.investigations, findings: this.state.findings
             , billItems: billItems, billId: this.state.billId, speciality: this.state.speciality
             , cashPayable: this.state.cashPayable, insurancePayable: this.state.insurancePayable, shortfallPayable: this.state.shortfallPayable, specialContractPayable: this.state.specialContractPayable, totalPayable: this.state.totalPayable
             , cashOwing: this.state.cashOwing, insuranceOwing: this.state.insuranceOwing, shortfallOwing: this.state.shortfallOwing, specialContractOwing: this.state.specialContractOwing, totalOwing: this.state.totalOwing, authorisationNumber: this.state.authorisationNumber, authorisationAmount: this.state.authorisationAmount
        }
        axios.post(localStorage.getItem('host') + '/patientmanagement/dentalsave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.getList()
                document.getElementById('topDiv').scrollIntoView()
                this.openDentistAction(this.state.visitId,  res.data )
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('DentistAction').style = 'display:none'
    }
    setNurseDetails(name, value) {
        switch (name) {
            case "weight":
                var bmi = 0;
                value = value > 0 && value < 150 ? value : 0
                if (value > 0 && this.state.height > 0) {
                    bmi = Number(((value * 10000) / (this.state.height * this.state.height)).toFixed("2"))
                }
                this.setState({ weight: value, bmi: bmi })
                break;
            case "height":
                bmi = 0;
                var waistHeightRatio = 0;
                value = value > 0 && value < 250 ? value : 0
                if (value > 0 && this.state.weight > 0) {
                    bmi = Number(((this.state.weight * 10000) / (value * value)).toFixed("2"))
                }
                if (value > 0 && this.state.waist > 0) {
                    waistHeightRatio = Number((this.state.waist / value).toFixed("2"))
                }
                this.setState({ height: value, bmi: bmi, waistHeightRatio: waistHeightRatio })
                break;
            default:
                waistHeightRatio = 0;
                value = value > 0 && value < 150 ? value : 0
                if (value > 0 && this.state.height > 0) {
                    waistHeightRatio = Number((value / this.state.height).toFixed("2"))
                }
                this.setState({ waist: value, waistHeightRatio: waistHeightRatio })
                break;
        }
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    }
    setVitalValue(index, name, value) {
        var list = this.state.vitals;
        switch (name) {
            case "vitalTime":
                list[index].vitalTime = value;
                break;
            case "temperature":
                list[index].temperature = value > 0 && value < 45 ? value : 0;
                break;
            case "pulse":
                list[index].pulse = value > 0 && value < 120 ? value : 0;
                break;
            case "glucometer":
                list[index].glucometer = value > 0 && value < 30 ? value : 0;
                break;
            case "systolic":
                list[index].systolic = value > 0 && value < 140 ? value : 0;
                break;
            case "diastolic":
                list[index].diastolic = value > 0 && value < 140 ? value : 0;
                break;
            default:
                list[index].spO2 = value > 0 && value < 100 ? value : 0;
                break;

        }
        this.setState({ vitals: list })
    }
    addVital() {
        let list = this.state.vitals.slice();
        const now = new Date();
        list.push({ id: 0, vitalTime: checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()), temperature: 0, pulse: 0, glucometer: 0, systolic: 0, diastolic: 0, spO2: 0 });
        this.setState({ vitals: list })
    }
    viewHistory(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/dentalsummaryview/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Dental Summary' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    viewConsolidated(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/dentalsummaryviewconsolidated/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Dental Consolidated' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    showTreatmentRecord(patientId) {

        this.setState({ treatmentRecords: [] })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/treatmentlistview/' + patientId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ treatmentRecords: res.data });
                document.getElementById('treatmentRecord').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    genericClosePopup() {
        document.getElementById('genericPopup').style = 'display:none'
    }

    treatmentClosePopup() {
        document.getElementById('treatmentRecord').style = 'display:none'
    }
    viewResults() {
        document.getElementById('resultPopup').style = 'display:block'
    }
    resultClosePopup() {
        document.getElementById('resultPopup').style = 'display:none'
    }
    uploadFile(fileType, file) {
        if (file !== null || file !== '') {
            var data = new FormData();
            data.append("file", file)
            data.append("fileType", fileType)
            data.append("visitId", this.state.visitId)
            axios.post(localStorage.getItem('host') + '/file/filesave', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data'

                }
            }).then(
                res => {
                    console.log(res.data)
                    if (fileType === "REFERRAL_LETTERS") {
                        this.setState({ referralLetters: res.data, msg: 'Successfully uploaded Referral Letter' });
                    }
                    else {
                        this.setState({ notes: res.data, msg: 'Successfully uploaded Notes' });
                    }

                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }, err => {
                    console.log(".....")
                }
            )

        }
    }
    deleteFile(id, fileExtension, fileType) {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/deletefile/' + id + '/' + fileType + '/' + fileExtension + '/' + this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                if (fileType === "REFERRAL_LETTERS") {
                    this.setState({ referralLetters: res.data, msg: 'Successfully deleted Referral Letter' });
                }
                else {
                    this.setState({ notes: res.data, msg: 'Successfully deleted Notes' });
                }
                this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    }
    showFile(id, fileExtension) {
        this.setState({ fileData: localStorage.getItem('host') + '/file/pdfview/' + id + '/' + fileExtension });
        document.getElementById('showFileNurseAction').style = 'display:block'
        //'data:application/pdf;base64,' +

    }
    fileNurseClosePopup() {
        this.setState({ fileData: '' });
        document.getElementById('showFileNurseAction').style = 'display:none'
    }
    showOldFile(id) {
        this.setState({ fileData: localStorage.getItem('host') + '/old/emrfileresultoutput/' + id  });
        document.getElementById('showFileNurseAction').style = 'display:block'
    }
    showOldResults(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/old/resultlistview/' + oldPatient , {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Nurse History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    toggleTabs(name) {
        switch (name) {
            case "examination":
                this.setState({  history: false, examination: true, investigation: false, treatment: false, treatmentPlan: false, referral: false, medicalCertificate: false, review: false, management: false, remainder: false, patientChart: false, bill: false })
                break
            case "investigation":
                this.setState({  history: false, examination: false, investigation: true, treatment: false, treatmentPlan: false, referral: false, medicalCertificate: false, review: false, management: false, remainder: false, patientChart: false, bill: false })
                break
            case "treatment":
                this.setState({  history: false, examination: false, investigation: false, treatment: true, treatmentPlan: false, referral: false, medicalCertificate: false, review: false, management: false, remainder: false, patientChart: false, bill: false })
                break
            case "treatmentPlan":
                this.setState({  history: false, examination: false, investigation: false, treatment: false, treatmentPlan: true, referral: false, medicalCertificate: false, review: false, management: false, remainder: false, patientChart: false, bill: false })
                break
            case "referral":
                this.setState({  history: false, examination: false, investigation: false, treatment: false, treatmentPlan: false, referral: true, medicalCertificate: false, review: false, management: false, remainder: false, patientChart: false, bill: false })
                break
            case "medicalCertificate":
                this.setState({  history: false, examination: false, investigation: false, treatment: false, treatmentPlan: false, referral: false, medicalCertificate: true, review: false, management: false, remainder: false, patientChart: false, bill: false })
                break
            case "review":
                this.setState({  history: false, examination: false, investigation: false, treatment: false, treatmentPlan: false, referral: false, medicalCertificate: false, review: true, management: false, remainder: false, patientChart: false, bill: false })
                break
            case "management":
                this.setState({  history: false, examination: false, investigation: false, treatment: false, treatmentPlan: false, referral: false, medicalCertificate: false, review: false, management: true, remainder: false, patientChart: false, bill: false })
                break
            case "remainder":
                this.setState({  history: false, examination: false, investigation: false, treatment: false, treatmentPlan: false, referral: false, medicalCertificate: false, review: false, management: false, remainder: true, patientChart: false , bill: false})
                break
            case "patientChart":
                this.setState({  history: false, examination: false, investigation: false, treatment: false, treatmentPlan: false, referral: false, medicalCertificate: false, review: false, management: false, remainder: false, patientChart: true, bill: false})
                break
            case "bill":
                this.setState({  history: false, examination: false, investigation: false, treatment: false, treatmentPlan: false, referral: false, medicalCertificate: false, review: false, management: false, remainder: false, patientChart: false, bill: true })
                break
                default:
                    this.setState({  history: true, examination: false, investigation: false, treatment: false, treatmentPlan: false, referral: false, medicalCertificate: false, review: false, management: false, remainder: false, patientChart: false, bill: false, respiratory: true, medicalHistory: false, dentalHistory: false, personalHistory: false })
                    break

        }
    }
    addReviewTable(reviewDate, reviewTime) {
        let list = this.state.reviews.slice();
        list.push({ id: 0, reviewDate: reviewDate, reviewTime: reviewTime, remarks: '', activeStatus: 1 });
        this.setState({ reviews: list });
    }
    addRemainderTable() {
        let list = this.state.remainderDates.slice();
        list.push({ id: 0, date: date1, remarks: '', patientId: this.state.patientId, companyId: this.state.companyId, activeStatus: 1 });
        this.setState({ remainderDates: list });
    }

    addMedicalCertificateTable() {
        let list = this.state.medicalCertificates.slice();
        list.push({ id: 0, name: '', startDate: date1, endDate: date1, activeStatus: 1 });
        this.setState({ medicalCertificates: list });
    }
    addInvestigationTable() {
        let list = this.state.investigations.slice();
        list.push({ id: 0, name: '', activeStatus: 1 });
        this.setState({ investigations: list });
    }
    addReferralTable() {
        let list = this.state.referrals.slice();
        list.push({ id: 0, consultant: '', referralNotes: '', activeStatus: 1 });
        this.setState({ referrals: list });
    }
    addTreatmentTable() {
        let list = this.state.treatments.slice();
        list.push({ id: 0, dosage: '', drugName: '', formulationName: '', frequencyName: '', routeName: '', durationName: '', repeats: '', activeStatus: 1 });
        this.setState({ treatments: list });
    }
    deleteReview(index) {
        let list = this.state.reviews.slice();
        list[index].activeStatus = 0
        this.setState({ reviews: list });
    }

    deleteMedicalCertificate(index) {
        let list = this.state.medicalCertificates.slice();
        list[index].activeStatus = 0
        this.setState({ medicalCertificates: list });
    }
     deleteRemainder(index) {
        let list = this.state.remainderDates.slice();
        list[index].activeStatus = 0
        this.setState({ remainderDates: list });
    }
    deleteInvestigation(index) {
        let list = this.state.investigations.slice();
        list[index].activeStatus = 0
        this.setState({ investigations: list });
    }
    deleteReferral(index) {
        let list = this.state.referrals.slice();
        list[index].activeStatus = 0
        this.setState({ referrals: list });
    }
    deleteTreatment(index) {
        let list = this.state.treatments.slice();
        list[index].activeStatus = 0
        this.setState({ treatments: list });
    }
    setReview(index, name, value) {
        let list = this.state.reviews.slice();
        switch (name) {
            case "reviewDate":
                list[index].reviewDate = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ reviews: list });
    }

    setRemainder(index, name, value) {
        let list = this.state.remainderDates.slice();
        switch (name) {
            case "date":
                list[index].date = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ remainderDates: list });
    }

    setMedicalCertificate(index, name, value) {
        let list = this.state.medicalCertificates.slice();
        switch (name) {
            case "name":
                list[index].name = value
                break;
            case "startDate":
                list[index].startDate = value
                break;
            default:
                list[index].endDate = value
                break;
        }
        this.setState({ medicalCertificates: list });
    }
    setInvestigation(index, value) {
        let list = this.state.investigations.slice();
        for (var i in this.state.services) {
            if (this.state.services[i].value === value) {
                list[index].name = value
                list[index].speciality = this.state.services[i].speciality
            }
        }
        this.setState({ investigations: list });
    }
    setReferral(index, name, value) {
        let list = this.state.referrals.slice();
        switch (name) {
            case "consultant":
                list[index].consultant = value
                break;
            default:
                list[index].referralNotes = value
                break;
        }
        this.setState({ referrals: list });
    }
    setTreatment(index, name, value) {
        let list = this.state.treatments.slice();
        switch (name) {
            case "dosage":
                list[index].dosage = value
                break;
            case "drugName":
                list[index].drugName = value
                break;
            case "formulationName":
                list[index].formulationName = value
                break;
            case "frequencyName":
                list[index].frequencyName = value
                break;
            case "routeName":
                list[index].routeName = value
                break;
            case "durationName":
                list[index].durationName = value
                break;
            default:
                list[index].repeats = value
                break;
        }
        this.setState({ treatments: list });
    }

    toggleHistoryTabs(name) {
        switch (name) {
            case "medicalHistory":
                this.setState({ chiefComplaint: false, medicalHistory: true, dentalHistory: false, personalHistory: false })
                break
            case "dentalHistory":
                this.setState({ chiefComplaint: false, medicalHistory: false, dentalHistory: true, personalHistory: false })
                break
            case "personalHistory":
                this.setState({ chiefComplaint: false, medicalHistory: false, dentalHistory: false, personalHistory: true })
                break
            default:
                this.setState({ chiefComplaint: true, medicalHistory: false, dentalHistory: false, personalHistory: false })
                break

        }
    }
    toggleExaminationTabs(name) {
        switch (name) {
            case "intraOralHardTissueExamination":
                this.setState({ extraOralExamination: false, intraOralHardTissueExamination: true, intraOralSoftTissueExamination: false })
                break
            case "intraOralSoftTissueExamination":
                this.setState({ extraOralExamination: false, intraOralHardTissueExamination: false, intraOralSoftTissueExamination: true })
                break
            default: 
                this.setState({ extraOralExamination: true, intraOralHardTissueExamination: false, intraOralSoftTissueExamination: false})
                break

        }
    }
    getDiagnosisList() {
        this.setState({ diagnosisList: [], diagnosis: '' })
        console.log('..... i am ')
        if (this.state.searchDiagnosis !== null && this.state.searchDiagnosis !== "") {
            console.log('..... i am ')
            this.setLoader(true)
            axios.get(localStorage.getItem('host') + '/master/diagnosislist?text=' + this.state.searchDiagnosis, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ diagnosisList: res.data });
                    this.setLoader(false)
                }, err => {
                    this.setState({ error: err + "" })
                    this.setLoader(false)
                }
            )

        }
    }
    insertDiagnosis() {
        if (this.state.diagnosis !== null && this.state.diagnosis !== '') {
            let list = this.state.patientDiagnosis.slice();
            for (var i in this.state.diagnosisList) {
                if(this.state.diagnosisList[i].code === this.state.diagnosis)
                   {
                    list.push({ id: 0, code: this.state.diagnosis, description: this.state.diagnosis+" - "+this.state.diagnosisList[i].description, activeStatus: 1 });
                   }
            }
            this.setState({ patientDiagnosis: list });
        }

    }
    deleteDiagnosis(index) {
        let list = this.state.patientDiagnosis.slice();
        list[index].activeStatus = 0
        this.setState({ patientDiagnosis: list });
    }
    updateAfterSave(visitId, results)
     {
     if(results.length > 0)
        {
        this.setState({results: results})
        }
      else
        {
            axios.get(localStorage.getItem('host') + '/patientmanagement/nursetrimmed/' + visitId, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, vitalColors: res.data.vitalColors});
                }, err => {
                    this.setState({ error: err + "" })
                }
            )
        }  
     }
     addPersonalHistoryTable()
     {
        let list = this.state.personalHistorys.slice();
        list.push({ id: 0, consultant: '', referralNotes: '', activeStatus: 1 });
        this.setState({ personalHistorys: list });
     }

     deletePersonalHistory(index)
{
    let list = this.state.personalHistorys.slice();
    list.splice(index, 1); 
    this.setState({ personalHistorys: list });  
}
     setPersonalHistory(index, name, value) {
        let list = this.state.personalHistorys.slice();
        switch (name) {
            case "habitsOther":
                list[index].habitsOther = value
                break;
            case "habits":
                list[index].habits = value
                break;
            case "duration":
                list[index].duration = value
                break;
            case "timeUnits":
                list[index].timeUnits = value
                break;
            case "oralHygeneHabits":
                list[index].oralHygeneHabits = value
                break;
            case "typeOfDentrifice":
                list[index].typeOfDentrifice = value
                break;
            case "typeOfDentrificeOther":
                list[index].typeOfDentrificeOther = value
                break;
            default:
                list[index].diet = value
                break;
        }
        this.setState({ personalHistorys: list });
    }
addDentalHistoryTable()
{
    let list = this.state.dentalHistorys.slice();
    list.push({ id: 0, consultant: '', referralNotes: '', activeStatus: 1 });
    this.setState({ dentalHistorys: list });
}

deleteDentalHistory(index)
{
    let list = this.state.dentalHistorys.slice();
    list.splice(index, 1); 
    this.setState({ dentalHistorys: list });  
}
setDentalHistory(index, name, value) {
    let list = this.state.dentalHistorys.slice();
    switch (name) {
        case "pastTreatmentOther":
            list[index].pastTreatmentOther = value
            break;
        case "pastTreatment":
            list[index].pastTreatment = value
            break;
        case "duration":
            list[index].duration = value
            break;
        case "timeUnits":
            list[index].timeUnits = value
            break;
        default:
            list[index].remarks = value
            break;
    }
    this.setState({ dentalHistorys: list });
}
addMedicalHistoryTable()
{
    let list = this.state.medicalHistorys.slice();
    list.push({ id: 0, consultant: '', referralNotes: '', activeStatus: 1 });
    this.setState({ medicalHistorys: list });
}

deleteMedicalHistory(index)
{
    let list = this.state.medicalHistorys.slice();
    list.splice(index, 1); 
    this.setState({ medicalHistorys: list });  
}
setMedicalHistory(index, name, value) {
    let list = this.state.medicalHistorys.slice();
    switch (name) {
        case "historyOf":
            list[index].historyOf = value
            break;
        case "historyOfOther":
            list[index].historyOfOther = value
            break;
        case "medications":
            list[index].medications = value
            break;
        case "surgeryHospitalisation":
            list[index].surgeryHospitalisation = value
            break;
        case "healthStatus":
            list[index].healthStatus = value
            break;
        case "allergies":
            list[index].allergies = value
            break;
        case "allergiesOther":
            list[index].allergiesOther = value
            break;
        default:
            list[index].weight = value
            break;
    }
    this.setState({ medicalHistorys: list });
}
addIntraOralHardTissueExaminationTable()
{
    let list = this.state.intraOralHardTissueExaminations.slice();
    list.push({ id: 0, consultant: '', referralNotes: '', activeStatus: 1 });
    this.setState({ intraOralHardTissueExaminations: list });   
}

deleteIntraOralHardTissueExamination(index)
{
    let list = this.state.intraOralHardTissueExaminations.slice();
    list.splice(index, 1); 
    this.setState({ intraOralHardTissueExaminations: list });  
}
setIntraOralHardTissueExamination(index, name, value) {
   let list = this.state.intraOralHardTissueExaminations.slice();
   switch (name) {
       case "teethIdentification":
           list[index].teethIdentification = value
           break;
       case "condition":
           list[index].condition = value
           break;
       case "conditionOther":
           list[index].conditionOther = value
           break;
       default:
           list[index].location = value
           break;
   }
   this.setState({ intraOralHardTissueExaminations: list });
}
addChiefComplaintTable()
{
    let list = this.state.chiefComplaints.slice();
    list.push({ id: 0, consultant: '', referralNotes: '', activeStatus: 1 });
    this.setState({ chiefComplaints: list });
}

deleteChiefComplaint(index)
{
    let list = this.state.chiefComplaints.slice();
    list.splice(index, 1); 
    this.setState({ chiefComplaints: list });  
}
setChiefComplaint(index, name, value) {
    let list = this.state.chiefComplaints.slice();
    switch (name) {
        case "complaint":
            list[index].complaint = value
            break;
        case "complaintOther":
            list[index].complaintOther = value
            break;
        case "remarks":
            list[index].remarks = value
            break;
        case "onset":
            list[index].onset = value
            break;
        case "duration":
            list[index].duration = value
            break;
        case "timeUnits":
            list[index].timeUnits = value
            break;
        case "severity":
            list[index].severity = value
            break;
        case "aggravatedBy":
            list[index].aggravatedBy = value
            break;
        case "aggravatedByOther":
            list[index].aggravatedByOther = value
            break;
        case "remarks2":
            list[index].remarks2 = value
            break;
        case "relievedBy":
            list[index].relievedBy = value
            break;
        case "relievedByOther":
            list[index].relievedByOther = value
            break;
        default:
            list[index].remarks3 = value
            break;
    }
    this.setState({ chiefComplaints: list });
}
     setLoader(loader)
       {
        this.setState({loader: loader})
       }
       getReviewTime(reviewTime)
          {
           var time = ''
           for(var j in this.state.appointmentTimes)
           {
            if(Number(reviewTime) === Number(this.state.appointmentTimes[j].id))
               {
                time = this.state.appointmentTimes[j].name
               }
           }
           return time
          }
          
    saveCanvas(blob, type)
    {
     this.setLoader(true)
      var data = new FormData();
      data.append("file", blob)
      axios.post(localStorage.getItem('host') + '/patientmanagement/uploadimage/'+this.state.visitId+'/'+type, data, {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'content-type': 'multipart/form-data'
          }
      }).then(
          res => {
              console.log(res.data)
              this.setState({ msg: res.data });
              setTimeout(() => {
                  this.setState({ msg: '' });
                  this.setLoader(false)
              }, 3000);
          }, err => {
             this.setLoader(false)
              console.log(".....")
          }
      )
    }
forceReRender(type)
      {
       var id = this.state.visitId;
       this.setState({visitId: 0});
       this.setLoader(true)
       axios.get(localStorage.getItem('host') + '/patientmanagement/deleteimage/'+id+'/' + type, {
         headers: {
             'Authorization': `Bearer ${localStorage.getItem('token')}`
         }
     }).then(
         res => {
            
             setTimeout(()=>{ this.setState({visitId: id});  this.setLoader(false) }, 3000)
         }
     )
      }   
      currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    processDateTime(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + checkZero(todayM) + '-' + checkZero(todayD)+ ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());;
        console.log(date1)
       return date1;
      }
      addServices()
      {  console.log('......... service cost id '+JSON.stringify(this.state.serviceCostId)) 
       if(this.state.serviceCostId.value > 0)
          {  console.log('......... service cost id '+this.state.serviceCostId.value) 
           const now = new Date();
           var date1 = (now.getFullYear()) + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()) + ' ' + checkZero(now.getHours()) + ':' + checkZero(now.getMinutes()) + ':' + checkZero(now.getSeconds());
           let list = this.state.billItems.slice();
           var s = '';
           for (var i in this.state.billServices) {
               if (this.state.billServices[i].id === Number(this.state.serviceCostId.value)) {
                   s = this.state.billServices[i];
               }
           }
           if(this.state.cashOnly === 1 || this.state.cashOnly === "1")
           {
            s.cashOnly = 1;
           }
           list.push({id: 0, serviceId: s.id, name: s.name, billItemType: 'SERVICE', serviceType: s.serviceType, serviceClass: s.serviceClass
           , tariffCode: s.tariffCode, billStartDate: date1, billEndDate: date1, quantity: s.quantity, unitCost: s.fixedCost, min: (Number(s.fixedCost) - this.getMargin(s)), max: (Number(s.fixedCost) + this.getMargin(s)), cost: s.fixedCost, totalCost: s.fixedCost, cashOnly: s.cashOnly, allowEdit: s.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: s.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
               for (var index in s.services)
               {
                   var ss = s.services[index];
                   list.push({id: 0, serviceId: ss.id, name: ss.name, billItemType: 'SERVICE', serviceType: ss.serviceType, serviceClass: ss.serviceClass
                       , tariffCode: ss.tariffCode, billStartDate: date1, billEndDate: date1, quantity: ss.quantity, unitCost: ss.fixedCost, min: (Number(ss.fixedCost) - this.getMargin(ss)), max: (Number(ss.fixedCost) + this.getMargin(ss)), cost: ss.fixedCost, totalCost: ss.fixedCost, cashOnly: ss.cashOnly, allowEdit: ss.allowEdit, vat: 0.0, dispensingFee: 0.0, authoAmount: ss.authoAmount, wac: 0.0, lastReceiptCost: 0.0, activeStatus: 1, productItemId: 0});
               }
           this.setState({ billItems: list });
           this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount)
           this.doValidate()
          }
      }  
  
      doValidate() {
          setTimeout(() => {
              this.validate();
          }, 1000);
      }
      validate() {
  
          var error = false;
          var billItems = this.state.billItems;
          if (billItems === null) {
              this.setState({ billItemsError: "Bill Items cannot be empty" })
              error = true
          }
          else if (billItems.length === 0) {
              this.setState({ billItemsError: "Bill Items cannot be empty" })
              error = true
          }
          else {
             
              
              for(var i in billItems)
              {
               billItems[i].unitCostError =  "" 
               if(billItems[i].allowEdit)
                 {
                  if (billItems[i].unitCost === null) {
                      billItems[i].unitCostError =  "Unit Cost cannot be empty"
                      error = true
                  }
                  else if (billItems[i].unitCost === "") {
                      billItems[i].unitCostError =  "Unit Cost cannot be empty"
                      error = true
                  }
                  else if (billItems[i].unitCost > billItems[i].max) {
                      billItems[i].unitCostError =  "Unit Cost cannot be greater than "+billItems[i].max
                      error = true
                  }
                  else if (billItems[i].unitCost < billItems[i].min) {
                      billItems[i].unitCostError =  "Unit Cost cannot be less than "+billItems[i].min
                      error = true
                  }
                  else {
                      billItems[i].unitCostError =  "" 
                  }
  
                  console.log(billItems[i].unitCostError)
                 }
            this.setState({ billItemsError: "", billItems: billItems })   
              }
          }
          console.log(" error" + error)
          if(this.state.allowSave === false)
            {
              this.setState({ error: "The visit for this patient cannot be saved. Please contact accounts department for Help", showSubmit: false })
            }
          else  if (error === true) {
              this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
          }
          else {
              this.setState({ error: "", showSubmit: true })
          }
      } 
  
   getMargin(service)
      {
          return Math.ceil(service.fixedCost * (service.margin / 100));
      }   
   setBillItem(name, index, value)   
      {
       var list = this.state.billItems;   
      switch(name)
         {    
            case "billStartDate":
               list[index].billStartDate = value
               break;
           case "billEndDate":
               list[index].billEndDate = value
               break;   
           case "quantity":
               if(list[index].billItemType === 'PRODUCT')
               {
                 if(Number(list[index].quantity) > Number(value))
                    {
                       list[index].quantity = value  
                    }
               }
               else
                 {
                   list[index].quantity = value
                   this.calcQuantity(index, list[index].quantity)
                 }
               break;   
           case "unitCost":
               list[index].unitCost = value
               this.calcQuantity(index, list[index].quantity)
               break;   
           case "cashOnly":
               list[index].cashOnly = Number(value)
               this.calcQuantity(index, list[index].quantity)
               break;     
           case "authoAmount":
               list[index].authoAmount = value 
               this.calcQuantity(index, list[index].quantity)
               break;  
            default: 
                    console.log("....")
                    break;   
         }
     this.setState({ billItems: list })    
     this.doValidate() 
      }
      calcBill(list, authorisationNumber, authorisationAmount)
       {
           var cashPayable = 0;
           var insurancePayable = 0;
           var shortfallPayable = 0;
           var specialContractPayable = 0;
           var authoAmount = 0;
           for (var index in list)
           {
               if (list[index].activeStatus === "1" || list[index].activeStatus === 1)
               {
  
                   if (list[index].cashOnly)
                   {
                      cashPayable += list[index].totalCost;
                   } else
                   {
                       switch (this.state.billCategory)
                       {
                           case 'CASH':
                               cashPayable += list[index].totalCost;
                               break;
                           case 'INSURANCE':
                               insurancePayable += list[index].totalCost;
                               authoAmount += Number(list[index].authoAmount);
                               break;
                           case 'SPECIAL_CONTRACT':
                               specialContractPayable += list[index].totalCost;
                               break;
                               default: 
                                       console.log("....")
                                       break;   
                       }
                   }
               }
           }
           if (authorisationNumber !== null && authorisationNumber !== '' && authorisationAmount > 0)
           {
               cashPayable += Number(insurancePayable) - Number(authorisationAmount);
               insurancePayable = authorisationAmount;
           }
           if(authoAmount > 0)
            {
               shortfallPayable = Number(insurancePayable) - Number(authoAmount);
               insurancePayable = authoAmount;
            }
           var cashOwing = Number(cashPayable) - Number(this.state.cashPaid);
           var insuranceOwing = Number(insurancePayable) - Number(this.state.insurancePaid);
           var shortfallOwing = Number(shortfallPayable) - Number(this.state.shortfallPaid);
           var specialContractOwing = Number(specialContractPayable) - Number(this.state.specialContractPaid);
           var totalPayable = Number(cashPayable) + Number(insurancePayable) + Number(shortfallPayable) + Number(specialContractPayable);
           var totalOwing = Number(cashOwing) + Number(insuranceOwing) + Number(shortfallOwing) + Number(specialContractOwing);
           this.setState({cashPayable: cashPayable, insurancePayable: insurancePayable, shortfallPayable: shortfallPayable, specialContractPayable: specialContractPayable, totalPayable: totalPayable
               , cashOwing: cashOwing, insuranceOwing: insuranceOwing, shortfallOwing: shortfallOwing, specialContractOwing: specialContractOwing, totalOwing: totalOwing})
       }
       calcQuantity(index, value)
       {
           var list = this.state.billItems;  
           list[index].cost = Number(list[index].unitCost) * Number(value);
           list[index].totalCost = list[index].cost;
           this.setState({ billItems: list })  
           this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount);
       }
            hideEntry(index)
            {
               let list = this.state.billItems.slice();
               list[index].activeStatus = 0
               this.setState({ billItems: list }); 
               this.calcBill(list, this.state.authorisationNumber, this.state.authorisationAmount);
            }
            openToothinfo(toothInfos, index) {
                console.log(toothInfos)
                document.getElementById('toothInfoAction').style = 'display:block'
                var list =  toothInfos !== null && toothInfos !== ""?JSON.parse(toothInfos):[]
                this.setState({ index: index , toothInfos: list})
            
            }
        
            saveToothInfo()
              {
              let list = this.state.billItems.slice();
              list[this.state.index].toothInfo = JSON.stringify(this.state.toothInfos)
              this.setState({ billItems: list });
              this.closeToothInfoPopup() 
              }
            closeToothInfoPopup() {
                document.getElementById('toothInfoAction').style = 'display:none'
            }
        
        
            addToothInfoTable() {
                let list = this.state.toothInfos.slice();
                list.push({  toothNumber: '', toothSurface: '' });
                this.setState({ toothInfos: list });
            }
            
            setToothInfo(index, name, value) {
                let list = this.state.toothInfos.slice();
                switch (name) {
                    case "toothNumber":
                        list[index].toothNumber = value
                        break;
                    default:
                        list[index].toothSurface = value
                        break;
                }
                this.setState({ toothInfos: list });
            }
            
            deleteToothInfo(index)
            {
                let list = this.state.toothInfos.slice();
                list.splice(index, 1); 
                this.setState({ toothInfos: list });  
            }
            claimSubmission(bool, overrideClaim, sendAuthoCode) {
              /* if (bool === 'false')
               {
                   document.getElementById("submitHealth263").disabled = true;
               } else
               {
                   document.getElementById("reverseHealth263").disabled = true;
               }*/
               this.props.setLoader(true)
               axios.get(localStorage.getItem('host') + '/interchange/sendtohealth263/OUTPATIENT/' + this.state.billId + '/' + this.state.patientInsuranceId + '/' + this.state.companyId + '/' + bool + "/" + overrideClaim + "/" + sendAuthoCode, {
                   headers: {
                       'Authorization': `Bearer ${localStorage.getItem('token')}`
                   }
               })
                       .then(res => {
                           if(res.data.error)
                              {
                               this.setState({error: res.data.error})
                               this.updateHealth263(this.state.billId)
                              }
                           else
                              {   
                           this.updateHealth263(this.state.billId)
                           this.props.getClaimDetails(this.state.billId);
                              }
     
                           this.props.setLoader(false)
                       })
           }
           updateHealth263(billId)
             {
               this.props.setLoader(true)
               this.setState({health263Claim: false, canSendToHealth263: false, canSendToHealth263WithOverride: false, canCancelHealth263WithBio: false, canCancelHealth263: false, patientEnrolled: false, health263MessageShow: false, combinedMessages: ''})
               axios.get(localStorage.getItem('host') + '/patient/gethealth263update/' + billId, {
                   headers: {
                       'Authorization': `Bearer ${localStorage.getItem('token')}`
                   }
               }).then(
                   res => {
                       this.setState({health263Claim: res.data.health263Claim, canSendToHealth263: res.data.canSendToHealth263, canSendToHealth263WithOverride: res.data.canSendToHealth263WithOverride, canCancelHealth263WithBio: res.data.canCancelHealth263WithBio, canCancelHealth263: res.data.canCancelHealth263, patientEnrolled: res.data.patientEnrolled, health263MessageShow: res.data.health263MessageShow, combinedMessages: res.data.combinedMessages});
                       window.scrollTo(0, 0);
                          this.props.setLoader(false)
                   }, err => {
                        this.setState({error: err+""})
                        window.scrollTo(0, 0);
                       this.props.setLoader(false)
                   }
               )
       
             }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (<div id="DentistAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info" id="topDiv">Dentist Action</div>
            <div className="card-body" >
                {divMsg}
                {loaderDiv}
                <div id="genericPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">{this.state.popupName}</div>
                        <div className="card-body" >
                            <span dangerouslySetInnerHTML={{ __html: this.state.htmlText }}></span>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.genericClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="treatmentRecord" class="page-popup page-popup-overlay"><div class="card" >
                    <div class="card-header   bg-info">Treatment Record</div>
                    <div class="card-body" >
                        {this.state.treatmentRecords.map((item, index) => (
                            <div class="row" key={index} >
                                <div class="col-xs-12 col-md-12 col-lg-12">
                                    <div class="card border-success">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col"><span className="fw-bold">Date:</span> {item.treatmentDateTime}</div>
                                                <div className="col"><span className="fw-bold"></span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table table-striped" >
                                        <tr>
                                            <th>Drug</th>
                                            <th>Dosage</th>
                                            <th>Frequency</th>
                                            <th>Route</th>
                                            <th>Duration</th>
                                            <th>Formulation</th>
                                            <th>Repeats</th>
                                        </tr>
                                        <tbody>
                                            {item.treatments.map((treatment, i) => (
                                                <tr key={i} >
                                                    <td>{treatment.drugName}</td>
                                                    <td>{treatment.dosage}</td>
                                                    <td>{treatment.frequencyName}</td>
                                                    <td>{treatment.routeName}</td>
                                                    <td> {treatment.durationName}</td>
                                                    <td>{treatment.formulationName}</td>
                                                    <td>{treatment.repeats}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div class="row">
                        <div class="col" align="center" ><input class="btn btn-primary" type='button' onClick={() => this.treatmentClosePopup()} value="Close" /></div>
                    </div>
                </div>
                </div>
                <div id="resultPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">Result View</div>
                        <div className="card-body" >
                            <table className="table table-striped">
                                <tr>
                                    <th>Treatment Date</th>
                                    <th>File</th>
                                    <th>Remarks</th>
                                </tr>
                                <tbody>
                                    {this.state.results.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.visitDate}</td>
                                            {item.fileStorageId > 0 ? (<td><span className="link" onClick={() => this.showFile(item.fileStorageId, item.fileExtension)}>{item.fileName}</span></td>) : (<td></td>)}
                                            <td>{item.remarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.resultClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="showFileNurseAction" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                            <iframe title="File View" src={this.state.fileData} type="application/pdf" style={{ width: 100 + '%', height: 500 + 'px' }}></iframe>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-2">
                        {this.state.id > 0 && (<div ><span className="link" onClick={() => this.props.getPrintOut(this.state.visitId, 'DOCTOR', [], this.state.companyId)} title="Print" ><i className="bi bi-printer-fill" style={{ fontSize: 3 + 'em' }}></i></span></div>)}
                        {this.state.allergies && (<div className="card card-red">
                            <div className="card-header text-dark">
                                Allergies
                            </div>
                            <div className="card-body">
                                <span>{this.state.allergies}</span>
                            </div>
                        </div>)}
                        {this.state.id > 0 && (<div className="card card-green">
                            <div className="card-header text-dark">Current</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewHistory(this.state.visitId)} >Current</span></li>
                                </ul>
                            </div>
                        </div>)}
                        <div className="card card-green">
                            <div className="card-header  text-dark">Consolidated</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewConsolidated(this.state.visitId)}>Consolidated</span></li>
                                </ul>
                            </div>
                        </div>
                        {this.state.historyVisits.length > 0 && (<div className="card card-green" >
                            <div className="card-header  text-dark">History</div>
                            <div className="card-body"> 
                                <ul>
                                    {this.state.historyVisits.map((item, index) => (
                                        <li key={index}><span className="link" onClick={() => this.viewHistory(item.id)} >{item.name}</span></li>
                                    ))}
                                </ul>
                            </div>
                        </div>)}
                        <div className="card  card-green" >
                            <div className="card-header  text-dark">Other Info</div>
                            <div className="card-body">
                                <ul>
                                    {(this.state.results.length > 0) && (<li><span className="link" onClick={() => this.viewResults()}>View Results</span></li>)}
                                    <li><span className="link" onClick={() => this.showTreatmentRecord(this.state.patientId)}>View Prescription Records</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Notes</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('NOTES', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.notes.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Notes</div>
                            <div className="card-body">
                                <table >
                                    {this.state.notes.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'NOTES')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                    </div>
                    <div className="col-lg-10 col-md-10">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">Bill:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Category:</span> {this.state.billCategoryString}</div>
                                    <div className="col"><span className="fw-bold">Employer:</span> {this.state.employer}</div>
                                    <div className="col"></div>
                                </div>
                            </div>
                        </div>
                        {this.state.showNurse &&
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Weight (Kg):</span> {this.state.weight}</div>
                                    <div className="col"><span className="fw-bold">Height (cm):</span> {this.state.height}</div>
                                    <div className="col"><span className="fw-bold">BMI:</span> {this.state.bmi}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Drug Allergies:</span> {this.state.drugAllergy}</div>
                                    <div className="col"><span className="fw-bold">Other Allergies:</span> {this.state.otherAllergy}</div>
                                    <div className="col"><span className="fw-bold">Bed Details:</span> <span >{this.state.bedDetails}</span></div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Notes:</span> <span >{this.state.nurseNotes}</span></div>
                                </div>
                            </div>
                        </div>}
                        {this.state.showUrinalysis && (<div className="card card-blue"><div className="card-header">Urine Analysis</div><div className="card-body">
                            <div className="row">
                                <div className="col"><span className="fw-bold">Ph:</span> {this.state.ph}</div>
                                <div className="col"><span className="fw-bold">Ketones:</span> {this.state.ketones}</div>
                                <div className="col"><span className="fw-bold">Sg:</span> {this.state.sg}</div>
                            </div>
                            <div className="row">
                                <div className="col"><span className="fw-bold">Proteins:</span> {this.state.proteins}</div>
                                <div className="col"><span className="fw-bold">Leucocytes:</span> {this.state.leucocytes}</div>
                                <div className="col"><span className="fw-bold">Urobilinogen:</span> {this.state.urobilinogen}</div>
                            </div>
                            <div className="row">
                                <div className="col"><span className="fw-bold">Blood:</span> {this.state.blood}</div>
                                <div className="col"><span className="fw-bold">Nitrate:</span> {this.state.nitrate}</div>
                                <div className="col"><span className="fw-bold">Glucose:</span> {this.state.glucose}</div>
                            </div>
                            <div className="row">
                                <div className="col"><span className="fw-bold">Other:</span> {this.state.otherUrineAnaysis}</div>
                            </div>
                        </div></div>)}
                        { this.state.vitalColors.length > 0 &&
                        <div className="card border-success">
                            <div className="card-body">
                                <table className="table" id="tableNurse">
                                    <tr>
                                        <th>Time</th>
                                        <th>Temperature</th>
                                        <th>Bp</th>
                                        <th>Pulse (bmp)</th>
                                        <th>Glucometer (mmol/l)</th>
                                        <th>SpO2 (%)</th>
                                        <th>GCS / 15</th>
                                    </tr>
                                    <tbody>
                                        {this.state.vitalColors.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.vitalTime}</td>
                                                <td style={{ color: item.temperatureColor }}>{item.temperature}</td>
                                                <td><span style={{ color: item.systolicColor }}>{item.systolic}</span>/<span style={{ color: item.diastolicColor }}>{item.diastolic}</span></td>
                                                <td style={{ color: item.pulseColor }}>{item.pulse}</td>
                                                <td style={{ color: item.glucometerColor }}>{item.glucometer}</td>
                                                <td style={{ color: item.spO2Color }}>{item.spO2}</td>
                                                <td style={{ color: item.gcsColor }}>{item.gcs}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        }
                        {this.state.health263Claim === true && (<div>
                                        <div className="row">
                                            <div className="col"><input type="button" value="Presign Bio" className="btn btn-info" onClick={() => this.props.getPresignPi(this.state.patientId, this.state.billId)} /></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12" align="center">{this.state.combinedMessages !== '' && (<div className="alert alert-danger">{this.state.combinedMessages}</div>)}  </div>
                                        </div>
                                        <div className="row">
                                            {this.state.canSendToHealth263 && (<div align="center" className="col"><input type="button" id="submitHealth263" className="btn btn-success" onClick={() => this.claimSubmission('false', 'false', 'true')} value="CLAIM WITH BIO" /> </div>)}
                                            {this.state.canSendToHealth263WithOverride && true === false && (<div align="center" className="col"><input type="button" id="submitHealth263" className="btn btn-success" onClick={() => this.claimSubmission('false', 'true', 'false')} value="CLAIM BY-PASS" /> </div>)}
                                            {this.state.canSendToHealth263WithOverride && (<div align="center" className="col"><input type="button" id="submitHealth263" className="btn btn-success" onClick={() => this.claimSubmission('false', 'false', 'false')} value="CLAIM WITH NO BIO" /> </div>)}
                                            {this.state.canCancelHealth263WithBio &&(<div align="center" className="col"><input type="button" id="reverseHealth263" className="btn btn-danger" onClick={() => this.claimSubmission('true', 'false', 'true')} value="REVERSE CLAIM WITH BIO" /></div>)}
                                            {this.state.canCancelHealth263 &&(<div align="center" className="col"><input type="button" id="reverseHealth263" className="btn btn-danger" onClick={() => this.claimSubmission('true', 'false', 'false')} value="REVERSE CLAIM WITH NO BIO" /></div>)}
                                            <div align="center" className="col"><input type="button" id="responseHealth263" className="btn btn-success" onClick={() => this.props.getClaimDetails(this.state.billId)} value="View Claim Response" /></div>
                                        </div>
                                    </div>) }
                        <div className="row">
                            <div className="col" align="right"><button onClick={() => this.save()} className="btn btn-primary">Save</button></div>
                            <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                            <div className="col" align="right"></div>
                            </div>
                        <br />
                        <ul className="nav nav-tabs" role="tablist">
                              <li className="nav-item"><span className={this.state.history ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("history") }}>History</span></li>
                            <li className="nav-item"><span className={this.state.examination ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("examination") }}>Examination</span></li>
                            <li className="nav-item"><span className={this.state.investigation ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("investigation") }}>Investigations</span></li>
                            <li className="nav-item"><span className={this.state.patientChart ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("patientChart") }}>Patient Chart</span></li>
                            <li className="nav-item"><span className={this.state.treatmentPlan ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("treatmentPlan") }}>Treatment Plan</span></li>
                            <li className="nav-item"><span className={this.state.treatment ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("treatment") }}>Pharmacy Prescription</span></li>
                            <li className="nav-item"><span className={this.state.referral ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("referral") }}>Referral</span></li>
                            <li className="nav-item"><span className={this.state.medicalCertificate ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("medicalCertificate") }}>Medical Certificate</span></li>
                            <li className="nav-item"><span className={this.state.review ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("review") }}>Review</span></li>
                            <li className="nav-item"><span className={this.state.remainder ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("remainder") }}>Remainder</span></li>
                            <li className="nav-item"><span className={this.state.bill ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("bill") }}>Bill</span></li>
                            </ul>
                        <div className="tab-content" id="myTabContent">
                          <div className={this.state.history ? "tab-pane fade show active" : "tab-pane fade"} >
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        History
                                    </div>
                                    <div className="card-body">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item"><span className={this.state.chiefComplaint ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("chiefComplaint") }}>Chief Complaint</span></li>
                                            <li className="nav-item"><span className={this.state.medicalHistory ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("medicalHistory") }}>Medical History</span></li>
                                            <li className="nav-item"><span className={this.state.dentalHistory ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("dentalHistory") }}>Dental History</span></li>
                                            <li className="nav-item"><span className={this.state.personalHistory ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("personalHistory") }}>Personal History</span></li>
                                            </ul>
                                        <br />
                                        <div className="tab-content">
                                            <div className={this.state.chiefComplaint ? "tab-pane fade show active" : "tab-pane fade"}>
                                            <table class="table table-striped" id="tableChiefComplaint">
        <thead>
            <tr>
                <th>Action</th>
                <th>Complaint</th>
                <th>Remarks</th>
                <th>Onset</th>
                <th>Duration</th>
                <th>Time Units</th>
                <th>Severity</th>
                <th>Aggravated By</th>
                <th>Remarks</th>
                <th>Relieved By</th>
                <th>Remarks</th>
            </tr>
        </thead>
        <tbody>
        {this.state.chiefComplaints.map((item, index) => (
            <tr key={index} >
<td><span className="link-red" onClick={() => this.deleteChiefComplaint(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                <td><select class="form-control"  value={item.complaint} onChange={ e => this.setChiefComplaint(index, "complaint", e.target.value)}>
                            <option value="">Choose</option>
                            <option value="Tooth Ache">Tooth Ache</option>
                            <option value="Bleeding From Gums">Bleeding From Gums</option>
                            <option value="Difficulty In Eating">Difficulty In Eating</option>
                            <option value="Sensitive Teeth">Sensitive Teeth</option>
                            <option value="Tooth mobility">Tooth mobility</option>
                            <option value="Difficulty In Opening Mouth">Difficulty In Opening Mouth</option>
                            <option value="Burning Sensation">Burning Sensation</option>
                            <option value="Ulcers In The Mouth">Ulcers In The Mouth</option>
                            <option value="Bad Breath">Bad Breath</option>
                            <option value="Dryness Of Mouth">Dryness Of Mouth</option>
                            <option value="Binding Of Teeth">Binding Of Teeth</option>
                            <option value="Loose Teeth">Loose Teeth</option>
                            <option value="Jaw Pain">Jaw Pain</option>
                            <option value="Esthetics">Esthetics</option>
                            <option value="Damaged Prosthesis">Damaged Prosthesis</option>
                            <option value="Periodic Consultation">Periodic Consultation</option>
                            <option value="Review">Review</option>
                            <option value="Cavities On Teeth">Cavities On Teeth</option>
                            <option value="Food Packaging Between Teeth">Food Packaging Between Teeth</option>
                            <option value="Fractured Filling">Fractured Filling</option>
                            <option value="Continuation Of Previous Treatment">Continuation Of Previous Treatment</option>
                            <option value="Others">Others</option>
                    </select>{item.complaint === "Others" &&(<input type="text" class="form-control" value={item.complaintOther} onChange={ e => this.setChiefComplaint(index, "complaintOther", e.target.value)} />)}</td>
                    <td><input type="text" class="form-control" value={item.remarks} onChange={ e => this.setChiefComplaint(index, "remarks", e.target.value)} /></td> 
                    <td><select class="form-control"  value={item.onset} onChange={ e => this.setChiefComplaint(index, "onset", e.target.value)} >
                            <option value="">Choose</option>
                             <option value="Slow">Slow</option>
                              <option value="Sudden">Sudden</option>
                               <option value="Gradual">Gradual</option>
                        </select></td>
                     <td><input type="text" class="form-control"  value={item.duration} onChange={ e => this.setChiefComplaint(index, "duration", e.target.value)}   /></td>    
                      <td><select class="form-control" value={item.timeUnits} onChange={ e => this.setChiefComplaint(index, "timeUnits", e.target.value)}  >
                            <option value="">Choose</option>
                             <option value="Min">Min</option>
                              <option value="Hours">Hours</option>
                               <option value="Days">Days</option>
                               <option value="Weeks">Weeks</option>
                               <option value="Months">Months</option>
                               <option value="Years">Years</option>
                        </select></td>
                       <td><select class="form-control"  value={item.severity} onChange={ e => this.setChiefComplaint(index, "severity", e.target.value)} >
                            <option value="">Choose</option>
                             <option value="Mild">Mild</option>
                              <option value="Moderate">Moderate</option>
                               <option value="Severe">Severe</option>
                        </select></td>      
                       <td><select class="form-control" value={item.aggravatedBy} onChange={ e => this.setChiefComplaint(index, "aggravatedBy", e.target.value)} >
                            <option value="">Choose</option>
                             <option value="Hot Items">Hot Items</option>
                              <option value="Cold Items">Cold Items</option>
                               <option value="Hard Food">Hard Food</option>
                               <option value="Others">Others</option>
                        </select>{item.aggravatedBy === "Others" &&(<input type="text" class="form-control" value={item.aggravatedByOther} onChange={ e => this.setChiefComplaint(index, "aggravatedByOther", e.target.value)} />)}</td>
                     <td><input type="text" class="form-control" value={item.remarks2} onChange={ e => this.setChiefComplaint(index, "remarks2", e.target.value)} /></td>       
                       <td><select class="form-control" value={item.remarks2} onChange={ e => this.setChiefComplaint(index, "relievedBy", e.target.value)}>
                            <option value="">Choose</option>
                             <option value="Medication">Medication</option>
                              <option value="Others">Others</option>
                        </select>{item.relievedBy === "Others" &&(<input type="text" class="form-control" value={item.relievedByOther} onChange={ e => this.setChiefComplaint(index, "relievedByOther", e.target.value)} />)}</td>    
                     <td><input type="text" class="form-control" value={item.remarks3} onChange={ e => this.setChiefComplaint(index, "remarks3", e.target.value)}/></td>     
            </tr>
             ))}
        </tbody>
    </table>
                                                    <button onClick={() => this.addChiefComplaintTable()} className="btn btn-primary" >Insert New Chief Complaint Line</button> 
                                                </div>
                                            <div className={this.state.medicalHistory ? "tab-pane fade show active" : "tab-pane fade"}>
                                            <table class="table table-striped" id="tableMedicalHistory">
        <thead>
            <tr>
                <th>Action</th>
                <th>History Of</th>
                <th>Medications</th>
                <th>Surgery / Hospitalisation</th>
                <th>Health Status</th>
                <th>Allergies</th>
                <th>Weight (Kgs)</th>
            </tr>
        </thead>
        <tbody>
        {this.state.medicalHistorys.map((item, index) => (
            <tr key={index}>
                <td><span className="link-red" onClick={() => this.deleteMedicalHistory(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                <td><select class="form-control" value={item.historyOf} onChange={ e => this.setMedicalHistory(index, "historyOf", e.target.value)}>
                            <option value="">Choose</option>
                            <option value="Artificial Joints">Artificial Joints</option>
                            <option value="Kidney Problems">Kidney Problems</option>
                            <option value="Sinus Problems">Sinus Problems</option>
                            <option value="Liver Disease">Liver Disease</option>
                            <option value="Immune Disorder">Immune Disorder</option>
                            <option value="Tuberculosis">Tuberculosis</option>
                            <option value="Haemophilia">Haemophilia</option>
                            <option value="Thyroid Problems">Thyroid Problems</option>
                            <option value="Diabetes">Diabetes</option>
                            <option value="Hypertension">Hypertension</option>
                            <option value="HIV">HIV</option>
                            <option value="Hepatitis">Hepatitis</option>
                            <option value="Heart Disease">Heart Disease</option>
                            <option value="Cerebral Palsy">Cerebral Palsy</option>
                            <option value="Rheumatic Fever">Rheumatic Fever</option>
                            <option value="Epilepsy">Epilepsy</option>
                            <option value="Asthma">Asthma</option>
                            <option value="Others">Others</option>
                    </select>{item.historyOf === "Others" &&(<input type="text" class="form-control" value={item.historyOfOther} onChange={ e => this.setMedicalHistory(index, "historyOfOther", e.target.value)} />)}</td>
                    <td><input type="text" class="form-control" value={item.medications} onChange={ e => this.setMedicalHistory(index, "medications", e.target.value)} /></td> 
                    <td><input type="text" class="form-control" value={item.surgeryHospitalisation} onChange={ e => this.setMedicalHistory(index, "surgeryHospitalisation", e.target.value)} /></td> 
                    <td><select class="form-control" value={item.healthStatus} onChange={ e => this.setMedicalHistory(index, "healthStatus", e.target.value)}>
                            <option value="">Choose</option>
                             <option value="Good">Good</option>
                              <option value="Fair">Fair</option>
                               <option value="Poor">Poor</option>
                        </select></td>
                       <td><select class="form-control" value={item.allergies} onChange={ e => this.setMedicalHistory(index, "allergies", e.target.value)}>
                            <option value="">Choose</option>
                             <option value="Food">Food</option>
                              <option value="Drug">Drug</option>
                               <option value="Anasthesia">Anasthesia</option>
                               <option value="Others">Others</option>
                        </select>{item.allergies === "Others" &&(<input type="text" class="form-control" value={item.allergiesOther} onChange={ e => this.setMedicalHistory(index, "allergiesOther", e.target.value)} />)}</td>
                     <td><input type="text" class="form-control" value={item.weight} onChange={ e => this.setMedicalHistory(index, "weight", e.target.value)} /></td>       
            </tr>
              ))}
        </tbody>
    </table>
                                                    <button onClick={() => this.addMedicalHistoryTable()} className="btn btn-primary" >Insert New Medical History Line</button> 
                                                </div>
                                            <div className={this.state.dentalHistory ? "tab-pane fade show active" : "tab-pane fade"}>
                                            <table class="table table-striped" id="tableDentalHistory">
        <thead>
            <tr>
                <th>Action</th>
                <th>Past Treatment</th>
                <th>Duration</th>
                <th>Time Units</th>
                <th>Remarks</th>
            </tr>
        </thead>
        <tbody>
        {this.state.dentalHistorys.map((item, index) => (
            <tr key={index}>
<td><span className="link-red" onClick={() => this.deleteDentalHistory(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                <td><select class="form-control" value={item.pastTreatment} onChange={ e => this.setDentalHistory(index, "pastTreatment", e.target.value)} >
                            <option value="">Choose</option>
                            <option value="Restorations">Restorations</option>
                            <option value="Treatment for Periodontal (Gum) Disease">Treatment for Periodontal (Gum) Disease</option>
                            <option value="Orthodontics {Braces}">Orthodontics (Braces)</option>
                            <option value="Oral Surgery">Oral Surgery</option>
                            <option value="Dental Implants">Dental Implants</option>
                            <option value="Denture(s) Or Partial Dentures">Denture(s) Or Partial Dentures</option>
                            <option value="Endodontics">Endodontics</option>
                            <option value="Crown And Bridge Work">Crown And Bridge Work</option>
                            <option value="Others">Others</option>
                    </select>{item.pastTreatment === "Others" &&(<input type="text" class="form-control" value={item.pastTreatmentOther} onChange={ e => this.setDentalHistory(index, "pastTreatmentOther", e.target.value)} />)}</td>
                    <td><input type="text" class="form-control" value={item.duration} onChange={ e => this.setDentalHistory(index, "duration", e.target.value)}  /></td> 
                     <td><select class="form-control" value={item.timeUnits} onChange={ e => this.setDentalHistory(index, "timeUnits", e.target.value)} >
                            <option value="">Choose</option>
                             <option value="Min">Min</option>
                              <option value="Hours">Hours</option>
                               <option value="Days">Days</option>
                               <option value="Weeks">Weeks</option>
                               <option value="Months">Months</option>
                               <option value="Years">Years</option>
                        </select></td>
                     <td><input type="text" class="form-control" value={item.remarks} onChange={ e => this.setDentalHistory(index, "remarks", e.target.value)}  /></td>       
            </tr>
             ))}
        </tbody>
    </table>
                                                    <button onClick={() => this.addDentalHistoryTable()} className="btn btn-primary" >Insert New Dental History Line</button> 
                                            </div>
                                            <div className={this.state.personalHistory ? "tab-pane fade show active" : "tab-pane fade"}>
                                            <table class="table table-striped" id="tablePersonalHistory">
        <thead>
            <tr>
                <th>Action</th>
                <th>H/O Habits</th>
                <th>Duration</th>
                <th>Time Units</th>
                <th>Oral Hygiene Habits</th>
                <th>Type Of Dentrifice</th>
                <th>Diet</th>
            </tr>
        </thead>
        <tbody>
        {this.state.personalHistorys.map((item, index) => (
            <tr key={index}>
                <td><span className="link-red" onClick={() => this.deletePersonalHistory(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                <td><select class="form-control" value={item.habits} onChange={ e => this.setPersonalHistory(index, "habits", e.target.value)} >
                            <option value="">Choose</option>
                            <option value="Thumb Sucking">Thumb Sucking</option>
                            <option value="Bruxism">Bruxism</option>
                            <option value="Tongue Rolling">Tongue Rolling</option>
                            <option value="Tongue Thrusting">Tongue Thrusting</option>
                            <option value="Tobacco Chewing">Tobacco Chewing</option>
                            <option value="Snuffing">Snuffing</option>
                            <option value="Oral Mutilations">Oral Mutilations</option>
                            <option value="Smoking">Smoking</option>
                            <option value="Alcohol">Alcohol</option>
                            <option value="Any Drugs">Any Drugs</option>
                            <option value="None">None</option>
                            <option value="Others">Others</option>
                    </select>{item.habits === "Others" &&(<input type="text" class="form-control" value={item.habitsOther} onChange={ e => this.setPersonalHistory(index, "habitsOther", e.target.value)} />)}</td>
                     <td><input type="text" class="form-control" value={item.duration} onChange={ e => this.setPersonalHistory(index, "duration", e.target.value)} /></td>    
                      <td><select class="form-control" value={item.timeUnits} onChange={ e => this.setPersonalHistory(index, "timeUnits", e.target.value)}>
                            <option value="">Choose</option>
                             <option value="Min">Min</option>
                              <option value="Hours">Hours</option>
                               <option value="Days">Days</option>
                               <option value="Weeks">Weeks</option>
                               <option value="Months">Months</option>
                               <option value="Years">Years</option>
                        </select></td>
                    <td><select class="form-control" value={item.oralHygeneHabits} onChange={ e => this.setPersonalHistory(index, "oralHygeneHabits", e.target.value)}>
                            <option value="">Choose</option>
                             <option value="Brushing">Brushing</option>
                              <option value="Flossing">Flossing</option>
                               <option value="Tongue Cleaning">Tongue Cleaning</option>
                        </select></td>
                       <td><select class="form-control" value={item.typeOfDentrifice} onChange={ e => this.setPersonalHistory(index, "typeOfDentrifice", e.target.value)}>
                            <option value="">Choose</option>
                             <option value="Flourinated Paste">Flourinated Paste</option>
                              <option value="Flourinated Powder">Flourinated Powder</option>
                               <option value="Non Flourinated Paste">Non Flourinated Paste</option>
                               <option value="Non Flourinated Powder">Non Flourinated Powder</option>
                               <option value="Others">Other</option>
                        </select>{item.typeOfDentrifice === "Others" &&(<input type="text" class="form-control" value={item.typeOfDentrificeOther} onChange={ e => this.setPersonalHistory(index, "typeOfDentrificeOther", e.target.value)} />)}</td>      
                       <td><select class="form-control" value={item.diet} onChange={ e => this.setPersonalHistory(index, "diet", e.target.value)}>
                            <option value="">Choose</option>
                             <option value="Vegeterian">Vegeterian</option>
                              <option value="Non Vegeterian">Non Vegeterian</option>
                               <option value="High Sugar">High Sugar</option>
                               <option value="Low Sugar">Low Sugar</option>
                        </select></td>                                               
            </tr>
            ))}
        </tbody>
    </table>
                                                    <button onClick={() => this.addPersonalHistoryTable()} className="btn btn-primary" >Insert New Personal History Line</button> 
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={this.state.examination ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Physical and General Examination
                                    </div>
                                    <div className="card-body">
                                    <ul className="nav nav-tabs">
                                            <li className="nav-item"><span className={this.state.extraOralExamination ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("extraOralExamination") }}>Extra Oral Examination</span></li>
                                            <li className="nav-item"><span className={this.state.intraOralSoftTissueExamination ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("intraOralSoftTissueExamination") }}>Intra Oral Soft Tissue Examination</span></li>
                                            <li className="nav-item"><span className={this.state.intraOralHardTissueExamination ? "nav-link active" : "nav-link"} onClick={() => { this.toggleExaminationTabs("intraOralHardTissueExamination") }}>Intra Oral Hard Tissue Examination</span></li>
                                        </ul>
                                        <br />
                                        <div className="tab-content">
                                            <div className={this.state.extraOralExamination ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text"> Sinuses </span><input type="text" value={this.state.sinuses} onChange={e => this.setState({ sinuses: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Parotid Gland </span> <input type="text" value={this.state.parotidGland} onChange={e => this.setState({ parotidGland: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text"> Lymph Nodes </span> <input type="text" value={this.state.lymphNodes} onChange={e => this.setState({ lymphNodes: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">TMJ </span><input type="text" value={this.state.tmj} onChange={e => this.setState({ tmj: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text"> Facial Symmetry</span> <input type="text" value={this.state.facialSymmetry} onChange={e => this.setState({ facialSymmetry: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Remarks</span> <textarea value={this.state.examinationRemarks} onChange={e => this.setState({ examinationRemarks: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.intraOralSoftTissueExamination ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text"> Lips </span> <input type="text" value={this.state.lips} onChange={e => this.setState({ lips: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Oroppharynx  </span> <input type="text" value={this.state.oroppharynx} onChange={e => this.setState({ oroppharynx: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Mucobuccal Fold</span>  <input type="text" value={this.state.mucobuccalFold} onChange={e => this.setState({ mucobuccalFold: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Floor of Mouth</span>  <input type="text" value={this.state.floorOfMouth} onChange={e => this.setState({ floorOfMouth: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text"> Buccalmucosa </span> <input type="text" value={this.state.buccalmucosa} onChange={e => this.setState({ buccalmucosa: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Salivary Gland</span>  <input type="text" value={this.state.salivaryGland} onChange={e => this.setState({ salivaryGland: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Tongue</span>  <input type="text" value={this.state.tongue} onChange={e => this.setState({ tongue: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text"> Gingiva</span>   <input type="text" value={this.state.gingiva} onChange={e => this.setState({ gingiva: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Hard Palate</span> <input type="text" value={this.state.hardPalate} onChange={e => this.setState({ hardPalate: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Residual Ridge</span> <input type="text" value={this.state.residualRidge} onChange={e => this.setState({ residualRidge: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text"> Soft Palate </span>  <input type="text" value={this.state.softPalate} onChange={e => this.setState({ softPalate: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text"> Periodontium</span>  <input type="text" value={this.state.periodontium} onChange={e => this.setState({ periodontium: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Remarks </span> <textarea value={this.state.intraOralSoftTissueExaminationRemarks} onChange={e => this.setState({ intraOralSoftTissueExaminationRemarks: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.intraOralHardTissueExamination ? "tab-pane fade show active" : "tab-pane fade"}>
                                            <table class="table" id="addIntraOralHardTissueExaminationTable">
                        <tr class="vis-acuity-tb-bg">
                            <th>Action</th>
                            <th>Identification</th>
                            <th>Condition</th>
                            <th>Location</th>
                        </tr>
                        {this.state.intraOralHardTissueExaminations.map((item, index) => (                        
                        <tr key={index}>
                            <td><span className="link-red" onClick={() => this.deleteIntraOralHardTissueExamination(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                            <td><input type="text" class="form-control" value={item.teethIdentification} onChange={ e => this.setIntraOralHardTissueExamination(index, "teethIdentification", e.target.value)} id="teethIdentification"/></td>
                            <td> <select class="form-control" id="condition" value={item.condition} onChange={ e => this.setIntraOralHardTissueExamination(index, "condition", e.target.value)}>
                                        <option value="">Please Select</option>
                                        <option value="Malpositioned">Malpositioned</option>
                                        <option value="Unerupted">Unerupted</option>
                                        <option value="Extracted">Extracted</option>
                                        <option value="To Be Extracted">To Be Extracted</option>
                                        <option value="Internal Resorption">Internal Resorption</option>
                                        <option value="Pontic">Pontic</option>
                                        <option value="Gold Filling">Gold Filling</option>
                                        <option value="Acrylic Crown">Acrylic Crown</option>
                                        <option value="Dry Socket">Dry Socket</option>
                                        <option value="Fractured Tooth">Fractured Tooth</option>
                                        <option value="Grossly Carious">Grossly Carious</option>
                                        <option value="Secondary Carries">Secondary Carries</option>
                                        <option value="Mobile">Mobile</option>
                                        <option value="Impacted">Impacted</option>
                                        <option value="Amalgam Filling">Amalgam Filling</option>
                                        <option value="Composite Filling">Composite Filling</option>
                                        <option value="Temporary Filling">Temporary Filling</option>
                                        <option value="Roots">Roots</option>
                                        <option value="Fractured Filling">Fractured Filling</option>
                                        <option value="Calculus Gross">Calculus Gross</option>
                                        <option value="Calculus Moderate">Calculus Moderate</option>
                                        <option value="Missing">Missing</option>
                                        <option value="Gingivitis">Gingivitis</option>
                                        <option value="Periodontal Pocket">Periodontal Pocket</option>
                                        <option value="Abrasion">Abrasion</option>
                                        <option value="Furcation Lesion">Furcation Lesion</option>
                                        <option value="Overhang">Overhang</option>
                                        <option value="Root Treated">Root Treated</option>
                                        <option value="Porcelain Crown">Porcelain Crown</option>
                                        <option value="Gold Crown">Gold Crown</option>
                                        <option value="Bridge">Bridge</option>
                                        <option value="Others">Other</option>
                                    </select>{item.condition === "Others" &&(<input type="text" class="form-control" value={item.conditionOther} onChange={ e => this.setIntraOralHardTissueExamination(index, "conditionOther", e.target.value)} />)}</td>
                            <td><select class="form-control" id="location" value={item.location} onChange={ e => this.setIntraOralHardTissueExamination(index, "location", e.target.value)}>
                                        <option value="">Please Select</option>
                                        <option value="O">O</option>
                                        <option value="L">L</option>
                                        <option value="B">B</option>
                                        <option value="P">P</option>
                                        <option value="D">D</option>
                                        <option value="OB">OB</option>
                                        <option value="OM">OM</option>
                                        <option value="OD">OD</option>
                                        <option value="OL">OL</option>
                                        <option value="MOD">MOD</option>
                                        <option value="M">M</option>
                                    </select></td>
                        </tr>
                         ))}
                    </table>   <button onClick={() => this.addIntraOralHardTissueExaminationTable()} className="btn btn-primary" >Insert New Intra Oral Hard Tissue Examination Line</button> 
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Occulsion </span><input type="text" value={this.state.occulsion} onChange={e => this.setState({ occulsion: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Examination Done </span> <input type="text" value={this.state.examinationDone} onChange={e => this.setState({ examinationDone: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other  </span><textarea value={this.state.otherIntraOralHardTissueExaminationNotes} onChange={e => this.setState({ otherIntraOralHardTissueExaminationNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div> 
                                        </div>



                                      
                                    </div></div>
                            </div>
                            <div className={this.state.management ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Management</div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group">
                                                <textarea className="form-control" value={this.state.managementData} onChange={e => this.setState({ managementData: e.target.value })} ></textarea></div></div>
                                        </div>
                                    </div></div>
                            </div>
                            <div className={this.state.review ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Review</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Comments</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.reviews.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                    <td>{item.reviewDate}</td>
                                                    <td> {this.getReviewTime(item.reviewTime)} </td>
                                                        <td><textarea className="form-control" value={item.remarks} onChange={e => { this.setReview(index, 'remarks', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReview(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.props.showCalenderReview()} className="btn btn-primary" >Insert New Review Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.medicalCertificate ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Medical Certificate</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.medicalCertificates.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><select className="form-control" value={item.name} onChange={e => { this.setMedicalCertificate(index, 'name', e.target.value); }} >
                                                            <option value="">Choose Type</option>
                                                            {this.state.medicalCertificateTypes.map((m, index) => (
                                                                <option key={index} value={m} >{m}</option>
                                                            ))}
                                                        </select></td>
                                                        <td><DatePicker className="form-control" selected={new Date(item.startDate)} onChange={d => { this.setMedicalCertificate(index, "startDate", this.processDate(d)) }} dateFormat="yyyy-MM-dd" /></td>
                                                        <td><DatePicker className="form-control" selected={new Date(item.endDate)} onChange={d => { this.setMedicalCertificate(index, "endDate", this.processDate(d)) }} dateFormat="yyyy-MM-dd" /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteMedicalCertificate(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addMedicalCertificateTable()} className="btn btn-primary" >Insert New Medical Certificate Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.remainder ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Remainder</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Date</th>
                                                <th>Comment</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.remainderDates.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><DatePicker className="form-control" selected={new Date(item.date)} onChange={d => { this.setRemainder(index, "date", this.processDate(d)) }} dateFormat="yyyy-MM-dd" /></td>
                                                        <td><textarea className="form-control" value={item.remarks} onChange={e => { this.setRemainder(index, 'remarks', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteRemainder(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addRemainderTable()} className="btn btn-primary" >Insert New Remainder Line</button>
                                    </div></div>
                            </div> 
                            <div className={this.state.treatmentPlan ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Treatment Plan</div>
                                    <div className="card-body">
                                    <div className="row">
                                            <div className="col"><div className="input-group">
                                                <textarea className="form-control" value={this.state.treatmentPlanData} onChange={e => this.setState({ treatmentPlanData: e.target.value })} ></textarea></div></div>
                                        </div>
                                    </div></div>
                            </div>
                            <div className={this.state.patientChart ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                            <div className="card card-blue">
                                            <div className="card-header">Patient Chart</div>
                                            <div className="card-body">   
                                            { this.state.visitId > 0 &&   (<ReactPainter
                                                width={1000}
                                                height={500}
                                            image={localStorage.getItem('host')+'/patientmanagement/gynaeobsbreastsimage/DENTALCHART/'+this.state.visitId}
                                            onSave={blob =>  this.saveCanvas(blob, 'DENTALCHART')}
                                            render={({ canvas, triggerSave, setColor  }) => (
                                            <div className="row">
                                                <div className="col"><div className="input-group"><span className="input-group-text">Pen Color </span> <input type="color"  className="form-control" onChange={e => setColor(e.target.value)} /></div></div>
                                                <div className="col"> <button onClick={triggerSave} className="btn btn-primary">Save</button></div>
                                                <div className="col">  <button onClick={() => this.forceReRender('DENTALCHART')} className="btn btn-primary">Reset</button></div>
                                                <div>{canvas}</div>
                                            </div>
                                            )}
                                        />)}
                                            </div></div>
                            </div>
                            <div className={this.state.referral ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Referral</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Remarks</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.referrals.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><input type="text" className="form-control" value={item.consultant} onChange={e => { this.setReferral(index, 'consultant', e.target.value); }} /></td>
                                                        <td><textarea className="form-control" value={item.referralNotes} onChange={e => { this.setReferral(index, 'referralNotes', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReferral(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addReferralTable()} className="btn btn-primary" >Insert New Referral Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.treatment ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Treatment</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Drug</th>
                                                <th>Dosage</th>
                                                <th>Frequency</th>
                                                <th>Route</th>
                                                <th>Duration</th>
                                                <th>Formulation</th>
                                                <th>Repeats</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.treatments.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><input type="text" list={'drugList' + index} className="form-control" value={item.drugName} onChange={e => { this.setTreatment(index, 'drugName', e.target.value); }} />
                                                            <datalist id={'drugList' + index}>
                                                                {this.state.drugs.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" className="form-control" value={item.dosage} onChange={e => { this.setTreatment(index, 'dosage', e.target.value); }} /></td>
                                                        <td><input type="text" list={'frequencyList' + index} className="form-control" value={item.frequencyName} onChange={e => { this.setTreatment(index, 'frequencyName', e.target.value); }} />
                                                            <datalist id={'frequencyList' + index}>
                                                                {this.state.frequencys.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'routeList' + index} className="form-control" value={item.routeName} onChange={e => { this.setTreatment(index, 'routeName', e.target.value); }} />
                                                            <datalist id={'routeList' + index}>
                                                                {this.state.routes.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'durationList' + index} className="form-control" value={item.durationName} onChange={e => { this.setTreatment(index, 'durationName', e.target.value); }} />
                                                            <datalist id={'durationList' + index}>
                                                                {this.state.durations.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'formulationList' + index} className="form-control" value={item.formulationName} onChange={e => { this.setTreatment(index, 'formulationName', e.target.value); }} />
                                                            <datalist id={'formulationList' + index}>
                                                                {this.state.formulations.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" className="form-control" value={item.repeats} onChange={e => { this.setTreatment(index, 'repeats', e.target.value); }} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteTreatment(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addTreatmentTable()} className="btn btn-primary" >Insert New Treatment Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.investigation ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Investigation</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Name</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.investigations.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><Select className="form-control" value={this.state.services.filter(({ value }) => value === item.name)} onChange={e => { this.setInvestigation(index, e.value); }} options={this.state.services} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteInvestigation(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addInvestigationTable()} className="btn btn-primary" >Insert New Investigation Line</button>
                                    </div></div>
                            </div>

<div className={this.state.bill ? "tab-pane fade show active" : "tab-pane fade"}>
      <br />
                  <div className="card"><div className="card-body">
                        <div className="row">
                            <div className="col "><div className="input-group">
                                <span className="input-group-text">Service</span>
                                <Select
                                value={this.state.serviceCostId}
                                className="form-control"
                                onChange={e => this.setState({ serviceCostId: e })}
                                options={this.state.options}
                            /></div></div>
                            <div className="col "><div className="input-group">
                                <span className="input-group-text">Cash Only</span><select className="form-control" data-live-search="true" value={this.state.cashOnly} onChange={e => this.setState({ cashOnly: e.target.value })}>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </select></div></div>
                            <div className="col" align="left">{ this.state.serviceCostId !== "" && (<input type="button" onClick={() => this.addServices()} value="Add" className="btn btn-success" />)}</div>
                        </div>
                        </div></div>
                        <br />
                                            <div id="toothInfoAction" className="page-popup page-popup-overlay" >
                                                <div className="card" >
                                                    <div className="card-header bg-info">Tooth Number Action</div>
                                                    <div className="card-body" >
                                                        <button onClick={() => this.addToothInfoTable()}><i class="bi bi-plus-square-fill"></i></button>
                                                        <table className="table">
                                                            <tr>
                                                                <th>Tooth Number</th>
                                                                <th>Surface</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            {this.state.toothInfos.map((item, index) => (
                                                                <tr>
                                                                 <td><input type="text" className="form-control" value={item.toothNumber} onChange={e => { this.setToothInfo(index, 'toothNumber', e.target.value); }} /></td>
                                                                 <td><select className="form-control" value={item.toothSurface} onChange={e => { this.setToothInfo(index, 'toothSurface', e.target.value); }} >
                                                            <option value="">Choose Type</option>
                                                            {this.state.toothSurfaces.map((m, index) => (
                                                                <option key={index} value={m.id} >{m.name}</option>
                                                            ))}
                                                        </select></td>
                                                                 <td><span className="link-red" onClick={() => this.deleteToothInfo(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                                </tr>
                                                            ))}
                                                        </table>
                                                        <div className="row">
                                                        <div className="col" align="center" ><button onClick={() => this.saveToothInfo()} className="btn btn-primary">Save</button></div> 
                                                        <div className="col" align="center" ><button onClick={() => this.closeToothInfoPopup()} className="btn btn-primary">Close</button></div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                        <br />
                        <table className="table table-striped">
                            <tr>
                                <th>Line</th>
                                <th>Name</th>
                                <th>Tariff</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Quantity</th>
                                <th>Unit Cost</th>
                                <th>Cost</th>
                                { this.state.speciality === 'DENTAL' && (<th>Tooth Numbers</th>)}
                                <th>Autho Amount</th>
                                <th>Total Cost</th>
                                <th>Cash Only</th>
                                <th>Action</th>
                            </tr>
                            <tbody>
                            {this.state.billItems.map((billItem, index) => (
                                billItem.activeStatus === 1 &&(<tr >
                                    <td >{index + 1}</td>
                                    <td >{billItem.name}</td>
                                    <td>{billItem.tariffCode}</td>
                                    <td><DatePicker selected={new Date(billItem.billStartDate)} onChange={e => this.setBillItem("billStartDate", index, e)} timeInputLabel="Time:" showTimeInput dateFormat="yyyy-MM-dd HH:mm:ss" /></td>
                                    <td><DatePicker selected={new Date(billItem.billEndDate)} onChange={e => this.setBillItem("billEndDate", index, e)} timeInputLabel="Time:" showTimeInput dateFormat="yyyy-MM-dd HH:mm:ss" /></td>
                                    {billItem.serviceType === 'MULTIPLE_QUANTITY' || billItem.billItemType === "PRODUCT"?(<td><input type="text" className="form-control" value={billItem.quantity} onChange={e => {this.setBillItem("quantity", index, e.target.value); this.calcQuantity(index, e.target.value)}} /></td>):(<td>{billItem.quantity}</td>)}
                                    {billItem.allowEdit === 1 ?(<td><div className="input-group has-validation"><input type="text" className={billItem.unitCostError !== "" ? "form-control is-invalid" : "form-control"} v-model="billItem.unitCost" value={billItem.unitCost} onChange={e => this.setBillItem("unitCost", index, e.target.value)} /><div className="invalid-feedback">{billItem.unitCostError}</div></div></td>):(<td>{this.currencyFormat(billItem.unitCost)}</td>)}
                                    <td>{this.currencyFormat(billItem.cost)}</td>
                                    { this.state.speciality === 'DENTAL' && (<td>{ billItem.serviceClass === "TOOTH" && (<span>{billItem.toothInfo}<span className="link" onClick={() => this.openToothinfo(billItem.toothInfo, index)} ><i class="bi bi-box-arrow-in-down"></i></span></span>)}</td>)}
                                    <td>{billItem.authoAmount}</td>
                                    <td>{this.currencyFormat(billItem.totalCost)}</td>
                                    <td><select className="form-control" value={billItem.cashOnly} onChange={e => { this.setBillItem("cashOnly",index, e.target.value) }}  >
                                    <option value="1" >Yes</option>
                                        <option value="0" >No</option>
                                    </select></td>
                                    <td><span className="link-red" onClick={() => this.hideEntry(index)}><i className="bi bi-trash-fill"></i></span></td>
                                </tr>)
                            ))}
                            </tbody>
                        </table>
                        <div className="row">
                        <div className="input-group">
                                    <input type="hidden" className={this.state.billItemsError !== "" ? "form-control is-invalid" : "form-control"} />
                                    <div className="invalid-feedback">{this.state.billItemsError}</div> </div></div>    
                     <div className="row">
                            <div className="col"><div className="input-group"><span className="input-group-text">Authorisation Number</span><input type="text" className="form-control" data-live-search="true" onChange={e => {this.setState({ authorisationNumber: e.target.value }); this.calcBill(this.state.billItems, e.target.value, this.state.authorisationAmount);}} value={ this.state.authorisationNumber} /></div></div>
                            <div className="col"><div className="input-group"><span className="input-group-text">Authorisation Amount</span><input type="text" className="form-control" data-live-search="true" onChange={e => {this.setState({ authorisationAmount: e.target.value }); this.calcBill(this.state.billItems, this.state.authorisationNumber, e.target.value);}} value={ this.state.authorisationAmount} /></div></div>
                            </div>            
                     
                        <table className="table table-striped">
                            <tr>
                                <th></th>
                                <th>Cash</th>
                                <th>Insurance</th>
                                <th>Shortfall</th>
                                <th>Special Contract</th>
                                <th>Total</th>
                            </tr>
                            <tbody>
                            <tr>
                                <th>Payable</th>
                                <td>{this.currencyFormat(this.state.cashPayable)}</td>
                                <td>{this.currencyFormat(this.state.insurancePayable)}</td>
                                <td>{this.currencyFormat(this.state.shortfallPayable)}</td>
                                <td>{this.currencyFormat(this.state.specialContractPayable)}</td>
                                <td>{this.currencyFormat(this.state.totalPayable)}</td>
                            </tr>
                            <tr>
                                <th>Paid</th>
                                <td>{this.currencyFormat(this.state.cashPaid)}</td>
                                <td>{this.currencyFormat(this.state.insurancePaid)}</td>
                                <td>{this.currencyFormat(this.state.shortfallPaid)}</td>
                                <td>{this.currencyFormat(this.state.specialContractPaid)}</td>
                                <td>{this.currencyFormat(this.state.totalPaid)}</td>
                            </tr>
                            <tr>
                                <th>Owing</th>
                                <td>{this.currencyFormat(this.state.cashOwing)}</td>
                                <td>{this.currencyFormat(this.state.insuranceOwing)}</td>
                                <td>{this.currencyFormat(this.state.shortfallOwing)}</td>
                                <td>{this.currencyFormat(this.state.specialContractOwing)}</td>
                                <td>{this.currencyFormat(this.state.totalOwing)}</td>
                            </tr></tbody>

                        </table>
                        </div>
                        </div>

                        <div >
                            <div className="card card-blue">
                                <div className="card-header  text-dark">
                                    Diagnosis
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-12 col-lg-12">
                                            <a href="https://icd.who.int/browse10/2019/en" target="_blank" rel="noreferrer">Check Online</a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col"><div className="input-group">
                                            <span className="input-group-text">Diagnosis Text</span><textarea className="form-control" value={this.state.diagnosisText} onChange={e => { this.setState({ diagnosisText: e.target.value }) }} ></textarea></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col"><div className="input-group">
                                            <input type="text" value={this.state.searchDiagnosis} onChange={e => { this.setState({ searchDiagnosis: e.target.value }) }} className="form-control" placeholder="Search ICD10 Diagnosis Here" />
                                            <button className="btn btn-success" onClick={() => this.getDiagnosisList()} ><i class="bi bi-search"></i></button>
                                        </div>
                                        </div>
                                        </div>
                                    { this.state.diagnosisList.length > 0 &&(<div className="row">
                                        <div className="col"><div className="input-group"> 
                                        <select class="form-select" size={this.state.diagnosisList.length}  onChange={e => this.setState({ diagnosis: e.target.value })} aria-label="size 3 select example">
                                        <option value="">Choose Diagnosis</option>
                                      {this.state.diagnosisList.map((item, index) =>(  
  <option key={index} value={item.code}>{item.code} - {item.description}</option>
  ))}
</select><button onClick={() => this.insertDiagnosis()} className="btn btn-success" ><i class="bi bi-plus-circle-fill"></i></button></div></div>
                                    </div> )}
                                    <table className="table">
                                        <tr>
                                            <th>Description</th>
                                            <th>Active Status</th>
                                        </tr>
                                        {this.state.patientDiagnosis.map((item, index) =>  (item.activeStatus === 1 &&(
                                            <tr key={index}>
                                                <td>{item.description}</td>
                                                <td><span className="link-red" onClick={() => this.deleteDiagnosis(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                            </tr>)))}
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
             
               
            </div >
        </div >
        </div >)

    }
}      