import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'


export default class ChangePassword extends Component {
    state = { password1: '', id: 0, oldPassword: '', password: '', msg: '', error: '' }
    componentDidMount() {
       
    }
    changePassword() {
        var error = "";
        if(this.state.oldPassword === "")
           {
           error = "Old Password cannot be empty";    
           }
         if(error === "" && this.state.password === "")
            {
             error = "Passsword cannot be empty";
            }   
         if(error === "" && this.state.password.length < 6)
            {
             error = "Passsword cannot be less than 6 characters";
            }     
         if(error === "" && this.state.password !== this.state.password1)
            {
             error = "Passswords are not the same";
            }   
        if(error === "")
           {

        var data = { id: localStorage.getItem('userId'), oldPassword: this.state.oldPassword, password: this.state.password }
        axios.post(localStorage.getItem('host') + '/auth/passwordform', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                if(res.data.includes('Error'))
                   {
                    this.setState({ error: res.data });
                    setTimeout(() => {
                        this.setState({ error: '' });
                    }, 3000);
                   }
                else{
                    this.setState({ msg: res.data });
                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);

                }   
            }, err => {
                console.log(".....")
            }
        )
           }
        else
           {
            this.setState({error: error})   
           }   
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
                <Top />

                <div className="container-fluid">
                                <div className="card card-green">
                                    <div className="card-header">
                                       Change Password
                                    </div>
                                    <div className="card-body">
                                        {divMsg}
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Old Password</span>
                                                <input type="password" className="form-control" value={this.state.oldPassword} onChange={e => { this.setState({ oldPassword: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                                 </div></div>
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">New Password</span>
                                                <input type="password" className="form-control" value={this.state.password} onChange={e => { this.setState({ password: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Confirm Password</span>
                                                <input type="password" className="form-control" value={this.state.password1} onChange={e => { this.setState({ password1: e.target.value }) }} /></div>
                                            </div></div>
                                        <div className="row">
                                            <div className="col" align="center"><input type="button" className="btn btn-primary" span onClick={() => this.changePassword()} value="Change Password" /></div>
                                            
                                        </div>
                                    </div></div>
                </div></div>
                </div>
        )

    }
}    