import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class RateAction extends Component {
    state = { id: 0, rate: 0, msg: '', error: '', fullname: '', number: '', billCategory: '', totalPayable: 0.00, totalPaid: 0.00, totalOwing: 0.00, currencyId: '', currencys: [] }

    getRateAction(item) {
       
        axios.get(localStorage.getItem('host') + "/master/currencylist/"+item.companyId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
        this.setState({ id: item.id, currencys: res.data, rate: item.rate, fullname: item.fullname, number: item.number, billCategory: item.billCategory, totalPayable: item.totalPayable, totalPaid: item.totalPaid, totalOwing: item.totalOwing, currencyId: item.currencyId })
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    save() {
        var error = "";
        if (error === "") {
            this.props.setLoader(true)
            var currencyRate =  0
            for(var i in this.state.currencys)
                {
                 if(this.state.currencys[i].id === this.state.currencyId)
                    {
                        currencyRate = this.state.currencys[i].rate;
                    }
                }
            var data = {id: this.state.id, currencyRate: currencyRate, rate: this.state.rate, currencyId: this.state.currencyId}     
            axios.post(localStorage.getItem('host') + "/bill/applyrate", data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then(res => {
                    this.props.setMsg(res.data);
                    this.closePopup();
                    this.props.getList();
                    this.props.setLoader(false)
                })
        } else {
            this.setState({ error: error })
        }
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    closePopup() {
        document.getElementById('rateAction').style = 'display:none'
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="rateAction" class="page-popup page-popup-overlay"   ><div class="card" >
            <div class="card-header bg-info">Cancel Receipt</div>
            <div class="card-body" >
                <div class="card border-success">
                    <div class="card-body">
                        {divMsg}
                        <div class="row">
                            <div class="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                            <div class="col"><span className="fw-bold">Number:</span> {this.state.number}</div>
                            <div class="col"><span className="fw-bold">Category:</span> {this.state.billCategory}</div>
                        </div>
                        <div class="row">
                            <div class="col"><span className="fw-bold">Payable:</span> {this.currencyFormat(this.state.totalPayable)}</div>
                            <div class="col"><span className="fw-bold">Paid:</span> {this.currencyFormat(this.state.totalPaid)}</div>
                            <div class="col"><span className="fw-bold">Owing:</span> {this.currencyFormat(this.state.totalOwing)}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                 <div class="col-lg-6" ><label>Currency</label><select class="form-control" value={this.state.currencyId} onChange={e => this.setState({currencyId: e.target.value})}>
                 {this.state.currencys.map((item, index) => (
                                          <option key={index} value={item.id} >{item.name}</option>
                                          ))}
                                    </select></div> 
                 <div class="col-lg-6" ><label>Current Rate</label><input type="text" class="form-control" value={this.state.rate} onChange={e => this.setState({rate: e.target.value})} autocomplete="no"  /></div>                 
             </div>
                <div class="row">
                    <div class="col" align="center">{this.state.currencyId > 0 && this.state.rate > 0 ? (<button onClick={() => this.save()} id="submitButton2" class="btn btn-primary" >Submit</button>) : (<span></span>)}</div>
                    <div class="col" align="center"><button onClick={() => this.closePopup()} class="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}    