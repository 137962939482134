import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class OptomAction extends Component {
    state = {
        loader: false, htmlText: '', id: 0, companyId: 0, patientId: 0, patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], referrals: [], treatments: [], drugs: [], frequencys: [], routes: [], durations: [], formulations: [],
        fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: 0.0, height: 0.0, waist: 0.0, headCircumference: 0.0, muac: 0, bmi: 0.0, waistHeightRatio: 0.0, nurseNotes: '', vitalColors: [], notes: [], referralLetters: [], oldPatient: '', managementData: '', historyVisits: [], visitId: 0, msg: '', error: '',
        presentingComplaints: '', pastOcularHistory: '', allergyHistory: '', pastMedicalHistory: '', currentMedication: '', familyHistory: '', socialHistory: '', treatmentRecords: [], 
        examination: '', uncorrectedDistanceVisualAcuityRight: '', uncorrectedDistanceVisualAcuityLeft: '', nearVisualAcuityRight: '', nearVisualAcuityLeft: '', aidedVisualAcuityRight: '', aidedVisualAcuityLeft: '', refractionLeft: '', refractionRight: '', refractionAddLeft: '', refractionAddRight: '', fundi: '',
        tint: '', bifocalType: '', prismsRight: '', prismsLeft: '', segmentsRight: '', segmentsLeft: '', opticalCentresD: '', sphRight: '', cylRight: '', axisRight: '', addRight: '', sphLeft: '', cylLeft: '', axisLeft: '', addLeft: '', specialInstructions: '', data: '',
        presentingComplaintsH: true, pastOcularHistoryH: false, allergyHistoryH: false, pastMedicalHistoryH: false, currentMedicationH: false, familyHistoryH: false, socialHistoryH: false,
        history: true, examinationT: false, treatment: false, referral: false, medicalCertificate: false, review: false, management: false, optomPrescriptionT: false
    }
    componentDidMount() {

    }
    openOptomAction(visitId, msg) {
        if(msg === '')
           {
            this.setLoader(true) 
           }
        this.setState({
            visitId: visitId, htmlText: '', id: 0, companyId: 0, patientId: 0, patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], referrals: [], treatments: [], drugs: [], frequencys: [], routes: [], durations: [], formulations: [],
            fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: 0.0, height: 0.0, waist: 0.0, headCircumference: 0.0, muac: 0, bmi: 0.0, waistHeightRatio: 0.0, nurseNotes: '', vitalColors: [], notes: [], referralLetters: [], oldPatient: '', managementData: '', historyVisits: [], msg: msg, error: '',
            presentingComplaints: '', pastOcularHistory: '', allergyHistory: '', pastMedicalHistory: '', currentMedication: '', familyHistory: '', socialHistory: '', treatmentRecords: [], 
            tint: '', bifocalType: '', prismsRight: '', prismsLeft: '', segmentsRight: '', segmentsLeft: '', opticalCentresD: '', sphRight: '', cylRight: '', axisRight: '', addRight: '', sphLeft: '', cylLeft: '', axisLeft: '', addLeft: '', specialInstructions: '', data: '',
            examination: '', uncorrectedDistanceVisualAcuityRight: '', uncorrectedDistanceVisualAcuityLeft: '', nearVisualAcuityRight: '', nearVisualAcuityLeft: '', aidedVisualAcuityRight: '', aidedVisualAcuityLeft: '', refractionLeft: '', refractionRight: '', refractionAddLeft: '', refractionAddRight: '', fundi: '',
            presentingComplaintsH: true, pastOcularHistoryH: false, allergyHistoryH: false, pastMedicalHistoryH: false, currentMedicationH: false, familyHistoryH: false, socialHistoryH: false,
            history: true, examinationT: false, treatment: false, referral: false, medicalCertificate: false, review: false, management: false, optomPrescriptionT: false
        })
        axios.get(localStorage.getItem('host') + '/patientmanagement/optom/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({
                    id: res.data.id, companyId: res.data.companyId, patientId: res.data.patientId, patientDiagnosis: res.data.patientDiagnosis, diagnosisText: res.data.diagnosisText, reviews: res.data.reviews, medicalCertificateTypes: res.data.medicalCertificateTypes, medicalCertificates: res.data.medicalCertificates, rehabilitations: res.data.rehabilitations, referrals: res.data.referrals, treatments: res.data.treatments, drugs: res.data.drugs, frequencys: res.data.frequencys, routes: res.data.routes, durations: res.data.durations, formulations: res.data.formulations, services: res.data.services, investigations: res.data.investigations, findings: res.data.findings, emrOlds: res.data.emrOlds, oldResults: res.data.oldResults ,
            /* HISTORY  */     presentingComplaints: res.data.history.presentingComplaints, pastOcularHistory: res.data.history.pastOcularHistory, allergyHistory: res.data.history.allergyHistory, pastMedicalHistory: res.data.history.pastMedicalHistory, currentMedication: res.data.history.currentMedication, familyHistory: res.data.history.familyHistory, socialHistory: res.data.history.socialHistory,
                        /* PRESCRIPTION  */     tint: res.data.optomPrescription.tint, bifocalType: res.data.optomPrescription.bifocalType, prismsRight: res.data.optomPrescription.prismsRight, prismsLeft: res.data.optomPrescription.prismsLeft, segmentsRight: res.data.optomPrescription.segmentsRight, segmentsLeft: res.data.optomPrescription.segmentsLeft, opticalCentresD: res.data.optomPrescription.opticalCentresD, sphRight: res.data.optomPrescription.sphRight, cylRight: res.data.optomPrescription.cylRight, axisRight: res.data.optomPrescription.axisRight, addRight: res.data.optomPrescription.addRight, sphLeft: res.data.optomPrescription.sphLeft, cylLeft: res.data.optomPrescription.cylLeft, axisLeft: res.data.optomPrescription.axisLeft, addLeft: res.data.optomPrescription.addLeft, specialInstructions: res.data.optomPrescription.specialInstructions, data: res.data.optomPrescription.data,
             /* EXAMINATION */ examination: res.data.examination.examination, uncorrectedDistanceVisualAcuityRight: res.data.examination.uncorrectedDistanceVisualAcuityRight, uncorrectedDistanceVisualAcuityLeft: res.data.examination.uncorrectedDistanceVisualAcuityLeft, nearVisualAcuityRight: res.data.examination.nearVisualAcuityRight, nearVisualAcuityLeft: res.data.examination.nearVisualAcuityLeft, aidedVisualAcuityRight: res.data.examination.aidedVisualAcuityRight, aidedVisualAcuityLeft: res.data.examination.aidedVisualAcuityLeft, refractionLeft: res.data.examination.refractionLeft, refractionRight: res.data.examination.refractionRight, refractionAddLeft: res.data.examination.refractionAddLeft, refractionAddRight: res.data.examination.refractionAddRight, fundi: res.data.examination.fundi,
                   fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, vitalColors: res.data.vitalColors, notes: res.data.notes, referralLetters: res.data.referralLetters, oldPatient: res.data.oldPatient, managementData: res.data.managementData, historyVisits: res.data.historyVisits
                });
                setTimeout(() => { this.setLoader(false)  }, 2000);
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    save() {
        this.setLoader(true)
        var data = {
            patientId: this.state.patientId, visitId: this.state.visitId, companyId: this.state.companyId
            , history: {presentingComplaints: this.state.presentingComplaints, pastOcularHistory: this.state.pastOcularHistory, allergyHistory: this.state.allergyHistory, pastMedicalHistory: this.state.pastMedicalHistory, currentMedication: this.state.currentMedication, familyHistory: this.state.familyHistory, socialHistory: this.state.socialHistory }
            , examination: { examination: this.state.examination, uncorrectedDistanceVisualAcuityRight: this.state.uncorrectedDistanceVisualAcuityRight, uncorrectedDistanceVisualAcuityLeft: this.state.uncorrectedDistanceVisualAcuityLeft, nearVisualAcuityRight: this.state.nearVisualAcuityRight, nearVisualAcuityLeft: this.state.nearVisualAcuityLeft, aidedVisualAcuityRight: this.state.aidedVisualAcuityRight, aidedVisualAcuityLeft: this.state.aidedVisualAcuityLeft, refractionLeft: this.state.refractionLeft, refractionRight: this.state.refractionRight, refractionAddLeft: this.state.refractionAddLeft, refractionAddRight: this.state.refractionAddRight, fundi: this.state.fundi }
            , optomPrescription: { tint: this.state.tint, bifocalType: this.state.bifocalType, prismsRight: this.state.prismsRight, prismsLeft: this.state.prismsLeft, segmentsRight: this.state.segmentsRight, segmentsLeft: this.state.segmentsLeft, opticalCentresD: this.state.opticalCentresD, sphRight: this.state.sphRight, cylRight: this.state.cylRight, axisRight: this.state.axisRight, addRight: this.state.addRight, sphLeft: this.state.sphLeft, cylLeft: this.state.cylLeft, axisLeft: this.state.axisLeft, addLeft: this.state.addLeft, specialInstructions: this.state.specialInstructions, data: this.state.data }
            , patientDiagnosis: this.state.patientDiagnosis, diagnosisText: this.state.diagnosisText, reviews: this.state.reviews, medicalCertificates: this.state.medicalCertificates, referrals: this.state.referrals, treatments: this.state.treatments
        }
        axios.post(localStorage.getItem('host') + '/patientmanagement/optomsave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                document.getElementById('topDiv').scrollIntoView()
                this.openOptomAction(this.state.visitId, res.data ) 
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('optomAction').style = 'display:none'
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    }
    viewHistory(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/summaryviewoptom/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    viewConsolidated(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/summaryviewconsolidatedoptom/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor Consolidated' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    showTreatmentRecord(patientId) {

        this.setState({ treatmentRecords: [] })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/treatmentlistview/' + patientId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ treatmentRecords: res.data });
                document.getElementById('treatmentRecord').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    genericClosePopup() {
        document.getElementById('genericPopup').style = 'display:none'
    }

    treatmentClosePopup() {
        document.getElementById('treatmentRecord').style = 'display:none'
    }
    uploadFile(fileType, file) {
        if (file !== null || file !== '') {
            var data = new FormData();
            data.append("file", file)
            data.append("fileType", fileType)
            data.append("visitId", this.state.visitId)
            axios.post(localStorage.getItem('host') + '/file/filesave', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data'

                }
            }).then(
                res => {
                    console.log(res.data)
                    if (fileType === "REFERRAL_LETTERS") {
                        this.setState({ referralLetters: res.data, msg: 'Successfully uploaded Referral Letter' });
                    }
                    else {
                        this.setState({ notes: res.data, msg: 'Successfully uploaded Notes' });
                    }

                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }, err => {
                    console.log(".....")
                }
            )

        }
    }
    deleteFile(id, fileExtension, fileType) {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/deletefile/' + id + '/' + fileType + '/' + fileExtension + '/' + this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                if (fileType === "REFERRAL_LETTERS") {
                    this.setState({ referralLetters: res.data, msg: 'Successfully deleted Referral Letter' });
                }
                else {
                    this.setState({ notes: res.data, msg: 'Successfully deleted Notes' });
                }
                this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    }
    showFile(id, fileExtension) {
        this.setState({ fileData: '' });
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/getfile/' + id + '/' + fileExtension, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ fileData: res.data });
                document.getElementById('showFileNurseAction').style = 'display:block'
                this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )

    }
    toggleTabs(name) {
        switch (name) {
            case "examinationT":
                this.setState({ history: false, examinationT: true, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: false, optomPrescriptionT: false })
                break
           case "treatment":
                this.setState({ history: false, examinationT: false, investigation: false, treatment: true, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: false, optomPrescriptionT: false })
                break
            case "referral":
                this.setState({ history: false, examinationT: false, investigation: false, treatment: false, rehabilitation: false, referral: true, medicalCertificate: false, review: false, management: false, optomPrescriptionT: false })
                break
            case "medicalCertificate":
                this.setState({ history: false, examinationT: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: true, review: false, management: false, optomPrescriptionT: false })
                break
            case "review":
                this.setState({ history: false, examinationT: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: true, management: false, optomPrescriptionT: false })
                break
            case "management":
                this.setState({ history: false, examinationT: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: true, optomPrescriptionT: false })
                break
            case "optomPrescriptionT":
                this.setState({ history: false, examinationT: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: false, optomPrescriptionT: true })
                break
            default:
                this.setState({  history: true, examinationT: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, management: false, optomPrescriptionT: false })
                break

        }
    }
    addReviewTable() {
        let list = this.state.reviews.slice();
        list.push({ id: 0, reviewDate: date1, remarks: '', activeStatus: 1 });
        this.setState({ reviews: list });
    }

    addMedicalCertificateTable() {
        let list = this.state.medicalCertificates.slice();
        list.push({ id: 0, name: '', startDate: date1, endDate: date1, activeStatus: 1 });
        this.setState({ medicalCertificates: list });
    }
    addRehabilitationTable() {
        let list = this.state.rehabilitations.slice();
        list.push({ id: 0, type: '', remarks: '', activeStatus: 1 });
        this.setState({ rehabilitations: list });
    }
    addInvestigationTable() {
        let list = this.state.investigations.slice();
        list.push({ id: 0, name: '', activeStatus: 1 });
        this.setState({ investigations: list });
    }
    addReferralTable() {
        let list = this.state.referrals.slice();
        list.push({ id: 0, consultant: '', referralNotes: '', activeStatus: 1 });
        this.setState({ referrals: list });
    }
    addTreatmentTable() {
        let list = this.state.treatments.slice();
        list.push({ id: 0, dosage: '', drugName: '', formulationName: '', frequencyName: '', routeName: '', durationName: '', repeats: '', activeStatus: 1 });
        this.setState({ treatments: list });
    }
    deleteReview(index) {
        let list = this.state.reviews.slice();
        list[index].activeStatus = 0
        this.setState({ reviews: list });
    }

    deleteMedicalCertificate(index) {
        let list = this.state.medicalCertificates.slice();
        list[index].activeStatus = 0
        this.setState({ medicalCertificates: list });
    }
    deleteRehabilitation(index) {
        let list = this.state.rehabilitations.slice();
        list[index].activeStatus = 0
        this.setState({ rehabilitations: list });
    }
    deleteInvestigation(index) {
        let list = this.state.investigations.slice();
        list[index].activeStatus = 0
        this.setState({ investigations: list });
    }
    deleteReferral(index) {
        let list = this.state.referrals.slice();
        list[index].activeStatus = 0
        this.setState({ referrals: list });
    }
    deleteTreatment(index) {
        let list = this.state.treatments.slice();
        list[index].activeStatus = 0
        this.setState({ treatments: list });
    }
    setReview(index, name, value) {
        let list = this.state.reviews.slice();
        switch (name) {
            case "reviewDate":
                list[index].reviewDate = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ reviews: list });
    }

    setMedicalCertificate(index, name, value) {
        let list = this.state.medicalCertificates.slice();
        switch (name) {
            case "name":
                list[index].name = value
                break;
            case "startDate":
                list[index].startDate = value
                break;
            default:
                list[index].endDate = value
                break;
        }
        this.setState({ medicalCertificates: list });
    }
    setRehabilitation(index, name, value) {
        let list = this.state.rehabilitations.slice();
        switch (name) {
            case "type":
                list[index].type = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ rehabilitations: list });
    }
    setInvestigation(index, value) {
        let list = this.state.investigations.slice();
        for (var i in this.state.services) {
            if (this.state.services[i].value === value) {
                list[index].name = value
                list[index].speciality = this.state.services[i].speciality
            }
        }
        this.setState({ investigations: list });
    }
    setReferral(index, name, value) {
        let list = this.state.referrals.slice();
        switch (name) {
            case "consultant":
                list[index].consultant = value
                break;
            default:
                list[index].referralNotes = value
                break;
        }
        this.setState({ referrals: list });
    }
    setTreatment(index, name, value) {
        let list = this.state.treatments.slice();
        switch (name) {
            case "dosage":
                list[index].dosage = value
                break;
            case "drugName":
                list[index].drugName = value
                break;
            case "formulationName":
                list[index].formulationName = value
                break;
            case "frequencyName":
                list[index].frequencyName = value
                break;
            case "routeName":
                list[index].routeName = value
                break;
            case "durationName":
                list[index].durationName = value
                break;
            default:
                list[index].repeats = value
                break;
        }
        this.setState({ treatments: list });
    }

    toggleHistoryTabs(name) {
        switch (name) {
            case "pastOcularHistoryH":
                this.setState({ presentingComplaintsH: false, pastOcularHistoryH: true, allergyHistoryH: false, pastMedicalHistoryH: false, currentMedicationH: false, familyHistoryH: false, socialHistoryH: false })
                break
            case "allergyHistoryH":
                this.setState({ presentingComplaintsH: false, pastOcularHistoryH: false, allergyHistoryH: true, pastMedicalHistoryH: false, currentMedicationH: false, familyHistoryH: false, socialHistoryH: false })
                break
            case "pastMedicalHistoryH":
                this.setState({ presentingComplaintsH: false, pastOcularHistoryH: false, allergyHistoryH: false, pastMedicalHistoryH: true, currentMedicationH: false, familyHistoryH: false, socialHistoryH: false })
                break
            case "currentMedicationH":
                this.setState({ presentingComplaintsH: false, pastOcularHistoryH: false, allergyHistoryH: false, pastMedicalHistoryH: false, currentMedicationH: true, familyHistoryH: false, socialHistoryH: false})
                break
            case "familyHistoryH":
                this.setState({ presentingComplaintsH: false, pastOcularHistoryH: false, allergyHistoryH: false, pastMedicalHistoryH: false, currentMedicationH: false, familyHistoryH: true, socialHistoryH: false })
                break
            case "socialHistoryH":
                this.setState({ presentingComplaintsH: false, pastOcularHistoryH: false, allergyHistoryH: false, pastMedicalHistoryH: false, currentMedicationH: false, familyHistoryH: false, socialHistoryH: true })
                break
            default:
                this.setState({ presentingComplaintsH: true, pastOcularHistoryH: false, allergyHistoryH: false, pastMedicalHistoryH: false, currentMedicationH: false, familyHistoryH: false, socialHistoryH: false })
                break

        }
    }
    getDiagnosisList() {
        this.setState({ diagnosisList: [], diagnosis: '' })
        console.log('..... i am ')
        if (this.state.searchDiagnosis !== null && this.state.searchDiagnosis !== "") {
            console.log('..... i am ')
            this.setLoader(true)
            axios.get(localStorage.getItem('host') + '/master/diagnosislist?text=' + this.state.searchDiagnosis, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ diagnosisList: res.data });
                    this.setLoader(false)
                }, err => {
                    this.setState({ error: err + "" })
                    this.setLoader(false)
                }
            )

        }
    }
    insertDiagnosis() {
        if (this.state.diagnosis !== null && this.state.diagnosis !== '') {
            let list = this.state.patientDiagnosis.slice();
            for (var i in this.state.diagnosisList) {
                if(this.state.diagnosisList[i].code === this.state.diagnosis)
                   {
                    list.push({ id: 0, code: this.state.diagnosis, description: this.state.diagnosis+" - "+this.state.diagnosisList[i].description, activeStatus: 1 });
                   }
            }
            this.setState({ patientDiagnosis: list });
        }

    }
    deleteDiagnosis(index) {
        let list = this.state.patientDiagnosis.slice();
        list[index].activeStatus = 0
        this.setState({ patientDiagnosis: list });
    }
    updateAfterSave(visitId, results)
     {
     if(results.length > 0)
        {
        this.setState({results: results})
        }
      else
        {
            axios.get(localStorage.getItem('host') + '/patientmanagement/nursetrimmed/' + visitId, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, vitalColors: res.data.vitalColors});
                }, err => {
                    this.setState({ error: err + "" })
                }
            )
        }  
     }
     setLoader(loader)
       {
        this.setState({loader: loader})
       }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (<div id="optomAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info" id="topDiv">Optom Action</div>
            <div className="card-body" >
                {divMsg}
                {loaderDiv}
                <div id="genericPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">{this.state.popupName}</div>
                        <div className="card-body" >
                            <span dangerouslySetInnerHTML={{ __html: this.state.htmlText }}></span>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.genericClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="treatmentRecord" class="page-popup page-popup-overlay"><div class="card" >
                    <div class="card-header   bg-info">Treatment Record</div>
                    <div class="card-body" >
                        {this.state.treatmentRecords.map((item, index) => (
                            <div class="row" key={index} >
                                <div class="col-xs-12 col-md-12 col-lg-12">
                                    <div class="card border-success">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col"><span className="fw-bold">Date:</span> {item.treatmentDateTime}</div>
                                                <div className="col"><span className="fw-bold"></span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table table-striped" >
                                        <tr>
                                            <th>Drug</th>
                                            <th>Dosage</th>
                                            <th>Frequency</th>
                                            <th>Route</th>
                                            <th>Duration</th>
                                            <th>Formulation</th>
                                            <th>Repeats</th>
                                        </tr>
                                        <tbody>
                                            {item.treatments.map((treatment, i) => (
                                                <tr key={i} >
                                                    <td>{treatment.drugName}</td>
                                                    <td>{treatment.dosage}</td>
                                                    <td>{treatment.frequencyName}</td>
                                                    <td>{treatment.routeName}</td>
                                                    <td> {treatment.durationName}</td>
                                                    <td>{treatment.formulationName}</td>
                                                    <td>{treatment.repeats}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div class="row">
                        <div class="col" align="center" ><input class="btn btn-primary" type='button' onClick={() => this.treatmentClosePopup()} value="Close" /></div>
                    </div>
                </div>
                </div>
                <div id="showFileNurseAction" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                            <iframe title="File View" src={'data:application/pdf;base64,' + this.state.fileData} type="application/pdf" style={{ width: 100 + '%', height: 500 + 'px' }}></iframe>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {this.state.id > 0 && (<div ><span className="link" onClick={() => this.props.getPrintOut(this.state.visitId, 'OPTOM', [], this.state.companyId)} title="Print" ><i className="bi bi-printer-fill" style={{ fontSize: 3 + 'em' }}></i></span></div>)}
                        {this.state.allergies && (<div className="card card-red">
                            <div className="card-header text-dark">
                                Allergies
                            </div>
                            <div className="card-body">
                                <span>{this.state.allergies}</span>
                            </div>
                        </div>)}
                        {this.state.id > 0 && (<div className="card card-green">
                            <div className="card-header text-dark">Current</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewHistory(this.state.visitId)} >Current</span></li>
                                </ul>
                            </div>
                        </div>)}
                        <div className="card card-green">
                            <div className="card-header  text-dark">Consolidated</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewConsolidated(this.state.visitId)}>Consolidated</span></li>
                                </ul>
                            </div>
                        </div>
                        {this.state.historyVisits.length > 0 && (<div className="card card-green" >
                            <div className="card-header  text-dark">History</div>
                            <div className="card-body"> 
                                <ul>
                                    {this.state.historyVisits.map((item, index) => (
                                        <li key={index}><span className="link" onClick={() => this.viewHistory(item.id)} >{item.name}</span></li>
                                    ))}
                                </ul>
                            </div>
                        </div>)}
                        <div className="card  card-green" >
                            <div className="card-header  text-dark">Other Info</div>
                            <div className="card-body">
                                <ul>
                                   <li><span className="link" onClick={() => this.showTreatmentRecord(this.state.patientId)}>View Treatment Records</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Notes</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('NOTES', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.notes.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Notes</div>
                            <div className="card-body">
                                <table >
                                    {this.state.notes.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'NOTES')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">Bill:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" align="right"><button onClick={() => this.save()} className="btn btn-primary">Save</button></div>
                            <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                              </div>
                        <br />
                        <ul className="nav nav-tabs" role="tablist">
                           <li className="nav-item"><span className={this.state.history ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("history") }}>History</span></li>
                            <li className="nav-item"><span className={this.state.examinationT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("examinationT") }}>Examination</span></li>
                           <li className="nav-item"><span className={this.state.treatment ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("treatment") }}>Treatment</span></li>
                            <li className="nav-item"><span className={this.state.referral ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("referral") }}>Referral</span></li>
                            <li className="nav-item"><span className={this.state.medicalCertificate ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("medicalCertificate") }}>Medical Certificate</span></li>
                            <li className="nav-item"><span className={this.state.review ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("review") }}>Review</span></li>
                            <li className="nav-item"><span className={this.state.management ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("management") }}>Management</span></li>
                            <li className="nav-item"><span className={this.state.optomPrescriptionT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("optomPrescriptionT") }}>Optom Prescription</span></li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                        <div className={this.state.history ? "tab-pane fade show active" : "tab-pane fade"} >
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        History
                                    </div>
                                    <div className="card-body">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item"><span className={this.state.presentingComplaintsH ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("presentingComplaintsH") }}>PRESENTING COMPLAINTS</span></li>
                                            <li className="nav-item"><span className={this.state.pastOcularHistoryH ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("pastOcularHistoryH") }}>PAST OCULAR HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.pastMedicalHistoryH ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("pastMedicalHistoryH") }}>PAST MEDICAL HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.currentMedicationH ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("currentMedicationH") }}>CURRENT MEDICATION</span></li>
                                            <li className="nav-item"><span className={this.state.allergyHistoryH ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("allergyHistoryH") }}>ALLERGY HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.familyHistoryH ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("familyHistoryH") }}>FAMILY HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.socialHistoryH ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("socialHistoryH") }}>SOCIAL HISTORY</span></li>
                                        </ul>
                                        <br />
                                        <div className="tab-content">
                                            <div className={this.state.presentingComplaintsH ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.presentingComplaints} onChange={e => { this.setState({ presentingComplaints: e.target.value }) }} rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.pastOcularHistoryH ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.pastOcularHistory} onChange={e => { this.setState({ pastOcularHistory: e.target.value }) }}  rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.pastMedicalHistoryH ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.pastMedicalHistory} onChange={e => { this.setState({ pastMedicalHistory: e.target.value }) }}  rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.currentMedicationH ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div ><div className="input-group">
                                                        <textarea className="form-control" value={this.state.currentMedication} onChange={e => { this.setState({ currentMedication: e.target.value }) }}  rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.allergyHistoryH ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.allergyHistory} onChange={e => { this.setState({ allergyHistory: e.target.value }) }}  rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.familyHistoryH ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.familyHistory} onChange={e => { this.setState({ familyHistory: e.target.value }) }}  rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.socialHistoryH ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.socialHistory} onChange={e => { this.setState({ socialHistory: e.target.value }) }}  rows="10"></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={this.state.examinationT ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Physical and General Examination
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col" align="right"><div className="input-group"><span className="input-group-text">Examination </span><textarea value={this.state.examination} onChange={e => this.setState({ examination: e.target.value })} className="form-control" rows="5" ></textarea></div></div>
                                        </div>
                                    <table class="table table-striped">
        <tr>
            <th colspan="3" align="center">Right</th>
            <td></td>
            <th colspan="3"  align="center">Left</th>            
        </tr>
        <tbody>
        <tr>
            <td colspan="3" ><input type="text" class="form-control" value={this.state.uncorrectedDistanceVisualAcuityRight} onChange={e => { this.setState({ uncorrectedDistanceVisualAcuityRight: e.target.value }) }} /></td>
            <th>Uncorrected Distance Visual Acuity</th>
            <td colspan="3" ><input type="text" class="form-control" value={this.state.uncorrectedDistanceVisualAcuityLeft} onChange={e => { this.setState({ uncorrectedDistanceVisualAcuityLeft: e.target.value }) }} /></td>
        </tr>
        <tr>
            <td colspan="3" ><input type="text" class="form-control" value={this.state.nearVisualAcuityRight} onChange={e => { this.setState({ nearVisualAcuityRight: e.target.value }) }} /></td>
            <th>Near Visual Acuity</th>
            <td colspan="3" ><input type="text" class="form-control" value={this.state.nearVisualAcuityLeft} onChange={e => { this.setState({ nearVisualAcuityLeft: e.target.value }) }} /></td>
        </tr>
        <tr>
            <td colspan="3" ><input type="text" class="form-control" value={this.state.aidedVisualAcuityRight} onChange={e => { this.setState({ aidedVisualAcuityRight: e.target.value }) }} /></td>
            <th>Aided Visual Acuity</th>
            <td colspan="3" ><input type="text" class="form-control" value={this.state.aidedVisualAcuityLeft} onChange={e => { this.setState({ aidedVisualAcuityLeft: e.target.value }) }} /></td>
        </tr>
        <tr>
            <td><input type="text" class="form-control" value={this.state.refractionRight} onChange={e => { this.setState({ refractionRight: e.target.value }) }} /></td>
            <th>Add</th>
            <td><input type="text" class="form-control" value={this.state.refractionAddRight} onChange={e => { this.setState({ refractionAddRight: e.target.value }) }} /></td>
            <th>Refraction</th>
            <td><input type="text" class="form-control" value={this.state.refractionLeft} onChange={e => { this.setState({ refractionLeft: e.target.value }) }} /></td>
            <th>Add</th>
            <td><input type="text" class="form-control" value={this.state.refractionAddLeft} onChange={e => { this.setState({ refractionAddLeft: e.target.value }) }} /></td>
        </tr>  
        </tbody>      
    </table>   
                                        <div className="row">
                                            <div className="col" align="right"><div className="input-group"><span className="input-group-text">Fundi </span><textarea value={this.state.fundi} onChange={e => this.setState({ fundi: e.target.value })} className="form-control" rows="5" ></textarea></div></div>
                                        </div>
                                        <br />
                                    </div></div>
                            </div>
                            <div className={this.state.management ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Management</div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Detail</span>
                                                <textarea className="form-control" value={this.state.managementData} onChange={e => this.setState({ managementData: e.target.value })} ></textarea></div></div>
                                        </div>
                                    </div></div>
                            </div>
                            <div className={this.state.review ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Review</div>
                                    <div className="card-body">
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Date</th>
                                                <th>Comments</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.reviews.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><DatePicker className="form-control" selected={new Date(item.reviewDate)} onChange={d => { this.setReview(index, "reviewDate", this.processDate(d)) }} dateFormat="yyyy-MM-dd" /></td>
                                                        <td><textarea className="form-control" value={item.remarks} onChange={e => { this.setReview(index, 'remarks', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReview(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        <button onClick={() => this.addReviewTable()} className="btn btn-primary" >Insert New Review Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.medicalCertificate ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Medical Certificate</div>
                                    <div className="card-body">
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.medicalCertificates.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><select className="form-control" value={item.name} onChange={e => { this.setMedicalCertificate(index, 'name', e.target.value); }} >
                                                            <option value="">Choose Type</option>
                                                            {this.state.medicalCertificateTypes.map((m, index) => (
                                                                <option key={index} value={m} >{m}</option>
                                                            ))}
                                                        </select></td>
                                                        <td><DatePicker className="form-control" selected={new Date(item.startDate)} onChange={d => { this.setMedicalCertificate(index, "startDate", this.processDate(d)) }} dateFormat="yyyy-MM-dd" /></td>
                                                        <td><DatePicker className="form-control" selected={new Date(item.endDate)} onChange={d => { this.setMedicalCertificate(index, "endDate", this.processDate(d)) }} dateFormat="yyyy-MM-dd" /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteMedicalCertificate(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        <button onClick={() => this.addMedicalCertificateTable()} className="btn btn-primary" >Insert New Medical Certificate Line</button>
                                    </div></div>
                            </div>
                             <div className={this.state.referral ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Referral</div>
                                    <div className="card-body">
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Remarks</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.referrals.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><input type="text" className="form-control" value={item.consultant} onChange={e => { this.setReferral(index, 'consultant', e.target.value); }} /></td>
                                                        <td><textarea className="form-control" value={item.referralNotes} onChange={e => { this.setReferral(index, 'referralNotes', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReferral(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        <button onClick={() => this.addReferralTable()} className="btn btn-primary" >Insert New Referral Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.treatment ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Treatment</div>
                                    <div className="card-body">
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Drug</th>
                                                <th>Dosage</th>
                                                <th>Frequency</th>
                                                <th>Route</th>
                                                <th>Duration</th>
                                                <th>Formulation</th>
                                                <th>Repeats</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.treatments.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><input type="text" list={'drugList' + index} className="form-control" value={item.drugName} onChange={e => { this.setTreatment(index, 'drugName', e.target.value); }} />
                                                            <datalist id={'drugList' + index}>
                                                                {this.state.drugs.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" className="form-control" value={item.dosage} onChange={e => { this.setTreatment(index, 'dosage', e.target.value); }} /></td>
                                                        <td><input type="text" list={'frequencyList' + index} className="form-control" value={item.frequencyName} onChange={e => { this.setTreatment(index, 'frequencyName', e.target.value); }} />
                                                            <datalist id={'frequencyList' + index}>
                                                                {this.state.frequencys.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'routeList' + index} className="form-control" value={item.routeName} onChange={e => { this.setTreatment(index, 'routeName', e.target.value); }} />
                                                            <datalist id={'routeList' + index}>
                                                                {this.state.routes.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'durationList' + index} className="form-control" value={item.durationName} onChange={e => { this.setTreatment(index, 'durationName', e.target.value); }} />
                                                            <datalist id={'durationList' + index}>
                                                                {this.state.durations.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'formulationList' + index} className="form-control" value={item.formulationName} onChange={e => { this.setTreatment(index, 'formulationName', e.target.value); }} />
                                                            <datalist id={'formulationList' + index}>
                                                                {this.state.formulations.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" className="form-control" value={item.repeats} onChange={e => { this.setTreatment(index, 'repeats', e.target.value); }} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteTreatment(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        <button onClick={() => this.addTreatmentTable()} className="btn btn-primary" >Insert New Treatment Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.optomPrescriptionT ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Optom Prescription
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-striped">
                                    <tr>
            <td></td>
            <th>SPH</th>
            <th>CYL</th>
            <th>AXIS</th>
            <th>ADD</th>
        </tr>        
        <tbody>
        <tr>
            <th>R</th>
            <td><input type="text" class="form-control" value={this.state.sphRight} onChange={e => this.setState({ sphRight: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.cylRight} onChange={e => this.setState({ cylRight: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.axisRight} onChange={e => this.setState({ axisRight: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.addRight} onChange={e => this.setState({ addRight: e.target.value })} /></td>
        </tr>
        <tr>
            <th>L</th>
            <td><input type="text" class="form-control" value={this.state.sphLeft} onChange={e => this.setState({ sphLeft: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.cylLeft} onChange={e => this.setState({ cylLeft: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.axisLeft} onChange={e => this.setState({ axisLeft: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.addLeft} onChange={e => this.setState({ addLeft: e.target.value })} /></td>
        </tr>
        <tr>
            <td></td>
            <th>Prisms</th>
            <th>Segments</th>
            <td></td>
            <td></td>
        </tr>       
        <tr>
            <th>R</th>
            <td><input type="text" class="form-control" value={this.state.prismsRight} onChange={e => this.setState({ prismsRight: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.segmentsRight} onChange={e => this.setState({ segmentsRight: e.target.value })} /></td>  
            <th>Tint</th>   
            <td><input type="text" class="form-control" value={this.state.tint} onChange={e => this.setState({ tint: e.target.value })} /></td>  
        </tr> 
        <tr>
            <th>L</th>
            <td><input type="text" class="form-control" value={this.state.prismsLeft} onChange={e => this.setState({ prismsLeft: e.target.value })} /></td>
            <td><input type="text" class="form-control" value={this.state.segmentsLeft} onChange={e => this.setState({ segmentsLeft: e.target.value })} /></td>   
            <th>Bifocal Type</th>
            <td><input type="text" class="form-control" value={this.state.bifocalType} onChange={e => this.setState({ bifocalType: e.target.value })} /></td>   
        </tr> 
        <tr>
            <td>Optical Centres D</td>
            <td colspan="4"><input type="text" class="form-control" value={this.state.opticalCentresD} onChange={e => this.setState({ opticalCentresD: e.target.value })} /></td>
        </tr>
        <tr>
            <td>Special Instructions</td>
            <td colspan="4"><textarea class="form-control" value={this.state.specialInstructions} onChange={e => this.setState({ specialInstructions: e.target.value })}></textarea></td>
        </tr>
        </tbody>
    </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div >
                            <div className="card card-blue">
                                <div className="card-header  text-dark">
                                    Diagnosis
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-12 col-lg-12">
                                            <a href="https://icd.who.int/browse10/2019/en" target="_blank" rel="noreferrer">Check Online</a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col"><div className="input-group">
                                            <span className="input-group-text">Diagnosis Text</span><textarea className="form-control" value={this.state.diagnosisText} onChange={e => { this.setState({ diagnosisText: e.target.value }) }} ></textarea> </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col"><div className="input-group">
                                            <input type="text" value={this.state.searchDiagnosis} onChange={e => { this.setState({ searchDiagnosis: e.target.value }) }} className="form-control" placeholder="Search ICD10 Diagnosis Here" />
                                            <button className="btn btn-success" onClick={() => this.getDiagnosisList()} ><i class="bi bi-search"></i></button>
                                        </div>
                                        </div>
                                        </div>
                                        { this.state.diagnosisList.length > 0 &&(<div className="row">
                                        <div className="col"><div className="input-group"> 
                                        <select class="form-select" size={this.state.diagnosisList.length}  onChange={e => this.setState({ diagnosis: e.target.value })} aria-label="size 3 select example">
                                        <option value="">Choose Diagnosis</option>
                                       {this.state.diagnosisList.map((item, index) =>(  
  <option key={index} value={item.code}>{item.code} - {item.description}</option>
  ))}
</select><button onClick={() => this.insertDiagnosis()} className="btn btn-success" ><i class="bi bi-plus-circle-fill"></i></button></div></div>
                                    </div> )}
                                    <table className="table">
                                        <tr>
                                            <th>Description</th>
                                            <th>Active Status</th>
                                        </tr>
                                        {this.state.patientDiagnosis.map((item, index) =>  (item.activeStatus === 1 &&(
                                            <tr>
                                                <td>{item.description}</td>
                                                <td><span className="link-red" onClick={() => this.deleteDiagnosis(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                            </tr>)))}
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
             
               
            </div >
        </div >
        </div >)

    }
}      