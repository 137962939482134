import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class ViewCollected extends Component {
 
    state = {
       companyId: localStorage.getItem('companyId'), list: [], clusterCompanys: [], collected: "", available: 0, dispenseItemId: 0
         
    }

    getList(dispenseItemId, available) {
        this.props.setLoader(true)
        this.setState({ list: [], msg: '' , available: available, dispenseItemId: dispenseItemId});
        axios.get(localStorage.getItem('host') + '/sell/getcollecteditems/'+dispenseItemId,  {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    close() {
        document.getElementById('viewCollectedAction').style = 'display:none'
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    saveCollected()
      {
        var data = { available: this.state.available - this.state.collected, collected: this.state.collected, dispenseItemId: this.state.dispenseItemId };
        axios.post(localStorage.getItem('host') + '/sell/savecollected', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.getList(this.state.dispenseItemId ,(this.state.available - this.state.collected))
                this.setState({ msg: res.data })
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        ) 
      }
      cancel(id, dispenseItemId, collected, available)
       {
        axios.get(localStorage.getItem('host') + '/sell/deletecollected/'+id+'/'+dispenseItemId+'/'+collected,  {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.getList(dispenseItemId ,(available + collected))
                this.setState({ msg: res.data })
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
       } 
    render() {
        return (
            <div id="viewCollectedAction" className="page-popup page-popup-overlay"   >
                <div className="card" >
                    <div className="card-header bg-info">Collected List</div>
                    <div className="card-body" >
                        { this.state.available > 0 && (
                             <div className="row">
                             <div className="col-lg-12">
                                 <div className="card border-primary">
                                     <div className="card-body">
                                         <div className="row">
                                             <div className="col"><div className="input-group"><span className="input-group-text">Available </span> <input type="text" className="form-control" value={this.state.available} /></div></div>
                                             <div className="col"><div className="input-group"><span className="input-group-text">Collected</span><input type="text" className="form-control" value={this.state.collected} onChange={(e) => { var value = Number(e.target.value)  <= this.state.available? e.target.value: "";   this.setState({ collected: value }) }} /></div></div>
                                              <div className="col">   { this.state.collected > 0 && ( <button className="btn btn-success" onClick={() => this.saveCollected()} >Save</button>)}</div>
                                         </div></div></div>
                             </div>
                         </div>
                        )}
                        <table className="table table-striped" >
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Collected</th>
                                                    <th>Available</th>
                                                    <th>Action</th>
                                                 </tr><tbody>
                                                {this.state.list.map((l, index) =>  (
                                                    <tr key={index}>
                                                        <td>{l.collectedDate}</td>
                                                        <td>{l.collected}</td>
                                                        <td>{l.available}</td>
                                                        <td><span className="link-red" onClick={() => this.cancel(l.id, l.dispenseItemId, l.collected, l.available)} title="Cancel Visit"><i className="bi bi-x-square-fill"></i></span></td>
                                                        </tr>
                                                ))}
                                                </tbody>
                                            </table> 
                        <div className="row">
                            <div className="col-lg-12" align="center"><button onClick={() => this.close()} className="btn btn-primary">Close Window (Esc)</button></div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}      