import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());

function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}

export default class QuotationList extends Component {
    
    state = { id: 0, patientName: '',  startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD , reason: '', companyId: localStorage.getItem('companyId'), list: [], clusterCompanys: [], patientId: 0, reloadPatientList: false, duplicateCount: 0 , msg: ''}
    
    getQuotationListLoad(msg)
    {
        document.getElementById('quotationListAction').style = 'display:block'
        axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/'+localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({clusterCompanys: res.data, msg:msg});
                this.getQuotationList()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    getQuotationList() {
        this.props.setLoader(true)
        var data = { patientName: this.state.patientName, dateRange: this.state.startDate+","+this.state.endDate, companyId: this.state.companyId }
        axios.post(localStorage.getItem('host') + '/patient/quotationlist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    close() {
        document.getElementById('quotationListAction').style = 'display:none'
    } // v-bind:style="'height: '+heightQuotationList+'px;max-height: '+heightQuotationList+'px;overflow: auto;overflow-x: hidden;'"
    openQuotationAction(quotationId, patientId) {
        this.props.openQuotationAction(quotationId, patientId);
    }
    cancelQuotation(number, quotationId) {
        this.setState({number: number, id: quotationId})
        document.getElementById('cancelQuotationAction').style = 'display:block' 
    }
    sendCancelQuotation() {
        axios.get(localStorage.getItem('host') + '/patient/cancelquotation/' + this.state.id+'?reason='+this.state.reason, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ msg: res.data });
                this.props.setLoader(false)
                this.closeCancelPopup()
                this.getQuotationList()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    closeCancelPopup() {
        document.getElementById('cancelQuotationAction').style = 'display:none'
    } 
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div id="quotationListAction" className="page-popup page-popup-overlay"   >
                <div className="card" >
                    <div className="card-header  bg-info">Quotation List</div>
                    <div className="card-body" >
                {divMsg}
                    <div id="cancelQuotationAction" className="page-popup page-popup-overlay" >
                        <div className="card" >
                            <div className="card-header bg-info">Cancel Action</div>
                            <div className="card-body" >
                            <p>Are you sure you want to cancel  {this.state.number}?</p> <div className="row">
                                            <div className="col"> <div className="input-group"><span className="input-group-text">Reason</span>
                                                            <textarea className="form-control" value={this.state.reason} onChange={e => { this.setState({ reason: e.target.value }) }} ></textarea></div> </div>       
                                            </div>  
                                <div className="row">
                                <div className="col" align="center" >{ this.state.reason !== '' && this.state.reason.length > 4 &&(<button onClick={() => this.sendCancelQuotation()} className="btn btn-primary">Yes</button>)}</div> 
                                <div className="col" align="center" ><button onClick={() => this.closeCancelPopup()} className="btn btn-primary">No</button></div> 
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Patient Name </span> <input type="text" className="form-control" onChange={(e) => this.setState({ patientName: e.target.value })} /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">End Date</span> <DatePicker className="form-control" selected={new Date(this.state.endDate)}  minDate={new Date(this.state.startDate)} maxDate={new Date()}  onChange={d => { this.setState({ endDate: this.processDate(d) }); } } dateFormat="yyyy-MM-dd" /></div></div>
                                              <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" onChange={(e) => this.setState({ companyId: e.target.value })}  >
                                                {this.state.clusterCompanys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                            <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getQuotationList()} value="Search" /></div>
                                        </div></div></div>
                            </div>
                        </div>
                        <div>
                            <table className="table table-striped table-hover">
                                <tr>
                                    <th>Action</th>
                                    <th>Number</th>
                                    <th>Patient Name</th>
                                    <th>Consultant</th>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Total Amount</th>
                                </tr>
                                <tbody>
                                {this.state.list.map((quotation, index) => (
                                    <tr key={index} style={{backgroundColor:quotation.color}}>
                                      <td >
                                            <span className="link" onClick={() => this.props.getPrintOut(quotation.id, 'QUOTATION', [], quotation.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>
                                            <span className="link-red" onClick={() => this.cancelQuotation(quotation.number, quotation.id)} title="Cancel Visit"><i className="bi bi-x-square-fill"></i></span>
                                                </td>
                                        <td>{quotation.number}</td>
                                        <td>{quotation.patientName}</td>
                                        <td>{quotation.consultantName}</td>
                                        <td>{quotation.billType}</td>
                                        <td>{quotation.treatmentDateTime}</td>
                                        <td>{this.currencyFormat(quotation.totalPayable)}</td>
                                       </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col-lg-12" align="center"><button onClick={() => this.close()} className="btn btn-primary">Close Window (Esc)</button></div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}      