import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}

export default class NeurologyAction extends Component {
    state = {
        loader: false,htmlText: '', id: 0, referringDoctorName: '', companyId: 0, patientId: 0, patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], rehabilitations: [], referrals: [], treatments: [], drugs: [], frequencys: [], routes: [], durations: [], formulations: [], services: [], investigations: [], findings: [], dateLists: [], referralLetters: [],appointmentTimes: [],
        feedbackDoctor: '', feedbackHistory: '', feedbackExamination: '', feedbackReviewConsult: '', feedbackInvestigations: '', feedbackDiagnosis: '', feedbackPrescriptions: '', 
        fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: 0.0, height: 0.0, waist: 0.0, headCircumference: 0.0, muac: 0, bmi: 0.0, waistHeightRatio: 0.0, nurseNotes: '', vitalColors: [], notes: [], oldPatient: '', planData: '', chronicData: '', historyVisits: [], visitId: 0, msg: '', error: '', diagnosis: '',
        familyHistory: '', socialHistory: '', systemicReview: '', presentingComplaintList: [], historyOfPresentingComplaints: '', pastMedicalHistory: '', currentMedication: '', allergyHistory: '',
        coughChoice: '', coughText: '', coryzaChoice: '', coryzaText: '', sobChoice: '', sobText: '', sneazingChoice: '', sneazingText: '', phelgmChoice: '', phelgmText: '', sputumChoice: '', sputumText: '', haemoptysisChoice: '', haemoptysisText: '', wheezingChoice: '', wheezingText: '', nasalChoice: '', nasalText: '', soreChoice: '', soreText: '', chestPainChoice: '', chestPainText: '', respitoryNotes: '',
        nauseaChoice: '', nauseaText: '', vomitingChoice: '', vomitingText: '', diarrhoeaChoice: '', diarrhoeaText: '', abdominialChoice: '', abdominialText: '', flatulanceChoice: '', flatulanceText: '', flatulentChoice: '', flatulentText: '', waterChoice: '', waterText: '', constipationChoice: '', constipationText: '', prChoice: '', prText: '', wtChoice: '', wtText: '', melenaChoice: '', melenaText: '', jaundiceChoice: '', jaundiceText: '', loaChoice: '', loaText: '', lowChoice: '', lowText: '', gitNotes: '',
        dysuriaChoice: '', dysuriaText: '', frequencyChoice: '', frequencyText: '', haematuriaChoice: '', haematuriaText: '', weakChoice: '', weakText: '', strainingChoice: '', strainingText: '', urineChoice: '', urineText: '', urethalChoice: '', urethalText: '', pvChoice: '', pvText: '', nocturiaChoice: '', nocturiaText: '', genitalChoice: '', genitalText: '', lossChoice: '', lossText: '', erectileChoice: '', erectileText: '', gusNotes: '',
        dysaruniaChoice: '', dysaruniaText: '', dysmemorrhoeaChoice: '', dysmemorrhoeaText: '', imbChoice: '', imbText: '', contactChoice: '', contactText: '', menorrhagiaChoice: '', menorrhagiaText: '', lapChoice: '', lapText: '', backacheChoice: '', backackeText: '', subfertilityChoice: '', subfertilityText: '', hotChoice: '', hotText: '', lnmpChoice: '', lnmpText: '', gynaeNotes: '',
        orthopneaChoice: '', orthopneaText: '', pndChoice: '', pndText: '', easyChoice: '', easyText: '', palpitationsChoice: '', palpitationsText: '', synacopeChoice: '', synacopeText: '', legSwellingChoice: '', legSwellingText: '', chestPaincvsChoice: '', chestPaincvsText: '', calfSwellingChoice: '', calfSwellingText: '', pinkFrothChoice: '', pinkFrothText: '', gelatinousChoice: '', gelatinousText: '', cvsNotes: '',
        headacheChoice: '', headacheText: '', paraesthesiaChoice: '', paraesthesiaText: '', dizzinessChoice: '', dizzinessText: '', backackeChoice: '', backacheText: '', photophibiaChoice: '', photophibiaText: '', neckstiffnessChoice: '', neckstiffnessText: '', fittingChoice: '', fittingText: '', paresisChoice: '', paresisText: '', paralysisChoice: '', paralysisText: '', insomniaChoice: '', insomniaText: '', hypersomniaChoice: '', hypersomniaText: '', abnChoice: '', abnText: '', cnsNotes: '',
        feverChoice: '', feverText: '', sweatingChoice: '', sweatingText: '', chillsChoice: '', chillsText: '', rigorsChoice: '', rigorsText: '', fatigueChoice: '', fatigueText: '', myalgiaChoice: '', myalgiaText: '', constitutionalNotes: '',
        bleedingChoice: '', bleedingText: '', bruisingChoice: '', bruisingText: '', swellingChoice: '', swellingText: '', deformityChoice: '', deformityText: '', mssNotes: '',
        rashChoice: '', rashText: '', itchynessChoice: '', itchynessText: '', blistersChoice: '', blistersText: '', lumpsChoice: '', lumpsText: '', ulcersChoice: '', ulcersText: '', swellingdemaChoice: '', swellingdemaText: '', dematologyNotes: '',
        /* Extra History */ 
        painText: '', durationText: '', timingText: '', aggravatedByText: '', relievedByText: '', headacheLeftChoice: '', headacheLeftText: '', headacheRightChoice: '', headacheRightText: '', holocranialChoice: '', holocranialText: '', frontalChoice: '', frontalText: '', temporalChoice: '', temporalText: '', occipitalChoice: '', occipitalText: '', periorbitalChoice: '', periorbitalText: '', confusionChoice: '', confusionText: '', 
        changeinCharacterChoice: '', changeinCharacterText: '', weaknessChoice: '', weaknessText: '', hemiparesisLeftChoice: '', hemiparesisLeftText: '', hemiparesisRightChoice: '', hemiparesisRightText: '', weaknessHemiplegiaChoice: '', weaknessHemiplegiaText: '', hemiplegiaLeftChoice: '', hemiplegiaLeftText: '', hemiplegiaRightChoice: '', hemiplegiaRightText: '', paraparesisChoice: '', paraparesisText: '', paraplegiaChoice: '', paraplegiaText: '', quadriparesisChoice: '', quadriparesisText: '', 
        quadriplegiaChoice: '', quadriplegiaText: '', monoparesisChoice: '', monoparesisText: '', monoparesisLeftChoice: '', monoparesisLeftText: '', monoparesisRightChoice: '', monoparesisRightText: '', monoparesisUpperLimbChoice: '', monoparesisUpperLimbText: '', monoparesisLowerLimbChoice: '', monoparesisLowerLimbText: '', seizuresChoice: '', seizuresText: '', seizuresAuraChoice: '', seizuresAuraText: '', auraVisualChoice: '', auraVisualText: '', auraOlfactoryChoice: '', auraOlfactoryText: '', auraAutonomicChoice: '', 
        auraAutonomicText: '', thoracicChoice: '', thoracicText: '', lumbarChoice: '', lumbarText: '', lowerBackacheChoice: '', lowerBackacheText: '', mechanicalChoice: '', mechanicalText: '', sciaticaChoice: '', sciaticaText: '', sciaticaLeftChoice: '', sciaticaLeftText: '', sciaticaRightChoice: '', sciaticaRightText: '', cervicalPainChoice: '', cervicalPainText: '', interScapularPainChoice: '', interScapularPainText: '', paraesthesiaUpperLimbChoice: '', 
        paraesthesiaUpperLimbText: '', paraesthesiaLowerLimbChoice: '', paraesthesiaLowerLimbText: '', lossOfBalanceChoice: '', lossOfBalanceText: '', neurogenicClaudicationChoice: '', neurogenicClaudicationText: '', radiculapathyChoice: '', radiculapathyText: '', radiculapathyLeftChoice: '', radiculapathyLeftText: '', radiculapathyLeftC2Choice: '', radiculapathyLeftC2Text: '', radiculapathyLeftC3Choice: '', radiculapathyLeftC3Text: '', radiculapathyLeftC4Choice: '', radiculapathyLeftC4Text: '', radiculapathyLeftC5Choice: '', 
        radiculapathyLeftC5Text: '', radiculapathyLeftC6Choice: '', radiculapathyLeftC6Text: '', radiculapathyLeftC7Choice: '', radiculapathyLeftC7Text: '', radiculapathyLeftC8Choice: '', radiculapathyLeftC8Text: '', radiculapathyLeftT1Choice: '', radiculapathyLeftT1Text: '', radiculapathyLeftT2Choice: '', radiculapathyLeftT2Text: '', radiculapathyLeftT3Choice: '', radiculapathyLeftT3Text: '', radiculapathyLeftT4Choice: '', radiculapathyLeftT4Text: '', radiculapathyLeftT5Choice: '', radiculapathyLeftT5Text: '', radiculapathyLeftT6Choice: '', 
        radiculapathyLeftT6Text: '', radiculapathyLeftT7Choice: '', radiculapathyLeftT7Text: '', radiculapathyLeftT8Choice: '', radiculapathyLeftT8Text: '', radiculapathyLeftT9Choice: '', radiculapathyLeftT9Text: '', radiculapathyLeftT10Choice: '', radiculapathyLeftT10Text: '', radiculapathyLeftT11Choice: '', radiculapathyLeftT11Text: '', radiculapathyLeftT12Choice: '', radiculapathyLeftT12Text: '', radiculapathyLeftL1Choice: '', radiculapathyLeftL1Text: '', radiculapathyLeftL2Choice: '', radiculapathyLeftL2Text: '', radiculapathyLeftL3Choice: '', 
        radiculapathyLeftL3Text: '', radiculapathyLeftL4Choice: '', radiculapathyLeftL4Text: '', radiculapathyLeftL5Choice: '', radiculapathyLeftL5Text: '', radiculapathyLeftS1Choice: '', radiculapathyLeftS1Text: '', radiculapathyLeftS3Choice: '', radiculapathyLeftS3Text: '', radiculapathyLeftS45Choice: '', radiculapathyLeftS45Text: '', radiculapathyRightChoice: '', radiculapathyRightText: '', radiculapathyRightC2Choice: '', radiculapathyRightC2Text: '', radiculapathyRightC3Choice: '', radiculapathyRightC3Text: '', radiculapathyRightC4Choice: '', 
        radiculapathyRightC4Text: '', radiculapathyRightC5Choice: '', radiculapathyRightC5Text: '', radiculapathyRightC6Choice: '', radiculapathyRightC6Text: '', radiculapathyRightC7Choice: '', radiculapathyRightC7Text: '', radiculapathyRightC8Choice: '', radiculapathyRightC8Text: '', radiculapathyRightT1Choice: '', radiculapathyRightT1Text: '', radiculapathyRightT2Choice: '', radiculapathyRightT2Text: '', radiculapathyRightT3Choice: '', radiculapathyRightT3Text: '', radiculapathyRightT4Choice: '', radiculapathyRightT4Text: '', 
        radiculapathyRightT5Choice: '', radiculapathyRightT5Text: '', radiculapathyRightT6Choice: '', radiculapathyRightT6Text: '', radiculapathyRightT7Choice: '', radiculapathyRightT7Text: '', radiculapathyRightT8Choice: '', radiculapathyRightT8Text: '', radiculapathyRightT9Choice: '', radiculapathyRightT9Text: '', radiculapathyRightT10Choice: '', radiculapathyRightT10Text: '', radiculapathyRightT11Choice: '', radiculapathyRightT11Text: '', radiculapathyRightT12Choice: '', radiculapathyRightT12Text: '', radiculapathyRightL1Choice: '', 
        radiculapathyRightL1Text: '', radiculapathyRightL2Choice: '', radiculapathyRightL2Text: '', radiculapathyRightL3Choice: '', radiculapathyRightL3Text: '', radiculapathyRightL4Choice: '', radiculapathyRightL4Text: '', radiculapathyRightL5Choice: '', radiculapathyRightL5Text: '', radiculapathyRightS1Choice: '', radiculapathyRightS1Text: '', radiculapathyRightS3Choice: '', radiculapathyRightS3Text: '', radiculapathyRightS45Choice: '', radiculapathyRightS45Text: '', sleep: '',
        /*EXAMINATION */
        generalNotes: '', cvsExam: '', respExam: '', abdomenExam: '', skinExam: '', jointsExam: '', bodyMassIndexExam: '', meningismExam: '', higherFunctionsExam: '', spineExam: '', cranialNerve1Right: '', 
        cranialNerve1Left: '', cranialNerve2Right: '', cranialNerve2Left: '', cranialNerve3Right: '', cranialNerve3Left: '', cranialNerve5Right: '', cranialNerve5Left: '', cranialNerve7Right: '', cranialNerve7Left: '', cranialNerve8Right: '', cranialNerve8Left: '', cranialNerve9Right: '', cranialNerve9Left: '', cranialNerve10Right: '', cranialNerve10Left: '', cranialNerve11Right: '', cranialNerve11Left: '', cranialNerve12Right: '', cranialNerve12Left: '',shapeRight: '', shapeLeft: '', sizeRight: '', sizeLeft: '', pupillaryRight: '', pupillaryLeft: '', consensualReflexRight: '', 
        consensualReflexLeft: '', rapdRight: '', rapdLeft: '', funduscopyRight: '', funduscopyLeft: '', inspectionExam: '', involuntaryExam: '', toneExam: '', rigidityExam: '', bradykinesiaExam: '', hoffmanRight: '', hoffmanLeft: '', bicepsJerkRight: '', bicepsJerkLeft: '', brachioradialisJerkRight: '', brachioradialisJerkLeft: '', tricepsJerkRight: '', tricepsJerkLeft: '', kneeJerkRight: '', 
        kneeJerkLeft: '', ankleJerkRight: '', ankleJerkLeft: '', plantarResponseRight: '', plantarResponseLeft: '', abdominalReflexesRight: '', abdominalReflexesLeft: '', otherReflexesRight: '', otherReflexesLeft: '', shoulderAbductionRight: '', shoulderAbductionLeft: '', shoulderAdductionRight: '', shoulderAdductionLeft: '', elbowFlexionRight: '', elbowFlexionLeft: '', elbowExtensionRight: '', elbowExtensionLeft: '', wristFlexionRight: '', wristFlexionLeft: '', wristExtensionRight: '', wristExtensionLeft: '', fingerFlexionRight: '', fingerFlexionLeft: '', fingerExtensionRight: '', fingerExtensionLeft: '', 
        fingerAbductionRight: '', fingerAbductionLeft: '', hipFlexionRight: '', hipFlexionLeft: '', hipExtensionRight: '', hipExtensionLeft: '', hipAbductionRight: '', hipAbductionLeft: '', kneeFlexionRight: '', kneeFlexionLeft: '', kneeExtensionRight: '', kneeExtensionLeft: '', ankleDorsiflexionRight: '', ankleDorsiflexionLeft: '', anklePlantarFlexionRight: '', anklePlantarFlexionLeft: '', ankleEversionRight: '', ankleEversionLeft: '', ankleInversionRight: '', ankleInversionLeft: '', toeFlexionRight: '', toeFlexionLeft: '', toeExtensionRight: '', toeExtensionLeft: '', fineTouchExam: '', pinPrickExam: '', jointPositionSenseExam: '', sensoryLossPatternExam: '', rhombergTestExam: '', nystagmusExam: '', speechExam: '', rapidAlternatingMovementsExam: '', fingerToNoseTestExam: '', heelKneeShinTestExam: '', tandemGaitExam: '', gaitExam: '', otherExamFindings: '', assessmentExam: '', planExam: '',
        
        hBA1C: '', cholesterol: '', otherName: '', otherValue: '', treatmentRecords: [], results: [],
        historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: true, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false ,
        diagnosisTab: false, history: true, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: false
    }
    componentDidMount() {

    }
    openNeurologyAction(visitId, msg) {
        if(msg === '')
           {
            this.setLoader(true) 
           }
        this.setState({
            htmlText: '', id: 0, referringDoctorName: '', companyId: 0, patientId: 0, patientDiagnosis: [], diagnosisList: [], searchDiagnosis: '', diagnosisText: '', reviews: [], medicalCertificateTypes: [], medicalCertificates: [], rehabilitations: [], referrals: [], treatments: [], drugs: [], frequencys: [], routes: [], durations: [], formulations: [], services: [], investigations: [], findings: [], dateLists:[], referralLetters: [],appointmentTimes: [],
            feedbackHistory: '', feedbackExamination: '', feedbackReviewConsult: '', feedbackInvestigations: '', feedbackDiagnosis: '', feedbackPrescriptions: '', feedbackDoctor:'', 
            fullname: '', number: '', mobile: '', gender: '', idNumber: '', dateOfBirth: '', age: '', weight: 0.0, height: 0.0, waist: 0.0, headCircumference: 0.0, muac: 0, bmi: 0.0, waistHeightRatio: 0.0, nurseNotes: '', vitalColors: [], notes: [], oldPatient: '', planData: '', chronicData: '', historyVisits: [], visitId: visitId, msg: '', error: '',
            familyHistory: '', socialHistory: '', systemicReview: '', presentingComplaintList: [], historyOfPresentingComplaints: '', pastMedicalHistory: '', currentMedication: '', allergyHistory: '',
            coughChoice: '', coughText: '', coryzaChoice: '', coryzaText: '', sobChoice: '', sobText: '', sneazingChoice: '', sneazingText: '', phelgmChoice: '', phelgmText: '', sputumChoice: '', sputumText: '', haemoptysisChoice: '', haemoptysisText: '', wheezingChoice: '', wheezingText: '', nasalChoice: '', nasalText: '', soreChoice: '', soreText: '', chestPainChoice: '', chestPainText: '', respitoryNotes: '',
            nauseaChoice: '', nauseaText: '', vomitingChoice: '', vomitingText: '', diarrhoeaChoice: '', diarrhoeaText: '', abdominialChoice: '', abdominialText: '', flatulanceChoice: '', flatulanceText: '', flatulentChoice: '', flatulentText: '', waterChoice: '', waterText: '', constipationChoice: '', constipationText: '', prChoice: '', prText: '', wtChoice: '', wtText: '', melenaChoice: '', melenaText: '', jaundiceChoice: '', jaundiceText: '', loaChoice: '', loaText: '', lowChoice: '', lowText: '', gitNotes: '',
            dysuriaChoice: '', dysuriaText: '', frequencyChoice: '', frequencyText: '', haematuriaChoice: '', haematuriaText: '', weakChoice: '', weakText: '', strainingChoice: '', strainingText: '', urineChoice: '', urineText: '', urethalChoice: '', urethalText: '', pvChoice: '', pvText: '', nocturiaChoice: '', nocturiaText: '', genitalChoice: '', genitalText: '', lossChoice: '', lossText: '', erectileChoice: '', erectileText: '', gusNotes: '',
            dysaruniaChoice: '', dysaruniaText: '', dysmemorrhoeaChoice: '', dysmemorrhoeaText: '', imbChoice: '', imbText: '', contactChoice: '', contactText: '', menorrhagiaChoice: '', menorrhagiaText: '', lapChoice: '', lapText: '', backacheChoice: '', backackeText: '', subfertilityChoice: '', subfertilityText: '', hotChoice: '', hotText: '', lnmpChoice: '', lnmpText: '', gynaeNotes: '',
            orthopneaChoice: '', orthopneaText: '', pndChoice: '', pndText: '', easyChoice: '', easyText: '', palpitationsChoice: '', palpitationsText: '', synacopeChoice: '', synacopeText: '', legSwellingChoice: '', legSwellingText: '', chestPaincvsChoice: '', chestPaincvsText: '', calfSwellingChoice: '', calfSwellingText: '', pinkFrothChoice: '', pinkFrothText: '', gelatinousChoice: '', gelatinousText: '', cvsNotes: '',
            headacheChoice: '', headacheText: '', paraesthesiaChoice: '', paraesthesiaText: '', dizzinessChoice: '', dizzinessText: '', backackeChoice: '', backacheText: '', photophibiaChoice: '', photophibiaText: '', neckstiffnessChoice: '', neckstiffnessText: '', fittingChoice: '', fittingText: '', paresisChoice: '', paresisText: '', paralysisChoice: '', paralysisText: '', insomniaChoice: '', insomniaText: '', hypersomniaChoice: '', hypersomniaText: '', abnChoice: '', abnText: '', cnsNotes: '',
            feverChoice: '', feverText: '', sweatingChoice: '', sweatingText: '', chillsChoice: '', chillsText: '', rigorsChoice: '', rigorsText: '', fatigueChoice: '', fatigueText: '', myalgiaChoice: '', myalgiaText: '', constitutionalNotes: '',
            bleedingChoice: '', bleedingText: '', bruisingChoice: '', bruisingText: '', swellingChoice: '', swellingText: '', deformityChoice: '', deformityText: '', mssNotes: '',
            rashChoice: '', rashText: '', itchynessChoice: '', itchynessText: '', blistersChoice: '', blistersText: '', lumpsChoice: '', lumpsText: '', ulcersChoice: '', ulcersText: '', swellingdemaChoice: '', swellingdemaText: '', dematologyNotes: '',
           /* Extra History */ 
        painText: '', durationText: '', timingText: '', aggravatedByText: '', relievedByText: '', headacheLeftChoice: '', headacheLeftText: '', headacheRightChoice: '', headacheRightText: '', holocranialChoice: '', holocranialText: '', frontalChoice: '', frontalText: '', temporalChoice: '', temporalText: '', occipitalChoice: '', occipitalText: '', periorbitalChoice: '', periorbitalText: '', confusionChoice: '', confusionText: '', 
        changeinCharacterChoice: '', changeinCharacterText: '', weaknessChoice: '', weaknessText: '', hemiparesisLeftChoice: '', hemiparesisLeftText: '', hemiparesisRightChoice: '', hemiparesisRightText: '', weaknessHemiplegiaChoice: '', weaknessHemiplegiaText: '', hemiplegiaLeftChoice: '', hemiplegiaLeftText: '', hemiplegiaRightChoice: '', hemiplegiaRightText: '', paraparesisChoice: '', paraparesisText: '', paraplegiaChoice: '', paraplegiaText: '', quadriparesisChoice: '', quadriparesisText: '', 
        quadriplegiaChoice: '', quadriplegiaText: '', monoparesisChoice: '', monoparesisText: '', monoparesisLeftChoice: '', monoparesisLeftText: '', monoparesisRightChoice: '', monoparesisRightText: '', monoparesisUpperLimbChoice: '', monoparesisUpperLimbText: '', monoparesisLowerLimbChoice: '', monoparesisLowerLimbText: '', seizuresChoice: '', seizuresText: '', seizuresAuraChoice: '', seizuresAuraText: '', auraVisualChoice: '', auraVisualText: '', auraOlfactoryChoice: '', auraOlfactoryText: '', auraAutonomicChoice: '', 
        auraAutonomicText: '', thoracicChoice: '', thoracicText: '', lumbarChoice: '', lumbarText: '', lowerBackacheChoice: '', lowerBackacheText: '', mechanicalChoice: '', mechanicalText: '', sciaticaChoice: '', sciaticaText: '', sciaticaLeftChoice: '', sciaticaLeftText: '', sciaticaRightChoice: '', sciaticaRightText: '', cervicalPainChoice: '', cervicalPainText: '', interScapularPainChoice: '', interScapularPainText: '', paraesthesiaUpperLimbChoice: '', 
        paraesthesiaUpperLimbText: '', paraesthesiaLowerLimbChoice: '', paraesthesiaLowerLimbText: '', lossOfBalanceChoice: '', lossOfBalanceText: '', neurogenicClaudicationChoice: '', neurogenicClaudicationText: '', radiculapathyChoice: '', radiculapathyText: '', radiculapathyLeftChoice: '', radiculapathyLeftText: '', radiculapathyLeftC2Choice: '', radiculapathyLeftC2Text: '', radiculapathyLeftC3Choice: '', radiculapathyLeftC3Text: '', radiculapathyLeftC4Choice: '', radiculapathyLeftC4Text: '', radiculapathyLeftC5Choice: '', 
        radiculapathyLeftC5Text: '', radiculapathyLeftC6Choice: '', radiculapathyLeftC6Text: '', radiculapathyLeftC7Choice: '', radiculapathyLeftC7Text: '', radiculapathyLeftC8Choice: '', radiculapathyLeftC8Text: '', radiculapathyLeftT1Choice: '', radiculapathyLeftT1Text: '', radiculapathyLeftT2Choice: '', radiculapathyLeftT2Text: '', radiculapathyLeftT3Choice: '', radiculapathyLeftT3Text: '', radiculapathyLeftT4Choice: '', radiculapathyLeftT4Text: '', radiculapathyLeftT5Choice: '', radiculapathyLeftT5Text: '', radiculapathyLeftT6Choice: '', 
        radiculapathyLeftT6Text: '', radiculapathyLeftT7Choice: '', radiculapathyLeftT7Text: '', radiculapathyLeftT8Choice: '', radiculapathyLeftT8Text: '', radiculapathyLeftT9Choice: '', radiculapathyLeftT9Text: '', radiculapathyLeftT10Choice: '', radiculapathyLeftT10Text: '', radiculapathyLeftT11Choice: '', radiculapathyLeftT11Text: '', radiculapathyLeftT12Choice: '', radiculapathyLeftT12Text: '', radiculapathyLeftL1Choice: '', radiculapathyLeftL1Text: '', radiculapathyLeftL2Choice: '', radiculapathyLeftL2Text: '', radiculapathyLeftL3Choice: '', 
        radiculapathyLeftL3Text: '', radiculapathyLeftL4Choice: '', radiculapathyLeftL4Text: '', radiculapathyLeftL5Choice: '', radiculapathyLeftL5Text: '', radiculapathyLeftS1Choice: '', radiculapathyLeftS1Text: '', radiculapathyLeftS3Choice: '', radiculapathyLeftS3Text: '', radiculapathyLeftS45Choice: '', radiculapathyLeftS45Text: '', radiculapathyRightChoice: '', radiculapathyRightText: '', radiculapathyRightC2Choice: '', radiculapathyRightC2Text: '', radiculapathyRightC3Choice: '', radiculapathyRightC3Text: '', radiculapathyRightC4Choice: '', 
        radiculapathyRightC4Text: '', radiculapathyRightC5Choice: '', radiculapathyRightC5Text: '', radiculapathyRightC6Choice: '', radiculapathyRightC6Text: '', radiculapathyRightC7Choice: '', radiculapathyRightC7Text: '', radiculapathyRightC8Choice: '', radiculapathyRightC8Text: '', radiculapathyRightT1Choice: '', radiculapathyRightT1Text: '', radiculapathyRightT2Choice: '', radiculapathyRightT2Text: '', radiculapathyRightT3Choice: '', radiculapathyRightT3Text: '', radiculapathyRightT4Choice: '', radiculapathyRightT4Text: '', 
        radiculapathyRightT5Choice: '', radiculapathyRightT5Text: '', radiculapathyRightT6Choice: '', radiculapathyRightT6Text: '', radiculapathyRightT7Choice: '', radiculapathyRightT7Text: '', radiculapathyRightT8Choice: '', radiculapathyRightT8Text: '', radiculapathyRightT9Choice: '', radiculapathyRightT9Text: '', radiculapathyRightT10Choice: '', radiculapathyRightT10Text: '', radiculapathyRightT11Choice: '', radiculapathyRightT11Text: '', radiculapathyRightT12Choice: '', radiculapathyRightT12Text: '', radiculapathyRightL1Choice: '', sleep: '',
        radiculapathyRightL1Text: '', radiculapathyRightL2Choice: '', radiculapathyRightL2Text: '', radiculapathyRightL3Choice: '', radiculapathyRightL3Text: '', radiculapathyRightL4Choice: '', radiculapathyRightL4Text: '', radiculapathyRightL5Choice: '', radiculapathyRightL5Text: '', radiculapathyRightS1Choice: '', radiculapathyRightS1Text: '', radiculapathyRightS3Choice: '', radiculapathyRightS3Text: '', radiculapathyRightS45Choice: '', radiculapathyRightS45Text: '',
        /*EXAMINATION */
        generalNotes: '', cvsExam: '', respExam: '', abdomenExam: '', skinExam: '', jointsExam: '', bodyMassIndexExam: '', meningismExam: '', higherFunctionsExam: '', spineExam: '', cranialNerve1Right: '', 
        cranialNerve1Left: '', cranialNerve2Right: '', cranialNerve2Left: '', cranialNerve3Right: '', cranialNerve3Left: '', cranialNerve5Right: '', cranialNerve5Left: '', cranialNerve7Right: '', cranialNerve7Left: '', cranialNerve8Right: '', cranialNerve8Left: '', cranialNerve9Right: '', cranialNerve9Left: '', cranialNerve10Right: '', cranialNerve10Left: '', cranialNerve11Right: '', cranialNerve11Left: '', cranialNerve12Right: '', cranialNerve12Left: '',shapeRight: '', shapeLeft: '', sizeRight: '', sizeLeft: '', pupillaryRight: '', pupillaryLeft: '', consensualReflexRight: '', 
        consensualReflexLeft: '', rapdRight: '', rapdLeft: '', funduscopyRight: '', funduscopyLeft: '', inspectionExam: '', involuntaryExam: '', toneExam: '', rigidityExam: '', bradykinesiaExam: '', hoffmanRight: '', hoffmanLeft: '', bicepsJerkRight: '', bicepsJerkLeft: '', brachioradialisJerkRight: '', brachioradialisJerkLeft: '', tricepsJerkRight: '', tricepsJerkLeft: '', kneeJerkRight: '', 
        kneeJerkLeft: '', ankleJerkRight: '', ankleJerkLeft: '', plantarResponseRight: '', plantarResponseLeft: '', abdominalReflexesRight: '', abdominalReflexesLeft: '', otherReflexesRight: '', otherReflexesLeft: '', shoulderAbductionRight: '', shoulderAbductionLeft: '', shoulderAdductionRight: '', shoulderAdductionLeft: '', elbowFlexionRight: '', elbowFlexionLeft: '', elbowExtensionRight: '', elbowExtensionLeft: '', wristFlexionRight: '', wristFlexionLeft: '', wristExtensionRight: '', wristExtensionLeft: '', fingerFlexionRight: '', fingerFlexionLeft: '', fingerExtensionRight: '', fingerExtensionLeft: '', 
        fingerAbductionRight: '', fingerAbductionLeft: '', hipFlexionRight: '', hipFlexionLeft: '', hipExtensionRight: '', hipExtensionLeft: '', hipAbductionRight: '', hipAbductionLeft: '', kneeFlexionRight: '', kneeFlexionLeft: '', kneeExtensionRight: '', kneeExtensionLeft: '', ankleDorsiflexionRight: '', ankleDorsiflexionLeft: '', anklePlantarFlexionRight: '', anklePlantarFlexionLeft: '', ankleEversionRight: '', ankleEversionLeft: '', ankleInversionRight: '', ankleInversionLeft: '', toeFlexionRight: '', toeFlexionLeft: '', toeExtensionRight: '', toeExtensionLeft: '', fineTouchExam: '', pinPrickExam: '', jointPositionSenseExam: '', sensoryLossPatternExam: '', rhombergTestExam: '', nystagmusExam: '', speechExam: '', rapidAlternatingMovementsExam: '', fingerToNoseTestExam: '', heelKneeShinTestExam: '', tandemGaitExam: '', gaitExam: '', otherExamFindings: '', assessmentExam: '', planExam: '',
       
        hBA1C: '', cholesterol: '', otherName: '', otherValue: '', treatmentRecords: [], results: [],
            historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: true, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false ,
            diagnosisTab: false, history: true, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: false
        })
        axios.get(localStorage.getItem('host') + '/patientmanagement/neurology/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for(var j in res.data.vitalColors)
                    {
                    res.data.vitalColors[j].temperature = res.data.vitalColors[j].temperature === 0?"":res.data.vitalColors[j].temperature
                    res.data.vitalColors[j].pulse = res.data.vitalColors[j].pulse === 0?"":res.data.vitalColors[j].pulse
                    res.data.vitalColors[j].glucometer = res.data.vitalColors[j].glucometer === 0?"":res.data.vitalColors[j].glucometer
                    res.data.vitalColors[j].spO2 = res.data.vitalColors[j].spO2 === 0?"":res.data.vitalColors[j].spO2
                    res.data.vitalColors[j].diastolic = res.data.vitalColors[j].diastolic === 0?"":res.data.vitalColors[j].diastolic
                    res.data.vitalColors[j].systolic = res.data.vitalColors[j].systolic === 0?"":res.data.vitalColors[j].systolic
                    }
                    res.data.weight = res.data.weight === 0?"":res.data.weight
                    res.data.height = res.data.height === 0?"":res.data.height
                    res.data.headCircumference = res.data.headCircumference === 0?"":res.data.headCircumference
                    res.data.muac = res.data.muac === 0?"":res.data.muac
                    res.data.bmi = res.data.bmi === 0?"":res.data.bmi
                    res.data.waistHeightRatio = res.data.waistHeightRatio === 0?"":res.data.waistHeightRatio
                this.setState({
                    showHeightGraph: res.data.showHeightGraph, showWeightGraph: res.data.showWeightGraph, id: res.data.id, referringDoctorName: res.data.referringDoctorName, companyId: res.data.companyId, patientId: res.data.patientId, patientDiagnosis: res.data.patientDiagnosis, diagnosisText: res.data.diagnosisText, reviews: res.data.reviews, medicalCertificateTypes: res.data.medicalCertificateTypes, medicalCertificates: res.data.medicalCertificates, rehabilitations: res.data.rehabilitations, referrals: res.data.referrals, treatments: res.data.treatments, drugs: res.data.drugs, frequencys: res.data.frequencys, routes: res.data.routes, durations: res.data.durations, formulations: res.data.formulations, services: res.data.services, investigations: res.data.investigations, findings: res.data.findings, referralLetters: res.data.referralLetters, msg: msg,appointmentTimes: res.data.appointmentTimes,
                    
            /* HISTORY  */  presentingComplaintList: res.data.history.presentingComplaintList, historyOfPresentingComplaints: res.data.history.historyOfPresentingComplaints, pastMedicalHistory: res.data.history.pastMedicalHistory, currentMedication: res.data.history.currentMedication, allergyHistory: res.data.history.allergyHistory,
                    coughChoice: res.data.history.coughChoice, coughText: res.data.history.coughText, coryzaChoice: res.data.history.coryzaChoice, coryzaText: res.data.history.coryzaText, sobChoice: res.data.history.sobChoice, sobText: res.data.history.sobText, sneazingChoice: res.data.history.sneazingChoice, sneazingText: res.data.history.sneazingText, phelgmChoice: res.data.history.phelgmChoice, phelgmText: res.data.history.phelgmText, sputumChoice: res.data.history.sputumChoice, sputumText: res.data.history.sputumText, haemoptysisChoice: res.data.history.haemoptysisChoice, haemoptysisText: res.data.history.haemoptysisText, wheezingChoice: res.data.history.wheezingChoice, wheezingText: res.data.history.wheezingText, nasalChoice: res.data.history.nasalChoice, nasalText: res.data.history.nasalText, soreChoice: res.data.history.soreChoice, soreText: res.data.history.soreText, chestPainChoice: res.data.history.chestPainChoice, chestPainText: res.data.history.chestPainText, respitoryNotes: res.data.history.respitoryNotes,
                    nauseaChoice: res.data.history.nauseaChoice, nauseaText: res.data.history.nauseaText, vomitingChoice: res.data.history.vomitingChoice, vomitingText: res.data.history.vomitingText, diarrhoeaChoice: res.data.history.diarrhoeaChoice, diarrhoeaText: res.data.history.diarrhoeaText, abdominialChoice: res.data.history.abdominialChoice, abdominialText: res.data.history.abdominialText, flatulanceChoice: res.data.history.flatulanceChoice, flatulanceText: res.data.history.flatulanceText, flatulentChoice: res.data.history.flatulentChoice, flatulentText: res.data.history.flatulentText, waterChoice: res.data.history.waterChoice, waterText: res.data.history.waterText, constipationChoice: res.data.history.constipationChoice, constipationText: res.data.history.constipationText, prChoice: res.data.history.prChoice, prText: res.data.history.prText, wtChoice: res.data.history.wtChoice, wtText: res.data.history.wtText, melenaChoice: res.data.history.melenaChoice, melenaText: res.data.history.melenaText, jaundiceChoice: res.data.history.jaundiceChoice, jaundiceText: res.data.history.jaundiceText, loaChoice: res.data.history.loaChoice, loaText: res.data.history.loaText, lowChoice: res.data.history.lowChoice, lowText: res.data.history.lowText, gitNotes: res.data.history.gitNotes,
                    dysuriaChoice: res.data.history.dysuriaChoice, dysuriaText: res.data.history.dysuriaText, frequencyChoice: res.data.history.frequencyChoice, frequencyText: res.data.history.frequencyText, haematuriaChoice: res.data.history.haematuriaChoice, haematuriaText: res.data.history.haematuriaText, weakChoice: res.data.history.weakChoice, weakText: res.data.history.weakText, strainingChoice: res.data.history.strainingChoice, strainingText: res.data.history.strainingText, urineChoice: res.data.history.urineChoice, urineText: res.data.history.urineText, urethalChoice: res.data.history.urethalChoice, urethalText: res.data.history.urethalText, pvChoice: res.data.history.pvChoice, pvText: res.data.history.pvText, nocturiaChoice: res.data.history.nocturiaChoice, nocturiaText: res.data.history.nocturiaText, genitalChoice: res.data.history.genitalChoice, genitalText: res.data.history.genitalText, lossChoice: res.data.history.lossChoice, lossText: res.data.history.lossText, erectileChoice: res.data.history.erectileChoice, erectileText: res.data.history.erectileText, gusNotes: res.data.history.gusNotes,
                    dysaruniaChoice: res.data.history.dysaruniaChoice, dysaruniaText: res.data.history.dysaruniaText, dysmemorrhoeaChoice: res.data.history.dysmemorrhoeaChoice, dysmemorrhoeaText: res.data.history.dysmemorrhoeaText, imbChoice: res.data.history.imbChoice, imbText: res.data.history.imbText, contactChoice: res.data.history.contactChoice, contactText: res.data.history.contactText, menorrhagiaChoice: res.data.history.menorrhagiaChoice, menorrhagiaText: res.data.history.menorrhagiaText, lapChoice: res.data.history.lapChoice, lapText: res.data.history.lapText, backacheChoice: res.data.history.backacheChoice, backackeText: res.data.history.backackeText, subfertilityChoice: res.data.history.subfertilityChoice, subfertilityText: res.data.history.subfertilityText, hotChoice: res.data.history.hotChoice, hotText: res.data.history.hotText, lnmpChoice: res.data.history.lnmpChoice, lnmpText: res.data.history.lnmpText, gynaeNotes: res.data.history.gynaeNotes,
                    orthopneaChoice: res.data.history.orthopneaChoice, orthopneaText: res.data.history.orthopneaText, pndChoice: res.data.history.pndChoice, pndText: res.data.history.pndText, easyChoice: res.data.history.easyChoice, easyText: res.data.history.easyText, palpitationsChoice: res.data.history.palpitationsChoice, palpitationsText: res.data.history.palpitationsText, synacopeChoice: res.data.history.synacopeChoice, synacopeText: res.data.history.synacopeText, legSwellingChoice: res.data.history.legSwellingChoice, legSwellingText: res.data.history.legSwellingText, chestPaincvsChoice: res.data.history.chestPaincvsChoice, chestPaincvsText: res.data.history.chestPaincvsText, calfSwellingChoice: res.data.history.calfSwellingChoice, calfSwellingText: res.data.history.calfSwellingText, pinkFrothChoice: res.data.history.pinkFrothChoice, pinkFrothText: res.data.history.pinkFrothText, gelatinousChoice: res.data.history.gelatinousChoice, gelatinousText: res.data.history.gelatinousText, cvsNotes: res.data.history.cvsNotes,
                    headacheChoice: res.data.history.headacheChoice, headacheText: res.data.history.headacheText, paraesthesiaChoice: res.data.history.paraesthesiaChoice, paraesthesiaText: res.data.history.paraesthesiaText, dizzinessChoice: res.data.history.dizzinessChoice, dizzinessText: res.data.history.dizzinessText, backackeChoice: res.data.history.backackeChoice, backacheText: res.data.history.backacheText, photophibiaChoice: res.data.history.photophibiaChoice, photophibiaText: res.data.history.photophibiaText, neckstiffnessChoice: res.data.history.neckstiffnessChoice, neckstiffnessText: res.data.history.neckstiffnessText, fittingChoice: res.data.history.fittingChoice, fittingText: res.data.history.fittingText, paresisChoice: res.data.history.paresisChoice, paresisText: res.data.history.paresisText, paralysisChoice: res.data.history.paralysisChoice, paralysisText: res.data.history.paralysisText, insomniaChoice: res.data.history.insomniaChoice, insomniaText: res.data.history.insomniaText, hypersomniaChoice: res.data.history.hypersomniaChoice, hypersomniaText: res.data.history.hypersomniaText, abnChoice: res.data.history.abnChoice, abnText: res.data.history.abnText, cnsNotes: res.data.history.cnsNotes,
                    feverChoice: res.data.history.feverChoice, feverText: res.data.history.feverText, sweatingChoice: res.data.history.sweatingChoice, sweatingText: res.data.history.sweatingText, chillsChoice: res.data.history.chillsChoice, chillsText: res.data.history.chillsText, rigorsChoice: res.data.history.rigorsChoice, rigorsText: res.data.history.rigorsText, fatigueChoice: res.data.history.fatigueChoice, fatigueText: res.data.history.fatigueText, myalgiaChoice: res.data.history.myalgiaChoice, myalgiaText: res.data.history.myalgiaText, constitutionalNotes: res.data.history.constitutionalNotes,
                    bleedingChoice: res.data.history.bleedingChoice, bleedingText: res.data.history.bleedingText, bruisingChoice: res.data.history.bruisingChoice, bruisingText: res.data.history.bruisingText, swellingChoice: res.data.history.swellingChoice, swellingText: res.data.history.swellingText, deformityChoice: res.data.history.deformityChoice, deformityText: res.data.history.deformityText, mssNotes: res.data.history.mssNotes,
                    rashChoice: res.data.history.rashChoice, rashText: res.data.history.rashText, itchynessChoice: res.data.history.itchynessChoice, itchynessText: res.data.history.itchynessText, blistersChoice: res.data.history.blistersChoice, blistersText: res.data.history.blistersText, lumpsChoice: res.data.history.lumpsChoice, lumpsText: res.data.history.lumpsText, ulcersChoice: res.data.history.ulcersChoice, ulcersText: res.data.history.ulcersText, swellingdemaChoice: res.data.history.swellingdemaChoice, swellingdemaText: res.data.history.swellingdemaText, dematologyNotes: res.data.history.dematologyNotes,sleep: res.data.history.sleep,
                     /* Extra History */ 
                    painText: res.data.history.painText, durationText: res.data.history.durationText, timingText: res.data.history.timingText, aggravatedByText: res.data.history.aggravatedByText, relievedByText: res.data.history.relievedByText, headacheLeftChoice: res.data.history.headacheLeftChoice, headacheLeftText: res.data.history.headacheLeftText, headacheRightChoice: res.data.history.headacheRightChoice, headacheRightText: res.data.history.headacheRightText, holocranialChoice: res.data.history.holocranialChoice, holocranialText: res.data.history.holocranialText, frontalChoice: res.data.history.frontalChoice, frontalText: res.data.history.frontalText, temporalChoice: res.data.history.temporalChoice, temporalText: res.data.history.temporalText, occipitalChoice: res.data.history.occipitalChoice, occipitalText: res.data.history.occipitalText, periorbitalChoice: res.data.history.periorbitalChoice, periorbitalText: res.data.history.periorbitalText, confusionChoice: res.data.history.confusionChoice, confusionText: res.data.history.confusionText, 
                    changeinCharacterChoice: res.data.history.changeinCharacterChoice, changeinCharacterText: res.data.history.changeinCharacterText, weaknessChoice: res.data.history.weaknessChoice, weaknessText: res.data.history.weaknessText, hemiparesisLeftChoice: res.data.history.hemiparesisLeftChoice, hemiparesisLeftText: res.data.history.hemiparesisLeftText, hemiparesisRightChoice: res.data.history.hemiparesisRightChoice, hemiparesisRightText: res.data.history.hemiparesisRightText, weaknessHemiplegiaChoice: res.data.history.weaknessHemiplegiaChoice, weaknessHemiplegiaText: res.data.history.weaknessHemiplegiaText, hemiplegiaLeftChoice: res.data.history.hemiplegiaLeftChoice, hemiplegiaLeftText: res.data.history.hemiplegiaLeftText, hemiplegiaRightChoice: res.data.history.hemiplegiaRightChoice, hemiplegiaRightText: res.data.history.hemiplegiaRightText, paraparesisChoice: res.data.history.paraparesisChoice, paraparesisText: res.data.history.paraparesisText, paraplegiaChoice: res.data.history.paraplegiaChoice, paraplegiaText: res.data.history.paraplegiaText, quadriparesisChoice: res.data.history.quadriparesisChoice, quadriparesisText: res.data.history.quadriparesisText, 
                    quadriplegiaChoice: res.data.history.quadriplegiaChoice, quadriplegiaText: res.data.history.quadriplegiaText, monoparesisChoice: res.data.history.monoparesisChoice, monoparesisText: res.data.history.monoparesisText, monoparesisLeftChoice: res.data.history.monoparesisLeftChoice, monoparesisLeftText: res.data.history.monoparesisLeftText, monoparesisRightChoice: res.data.history.monoparesisRightChoice, monoparesisRightText: res.data.history.monoparesisRightText, monoparesisUpperLimbChoice: res.data.history.monoparesisUpperLimbChoice, monoparesisUpperLimbText: res.data.history.monoparesisUpperLimbText, monoparesisLowerLimbChoice: res.data.history.monoparesisLowerLimbChoice, monoparesisLowerLimbText: res.data.history.monoparesisLowerLimbText, seizuresChoice: res.data.history.seizuresChoice, seizuresText: res.data.history.seizuresText, seizuresAuraChoice: res.data.history.seizuresAuraChoice, seizuresAuraText: res.data.history.seizuresAuraText, auraVisualChoice: res.data.history.auraVisualChoice, auraVisualText: res.data.history.auraVisualText, auraOlfactoryChoice: res.data.history.auraOlfactoryChoice, auraOlfactoryText: res.data.history.auraOlfactoryText, auraAutonomicChoice: res.data.history.auraAutonomicChoice, 
                    auraAutonomicText: res.data.history.auraAutonomicText, thoracicChoice: res.data.history.thoracicChoice, thoracicText: res.data.history.thoracicText, lumbarChoice: res.data.history.lumbarChoice, lumbarText: res.data.history.lumbarText, lowerBackacheChoice: res.data.history.lowerBackacheChoice, lowerBackacheText: res.data.history.lowerBackacheText, mechanicalChoice: res.data.history.mechanicalChoice, mechanicalText: res.data.history.mechanicalText, sciaticaChoice: res.data.history.sciaticaChoice, sciaticaText: res.data.history.sciaticaText, sciaticaLeftChoice: res.data.history.sciaticaLeftChoice, sciaticaLeftText: res.data.history.sciaticaLeftText, sciaticaRightChoice: res.data.history.sciaticaRightChoice, sciaticaRightText: res.data.history.sciaticaRightText, cervicalPainChoice: res.data.history.cervicalPainChoice, cervicalPainText: res.data.history.cervicalPainText, interScapularPainChoice: res.data.history.interScapularPainChoice, interScapularPainText: res.data.history.interScapularPainText, paraesthesiaUpperLimbChoice: res.data.history.paraesthesiaUpperLimbChoice, 
                    paraesthesiaUpperLimbText: res.data.history.paraesthesiaUpperLimbText, paraesthesiaLowerLimbChoice: res.data.history.paraesthesiaLowerLimbChoice, paraesthesiaLowerLimbText: res.data.history.paraesthesiaLowerLimbText, lossOfBalanceChoice: res.data.history.lossOfBalanceChoice, lossOfBalanceText: res.data.history.lossOfBalanceText, neurogenicClaudicationChoice: res.data.history.neurogenicClaudicationChoice, neurogenicClaudicationText: res.data.history.neurogenicClaudicationText, radiculapathyChoice: res.data.history.radiculapathyChoice, radiculapathyText: res.data.history.radiculapathyText, radiculapathyLeftChoice: res.data.history.radiculapathyLeftChoice, radiculapathyLeftText: res.data.history.radiculapathyLeftText, radiculapathyLeftC2Choice: res.data.history.radiculapathyLeftC2Choice, radiculapathyLeftC2Text: res.data.history.radiculapathyLeftC2Text, radiculapathyLeftC3Choice: res.data.history.radiculapathyLeftC3Choice, radiculapathyLeftC3Text: res.data.history.radiculapathyLeftC3Text, radiculapathyLeftC4Choice: res.data.history.radiculapathyLeftC4Choice, radiculapathyLeftC4Text: res.data.history.radiculapathyLeftC4Text, radiculapathyLeftC5Choice: res.data.history.radiculapathyLeftC5Choice, 
                    radiculapathyLeftC5Text: res.data.history.radiculapathyLeftC5Text, radiculapathyLeftC6Choice: res.data.history.radiculapathyLeftC6Choice, radiculapathyLeftC6Text: res.data.history.radiculapathyLeftC6Text, radiculapathyLeftC7Choice: res.data.history.radiculapathyLeftC7Choice, radiculapathyLeftC7Text: res.data.history.radiculapathyLeftC7Text, radiculapathyLeftC8Choice: res.data.history.radiculapathyLeftC8Choice, radiculapathyLeftC8Text: res.data.history.radiculapathyLeftC8Text, radiculapathyLeftT1Choice: res.data.history.radiculapathyLeftT1Choice, radiculapathyLeftT1Text: res.data.history.radiculapathyLeftT1Text, radiculapathyLeftT2Choice: res.data.history.radiculapathyLeftT2Choice, radiculapathyLeftT2Text: res.data.history.radiculapathyLeftT2Text, radiculapathyLeftT3Choice: res.data.history.radiculapathyLeftT3Choice, radiculapathyLeftT3Text: res.data.history.radiculapathyLeftT3Text, radiculapathyLeftT4Choice: res.data.history.radiculapathyLeftT4Choice, radiculapathyLeftT4Text: res.data.history.radiculapathyLeftT4Text, radiculapathyLeftT5Choice: res.data.history.radiculapathyLeftT5Choice, radiculapathyLeftT5Text: res.data.history.radiculapathyLeftT5Text, radiculapathyLeftT6Choice: res.data.history.radiculapathyLeftT6Choice, 
                    radiculapathyLeftT6Text: res.data.history.radiculapathyLeftT6Text, radiculapathyLeftT7Choice: res.data.history.radiculapathyLeftT7Choice, radiculapathyLeftT7Text: res.data.history.radiculapathyLeftT7Text, radiculapathyLeftT8Choice: res.data.history.radiculapathyLeftT8Choice, radiculapathyLeftT8Text: res.data.history.radiculapathyLeftT8Text, radiculapathyLeftT9Choice: res.data.history.radiculapathyLeftT9Choice, radiculapathyLeftT9Text: res.data.history.radiculapathyLeftT9Text, radiculapathyLeftT10Choice: res.data.history.radiculapathyLeftT10Choice, radiculapathyLeftT10Text: res.data.history.radiculapathyLeftT10Text, radiculapathyLeftT11Choice: res.data.history.radiculapathyLeftT11Choice, radiculapathyLeftT11Text: res.data.history.radiculapathyLeftT11Text, radiculapathyLeftT12Choice: res.data.history.radiculapathyLeftT12Choice, radiculapathyLeftT12Text: res.data.history.radiculapathyLeftT12Text, radiculapathyLeftL1Choice: res.data.history.radiculapathyLeftL1Choice, radiculapathyLeftL1Text: res.data.history.radiculapathyLeftL1Text, radiculapathyLeftL2Choice: res.data.history.radiculapathyLeftL2Choice, radiculapathyLeftL2Text: res.data.history.radiculapathyLeftL2Text, radiculapathyLeftL3Choice: res.data.history.radiculapathyLeftL3Choice, 
                    radiculapathyLeftL3Text: res.data.history.radiculapathyLeftL3Text, radiculapathyLeftL4Choice: res.data.history.radiculapathyLeftL4Choice, radiculapathyLeftL4Text: res.data.history.radiculapathyLeftL4Text, radiculapathyLeftL5Choice: res.data.history.radiculapathyLeftL5Choice, radiculapathyLeftL5Text: res.data.history.radiculapathyLeftL5Text, radiculapathyLeftS1Choice: res.data.history.radiculapathyLeftS1Choice, radiculapathyLeftS1Text: res.data.history.radiculapathyLeftS1Text, radiculapathyLeftS3Choice: res.data.history.radiculapathyLeftS3Choice, radiculapathyLeftS3Text: res.data.history.radiculapathyLeftS3Text, radiculapathyLeftS45Choice: res.data.history.radiculapathyLeftS45Choice, radiculapathyLeftS45Text: res.data.history.radiculapathyLeftS45Text, radiculapathyRightChoice: res.data.history.radiculapathyRightChoice, radiculapathyRightText: res.data.history.radiculapathyRightText, radiculapathyRightC2Choice: res.data.history.radiculapathyRightC2Choice, radiculapathyRightC2Text: res.data.history.radiculapathyRightC2Text, radiculapathyRightC3Choice: res.data.history.radiculapathyRightC3Choice, radiculapathyRightC3Text: res.data.history.radiculapathyRightC3Text, radiculapathyRightC4Choice: res.data.history.radiculapathyRightC4Choice, 
                    radiculapathyRightC4Text: res.data.history.radiculapathyRightC4Text, radiculapathyRightC5Choice: res.data.history.radiculapathyRightC5Choice, radiculapathyRightC5Text: res.data.history.radiculapathyRightC5Text, radiculapathyRightC6Choice: res.data.history.radiculapathyRightC6Choice, radiculapathyRightC6Text: res.data.history.radiculapathyRightC6Text, radiculapathyRightC7Choice: res.data.history.radiculapathyRightC7Choice, radiculapathyRightC7Text: res.data.history.radiculapathyRightC7Text, radiculapathyRightC8Choice: res.data.history.radiculapathyRightC8Choice, radiculapathyRightC8Text: res.data.history.radiculapathyRightC8Text, radiculapathyRightT1Choice: res.data.history.radiculapathyRightT1Choice, radiculapathyRightT1Text: res.data.history.radiculapathyRightT1Text, radiculapathyRightT2Choice: res.data.history.radiculapathyRightT2Choice, radiculapathyRightT2Text: res.data.history.radiculapathyRightT2Text, radiculapathyRightT3Choice: res.data.history.radiculapathyRightT3Choice, radiculapathyRightT3Text: res.data.history.radiculapathyRightT3Text, radiculapathyRightT4Choice: res.data.history.radiculapathyRightT4Choice, radiculapathyRightT4Text: res.data.history.radiculapathyRightT4Text, 
                    radiculapathyRightT5Choice: res.data.history.radiculapathyRightT5Choice, radiculapathyRightT5Text: res.data.history.radiculapathyRightT5Text, radiculapathyRightT6Choice: res.data.history.radiculapathyRightT6Choice, radiculapathyRightT6Text: res.data.history.radiculapathyRightT6Text, radiculapathyRightT7Choice: res.data.history.radiculapathyRightT7Choice, radiculapathyRightT7Text: res.data.history.radiculapathyRightT7Text, radiculapathyRightT8Choice: res.data.history.radiculapathyRightT8Choice, radiculapathyRightT8Text: res.data.history.radiculapathyRightT8Text, radiculapathyRightT9Choice: res.data.history.radiculapathyRightT9Choice, radiculapathyRightT9Text: res.data.history.radiculapathyRightT9Text, radiculapathyRightT10Choice: res.data.history.radiculapathyRightT10Choice, radiculapathyRightT10Text: res.data.history.radiculapathyRightT10Text, radiculapathyRightT11Choice: res.data.history.radiculapathyRightT11Choice, radiculapathyRightT11Text: res.data.history.radiculapathyRightT11Text, radiculapathyRightT12Choice: res.data.history.radiculapathyRightT12Choice, radiculapathyRightT12Text: res.data.history.radiculapathyRightT12Text, radiculapathyRightL1Choice: res.data.history.radiculapathyRightL1Choice, 
                    radiculapathyRightL1Text: res.data.history.radiculapathyRightL1Text, radiculapathyRightL2Choice: res.data.history.radiculapathyRightL2Choice, radiculapathyRightL2Text: res.data.history.radiculapathyRightL2Text, radiculapathyRightL3Choice: res.data.history.radiculapathyRightL3Choice, radiculapathyRightL3Text: res.data.history.radiculapathyRightL3Text, radiculapathyRightL4Choice: res.data.history.radiculapathyRightL4Choice, radiculapathyRightL4Text: res.data.history.radiculapathyRightL4Text, radiculapathyRightL5Choice: res.data.history.radiculapathyRightL5Choice, radiculapathyRightL5Text: res.data.history.radiculapathyRightL5Text, radiculapathyRightS1Choice: res.data.history.radiculapathyRightS1Choice, radiculapathyRightS1Text: res.data.history.radiculapathyRightS1Text, radiculapathyRightS3Choice: res.data.history.radiculapathyRightS3Choice, radiculapathyRightS3Text: res.data.history.radiculapathyRightS3Text, radiculapathyRightS45Choice: res.data.history.radiculapathyRightS45Choice, radiculapathyRightS45Text: res.data.history.radiculapathyRightS45Text,
                    /*EXAMINATION */
                    generalNotes: res.data.examination.generalNotes, cvsExam: res.data.examination.cvsExam, respExam: res.data.examination.respExam, abdomenExam: res.data.examination.abdomenExam, skinExam: res.data.examination.skinExam, jointsExam: res.data.examination.jointsExam, bodyMassIndexExam: res.data.examination.bodyMassIndexExam, meningismExam: res.data.examination.meningismExam, higherFunctionsExam: res.data.examination.higherFunctionsExam, spineExam: res.data.examination.spineExam, cranialNerve1Right: res.data.examination.cranialNerve1Right, 
                    cranialNerve1Left: res.data.examination.cranialNerve1Left, cranialNerve2Right: res.data.examination.cranialNerve2Right, cranialNerve2Left: res.data.examination.cranialNerve2Left, cranialNerve3Right: res.data.examination.cranialNerve3Right, cranialNerve3Left: res.data.examination.cranialNerve3Left, cranialNerve5Right: res.data.examination.cranialNerve5Right, cranialNerve5Left: res.data.examination.cranialNerve5Left, cranialNerve7Right: res.data.examination.cranialNerve7Right, cranialNerve7Left: res.data.examination.cranialNerve7Left, cranialNerve8Right: res.data.examination.cranialNerve8Right, cranialNerve8Left: res.data.examination.cranialNerve8Left, cranialNerve9Right: res.data.examination.cranialNerve9Right, cranialNerve9Left: res.data.examination.cranialNerve9Left, cranialNerve10Right: res.data.examination.cranialNerve10Right, cranialNerve10Left: res.data.examination.cranialNerve10Left, cranialNerve11Right: res.data.examination.cranialNerve11Right, cranialNerve11Left: res.data.examination.cranialNerve11Left, cranialNerve12Right: res.data.examination.cranialNerve12Right, cranialNerve12Left: res.data.examination.cranialNerve12Left,shapeRight: res.data.examination.shapeRight, shapeLeft: res.data.examination.shapeLeft, sizeRight: res.data.examination.sizeRight, sizeLeft: res.data.examination.sizeLeft, pupillaryRight: res.data.examination.pupillaryRight, pupillaryLeft: res.data.examination.pupillaryLeft, consensualReflexRight: res.data.examination.consensualReflexRight, 
                    consensualReflexLeft: res.data.examination.consensualReflexLeft, rapdRight: res.data.examination.rapdRight, rapdLeft: res.data.examination.rapdLeft, funduscopyRight: res.data.examination.funduscopyRight, funduscopyLeft: res.data.examination.funduscopyLeft, inspectionExam: res.data.examination.inspectionExam, involuntaryExam: res.data.examination.involuntaryExam, toneExam: res.data.examination.toneExam, rigidityExam: res.data.examination.rigidityExam, bradykinesiaExam: res.data.examination.bradykinesiaExam, hoffmanRight: res.data.examination.hoffmanRight, hoffmanLeft: res.data.examination.hoffmanLeft, bicepsJerkRight: res.data.examination.bicepsJerkRight, bicepsJerkLeft: res.data.examination.bicepsJerkLeft, brachioradialisJerkRight: res.data.examination.brachioradialisJerkRight, brachioradialisJerkLeft: res.data.examination.brachioradialisJerkLeft, tricepsJerkRight: res.data.examination.tricepsJerkRight, tricepsJerkLeft: res.data.examination.tricepsJerkLeft, kneeJerkRight: res.data.examination.kneeJerkRight, 
                    kneeJerkLeft: res.data.examination.kneeJerkLeft, ankleJerkRight: res.data.examination.ankleJerkRight, ankleJerkLeft: res.data.examination.ankleJerkLeft, plantarResponseRight: res.data.examination.plantarResponseRight, plantarResponseLeft: res.data.examination.plantarResponseLeft, abdominalReflexesRight: res.data.examination.abdominalReflexesRight, abdominalReflexesLeft: res.data.examination.abdominalReflexesLeft, otherReflexesRight: res.data.examination.otherReflexesRight, otherReflexesLeft: res.data.examination.otherReflexesLeft, shoulderAbductionRight: res.data.examination.shoulderAbductionRight, shoulderAbductionLeft: res.data.examination.shoulderAbductionLeft, shoulderAdductionRight: res.data.examination.shoulderAdductionRight, shoulderAdductionLeft: res.data.examination.shoulderAdductionLeft, elbowFlexionRight: res.data.examination.elbowFlexionRight, elbowFlexionLeft: res.data.examination.elbowFlexionLeft, elbowExtensionRight: res.data.examination.elbowExtensionRight, elbowExtensionLeft: res.data.examination.elbowExtensionLeft, wristFlexionRight: res.data.examination.wristFlexionRight, wristFlexionLeft: res.data.examination.wristFlexionLeft, wristExtensionRight: res.data.examination.wristExtensionRight, wristExtensionLeft: res.data.examination.wristExtensionLeft, fingerFlexionRight: res.data.examination.fingerFlexionRight, fingerFlexionLeft: res.data.examination.fingerFlexionLeft, fingerExtensionRight: res.data.examination.fingerExtensionRight, 
                    fingerExtensionLeft: res.data.examination.fingerExtensionLeft, fingerAbductionRight: res.data.examination.fingerAbductionRight, fingerAbductionLeft: res.data.examination.fingerAbductionLeft, hipFlexionRight: res.data.examination.hipFlexionRight, hipFlexionLeft: res.data.examination.hipFlexionLeft, hipExtensionRight: res.data.examination.hipExtensionRight, hipExtensionLeft: res.data.examination.hipExtensionLeft, hipAbductionRight: res.data.examination.hipAbductionRight, hipAbductionLeft: res.data.examination.hipAbductionLeft, kneeFlexionRight: res.data.examination.kneeFlexionRight, kneeFlexionLeft: res.data.examination.kneeFlexionLeft, kneeExtensionRight: res.data.examination.kneeExtensionRight, kneeExtensionLeft: res.data.examination.kneeExtensionLeft, ankleDorsiflexionRight: res.data.examination.ankleDorsiflexionRight, ankleDorsiflexionLeft: res.data.examination.ankleDorsiflexionLeft, anklePlantarFlexionRight: res.data.examination.anklePlantarFlexionRight, anklePlantarFlexionLeft: res.data.examination.anklePlantarFlexionLeft, ankleEversionRight: res.data.examination.ankleEversionRight, ankleEversionLeft: res.data.examination.ankleEversionLeft, ankleInversionRight: res.data.examination.ankleInversionRight, ankleInversionLeft: res.data.examination.ankleInversionLeft, toeFlexionRight: res.data.examination.toeFlexionRight, toeFlexionLeft: res.data.examination.toeFlexionLeft, toeExtensionRight: res.data.examination.toeExtensionRight, toeExtensionLeft: res.data.examination.toeExtensionLeft, 
                    fineTouchExam: res.data.examination.fineTouchExam, pinPrickExam: res.data.examination.pinPrickExam, jointPositionSenseExam: res.data.examination.jointPositionSenseExam, sensoryLossPatternExam: res.data.examination.sensoryLossPatternExam, rhombergTestExam: res.data.examination.rhombergTestExam, nystagmusExam: res.data.examination.nystagmusExam, speechExam: res.data.examination.speechExam, rapidAlternatingMovementsExam: res.data.examination.rapidAlternatingMovementsExam, fingerToNoseTestExam: res.data.examination.fingerToNoseTestExam, heelKneeShinTestExam: res.data.examination.heelKneeShinTestExam, tandemGaitExam: res.data.examination.tandemGaitExam, gaitExam: res.data.examination.gaitExam, otherExamFindings: res.data.examination.otherExamFindings, assessmentExam: res.data.examination.assessmentExam, planExam: res.data.examination.planExam,                  
                    
                    hBA1C: res.data.hBA1C, cholesterol: res.data.cholesterol, otherName: res.data.otherName, otherValue: res.data.otherValue, allergies: res.data.allergies, results: res.data.results,
                    fullname: res.data.fullname, number: res.data.number, mobile: res.data.mobile, gender: res.data.gender, idNumber: res.data.idNumber, dateOfBirth: res.data.dateOfBirth, age: res.data.age, weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, vitalColors: res.data.vitalColors, notes: res.data.notes, oldPatient: res.data.oldPatient, planData: res.data.planData, chronicData: res.data.chronicData, historyVisits: res.data.historyVisits
                });
                setTimeout(() => { this.setLoader(false)  }, 2000);
            }, err => {
                console.log(".....")
                this.setState({ error: err + '' })
                this.setLoader(false)
            }
        )
    }
    save() {
        this.setLoader(true)
        var data = {
            patientId: this.state.patientId, visitId: this.state.visitId, history: {
                presentingComplaintList: this.state.presentingComplaintList, pastMedicalHistory: this.state.pastMedicalHistory, currentMedication: this.state.currentMedication, allergyHistory: this.state.allergyHistory, historyOfPresentingComplaints: this.state.historyOfPresentingComplaints,
                coughChoice: this.state.coughChoice, coughText: this.state.coughText, coryzaChoice: this.state.coryzaChoice, coryzaText: this.state.coryzaText, sobChoice: this.state.sobChoice, sobText: this.state.sobText, sneazingChoice: this.state.sneazingChoice, sneazingText: this.state.sneazingText, phelgmChoice: this.state.phelgmChoice, phelgmText: this.state.phelgmText, sputumChoice: this.state.sputumChoice, sputumText: this.state.sputumText, haemoptysisChoice: this.state.haemoptysisChoice, haemoptysisText: this.state.haemoptysisText, wheezingChoice: this.state.wheezingChoice, wheezingText: this.state.wheezingText, nasalChoice: this.state.nasalChoice, nasalText: this.state.nasalText, soreChoice: this.state.soreChoice, soreText: this.state.soreText, chestPainChoice: this.state.chestPainChoice, chestPainText: this.state.chestPainText, respitoryNotes: this.state.respitoryNotes,
                nauseaChoice: this.state.nauseaChoice, nauseaText: this.state.nauseaText, vomitingChoice: this.state.vomitingChoice, vomitingText: this.state.vomitingText, diarrhoeaChoice: this.state.diarrhoeaChoice, diarrhoeaText: this.state.diarrhoeaText, abdominialChoice: this.state.abdominialChoice, abdominialText: this.state.abdominialText, flatulanceChoice: this.state.flatulanceChoice, flatulanceText: this.state.flatulanceText, flatulentChoice: this.state.flatulentChoice, flatulentText: this.state.flatulentText, waterChoice: this.state.waterChoice, waterText: this.state.waterText, constipationChoice: this.state.constipationChoice, constipationText: this.state.constipationText, prChoice: this.state.prChoice, prText: this.state.prText, wtChoice: this.state.wtChoice, wtText: this.state.wtText, melenaChoice: this.state.melenaChoice, melenaText: this.state.melenaText, jaundiceChoice: this.state.jaundiceChoice, jaundiceText: this.state.jaundiceText, loaChoice: this.state.loaChoice, loaText: this.state.loaText, lowChoice: this.state.lowChoice, lowText: this.state.lowText, gitNotes: this.state.gitNotes,
                dysuriaChoice: this.state.dysuriaChoice, dysuriaText: this.state.dysuriaText, frequencyChoice: this.state.frequencyChoice, frequencyText: this.state.frequencyText, haematuriaChoice: this.state.haematuriaChoice, haematuriaText: this.state.haematuriaText, weakChoice: this.state.weakChoice, weakText: this.state.weakText, strainingChoice: this.state.strainingChoice, strainingText: this.state.strainingText, urineChoice: this.state.urineChoice, urineText: this.state.urineText, urethalChoice: this.state.urethalChoice, urethalText: this.state.urethalText, pvChoice: this.state.pvChoice, pvText: this.state.pvText, nocturiaChoice: this.state.nocturiaChoice, nocturiaText: this.state.nocturiaText, genitalChoice: this.state.genitalChoice, genitalText: this.state.genitalText, lossChoice: this.state.lossChoice, lossText: this.state.lossText, erectileChoice: this.state.erectileChoice, erectileText: this.state.erectileText, gusNotes: this.state.gusNotes,
                dysaruniaChoice: this.state.dysaruniaChoice, dysaruniaText: this.state.dysaruniaText, dysmemorrhoeaChoice: this.state.dysmemorrhoeaChoice, dysmemorrhoeaText: this.state.dysmemorrhoeaText, imbChoice: this.state.imbChoice, imbText: this.state.imbText, contactChoice: this.state.contactChoice, contactText: this.state.contactText, menorrhagiaChoice: this.state.menorrhagiaChoice, menorrhagiaText: this.state.menorrhagiaText, lapChoice: this.state.lapChoice, lapText: this.state.lapText, backacheChoice: this.state.backacheChoice, backackeText: this.state.backackeText, subfertilityChoice: this.state.subfertilityChoice, subfertilityText: this.state.subfertilityText, hotChoice: this.state.hotChoice, hotText: this.state.hotText, lnmpChoice: this.state.lnmpChoice, lnmpText: this.state.lnmpText, gynaeNotes: this.state.gynaeNotes,
                orthopneaChoice: this.state.orthopneaChoice, orthopneaText: this.state.orthopneaText, pndChoice: this.state.pndChoice, pndText: this.state.pndText, easyChoice: this.state.easyChoice, easyText: this.state.easyText, palpitationsChoice: this.state.palpitationsChoice, palpitationsText: this.state.palpitationsText, synacopeChoice: this.state.synacopeChoice, synacopeText: this.state.synacopeText, legSwellingChoice: this.state.legSwellingChoice, legSwellingText: this.state.legSwellingText, chestPaincvsChoice: this.state.chestPaincvsChoice, chestPaincvsText: this.state.chestPaincvsText, calfSwellingChoice: this.state.calfSwellingChoice, calfSwellingText: this.state.calfSwellingText, pinkFrothChoice: this.state.pinkFrothChoice, pinkFrothText: this.state.pinkFrothText, gelatinousChoice: this.state.gelatinousChoice, gelatinousText: this.state.gelatinousText, cvsNotes: this.state.cvsNotes,
                headacheChoice: this.state.headacheChoice, headacheText: this.state.headacheText, paraesthesiaChoice: this.state.paraesthesiaChoice, paraesthesiaText: this.state.paraesthesiaText, dizzinessChoice: this.state.dizzinessChoice, dizzinessText: this.state.dizzinessText, backackeChoice: this.state.backackeChoice, backacheText: this.state.backacheText, photophibiaChoice: this.state.photophibiaChoice, photophibiaText: this.state.photophibiaText, neckstiffnessChoice: this.state.neckstiffnessChoice, neckstiffnessText: this.state.neckstiffnessText, fittingChoice: this.state.fittingChoice, fittingText: this.state.fittingText, paresisChoice: this.state.paresisChoice, paresisText: this.state.paresisText, paralysisChoice: this.state.paralysisChoice, paralysisText: this.state.paralysisText, insomniaChoice: this.state.insomniaChoice, insomniaText: this.state.insomniaText, hypersomniaChoice: this.state.hypersomniaChoice, hypersomniaText: this.state.hypersomniaText, abnChoice: this.state.abnChoice, abnText: this.state.abnText, cnsNotes: this.state.cnsNotes,
                feverChoice: this.state.feverChoice, feverText: this.state.feverText, sweatingChoice: this.state.sweatingChoice, sweatingText: this.state.sweatingText, chillsChoice: this.state.chillsChoice, chillsText: this.state.chillsText, rigorsChoice: this.state.rigorsChoice, rigorsText: this.state.rigorsText, fatigueChoice: this.state.fatigueChoice, fatigueText: this.state.fatigueText, myalgiaChoice: this.state.myalgiaChoice, myalgiaText: this.state.myalgiaText, constitutionalNotes: this.state.constitutionalNotes,
                bleedingChoice: this.state.bleedingChoice, bleedingText: this.state.bleedingText, bruisingChoice: this.state.bruisingChoice, bruisingText: this.state.bruisingText, swellingChoice: this.state.swellingChoice, swellingText: this.state.swellingText, deformityChoice: this.state.deformityChoice, deformityText: this.state.deformityText, mssNotes: this.state.mssNotes,
                rashChoice: this.state.rashChoice, rashText: this.state.rashText, itchynessChoice: this.state.itchynessChoice, itchynessText: this.state.itchynessText, blistersChoice: this.state.blistersChoice, blistersText: this.state.blistersText, lumpsChoice: this.state.lumpsChoice, lumpsText: this.state.lumpsText, ulcersChoice: this.state.ulcersChoice, ulcersText: this.state.ulcersText, swellingdemaChoice: this.state.swellingdemaChoice, swellingdemaText: this.state.swellingdemaText, dematologyNotes: this.state.dematologyNotes,
                 /* Extra History */ 
                 painText: this.state.painText, durationText: this.state.durationText, timingText: this.state.timingText, aggravatedByText: this.state.aggravatedByText, relievedByText: this.state.relievedByText, headacheLeftChoice: this.state.headacheLeftChoice, headacheLeftText: this.state.headacheLeftText, headacheRightChoice: this.state.headacheRightChoice, headacheRightText: this.state.headacheRightText, holocranialChoice: this.state.holocranialChoice, holocranialText: this.state.holocranialText, frontalChoice: this.state.frontalChoice, frontalText: this.state.frontalText, temporalChoice: this.state.temporalChoice, temporalText: this.state.temporalText, occipitalChoice: this.state.occipitalChoice, occipitalText: this.state.occipitalText, periorbitalChoice: this.state.periorbitalChoice, periorbitalText: this.state.periorbitalText, confusionChoice: this.state.confusionChoice, confusionText: this.state.confusionText, 
                 changeinCharacterChoice: this.state.changeinCharacterChoice, changeinCharacterText: this.state.changeinCharacterText, weaknessChoice: this.state.weaknessChoice, weaknessText: this.state.weaknessText, hemiparesisLeftChoice: this.state.hemiparesisLeftChoice, hemiparesisLeftText: this.state.hemiparesisLeftText, hemiparesisRightChoice: this.state.hemiparesisRightChoice, hemiparesisRightText: this.state.hemiparesisRightText, weaknessHemiplegiaChoice: this.state.weaknessHemiplegiaChoice, weaknessHemiplegiaText: this.state.weaknessHemiplegiaText, hemiplegiaLeftChoice: this.state.hemiplegiaLeftChoice, hemiplegiaLeftText: this.state.hemiplegiaLeftText, hemiplegiaRightChoice: this.state.hemiplegiaRightChoice, hemiplegiaRightText: this.state.hemiplegiaRightText, paraparesisChoice: this.state.paraparesisChoice, paraparesisText: this.state.paraparesisText, paraplegiaChoice: this.state.paraplegiaChoice, paraplegiaText: this.state.paraplegiaText, quadriparesisChoice: this.state.quadriparesisChoice, quadriparesisText: this.state.quadriparesisText, 
                 quadriplegiaChoice: this.state.quadriplegiaChoice, quadriplegiaText: this.state.quadriplegiaText, monoparesisChoice: this.state.monoparesisChoice, monoparesisText: this.state.monoparesisText, monoparesisLeftChoice: this.state.monoparesisLeftChoice, monoparesisLeftText: this.state.monoparesisLeftText, monoparesisRightChoice: this.state.monoparesisRightChoice, monoparesisRightText: this.state.monoparesisRightText, monoparesisUpperLimbChoice: this.state.monoparesisUpperLimbChoice, monoparesisUpperLimbText: this.state.monoparesisUpperLimbText, monoparesisLowerLimbChoice: this.state.monoparesisLowerLimbChoice, monoparesisLowerLimbText: this.state.monoparesisLowerLimbText, seizuresChoice: this.state.seizuresChoice, seizuresText: this.state.seizuresText, seizuresAuraChoice: this.state.seizuresAuraChoice, seizuresAuraText: this.state.seizuresAuraText, auraVisualChoice: this.state.auraVisualChoice, auraVisualText: this.state.auraVisualText, auraOlfactoryChoice: this.state.auraOlfactoryChoice, auraOlfactoryText: this.state.auraOlfactoryText, auraAutonomicChoice: this.state.auraAutonomicChoice, 
                 auraAutonomicText: this.state.auraAutonomicText, thoracicChoice: this.state.thoracicChoice, thoracicText: this.state.thoracicText, lumbarChoice: this.state.lumbarChoice, lumbarText: this.state.lumbarText, lowerBackacheChoice: this.state.lowerBackacheChoice, lowerBackacheText: this.state.lowerBackacheText, mechanicalChoice: this.state.mechanicalChoice, mechanicalText: this.state.mechanicalText, sciaticaChoice: this.state.sciaticaChoice, sciaticaText: this.state.sciaticaText, sciaticaLeftChoice: this.state.sciaticaLeftChoice, sciaticaLeftText: this.state.sciaticaLeftText, sciaticaRightChoice: this.state.sciaticaRightChoice, sciaticaRightText: this.state.sciaticaRightText, cervicalPainChoice: this.state.cervicalPainChoice, cervicalPainText: this.state.cervicalPainText, interScapularPainChoice: this.state.interScapularPainChoice, interScapularPainText: this.state.interScapularPainText, paraesthesiaUpperLimbChoice: this.state.paraesthesiaUpperLimbChoice, 
                 paraesthesiaUpperLimbText: this.state.paraesthesiaUpperLimbText, paraesthesiaLowerLimbChoice: this.state.paraesthesiaLowerLimbChoice, paraesthesiaLowerLimbText: this.state.paraesthesiaLowerLimbText, lossOfBalanceChoice: this.state.lossOfBalanceChoice, lossOfBalanceText: this.state.lossOfBalanceText, neurogenicClaudicationChoice: this.state.neurogenicClaudicationChoice, neurogenicClaudicationText: this.state.neurogenicClaudicationText, radiculapathyChoice: this.state.radiculapathyChoice, radiculapathyText: this.state.radiculapathyText, radiculapathyLeftChoice: this.state.radiculapathyLeftChoice, radiculapathyLeftText: this.state.radiculapathyLeftText, radiculapathyLeftC2Choice: this.state.radiculapathyLeftC2Choice, radiculapathyLeftC2Text: this.state.radiculapathyLeftC2Text, radiculapathyLeftC3Choice: this.state.radiculapathyLeftC3Choice, radiculapathyLeftC3Text: this.state.radiculapathyLeftC3Text, radiculapathyLeftC4Choice: this.state.radiculapathyLeftC4Choice, radiculapathyLeftC4Text: this.state.radiculapathyLeftC4Text, radiculapathyLeftC5Choice: this.state.radiculapathyLeftC5Choice, 
                 radiculapathyLeftC5Text: this.state.radiculapathyLeftC5Text, radiculapathyLeftC6Choice: this.state.radiculapathyLeftC6Choice, radiculapathyLeftC6Text: this.state.radiculapathyLeftC6Text, radiculapathyLeftC7Choice: this.state.radiculapathyLeftC7Choice, radiculapathyLeftC7Text: this.state.radiculapathyLeftC7Text, radiculapathyLeftC8Choice: this.state.radiculapathyLeftC8Choice, radiculapathyLeftC8Text: this.state.radiculapathyLeftC8Text, radiculapathyLeftT1Choice: this.state.radiculapathyLeftT1Choice, radiculapathyLeftT1Text: this.state.radiculapathyLeftT1Text, radiculapathyLeftT2Choice: this.state.radiculapathyLeftT2Choice, radiculapathyLeftT2Text: this.state.radiculapathyLeftT2Text, radiculapathyLeftT3Choice: this.state.radiculapathyLeftT3Choice, radiculapathyLeftT3Text: this.state.radiculapathyLeftT3Text, radiculapathyLeftT4Choice: this.state.radiculapathyLeftT4Choice, radiculapathyLeftT4Text: this.state.radiculapathyLeftT4Text, radiculapathyLeftT5Choice: this.state.radiculapathyLeftT5Choice, radiculapathyLeftT5Text: this.state.radiculapathyLeftT5Text, radiculapathyLeftT6Choice: this.state.radiculapathyLeftT6Choice, 
                 radiculapathyLeftT6Text: this.state.radiculapathyLeftT6Text, radiculapathyLeftT7Choice: this.state.radiculapathyLeftT7Choice, radiculapathyLeftT7Text: this.state.radiculapathyLeftT7Text, radiculapathyLeftT8Choice: this.state.radiculapathyLeftT8Choice, radiculapathyLeftT8Text: this.state.radiculapathyLeftT8Text, radiculapathyLeftT9Choice: this.state.radiculapathyLeftT9Choice, radiculapathyLeftT9Text: this.state.radiculapathyLeftT9Text, radiculapathyLeftT10Choice: this.state.radiculapathyLeftT10Choice, radiculapathyLeftT10Text: this.state.radiculapathyLeftT10Text, radiculapathyLeftT11Choice: this.state.radiculapathyLeftT11Choice, radiculapathyLeftT11Text: this.state.radiculapathyLeftT11Text, radiculapathyLeftT12Choice: this.state.radiculapathyLeftT12Choice, radiculapathyLeftT12Text: this.state.radiculapathyLeftT12Text, radiculapathyLeftL1Choice: this.state.radiculapathyLeftL1Choice, radiculapathyLeftL1Text: this.state.radiculapathyLeftL1Text, radiculapathyLeftL2Choice: this.state.radiculapathyLeftL2Choice, radiculapathyLeftL2Text: this.state.radiculapathyLeftL2Text, radiculapathyLeftL3Choice: this.state.radiculapathyLeftL3Choice, 
                 radiculapathyLeftL3Text: this.state.radiculapathyLeftL3Text, radiculapathyLeftL4Choice: this.state.radiculapathyLeftL4Choice, radiculapathyLeftL4Text: this.state.radiculapathyLeftL4Text, radiculapathyLeftL5Choice: this.state.radiculapathyLeftL5Choice, radiculapathyLeftL5Text: this.state.radiculapathyLeftL5Text, radiculapathyLeftS1Choice: this.state.radiculapathyLeftS1Choice, radiculapathyLeftS1Text: this.state.radiculapathyLeftS1Text, radiculapathyLeftS3Choice: this.state.radiculapathyLeftS3Choice, radiculapathyLeftS3Text: this.state.radiculapathyLeftS3Text, radiculapathyLeftS45Choice: this.state.radiculapathyLeftS45Choice, radiculapathyLeftS45Text: this.state.radiculapathyLeftS45Text, radiculapathyRightChoice: this.state.radiculapathyRightChoice, radiculapathyRightText: this.state.radiculapathyRightText, radiculapathyRightC2Choice: this.state.radiculapathyRightC2Choice, radiculapathyRightC2Text: this.state.radiculapathyRightC2Text, radiculapathyRightC3Choice: this.state.radiculapathyRightC3Choice, radiculapathyRightC3Text: this.state.radiculapathyRightC3Text, radiculapathyRightC4Choice: this.state.radiculapathyRightC4Choice, 
                 radiculapathyRightC4Text: this.state.radiculapathyRightC4Text, radiculapathyRightC5Choice: this.state.radiculapathyRightC5Choice, radiculapathyRightC5Text: this.state.radiculapathyRightC5Text, radiculapathyRightC6Choice: this.state.radiculapathyRightC6Choice, radiculapathyRightC6Text: this.state.radiculapathyRightC6Text, radiculapathyRightC7Choice: this.state.radiculapathyRightC7Choice, radiculapathyRightC7Text: this.state.radiculapathyRightC7Text, radiculapathyRightC8Choice: this.state.radiculapathyRightC8Choice, radiculapathyRightC8Text: this.state.radiculapathyRightC8Text, radiculapathyRightT1Choice: this.state.radiculapathyRightT1Choice, radiculapathyRightT1Text: this.state.radiculapathyRightT1Text, radiculapathyRightT2Choice: this.state.radiculapathyRightT2Choice, radiculapathyRightT2Text: this.state.radiculapathyRightT2Text, radiculapathyRightT3Choice: this.state.radiculapathyRightT3Choice, radiculapathyRightT3Text: this.state.radiculapathyRightT3Text, radiculapathyRightT4Choice: this.state.radiculapathyRightT4Choice, radiculapathyRightT4Text: this.state.radiculapathyRightT4Text, 
                 radiculapathyRightT5Choice: this.state.radiculapathyRightT5Choice, radiculapathyRightT5Text: this.state.radiculapathyRightT5Text, radiculapathyRightT6Choice: this.state.radiculapathyRightT6Choice, radiculapathyRightT6Text: this.state.radiculapathyRightT6Text, radiculapathyRightT7Choice: this.state.radiculapathyRightT7Choice, radiculapathyRightT7Text: this.state.radiculapathyRightT7Text, radiculapathyRightT8Choice: this.state.radiculapathyRightT8Choice, radiculapathyRightT8Text: this.state.radiculapathyRightT8Text, radiculapathyRightT9Choice: this.state.radiculapathyRightT9Choice, radiculapathyRightT9Text: this.state.radiculapathyRightT9Text, radiculapathyRightT10Choice: this.state.radiculapathyRightT10Choice, radiculapathyRightT10Text: this.state.radiculapathyRightT10Text, radiculapathyRightT11Choice: this.state.radiculapathyRightT11Choice, radiculapathyRightT11Text: this.state.radiculapathyRightT11Text, radiculapathyRightT12Choice: this.state.radiculapathyRightT12Choice, radiculapathyRightT12Text: this.state.radiculapathyRightT12Text, radiculapathyRightL1Choice: this.state.radiculapathyRightL1Choice, 
                 radiculapathyRightL1Text: this.state.radiculapathyRightL1Text, radiculapathyRightL2Choice: this.state.radiculapathyRightL2Choice, radiculapathyRightL2Text: this.state.radiculapathyRightL2Text, radiculapathyRightL3Choice: this.state.radiculapathyRightL3Choice, radiculapathyRightL3Text: this.state.radiculapathyRightL3Text, radiculapathyRightL4Choice: this.state.radiculapathyRightL4Choice, radiculapathyRightL4Text: this.state.radiculapathyRightL4Text, radiculapathyRightL5Choice: this.state.radiculapathyRightL5Choice, radiculapathyRightL5Text: this.state.radiculapathyRightL5Text, radiculapathyRightS1Choice: this.state.radiculapathyRightS1Choice, radiculapathyRightS1Text: this.state.radiculapathyRightS1Text, radiculapathyRightS3Choice: this.state.radiculapathyRightS3Choice, radiculapathyRightS3Text: this.state.radiculapathyRightS3Text, radiculapathyRightS45Choice: this.state.radiculapathyRightS45Choice, radiculapathyRightS45Text: this.state.radiculapathyRightS45Text, sleep: this.state.sleep
            },
            examination: {  generalNotes: this.state.generalNotes, cvsExam: this.state.cvsExam, respExam: this.state.respExam, abdomenExam: this.state.abdomenExam, skinExam: this.state.skinExam, jointsExam: this.state.jointsExam, bodyMassIndexExam: this.state.bodyMassIndexExam, meningismExam: this.state.meningismExam, higherFunctionsExam: this.state.higherFunctionsExam, spineExam: this.state.spineExam, cranialNerve1Right: this.state.cranialNerve1Right, 
                cranialNerve1Left: this.state.cranialNerve1Left, cranialNerve2Right: this.state.cranialNerve2Right, cranialNerve2Left: this.state.cranialNerve2Left, cranialNerve3Right: this.state.cranialNerve3Right, cranialNerve3Left: this.state.cranialNerve3Left, cranialNerve5Right: this.state.cranialNerve5Right, cranialNerve5Left: this.state.cranialNerve5Left, cranialNerve7Right: this.state.cranialNerve7Right, cranialNerve7Left: this.state.cranialNerve7Left, cranialNerve8Right: this.state.cranialNerve8Right, cranialNerve8Left: this.state.cranialNerve8Left, cranialNerve9Right: this.state.cranialNerve9Right, cranialNerve9Left: this.state.cranialNerve9Left, cranialNerve10Right: this.state.cranialNerve10Right, cranialNerve10Left: this.state.cranialNerve10Left, cranialNerve11Right: this.state.cranialNerve11Right, cranialNerve11Left: this.state.cranialNerve11Left, cranialNerve12Right: this.state.cranialNerve12Right, cranialNerve12Left: this.state.cranialNerve12Left,shapeRight: this.state.shapeRight, shapeLeft: this.state.shapeLeft, sizeRight: this.state.sizeRight, sizeLeft: this.state.sizeLeft, pupillaryRight: this.state.pupillaryRight, pupillaryLeft: this.state.pupillaryLeft, consensualReflexRight: this.state.consensualReflexRight, 
                consensualReflexLeft: this.state.consensualReflexLeft, rapdRight: this.state.rapdRight, rapdLeft: this.state.rapdLeft, funduscopyRight: this.state.funduscopyRight, funduscopyLeft: this.state.funduscopyLeft, inspectionExam: this.state.inspectionExam, involuntaryExam: this.state.involuntaryExam, toneExam: this.state.toneExam, rigidityExam: this.state.rigidityExam, bradykinesiaExam: this.state.bradykinesiaExam, hoffmanRight: this.state.hoffmanRight, hoffmanLeft: this.state.hoffmanLeft, bicepsJerkRight: this.state.bicepsJerkRight, bicepsJerkLeft: this.state.bicepsJerkLeft, brachioradialisJerkRight: this.state.brachioradialisJerkRight, brachioradialisJerkLeft: this.state.brachioradialisJerkLeft, tricepsJerkRight: this.state.tricepsJerkRight, tricepsJerkLeft: this.state.tricepsJerkLeft, kneeJerkRight: this.state.kneeJerkRight, 
                kneeJerkLeft: this.state.kneeJerkLeft, ankleJerkRight: this.state.ankleJerkRight, ankleJerkLeft: this.state.ankleJerkLeft, plantarResponseRight: this.state.plantarResponseRight, plantarResponseLeft: this.state.plantarResponseLeft, abdominalReflexesRight: this.state.abdominalReflexesRight, abdominalReflexesLeft: this.state.abdominalReflexesLeft, otherReflexesRight: this.state.otherReflexesRight, otherReflexesLeft: this.state.otherReflexesLeft, shoulderAbductionRight: this.state.shoulderAbductionRight, shoulderAbductionLeft: this.state.shoulderAbductionLeft, shoulderAdductionRight: this.state.shoulderAdductionRight, shoulderAdductionLeft: this.state.shoulderAdductionLeft, elbowFlexionRight: this.state.elbowFlexionRight, elbowFlexionLeft: this.state.elbowFlexionLeft, elbowExtensionRight: this.state.elbowExtensionRight, elbowExtensionLeft: this.state.elbowExtensionLeft, wristFlexionRight: this.state.wristFlexionRight, wristFlexionLeft: this.state.wristFlexionLeft, wristExtensionRight: this.state.wristExtensionRight, wristExtensionLeft: this.state.wristExtensionLeft, fingerFlexionRight: this.state.fingerFlexionRight, fingerFlexionLeft: this.state.fingerFlexionLeft, fingerExtensionRight: this.state.fingerExtensionRight, 
                fingerExtensionLeft: this.state.fingerExtensionLeft, fingerAbductionRight: this.state.fingerAbductionRight, fingerAbductionLeft: this.state.fingerAbductionLeft, hipFlexionRight: this.state.hipFlexionRight, hipFlexionLeft: this.state.hipFlexionLeft, hipExtensionRight: this.state.hipExtensionRight, hipExtensionLeft: this.state.hipExtensionLeft, hipAbductionRight: this.state.hipAbductionRight, hipAbductionLeft: this.state.hipAbductionLeft, kneeFlexionRight: this.state.kneeFlexionRight, kneeFlexionLeft: this.state.kneeFlexionLeft, kneeExtensionRight: this.state.kneeExtensionRight, kneeExtensionLeft: this.state.kneeExtensionLeft, ankleDorsiflexionRight: this.state.ankleDorsiflexionRight, ankleDorsiflexionLeft: this.state.ankleDorsiflexionLeft, anklePlantarFlexionRight: this.state.anklePlantarFlexionRight, anklePlantarFlexionLeft: this.state.anklePlantarFlexionLeft, ankleEversionRight: this.state.ankleEversionRight, ankleEversionLeft: this.state.ankleEversionLeft, ankleInversionRight: this.state.ankleInversionRight, ankleInversionLeft: this.state.ankleInversionLeft, toeFlexionRight: this.state.toeFlexionRight, toeFlexionLeft: this.state.toeFlexionLeft, toeExtensionRight: this.state.toeExtensionRight, toeExtensionLeft: this.state.toeExtensionLeft, 
                fineTouchExam: this.state.fineTouchExam, pinPrickExam: this.state.pinPrickExam, jointPositionSenseExam: this.state.jointPositionSenseExam, sensoryLossPatternExam: this.state.sensoryLossPatternExam, rhombergTestExam: this.state.rhombergTestExam, nystagmusExam: this.state.nystagmusExam, speechExam: this.state.speechExam, rapidAlternatingMovementsExam: this.state.rapidAlternatingMovementsExam, fingerToNoseTestExam: this.state.fingerToNoseTestExam, heelKneeShinTestExam: this.state.heelKneeShinTestExam, tandemGaitExam: this.state.tandemGaitExam, gaitExam: this.state.gaitExam, otherExamFindings: this.state.otherExamFindings, assessmentExam: this.state.assessmentExam, planExam: this.state.planExam,                  
               }
            ,feedback: { doctor: this.state.feedbackDoctor, history: this.state.feedbackHistory, examination: this.state.feedbackExamination, reviewConsult: this.state.feedbackReviewConsult, investigations: this.state.feedbackInvestigations, diagnosis: this.state.feedbackDiagnosis, prescriptions: this.state.feedbackPrescriptions, dateLists: this.state.dateLists}
            , patientDiagnosis: this.state.patientDiagnosis, planData: this.state.planData, chronicData: this.state.chronicData, diagnosisText: this.state.diagnosisText, reviews: this.state.reviews, medicalCertificates: this.state.medicalCertificates, rehabilitations: this.state.rehabilitations, referrals: this.state.referrals, treatments: this.state.treatments, investigations: this.state.investigations, findings: this.state.findings
        }
        localStorage.setItem('nuero_data', JSON.stringify(data))
        axios.post(localStorage.getItem('host') + '/patientmanagement/neurologysave', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.getList()
                document.getElementById('topDiv').scrollIntoView()
                this.openNeurologyAction(this.state.visitId,  res.data )
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )

    }
    getFromLocalStorage()
     {
      var data = JSON.parse(localStorage.getItem('nuero_data'));  
      this.setState({
        patientDiagnosis: data.patientDiagnosis, diagnosisText: data.diagnosisText, reviews: data.reviews, medicalCertificates: data.medicalCertificates, rehabilitations: data.rehabilitations, referrals: data.referrals, treatments: data.treatments, investigations: data.investigations, findings: data.findings, 
        feedbackHistory: data.feedback.history, feedbackExamination: data.feedback.examination, feedbackReviewConsult: data.feedback.reviewConsult, feedbackInvestigations: data.feedback.investigations, feedbackDiagnosis: data.feedback.diagnosis, feedbackPrescriptions: data.feedback.prescriptions, feedbackDoctor: data.feedback.doctor, dateLists: data.feedback.dateLists,
/* HISTORY  */  presentingComplaintList: data.history.presentingComplaintList, historyOfPresentingComplaints: data.history.historyOfPresentingComplaints, pastMedicalHistory: data.history.pastMedicalHistory, currentMedication: data.history.currentMedication, allergyHistory: data.history.allergyHistory,
        coughChoice: data.history.coughChoice, coughText: data.history.coughText, coryzaChoice: data.history.coryzaChoice, coryzaText: data.history.coryzaText, sobChoice: data.history.sobChoice, sobText: data.history.sobText, sneazingChoice: data.history.sneazingChoice, sneazingText: data.history.sneazingText, phelgmChoice: data.history.phelgmChoice, phelgmText: data.history.phelgmText, sputumChoice: data.history.sputumChoice, sputumText: data.history.sputumText, haemoptysisChoice: data.history.haemoptysisChoice, haemoptysisText: data.history.haemoptysisText, wheezingChoice: data.history.wheezingChoice, wheezingText: data.history.wheezingText, nasalChoice: data.history.nasalChoice, nasalText: data.history.nasalText, soreChoice: data.history.soreChoice, soreText: data.history.soreText, chestPainChoice: data.history.chestPainChoice, chestPainText: data.history.chestPainText, respitoryNotes: data.history.respitoryNotes,
        nauseaChoice: data.history.nauseaChoice, nauseaText: data.history.nauseaText, vomitingChoice: data.history.vomitingChoice, vomitingText: data.history.vomitingText, diarrhoeaChoice: data.history.diarrhoeaChoice, diarrhoeaText: data.history.diarrhoeaText, abdominialChoice: data.history.abdominialChoice, abdominialText: data.history.abdominialText, flatulanceChoice: data.history.flatulanceChoice, flatulanceText: data.history.flatulanceText, flatulentChoice: data.history.flatulentChoice, flatulentText: data.history.flatulentText, waterChoice: data.history.waterChoice, waterText: data.history.waterText, constipationChoice: data.history.constipationChoice, constipationText: data.history.constipationText, prChoice: data.history.prChoice, prText: data.history.prText, wtChoice: data.history.wtChoice, wtText: data.history.wtText, melenaChoice: data.history.melenaChoice, melenaText: data.history.melenaText, jaundiceChoice: data.history.jaundiceChoice, jaundiceText: data.history.jaundiceText, loaChoice: data.history.loaChoice, loaText: data.history.loaText, lowChoice: data.history.lowChoice, lowText: data.history.lowText, gitNotes: data.history.gitNotes,
        dysuriaChoice: data.history.dysuriaChoice, dysuriaText: data.history.dysuriaText, frequencyChoice: data.history.frequencyChoice, frequencyText: data.history.frequencyText, haematuriaChoice: data.history.haematuriaChoice, haematuriaText: data.history.haematuriaText, weakChoice: data.history.weakChoice, weakText: data.history.weakText, strainingChoice: data.history.strainingChoice, strainingText: data.history.strainingText, urineChoice: data.history.urineChoice, urineText: data.history.urineText, urethalChoice: data.history.urethalChoice, urethalText: data.history.urethalText, pvChoice: data.history.pvChoice, pvText: data.history.pvText, nocturiaChoice: data.history.nocturiaChoice, nocturiaText: data.history.nocturiaText, genitalChoice: data.history.genitalChoice, genitalText: data.history.genitalText, lossChoice: data.history.lossChoice, lossText: data.history.lossText, erectileChoice: data.history.erectileChoice, erectileText: data.history.erectileText, gusNotes: data.history.gusNotes,
        dysaruniaChoice: data.history.dysaruniaChoice, dysaruniaText: data.history.dysaruniaText, dysmemorrhoeaChoice: data.history.dysmemorrhoeaChoice, dysmemorrhoeaText: data.history.dysmemorrhoeaText, imbChoice: data.history.imbChoice, imbText: data.history.imbText, contactChoice: data.history.contactChoice, contactText: data.history.contactText, menorrhagiaChoice: data.history.menorrhagiaChoice, menorrhagiaText: data.history.menorrhagiaText, lapChoice: data.history.lapChoice, lapText: data.history.lapText, backacheChoice: data.history.backacheChoice, backackeText: data.history.backackeText, subfertilityChoice: data.history.subfertilityChoice, subfertilityText: data.history.subfertilityText, hotChoice: data.history.hotChoice, hotText: data.history.hotText, lnmpChoice: data.history.lnmpChoice, lnmpText: data.history.lnmpText, gynaeNotes: data.history.gynaeNotes,
        orthopneaChoice: data.history.orthopneaChoice, orthopneaText: data.history.orthopneaText, pndChoice: data.history.pndChoice, pndText: data.history.pndText, easyChoice: data.history.easyChoice, easyText: data.history.easyText, palpitationsChoice: data.history.palpitationsChoice, palpitationsText: data.history.palpitationsText, synacopeChoice: data.history.synacopeChoice, synacopeText: data.history.synacopeText, legSwellingChoice: data.history.legSwellingChoice, legSwellingText: data.history.legSwellingText, chestPaincvsChoice: data.history.chestPaincvsChoice, chestPaincvsText: data.history.chestPaincvsText, calfSwellingChoice: data.history.calfSwellingChoice, calfSwellingText: data.history.calfSwellingText, pinkFrothChoice: data.history.pinkFrothChoice, pinkFrothText: data.history.pinkFrothText, gelatinousChoice: data.history.gelatinousChoice, gelatinousText: data.history.gelatinousText, cvsNotes: data.history.cvsNotes,
        headacheChoice: data.history.headacheChoice, headacheText: data.history.headacheText, paraesthesiaChoice: data.history.paraesthesiaChoice, paraesthesiaText: data.history.paraesthesiaText, dizzinessChoice: data.history.dizzinessChoice, dizzinessText: data.history.dizzinessText, backackeChoice: data.history.backackeChoice, backacheText: data.history.backacheText, photophibiaChoice: data.history.photophibiaChoice, photophibiaText: data.history.photophibiaText, neckstiffnessChoice: data.history.neckstiffnessChoice, neckstiffnessText: data.history.neckstiffnessText, fittingChoice: data.history.fittingChoice, fittingText: data.history.fittingText, paresisChoice: data.history.paresisChoice, paresisText: data.history.paresisText, paralysisChoice: data.history.paralysisChoice, paralysisText: data.history.paralysisText, insomniaChoice: data.history.insomniaChoice, insomniaText: data.history.insomniaText, hypersomniaChoice: data.history.hypersomniaChoice, hypersomniaText: data.history.hypersomniaText, abnChoice: data.history.abnChoice, abnText: data.history.abnText, cnsNotes: data.history.cnsNotes,
        feverChoice: data.history.feverChoice, feverText: data.history.feverText, sweatingChoice: data.history.sweatingChoice, sweatingText: data.history.sweatingText, chillsChoice: data.history.chillsChoice, chillsText: data.history.chillsText, rigorsChoice: data.history.rigorsChoice, rigorsText: data.history.rigorsText, fatigueChoice: data.history.fatigueChoice, fatigueText: data.history.fatigueText, myalgiaChoice: data.history.myalgiaChoice, myalgiaText: data.history.myalgiaText, constitutionalNotes: data.history.constitutionalNotes,
        bleedingChoice: data.history.bleedingChoice, bleedingText: data.history.bleedingText, bruisingChoice: data.history.bruisingChoice, bruisingText: data.history.bruisingText, swellingChoice: data.history.swellingChoice, swellingText: data.history.swellingText, deformityChoice: data.history.deformityChoice, deformityText: data.history.deformityText, mssNotes: data.history.mssNotes,
        rashChoice: data.history.rashChoice, rashText: data.history.rashText, itchynessChoice: data.history.itchynessChoice, itchynessText: data.history.itchynessText, blistersChoice: data.history.blistersChoice, blistersText: data.history.blistersText, lumpsChoice: data.history.lumpsChoice, lumpsText: data.history.lumpsText, ulcersChoice: data.history.ulcersChoice, ulcersText: data.history.ulcersText, swellingdemaChoice: data.history.swellingdemaChoice, swellingdemaText: data.history.swellingdemaText, dematologyNotes: data.history.dematologyNotes,
         /* Extra History */ 
        painText: data.history.painText, durationText: data.history.durationText, timingText: data.history.timingText, aggravatedByText: data.history.aggravatedByText, relievedByText: data.history.relievedByText, headacheLeftChoice: data.history.headacheLeftChoice, headacheLeftText: data.history.headacheLeftText, headacheRightChoice: data.history.headacheRightChoice, headacheRightText: data.history.headacheRightText, holocranialChoice: data.history.holocranialChoice, holocranialText: data.history.holocranialText, frontalChoice: data.history.frontalChoice, frontalText: data.history.frontalText, temporalChoice: data.history.temporalChoice, temporalText: data.history.temporalText, occipitalChoice: data.history.occipitalChoice, occipitalText: data.history.occipitalText, periorbitalChoice: data.history.periorbitalChoice, periorbitalText: data.history.periorbitalText, confusionChoice: data.history.confusionChoice, confusionText: data.history.confusionText, 
        changeinCharacterChoice: data.history.changeinCharacterChoice, changeinCharacterText: data.history.changeinCharacterText, weaknessChoice: data.history.weaknessChoice, weaknessText: data.history.weaknessText, hemiparesisLeftChoice: data.history.hemiparesisLeftChoice, hemiparesisLeftText: data.history.hemiparesisLeftText, hemiparesisRightChoice: data.history.hemiparesisRightChoice, hemiparesisRightText: data.history.hemiparesisRightText, weaknessHemiplegiaChoice: data.history.weaknessHemiplegiaChoice, weaknessHemiplegiaText: data.history.weaknessHemiplegiaText, hemiplegiaLeftChoice: data.history.hemiplegiaLeftChoice, hemiplegiaLeftText: data.history.hemiplegiaLeftText, hemiplegiaRightChoice: data.history.hemiplegiaRightChoice, hemiplegiaRightText: data.history.hemiplegiaRightText, paraparesisChoice: data.history.paraparesisChoice, paraparesisText: data.history.paraparesisText, paraplegiaChoice: data.history.paraplegiaChoice, paraplegiaText: data.history.paraplegiaText, quadriparesisChoice: data.history.quadriparesisChoice, quadriparesisText: data.history.quadriparesisText, 
        quadriplegiaChoice: data.history.quadriplegiaChoice, quadriplegiaText: data.history.quadriplegiaText, monoparesisChoice: data.history.monoparesisChoice, monoparesisText: data.history.monoparesisText, monoparesisLeftChoice: data.history.monoparesisLeftChoice, monoparesisLeftText: data.history.monoparesisLeftText, monoparesisRightChoice: data.history.monoparesisRightChoice, monoparesisRightText: data.history.monoparesisRightText, monoparesisUpperLimbChoice: data.history.monoparesisUpperLimbChoice, monoparesisUpperLimbText: data.history.monoparesisUpperLimbText, monoparesisLowerLimbChoice: data.history.monoparesisLowerLimbChoice, monoparesisLowerLimbText: data.history.monoparesisLowerLimbText, seizuresChoice: data.history.seizuresChoice, seizuresText: data.history.seizuresText, seizuresAuraChoice: data.history.seizuresAuraChoice, seizuresAuraText: data.history.seizuresAuraText, auraVisualChoice: data.history.auraVisualChoice, auraVisualText: data.history.auraVisualText, auraOlfactoryChoice: data.history.auraOlfactoryChoice, auraOlfactoryText: data.history.auraOlfactoryText, auraAutonomicChoice: data.history.auraAutonomicChoice, 
        auraAutonomicText: data.history.auraAutonomicText, thoracicChoice: data.history.thoracicChoice, thoracicText: data.history.thoracicText, lumbarChoice: data.history.lumbarChoice, lumbarText: data.history.lumbarText, lowerBackacheChoice: data.history.lowerBackacheChoice, lowerBackacheText: data.history.lowerBackacheText, mechanicalChoice: data.history.mechanicalChoice, mechanicalText: data.history.mechanicalText, sciaticaChoice: data.history.sciaticaChoice, sciaticaText: data.history.sciaticaText, sciaticaLeftChoice: data.history.sciaticaLeftChoice, sciaticaLeftText: data.history.sciaticaLeftText, sciaticaRightChoice: data.history.sciaticaRightChoice, sciaticaRightText: data.history.sciaticaRightText, cervicalPainChoice: data.history.cervicalPainChoice, cervicalPainText: data.history.cervicalPainText, interScapularPainChoice: data.history.interScapularPainChoice, interScapularPainText: data.history.interScapularPainText, paraesthesiaUpperLimbChoice: data.history.paraesthesiaUpperLimbChoice, 
        paraesthesiaUpperLimbText: data.history.paraesthesiaUpperLimbText, paraesthesiaLowerLimbChoice: data.history.paraesthesiaLowerLimbChoice, paraesthesiaLowerLimbText: data.history.paraesthesiaLowerLimbText, lossOfBalanceChoice: data.history.lossOfBalanceChoice, lossOfBalanceText: data.history.lossOfBalanceText, neurogenicClaudicationChoice: data.history.neurogenicClaudicationChoice, neurogenicClaudicationText: data.history.neurogenicClaudicationText, radiculapathyChoice: data.history.radiculapathyChoice, radiculapathyText: data.history.radiculapathyText, radiculapathyLeftChoice: data.history.radiculapathyLeftChoice, radiculapathyLeftText: data.history.radiculapathyLeftText, radiculapathyLeftC2Choice: data.history.radiculapathyLeftC2Choice, radiculapathyLeftC2Text: data.history.radiculapathyLeftC2Text, radiculapathyLeftC3Choice: data.history.radiculapathyLeftC3Choice, radiculapathyLeftC3Text: data.history.radiculapathyLeftC3Text, radiculapathyLeftC4Choice: data.history.radiculapathyLeftC4Choice, radiculapathyLeftC4Text: data.history.radiculapathyLeftC4Text, radiculapathyLeftC5Choice: data.history.radiculapathyLeftC5Choice, 
        radiculapathyLeftC5Text: data.history.radiculapathyLeftC5Text, radiculapathyLeftC6Choice: data.history.radiculapathyLeftC6Choice, radiculapathyLeftC6Text: data.history.radiculapathyLeftC6Text, radiculapathyLeftC7Choice: data.history.radiculapathyLeftC7Choice, radiculapathyLeftC7Text: data.history.radiculapathyLeftC7Text, radiculapathyLeftC8Choice: data.history.radiculapathyLeftC8Choice, radiculapathyLeftC8Text: data.history.radiculapathyLeftC8Text, radiculapathyLeftT1Choice: data.history.radiculapathyLeftT1Choice, radiculapathyLeftT1Text: data.history.radiculapathyLeftT1Text, radiculapathyLeftT2Choice: data.history.radiculapathyLeftT2Choice, radiculapathyLeftT2Text: data.history.radiculapathyLeftT2Text, radiculapathyLeftT3Choice: data.history.radiculapathyLeftT3Choice, radiculapathyLeftT3Text: data.history.radiculapathyLeftT3Text, radiculapathyLeftT4Choice: data.history.radiculapathyLeftT4Choice, radiculapathyLeftT4Text: data.history.radiculapathyLeftT4Text, radiculapathyLeftT5Choice: data.history.radiculapathyLeftT5Choice, radiculapathyLeftT5Text: data.history.radiculapathyLeftT5Text, radiculapathyLeftT6Choice: data.history.radiculapathyLeftT6Choice, 
        radiculapathyLeftT6Text: data.history.radiculapathyLeftT6Text, radiculapathyLeftT7Choice: data.history.radiculapathyLeftT7Choice, radiculapathyLeftT7Text: data.history.radiculapathyLeftT7Text, radiculapathyLeftT8Choice: data.history.radiculapathyLeftT8Choice, radiculapathyLeftT8Text: data.history.radiculapathyLeftT8Text, radiculapathyLeftT9Choice: data.history.radiculapathyLeftT9Choice, radiculapathyLeftT9Text: data.history.radiculapathyLeftT9Text, radiculapathyLeftT10Choice: data.history.radiculapathyLeftT10Choice, radiculapathyLeftT10Text: data.history.radiculapathyLeftT10Text, radiculapathyLeftT11Choice: data.history.radiculapathyLeftT11Choice, radiculapathyLeftT11Text: data.history.radiculapathyLeftT11Text, radiculapathyLeftT12Choice: data.history.radiculapathyLeftT12Choice, radiculapathyLeftT12Text: data.history.radiculapathyLeftT12Text, radiculapathyLeftL1Choice: data.history.radiculapathyLeftL1Choice, radiculapathyLeftL1Text: data.history.radiculapathyLeftL1Text, radiculapathyLeftL2Choice: data.history.radiculapathyLeftL2Choice, radiculapathyLeftL2Text: data.history.radiculapathyLeftL2Text, radiculapathyLeftL3Choice: data.history.radiculapathyLeftL3Choice, 
        radiculapathyLeftL3Text: data.history.radiculapathyLeftL3Text, radiculapathyLeftL4Choice: data.history.radiculapathyLeftL4Choice, radiculapathyLeftL4Text: data.history.radiculapathyLeftL4Text, radiculapathyLeftL5Choice: data.history.radiculapathyLeftL5Choice, radiculapathyLeftL5Text: data.history.radiculapathyLeftL5Text, radiculapathyLeftS1Choice: data.history.radiculapathyLeftS1Choice, radiculapathyLeftS1Text: data.history.radiculapathyLeftS1Text, radiculapathyLeftS3Choice: data.history.radiculapathyLeftS3Choice, radiculapathyLeftS3Text: data.history.radiculapathyLeftS3Text, radiculapathyLeftS45Choice: data.history.radiculapathyLeftS45Choice, radiculapathyLeftS45Text: data.history.radiculapathyLeftS45Text, radiculapathyRightChoice: data.history.radiculapathyRightChoice, radiculapathyRightText: data.history.radiculapathyRightText, radiculapathyRightC2Choice: data.history.radiculapathyRightC2Choice, radiculapathyRightC2Text: data.history.radiculapathyRightC2Text, radiculapathyRightC3Choice: data.history.radiculapathyRightC3Choice, radiculapathyRightC3Text: data.history.radiculapathyRightC3Text, radiculapathyRightC4Choice: data.history.radiculapathyRightC4Choice, 
        radiculapathyRightC4Text: data.history.radiculapathyRightC4Text, radiculapathyRightC5Choice: data.history.radiculapathyRightC5Choice, radiculapathyRightC5Text: data.history.radiculapathyRightC5Text, radiculapathyRightC6Choice: data.history.radiculapathyRightC6Choice, radiculapathyRightC6Text: data.history.radiculapathyRightC6Text, radiculapathyRightC7Choice: data.history.radiculapathyRightC7Choice, radiculapathyRightC7Text: data.history.radiculapathyRightC7Text, radiculapathyRightC8Choice: data.history.radiculapathyRightC8Choice, radiculapathyRightC8Text: data.history.radiculapathyRightC8Text, radiculapathyRightT1Choice: data.history.radiculapathyRightT1Choice, radiculapathyRightT1Text: data.history.radiculapathyRightT1Text, radiculapathyRightT2Choice: data.history.radiculapathyRightT2Choice, radiculapathyRightT2Text: data.history.radiculapathyRightT2Text, radiculapathyRightT3Choice: data.history.radiculapathyRightT3Choice, radiculapathyRightT3Text: data.history.radiculapathyRightT3Text, radiculapathyRightT4Choice: data.history.radiculapathyRightT4Choice, radiculapathyRightT4Text: data.history.radiculapathyRightT4Text, 
        radiculapathyRightT5Choice: data.history.radiculapathyRightT5Choice, radiculapathyRightT5Text: data.history.radiculapathyRightT5Text, radiculapathyRightT6Choice: data.history.radiculapathyRightT6Choice, radiculapathyRightT6Text: data.history.radiculapathyRightT6Text, radiculapathyRightT7Choice: data.history.radiculapathyRightT7Choice, radiculapathyRightT7Text: data.history.radiculapathyRightT7Text, radiculapathyRightT8Choice: data.history.radiculapathyRightT8Choice, radiculapathyRightT8Text: data.history.radiculapathyRightT8Text, radiculapathyRightT9Choice: data.history.radiculapathyRightT9Choice, radiculapathyRightT9Text: data.history.radiculapathyRightT9Text, radiculapathyRightT10Choice: data.history.radiculapathyRightT10Choice, radiculapathyRightT10Text: data.history.radiculapathyRightT10Text, radiculapathyRightT11Choice: data.history.radiculapathyRightT11Choice, radiculapathyRightT11Text: data.history.radiculapathyRightT11Text, radiculapathyRightT12Choice: data.history.radiculapathyRightT12Choice, radiculapathyRightT12Text: data.history.radiculapathyRightT12Text, radiculapathyRightL1Choice: data.history.radiculapathyRightL1Choice, 
        radiculapathyRightL1Text: data.history.radiculapathyRightL1Text, radiculapathyRightL2Choice: data.history.radiculapathyRightL2Choice, radiculapathyRightL2Text: data.history.radiculapathyRightL2Text, radiculapathyRightL3Choice: data.history.radiculapathyRightL3Choice, radiculapathyRightL3Text: data.history.radiculapathyRightL3Text, radiculapathyRightL4Choice: data.history.radiculapathyRightL4Choice, radiculapathyRightL4Text: data.history.radiculapathyRightL4Text, radiculapathyRightL5Choice: data.history.radiculapathyRightL5Choice, radiculapathyRightL5Text: data.history.radiculapathyRightL5Text, radiculapathyRightS1Choice: data.history.radiculapathyRightS1Choice, radiculapathyRightS1Text: data.history.radiculapathyRightS1Text, radiculapathyRightS3Choice: data.history.radiculapathyRightS3Choice, radiculapathyRightS3Text: data.history.radiculapathyRightS3Text, radiculapathyRightS45Choice: data.history.radiculapathyRightS45Choice, radiculapathyRightS45Text: data.history.radiculapathyRightS45Text,
        /*EXAMINATION */
        
        allergies: data.allergies,  planData: data.planData, chronicData: data.chronicData
    });
     }
    closePopup() {
        document.getElementById('neurologyAction').style = 'display:none'
    }
    processDate(date) {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
        return date1;


    }
    viewHistory(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/neurologysummaryview/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    viewConsolidated(visitId) {

        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/neurologysummaryviewconsolidated/' + visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Doctor Consolidated' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    showTreatmentRecord(patientId) {

        this.setState({ treatmentRecords: [] })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/neurologytreatmentlistview/' + patientId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ treatmentRecords: res.data });
                document.getElementById('treatmentRecord').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    genericClosePopup() {
        document.getElementById('genericPopup').style = 'display:none'
    }

    treatmentClosePopup() {
        document.getElementById('treatmentRecord').style = 'display:none'
    }
    viewResults() {
        document.getElementById('resultPopup').style = 'display:block'
    }
    resultClosePopup() {
        document.getElementById('resultPopup').style = 'display:none'
    }
    uploadFile(fileType, file) {
        if (file !== null || file !== '') {
            var data = new FormData();
            data.append("file", file)
            data.append("fileType", fileType)
            data.append("visitId", this.state.visitId)
            axios.post(localStorage.getItem('host') + '/file/filesave', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data'

                }
            }).then(
                res => {
                    console.log(res.data)
                    if (fileType === "REFERRAL_LETTERS") {
                        this.setState({ referralLetters: res.data, msg: 'Successfully uploaded Referral Letter' });
                    }
                    else {
                        this.setState({ notes: res.data, msg: 'Successfully uploaded Notes' });
                    }

                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }, err => {
                    console.log(".....")
                }
            )

        }
    }
    deleteFile(id, fileExtension, fileType) {
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/deletefile/' + id + '/' + fileType + '/' + fileExtension + '/' + this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                if (fileType === "REFERRAL_LETTERS") {
                    this.setState({ referralLetters: res.data, msg: 'Successfully deleted Referral Letter' });
                }
                else {
                    this.setState({ notes: res.data, msg: 'Successfully deleted Notes' });
                }
                this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.setLoader(false)
            }
        )
    }
    showFile(id, fileExtension) {
        this.setState({ fileData: localStorage.getItem('host') + '/file/pdfview/' + id + '/' + fileExtension });
        document.getElementById('showFileNurseAction').style = 'display:block'

    }
    fileNurseClosePopup() {
        this.setState({ fileData: '' });
        document.getElementById('showFileNurseAction').style = 'display:none'
    }
    showOldNurse(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get('https://ucare.kimbah.net/old/nurselistview/' + oldPatient + '/500', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                //   this.setState({ htmlText: res.data, popupName: 'Nurse History'});
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    showOldResults(oldPatient) {
        this.setState({ htmlText: '', popupName: '' })
        this.setLoader(true)
        axios.get('https://ucare.kimbah.net/old/nurselistview/' + oldPatient + '/500', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ htmlText: res.data, popupName: 'Nurse History' });
                document.getElementById('genericPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.setLoader(false)
            }
        )
    }
    toggleTabs(name) {
        switch (name) {
            case "diagnosis":
                this.setState({ diagnosisTab: true, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false, feedbackLetter: false })
                break
            case "examination":
                this.setState({ diagnosisTab: false, history: false, examination: true, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: false})
                break
            case "investigation":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: true, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: false})
                break
            case "treatment":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: true, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: false})
                break
            case "feedbackLetter":
                    this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: true})
                    break
            case "rehabilitation":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: true, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: false})
                break
            case "referral":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: true, medicalCertificate: false, review: false, plan: false, graphical: false , feedbackLetter: false})
                break
            case "medicalCertificate":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: true, review: false, plan: false, graphical: false, feedbackLetter: false })
                break
            case "review":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: true, plan: false, graphical: false, feedbackLetter: false })
                break
            case "plan":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: true, graphical: false, feedbackLetter: false })
                break
            case "graphical":
                this.setState({ diagnosisTab: false, history: false, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: true, feedbackLetter: false })
                break
            default:
                this.setState({ diagnosisTab: false, history: true, examination: false, investigation: false, treatment: false, rehabilitation: false, referral: false, medicalCertificate: false, review: false, plan: false, graphical: false, feedbackLetter: false })
                break

        }
    }
    addReviewTable(reviewDate, reviewTime) {
        let list = this.state.reviews.slice();
        list.push({ id: 0, reviewDate: reviewDate, reviewTime: reviewTime, remarks: '', activeStatus: 1 });
        this.setState({ reviews: list });
    }

    addMedicalCertificateTable() {
        let list = this.state.medicalCertificates.slice();
        list.push({ id: 0, name: '', startDate: date1, endDate: date1, activeStatus: 1 });
        this.setState({ medicalCertificates: list });
    }
    addRehabilitationTable() {
        let list = this.state.rehabilitations.slice();
        list.push({ id: 0, type: '', remarks: '', activeStatus: 1 });
        this.setState({ rehabilitations: list });
    }
    addInvestigationTable() {
        let list = this.state.investigations.slice();
        list.push({ id: 0, name: '', activeStatus: 1 });
        this.setState({ investigations: list });
    }
    addReferralTable() {
        let list = this.state.referrals.slice();
        list.push({ id: 0, consultant: '', referralNotes: '', activeStatus: 1 });
        this.setState({ referrals: list });
    }
    addTreatmentTable() {
        let list = this.state.treatments.slice();
        list.push({ id: 0, dosage: '', drugName: '', formulationName: '', frequencyName: '', routeName: 'po', durationName: '', repeats: '', activeStatus: 1 });
        this.setState({ treatments: list });
    }
    deleteReview(index) {
        let list = this.state.reviews.slice();
        list[index].activeStatus = 0
        this.setState({ reviews: list });
    }

    deleteMedicalCertificate(index) {
        let list = this.state.medicalCertificates.slice();
        list[index].activeStatus = 0
        this.setState({ medicalCertificates: list });
    }
    deleteRehabilitation(index) {
        let list = this.state.rehabilitations.slice();
        list[index].activeStatus = 0
        this.setState({ rehabilitations: list });
    }
    deleteInvestigation(index) {
        let list = this.state.investigations.slice();
        list[index].activeStatus = 0
        this.setState({ investigations: list });
    }
    deleteReferral(index) {
        let list = this.state.referrals.slice();
        list[index].activeStatus = 0
        this.setState({ referrals: list });
    }
    deleteTreatment(index) {
        let list = this.state.treatments.slice();
        list[index].activeStatus = 0
        this.setState({ treatments: list });
    }
    setReview(index, name, value) {
        let list = this.state.reviews.slice();
        switch (name) {
            case "reviewDate":
                list[index].reviewDate = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ reviews: list });
    }

    setMedicalCertificate(index, name, value) {
        let list = this.state.medicalCertificates.slice();
        switch (name) {
            case "name":
                list[index].name = value
                break;
            case "startDate":
                list[index].startDate = value
                break;
            default:
                list[index].endDate = value
                break;
        }
        this.setState({ medicalCertificates: list });
    }
    setRehabilitation(index, name, value) {
        let list = this.state.rehabilitations.slice();
        switch (name) {
            case "type":
                list[index].type = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ rehabilitations: list });
    }
    setInvestigation(index, value) {
        let list = this.state.investigations.slice();
        for (var i in this.state.services) {
            if (this.state.services[i].value === value) {
                list[index].name = value
                list[index].speciality = this.state.services[i].speciality
            }
        }
        this.setState({ investigations: list });
    }
    setReferral(index, name, value) {
        let list = this.state.referrals.slice();
        switch (name) {
            case "consultant":
                list[index].consultant = value
                break;
            default:
                list[index].referralNotes = value
                break;
        }
        this.setState({ referrals: list });
    }
    setTreatment(index, name, value) {
        let list = this.state.treatments.slice();
        switch (name) {
            case "dosage":
                list[index].dosage = value
                break;
            case "drugName":
                list[index].drugName = value
                break;
            case "formulationName":
                list[index].formulationName = value
                break;
            case "frequencyName":
                list[index].frequencyName = value
                break;
            case "routeName":
                list[index].routeName = value
                break;
            case "durationName":
                list[index].durationName = value
                break;
            default:
                list[index].repeats = value
                break;
        }
        this.setState({ treatments: list });
    }

    toggleHistoryTabs(name) {
        switch (name) {
            case "historyOfPresentingComplaintsT":
                this.setState({ historyOfPresentingComplaintsT: true, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false })
                break
            case "systemicReviewT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: true, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false })
                break
            case "socialHistoryT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: true, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false })
                break
            case "familyHistoryT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: true, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false })
                break
            case "commonSymptomsT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: true, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false })
                break
            case "git":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: true, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false })
                break
            case "gus":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: true, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false })
                break
            case "gynae":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: true, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false })
                break
            case "cvs":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: true, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false })
                break
            case "cns":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: true, constitutional: false, mss: false, dermatology: false, sleepT: false })
                break
            case "constitutional":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: true, mss: false, dermatology: false, sleepT: false })
                break
            case "mss":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: true, dermatology: false, sleepT: false })
                break
            case "dermatology":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: true, sleepT: false })
                break
            case "respiratory":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: true, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false })
                break
            case "pastMedicalHistoryT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: true, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false })
                break
            case "currentMedicationT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: true, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false })
                break
            case "allergyHistoryT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: true, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: false })
                break
            case "sleepT":
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: false, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false, sleepT: true })
                break
            default:
                this.setState({ historyOfPresentingComplaintsT: false, systemicReviewT: false, socialHistoryT: false, familyHistoryT: false, commonSymptomsT: false, presentingComplaintsT: true, pastMedicalHistoryT: false, currentMedicationT: false, allergyHistoryT: false, respiratory: false, git: false, gus: false, gynae: false, cvs: false, cns: false, constitutional: false, mss: false, dermatology: false , sleepT: false})
                break

        }
    }
    getDiagnosisList() {
        this.setState({ diagnosisList: [], diagnosis: '' })
        console.log('..... i am ')
        if (this.state.searchDiagnosis !== null && this.state.searchDiagnosis !== "") {
            console.log('..... i am ')
            this.setLoader(true)
            axios.get(localStorage.getItem('host') + '/master/diagnosislist?text=' + this.state.searchDiagnosis, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ diagnosisList: res.data });
                    this.setLoader(false)
                }, err => {
                    this.setState({ error: err + "" })
                    this.setLoader(false)
                }
            )

        }
    }
    insertDiagnosis() {
        if (this.state.diagnosis !== null && this.state.diagnosis !== '') {
            let list = this.state.patientDiagnosis.slice();
            for (var i in this.state.diagnosisList) {
                if(this.state.diagnosisList[i].code === this.state.diagnosis)
                   {
                    list.push({ id: 0, code: this.state.diagnosis, description: this.state.diagnosis+" - "+this.state.diagnosisList[i].description, activeStatus: 1 });
                   }
            }
            this.setState({ patientDiagnosis: list });
        }

    }
    deleteDiagnosis(index) {
        let list = this.state.patientDiagnosis.slice();
        list[index].activeStatus = 0
        this.setState({ patientDiagnosis: list });
    }
    setFeedbackItem(index, value)
     {
        let list = this.state.dateLists.slice();
        list[index].checked = value
        this.setState({ dateLists: list }); 
     }
    updateAfterSave(visitId, results) {
        if (results.length > 0) {
            this.setState({ results: results })
        }
        else {
            axios.get(localStorage.getItem('host') + '/patientmanagement/neurologynursetrimmed/' + visitId, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ weight: res.data.weight, height: res.data.height, waist: res.data.waist, headCircumference: res.data.headCircumference, muac: res.data.muac, bmi: res.data.bmi, waistHeightRatio: res.data.waistHeightRatio, nurseNotes: res.data.nurseNotes, vitalColors: res.data.vitalColors });
                }, err => {
                    this.setState({ error: err + "" })
                }
            )
        }
    }
    openGraphAction(type) {
        this.setState({ line: { labels: [], datasets: [], }, lineName: type === "HEIGHT" ? "Height Graph" : "Weight Graph" })
        this.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/getgraphdata/' + this.state.patientId + '/' + type, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ line: res.data });
                document.getElementById('graphPopup').style = 'display:block'
                this.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
            }
        )
    }
    graphClosePopup() {
        this.setState({ line: { labels: [], datasets: [], }, lineName: '' });
        document.getElementById('graphPopup').style = 'display:none'
    }
    showHideFromCheckBox(checked, id) {
        document.getElementById(id).style = 'display:' + (checked === true ? 'block' : 'none')
    }
    insertPresentingComplaintList()
    {
        let list = this.state.presentingComplaintList.slice();
        list.push({complaint: '', duration: ''})
        this.setState({ presentingComplaintList: list });  
    }
    setPresentingComplaintList(index, name, value) {
        let list = this.state.presentingComplaintList.slice();
        switch (name) {
            case "complaint":
                list[index].complaint = value
                break;
            default:
                list[index].duration = value
                break;
        }
        this.setState({ presentingComplaintList: list });
    }
    deletePresentingComplaint(i)
    {
    let list = this.state.presentingComplaintList.slice();   
    list.splice(i, 1);
    this.setState({ presentingComplaintList: list }); 
    }
    setLoader(loader)
      {
       this.setState({loader: loader})
      }
      getReviewTime(reviewTime)
         {
          var time = ''
          for(var j in this.state.appointmentTimes)
          {
           if(Number(reviewTime) === Number(this.state.appointmentTimes[j].id))
              {
               time = this.state.appointmentTimes[j].name
              }
          }
          return time
         }   
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (<div id="neurologyAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info" id="topDiv">Neurology Action</div>
            <div className="card-body" >
                {divMsg}
                {loaderDiv}
                <div id="genericPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">{this.state.popupName}</div>
                        <div className="card-body" >
                            <span dangerouslySetInnerHTML={{ __html: this.state.htmlText }}></span>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.genericClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="treatmentRecord" class="page-popup page-popup-overlay"><div class="card" >
                    <div class="card-header   bg-info">Treatment Record</div>
                    <div class="card-body" >
                        {this.state.treatmentRecords.map((item, index) => (
                            <div class="row" key={index} >
                                <div class="col-xs-12 col-md-12 col-lg-12">
                                    <div class="card border-success">
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="col"><span className="fw-bold">Date:</span> {item.treatmentDateTime}</div>
                                                <div className="col"><span className="fw-bold"></span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table table-striped" >
                                        <tr>
                                            <th>Drug</th>
                                            <th>Dosage</th>
                                            <th>Frequency</th>
                                            <th>Route</th>
                                            <th>Duration</th>
                                            <th>Formulation</th>
                                            <th>Repeats</th>
                                        </tr>
                                        <tbody>
                                            {item.treatments.map((treatment, i) => (
                                                <tr key={i} >
                                                    <td>{treatment.drugName}</td>
                                                    <td>{treatment.dosage}</td>
                                                    <td>{treatment.frequencyName}</td>
                                                    <td>{treatment.routeName}</td>
                                                    <td> {treatment.durationName}</td>
                                                    <td>{treatment.formulationName}</td>
                                                    <td>{treatment.repeats}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div class="row">
                        <div class="col" align="center" ><input class="btn btn-primary" type='button' onClick={() => this.treatmentClosePopup()} value="Close" /></div>
                    </div>
                </div>
                </div>
                <div id="resultPopup" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">Result View</div>
                        <div className="card-body" >
                            <table className="table table-striped">
                                <tr>
                                    <th>Treatment Date</th>
                                    <th>File</th>
                                    <th>Remarks</th>
                                </tr>
                                <tbody>
                                    {this.state.results.map((item, index) => (
                                        <tr key={index}>
                                        <td>{item.visitDate}</td>
                                            {item.fileStorageId > 0 ? (<td><span className="link" onClick={() => this.showFile(item.fileStorageId, item.fileExtension)}>{item.fileName}</span></td>) : (<td></td>)}
                                            <td>{item.remarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.resultClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="showFileNurseAction" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                        <iframe title="File View" src={this.state.fileData} type="application/pdf" style={{ width: 100 + '%', height: 500 + 'px' }}></iframe>
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-2">
                        {this.state.id > 0 && (<div ><span className="link" onClick={() => this.props.getPrintOut(this.state.visitId, 'NEUROLOGY', [], this.state.companyId)} title="Print" ><i className="bi bi-printer-fill" style={{ fontSize: 3 + 'em' }}></i></span></div>)}
                        <div class="row">
                            <div class="col">
                                <ul class="nav nav-pills  d-flex flex-column" id="pills-tab" role="tablist">
                                    <li className="nav-item"><span className={this.state.history ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("history") }}>History</span></li>
                                    <li className="nav-item"><span className={this.state.examination ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("examination") }}>Examination</span></li>
                                    <li className="nav-item"><span className={this.state.investigation ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("investigation") }}>Investigations</span></li>
                                    <li className="nav-item"><span className={this.state.diagnosisTab ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("diagnosis") }}>Diagnosis</span></li>
                                    <li className="nav-item"><span className={this.state.treatment ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("treatment") }}>Prescription</span></li>
                                    <li className="nav-item"><span className={this.state.rehabilitation ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("rehabilitation") }}>Rehabilitation</span></li>
                                    <li className="nav-item"><span className={this.state.referral ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("referral") }}>Referral</span></li>
                                    <li className="nav-item"><span className={this.state.medicalCertificate ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("medicalCertificate") }}>Medical Certificate</span></li>
                                    <li className="nav-item"><span className={this.state.review ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("review") }}>Review</span></li>
                                    <li className="nav-item"><span className={this.state.graphical ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("graphical") }}>Graphical</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="card  card-green" >
                            <div className="card-header  text-dark">Other Info</div>
                            <div className="card-body">
                                <ul>
                                    {this.state.results.length > 0 && (<li><span className="link" onClick={() => this.viewResults()}>View Results</span></li>)}
                                    <li><span className="link" onClick={() => this.showTreatmentRecord(this.state.patientId)}>View Prescription Records</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8">
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">Bill:</span> {this.state.idNumber}</div>
                                    <div className="col"><span className="fw-bold">Cell No:</span> {this.state.mobile}</div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Referring Doctor:</span> {this.state.referringDoctorName}</div>
                                    <div className="col"></div>
                                    <div className="col"></div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-success">
                            <div className="card-body">
                                <table className="table" id="tableNurse">
                                    <tr>
                                        <th>Time</th>
                                        <th>Temperature</th>
                                        <th>Bp</th>
                                        <th>Pulse (bmp)</th>
                                        <th>Glucometer (mmol/l)</th>
                                        <th>SpO2 (%)</th>
                                    </tr>
                                    <tbody>
                                        {this.state.vitalColors.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.vitalTime}</td>
                                                <td style={{ color: item.temperatureColor }}>{item.temperature}</td>
                                                <td><span style={{ color: item.systolicColor }}>{item.systolic}</span>/<span style={{ color: item.diastolicColor }}>{item.diastolic}</span></td>
                                                <td style={{ color: item.pulseColor }}>{item.pulse}</td>
                                                <td style={{ color: item.glucometerColor }}>{item.glucometer}</td>
                                                <td style={{ color: item.spO2Color }}>{item.spO2}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><div className="input-group">
                                        <span className="input-group-text">Problems</span><textarea className="form-control" value={this.state.diagnosisText} onChange={e => { this.setState({ diagnosisText: e.target.value }) }} ></textarea> </div>
                                    </div>
                                    <div className="col"><div className="input-group">
                                        <span className="input-group-text">Chronic Conditions</span><textarea className="form-control" value={this.state.chronicData} onChange={e => { this.setState({ chronicData: e.target.value }) }} ></textarea> </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Plan</span>
                                        <textarea className="form-control" value={this.state.planData} onChange={e => this.setState({ planData: e.target.value })} ></textarea></div></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" align="right"><button onClick={() => this.save()} className="btn btn-primary">Save</button></div>
                            <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                            <div className="col" align="right"><button onClick={() => this.props.openNurseAction(this.state.visitId)} className="btn btn-primary">Edit Nurse Details</button></div>
                            <div className="col" align="left"><button onClick={() => this.props.getResultAction(this.state.visitId)} className="btn btn-primary">Upload Results</button></div>
                          { localStorage.getItem('nuero_data') !== null && localStorage.getItem('nuero_data') !== "" && localStorage.getItem('nuero_data').length > 5 &&( <div className="col" align="left"><button onClick={() => this.getFromLocalStorage()} className="btn btn-primary">Retrive From Temp</button></div>)}
                        </div>
                        <br />
                        <div className="tab-content" id="myTabContent">
                            <div className={this.state.diagnosisTab ? "tab-pane fade show active" : "tab-pane fade"} >
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Diagnosis
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xs-12 col-md-12 col-lg-12">
                                                <a href="https://icd.who.int/browse10/2019/en" target="_blank" rel="noreferrer">Check Online</a>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col"><div className="input-group">
                                                <input type="text" value={this.state.searchDiagnosis} onChange={e => { this.setState({ searchDiagnosis: e.target.value }) }} className="form-control" placeholder="Search ICD10 Diagnosis Here" />
                                                <button className="btn btn-success" onClick={() => this.getDiagnosisList()} ><i class="bi bi-search"></i></button>
                                            </div>
                                            </div>
                                            </div>
                                    { this.state.diagnosisList.length > 0 &&(<div className="row">
                                        <div className="col"><div className="input-group"> 
                                        <select class="form-select" size={this.state.diagnosisList.length}  onChange={e => this.setState({ diagnosis: e.target.value })} aria-label="size 3 select example">
                                     <option value="">Choose Diagnosis</option>
                                        {this.state.diagnosisList.map((item, index) =>(  
  <option key={index} value={item.code}>{item.code} - {item.description}</option>
  ))}
</select><button onClick={() => this.insertDiagnosis()} className="btn btn-success" ><i class="bi bi-plus-circle-fill"></i></button></div></div>
                                    </div> )}
                                        <table className="table">
                                            <tr>
                                                <th>Description</th>
                                                <th>Active Status</th>
                                            </tr>
                                            {this.state.patientDiagnosis.map((item, index) => (item.activeStatus === 1 && (
                                                <tr>
                                                    <td>{item.description}</td>
                                                    <td><span className="link-red" onClick={() => this.deleteDiagnosis(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                </tr>)))}
                                        </table>
                                    </div></div>
                            </div>
                            <div className={this.state.history ? "tab-pane fade show active" : "tab-pane fade"} >
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        History
                                    </div>
                                    <div className="card-body">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item"><span className={this.state.presentingComplaintsT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("presentingComplaintsT") }}>PRESENTING COMPLAINTS</span></li>
                                            <li className="nav-item"><span className={this.state.historyOfPresentingComplaintsT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("historyOfPresentingComplaintsT") }}>HISTORY OF PRESENTING COMPLAINTS</span></li>
                                            <li className="nav-item"><span className={this.state.systemicReviewT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("systemicReviewT") }}>SYSTEMIC REVIEW</span></li>
                                            <li className="nav-item"><span className={this.state.pastMedicalHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("pastMedicalHistoryT") }}>PAST MEDICAL HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.allergyHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("allergyHistoryT") }}>DRUG AND ALLERGY HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.socialHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("socialHistoryT") }}>SOCIAL HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.familyHistoryT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("familyHistoryT") }}>FAMILY HISTORY</span></li>
                                            <li className="nav-item"><span className={this.state.commonSymptomsT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("commonSymptomsT") }}>COMMON SYMPTOMS</span></li>
                                            <li className="nav-item"><span className={this.state.respiratory ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("respiratory") }}>RESPIRATORY</span></li>
                                            <li className="nav-item"><span className={this.state.git ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("git") }}>GIT</span></li>
                                            <li className="nav-item"><span className={this.state.gus ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("gus") }}>GUS</span></li>
                                            <li className="nav-item"><span className={this.state.gynae ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("gynae") }}>GYNAE/OBS</span></li>
                                            <li className="nav-item"><span className={this.state.cvs ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("cvs") }}>CVS</span></li>
                                            <li className="nav-item"><span className={this.state.cns ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("cns") }}>CNS</span></li>
                                            <li className="nav-item"><span className={this.state.constitutional ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("constitutional") }}>CONSTITUTIONAL</span></li>
                                            <li className="nav-item"><span className={this.state.mss ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("mss") }}>MSS</span></li>
                                            <li className="nav-item"><span className={this.state.dermatology ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("dermatology") }}>DERMATOLOGY</span></li>
                                            <li className="nav-item"><span className={this.state.sleepT ? "nav-link active" : "nav-link"} onClick={() => { this.toggleHistoryTabs("sleepT") }}>SLEEP</span></li>
                                        </ul>
                                        <br />
                                        <div className="tab-content">
                                            <div className={this.state.historyOfPresentingComplaintsT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.historyOfPresentingComplaints} onChange={e => { this.setState({ historyOfPresentingComplaints: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.pastMedicalHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.pastMedicalHistory} onChange={e => { this.setState({ pastMedicalHistory: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.sleepT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.sleep} onChange={e => { this.setState({ sleep: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.presentingComplaintsT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <table class="table" >
                                                    <tr>
                                                        <th>Complaint</th>
                                                        <th>Duration</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tbody>
                                                        {this.state.presentingComplaintList.map((item, index) => (
                                                            <tr key={index}>
                                                                <td><input type="text" className="form-control" value={item.complaint} onChange={e => { this.setPresentingComplaintList(index, 'complaint', e.target.value); }} /></td>
                                                                <td><input type="text" className="form-control" value={item.duration} onChange={e => { this.setPresentingComplaintList(index, 'duration', e.target.value); }} /></td>
                                                                <td><span className="link-red" onClick={() => this.deletePresentingComplaint(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div class="row">
                                                    <div class="col-lg-12 form-inline"><input type="button" onClick={() => this.insertPresentingComplaintList()} class="btn btn-success" value="Insert New Line" /></div>
                                                </div>
                                            </div>
                                            <div className={this.state.systemicReviewT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div ><div className="input-group">
                                                        <textarea className="form-control" value={this.state.systemicReview} onChange={e => { this.setState({ systemicReview: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.socialHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div ><div className="input-group">
                                                        <textarea className="form-control" value={this.state.socialHistory} onChange={e => { this.setState({ socialHistory: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.familyHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div ><div className="input-group">
                                                        <textarea className="form-control" value={this.state.familyHistory} onChange={e => { this.setState({ familyHistory: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.commonSymptomsT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Pain ?/10 </span><input type="text" value={this.state.painText} onChange={e => this.setState({ painText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Pain Frequency </span> <input type="text" value={this.state.frequencyText} onChange={e => this.setState({ frequencyText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Pain Duration </span> <input type="text" value={this.state.durationText} onChange={e => this.setState({ durationText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Pain Timing </span><input type="text" value={this.state.timingText} onChange={e => this.setState({ timingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Pain Aggravated By</span> <input type="text" value={this.state.aggravatedByText} onChange={e => this.setState({ aggravatedByText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Pain Relieved By </span><input type="text" value={this.state.relievedByText} onChange={e => this.setState({ relievedByText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.headacheChoice === "1"} onChange={e => this.setState({ headacheChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Headache </span> <input type="text" value={this.state.headacheText} onChange={e => this.setState({ headacheText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.headacheChoice === "1" ? 'block' : 'none' }} ><div class="row">   <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.headacheLeftChoice === "1"} onChange={e => this.setState({ headacheLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Headache Left </span> <input type="text" value={this.state.headacheLeftText} onChange={e => this.setState({ headacheLeftText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.headacheRightChoice === "1"} onChange={e => this.setState({ headacheRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right </span><input type="text" value={this.state.headacheRightText} onChange={e => this.setState({ headacheRightText: e.target.value })} className="form-control" /></div></div>

                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.holocranialChoice === "1"} onChange={e => this.setState({ holocranialChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Holocranial</span>  <input type="text" value={this.state.holocranialText} onChange={e => this.setState({ holocranialText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.frontalChoice === "1"} onChange={e => this.setState({ frontalChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Frontal</span> <input type="text" value={this.state.frontalText} onChange={e => this.setState({ frontalText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.temporalChoice === "1"} onChange={e => this.setState({ temporalChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Temporal</span> <input type="text" value={this.state.temporalText} onChange={e => this.setState({ temporalText: e.target.value })} className="form-control" /></div></div>

                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.occipitalChoice === "1"} onChange={e => this.setState({ occipitalChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Occipital</span> <input type="text" value={this.state.occipitalText} onChange={e => this.setState({ occipitalText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.periorbitalChoice === "1"} onChange={e => this.setState({ periorbitalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Periorbital</span>  <input type="text" value={this.state.periorbitalText} onChange={e => this.setState({ periorbitalText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.confusionChoice === "1"} onChange={e => this.setState({ confusionChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Confusion </span> <input type="text" value={this.state.confusionText} onChange={e => this.setState({ confusionText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.vomitingChoice === "1"} onChange={e => this.setState({ vomitingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Vomiting  </span> <input type="text" value={this.state.vomitingText} onChange={e => this.setState({ vomitingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.changeinCharacterChoice === "1"} onChange={e => this.setState({ changeinCharacterChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Change in Character </span><input type="text" value={this.state.changeinCharacterText} onChange={e => this.setState({ changeinCharacterText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.weaknessChoice === "1"} onChange={e => this.setState({ weaknessChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Weakness</span> <input type="text" value={this.state.weaknessText} onChange={e => this.setState({ weaknessText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.weaknessChoice === "1" ? 'block' : 'none' }}>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hemiparesisLeftChoice === "1"} onChange={e => this.setState({ hemiparesisLeftChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hemiparesis Left</span> <input type="text" value={this.state.hemiparesisLeftText} onChange={e => this.setState({ hemiparesisLeftText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hemiparesisRightChoice === "1"} onChange={e => this.setState({ hemiparesisRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hemiparesis Right</span> <input type="text" value={this.state.hemiparesisRightText} onChange={e => this.setState({ hemiparesisRightText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.weaknessHemiplegiaChoice === "1"} onChange={e => this.setState({ weaknessHemiplegiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Weakness Hemiplegia</span>  <input type="text" value={this.state.weaknessHemiplegiaText} onChange={e => this.setState({ weaknessHemiplegiaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hemiplegiaLeftChoice === "1"} onChange={e => this.setState({ hemiplegiaLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Hemiplegia Left</span>  <input type="text" value={this.state.hemiplegiaLeftText} onChange={e => this.setState({ hemiplegiaLeftText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hemiplegiaRightChoice === "1"} onChange={e => this.setState({ hemiplegiaRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Hemiplegia Right </span>  <input type="text" value={this.state.hemiplegiaRightText} onChange={e => this.setState({ hemiplegiaRightText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paraparesisChoice === "1"} onChange={e => this.setState({ paraparesisChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Paraparesis</span>  <input type="text" value={this.state.paraparesisText} onChange={e => this.setState({ paraparesisText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paraplegiaChoice === "1"} onChange={e => this.setState({ paraplegiaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Paraplegia</span>   <input type="text" value={this.state.paraplegiaText} onChange={e => this.setState({ paraplegiaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.quadriparesisChoice === "1"} onChange={e => this.setState({ quadriparesisChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Quadriparesis </span> <input type="text" value={this.state.quadriparesisText} onChange={e => this.setState({ quadriparesisText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.quadriplegiaChoice === "1"} onChange={e => this.setState({ quadriplegiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Quadriplegia </span> <input type="text" value={this.state.quadriplegiaText} onChange={e => this.setState({ quadriplegiaText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.monoparesisChoice === "1"} onChange={e => this.setState({ monoparesisChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Monoparesis </span>  <input type="text" value={this.state.monoparesisText} onChange={e => this.setState({ monoparesisText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.monoparesisLeftChoice === "1"} onChange={e => this.setState({ monoparesisLeftChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text">Monoparesis Left</span>  <input type="text" value={this.state.monoparesisLeftText} onChange={e => this.setState({ monoparesisLeftText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.monoparesisRightChoice === "1"} onChange={e => this.setState({ monoparesisRightChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Monoparesis Right</span>  <input type="text" value={this.state.monoparesisRightText} onChange={e => this.setState({ monoparesisRightText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.monoparesisUpperLimbChoice === "1"} onChange={e => this.setState({ monoparesisUpperLimbChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Monoparesis Upper Limb</span>  <input type="text" value={this.state.monoparesisUpperLimbText} onChange={e => this.setState({ monoparesisUpperLimbText: e.target.value })} className="form-control" /></div></div>
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.monoparesisLowerLimbChoice === "1"} onChange={e => this.setState({ monoparesisLowerLimbChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Monoparesis Lower Limb</span>  <input type="text" value={this.state.monoparesisLowerLimbText} onChange={e => this.setState({ monoparesisLowerLimbText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.seizuresChoice === "1"} onChange={e => this.setState({ seizuresChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Seizures </span> <input type="text" value={this.state.seizuresText} onChange={e => this.setState({ seizuresText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.seizuresChoice === "1" ? 'block' : 'none' }} > <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.seizuresAuraChoice === "1"} onChange={e => this.setState({ seizuresAuraChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Seizures Aura </span> <input type="text" value={this.state.seizuresAuraText} onChange={e => this.setState({ seizuresAuraText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.auraVisualChoice === "1"} onChange={e => this.setState({ auraVisualChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Aura Visual </span>  <input type="text" value={this.state.auraVisualText} onChange={e => this.setState({ auraVisualText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.auraOlfactoryChoice === "1"} onChange={e => this.setState({ auraOlfactoryChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Aura Olfactory</span> <input type="text" value={this.state.auraOlfactoryText} onChange={e => this.setState({ auraOlfactoryText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.auraAutonomicChoice === "1"} onChange={e => this.setState({ auraAutonomicChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Aura Autonomic </span>  <input type="text" value={this.state.auraAutonomicText} onChange={e => this.setState({ auraAutonomicText: e.target.value })} className="form-control" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.backacheChoice === "1"} onChange={e => this.setState({ backacheChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Backache </span> <input type="text" value={this.state.backacheText} onChange={e => this.setState({ backacheText: e.target.value })} className="form-control" /></div> </div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.backacheChoice === "1" ? 'block' : 'none' }} > <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.thoracicChoice === "1"} onChange={e => this.setState({ thoracicChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Thoracic </span> <input type="text" value={this.state.thoracicText} onChange={e => this.setState({ thoracicText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lumbarChoice === "1"} onChange={e => this.setState({ lumbarChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Lumbar </span>  <input type="text" value={this.state.lumbarText} onChange={e => this.setState({ lumbarText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lowerBackacheChoice === "1"} onChange={e => this.setState({ lowerBackacheChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Lower Backache</span> <input type="text" value={this.state.lowerBackacheText} onChange={e => this.setState({ lowerBackacheText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.mechanicalChoice === "1"} onChange={e => this.setState({ mechanicalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Mechanical </span>  <input type="text" value={this.state.mechanicalText} onChange={e => this.setState({ mechanicalText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sciaticaChoice === "1"} onChange={e => this.setState({ sciaticaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Sciatica </span> <input type="text" value={this.state.sciaticaText} onChange={e => this.setState({ sciaticaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.sciaticaChoice === "1" ? 'block' : 'none' }} > <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sciaticaLeftChoice === "1"} onChange={e => this.setState({ sciaticaLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left </span> <input type="text" value={this.state.sciaticaLeftText} onChange={e => this.setState({ sciaticaLeftText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sciaticaRightChoice === "1"} onChange={e => this.setState({ sciaticaRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right </span>  <input type="text" value={this.state.sciaticaRightText} onChange={e => this.setState({ sciaticaRightText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.cervicalPainChoice === "1"} onChange={e => this.setState({ cervicalPainChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Cervical Pain </span>  <input type="text" value={this.state.cervicalPainText} onChange={e => this.setState({ cervicalPainText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.interScapularPainChoice === "1"} onChange={e => this.setState({ interScapularPainChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Inter Scapular Pain </span>  <input type="text" value={this.state.interScapularPainText} onChange={e => this.setState({ interScapularPainText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paraesthesiaChoice === "1"} onChange={e => this.setState({ paraesthesiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Paraesthesia </span> <input type="text" value={this.state.paraesthesiaText} onChange={e => this.setState({ paraesthesiaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.paraesthesiaChoice === "1" ? 'block' : 'none' }}> <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paraesthesiaUpperLimbChoice === "1"} onChange={e => this.setState({ paraesthesiaUpperLimbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Upper Limb </span> <input type="text" value={this.state.paraesthesiaUpperLimbText} onChange={e => this.setState({ paraesthesiaUpperLimbText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paraesthesiaLowerLimbChoice === "1"} onChange={e => this.setState({ paraesthesiaLowerLimbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Lower Limb </span>  <input type="text" value={this.state.paraesthesiaLowerLimbText} onChange={e => this.setState({ paraesthesiaLowerLimbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lossOfBalanceChoice === "1"} onChange={e => this.setState({ lossOfBalanceChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Loss of balance </span>  <input type="text" value={this.state.lossOfBalanceText} onChange={e => this.setState({ lossOfBalanceText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neurogenicClaudicationChoice === "1"} onChange={e => this.setState({ neurogenicClaudicationChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Neurogenic Claudication </span>  <input type="text" value={this.state.neurogenicClaudicationText} onChange={e => this.setState({ neurogenicClaudicationText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyChoice === "1"} onChange={e => this.setState({ radiculapathyChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Radiculopathy </span> <input type="text" value={this.state.radiculapathyText} onChange={e => this.setState({ radiculapathyText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div class="card border-warning" style={{ display: this.state.radiculapathyChoice === "1" ? 'block' : 'none' }}> <div class="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftChoice === "1"} onChange={e => this.setState({ radiculapathyLeftChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Left</span> <input type="text" value={this.state.radiculapathyLeftText} onChange={e => this.setState({ radiculapathyLeftText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                    <div class="card border-warning" style={{ display: this.state.radiculapathyLeftChoice === "1" ? 'block' : 'none' }}>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftC2Choice === "1"} onChange={e => this.setState({ radiculapathyLeftC2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C2 </span> <input type="text" value={this.state.radiculapathyLeftC2Text} onChange={e => this.setState({ radiculapathyLeftC2Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftC3Choice === "1"} onChange={e => this.setState({ radiculapathyLeftC3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C3 </span> <input type="text" value={this.state.radiculapathyLeftC3Text} onChange={e => this.setState({ radiculapathyLeftC3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftC4Choice === "1"} onChange={e => this.setState({ radiculapathyLeftC4Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C4 </span> <input type="text" value={this.state.radiculapathyLeftC4Text} onChange={e => this.setState({ radiculapathyLeftC4Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftC5Choice === "1"} onChange={e => this.setState({ radiculapathyLeftC5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C5 </span> <input type="text" value={this.state.radiculapathyLeftC5Text} onChange={e => this.setState({ radiculapathyLeftC5Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftC6Choice === "1"} onChange={e => this.setState({ radiculapathyLeftC6Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C6 </span> <input type="text" value={this.state.radiculapathyLeftC6Text} onChange={e => this.setState({ radiculapathyLeftC6Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftC7Choice === "1"} onChange={e => this.setState({ radiculapathyLeftC7Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C7 </span> <input type="text" value={this.state.radiculapathyLeftC7Text} onChange={e => this.setState({ radiculapathyLeftC7Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftC8Choice === "1"} onChange={e => this.setState({ radiculapathyLeftC8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C8 </span> <input type="text" value={this.state.radiculapathyLeftC8Text} onChange={e => this.setState({ radiculapathyLeftC8Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT1Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T1 </span> <input type="text" value={this.state.radiculapathyLeftT1Text} onChange={e => this.setState({ radiculapathyLeftT1Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT2Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T2 </span> <input type="text" value={this.state.radiculapathyLeftT2Text} onChange={e => this.setState({ radiculapathyLeftT2Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT3Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T3 </span> <input type="text" value={this.state.radiculapathyLeftT3Text} onChange={e => this.setState({ radiculapathyLeftT3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT4Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT4Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T4 </span> <input type="text" value={this.state.radiculapathyLeftT4Text} onChange={e => this.setState({ radiculapathyLeftT4Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT5Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T5 </span> <input type="text" value={this.state.radiculapathyLeftT5Text} onChange={e => this.setState({ radiculapathyLeftT5Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT6Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT6Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T6 </span> <input type="text" value={this.state.radiculapathyLeftT6Text} onChange={e => this.setState({ radiculapathyLeftT6Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT7Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT7Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T7 </span> <input type="text" value={this.state.radiculapathyLeftT7Text} onChange={e => this.setState({ radiculapathyLeftT7Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT8Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T8 </span> <input type="text" value={this.state.radiculapathyLeftT8Text} onChange={e => this.setState({ radiculapathyLeftT8Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT9Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT9Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T9 </span> <input type="text" value={this.state.radiculapathyLeftT9Text} onChange={e => this.setState({ radiculapathyLeftT9Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT10Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT10Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T10 </span> <input type="text" value={this.state.radiculapathyLeftT10Text} onChange={e => this.setState({ radiculapathyLeftT10Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT11Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT11Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T11 </span> <input type="text" value={this.state.radiculapathyLeftT11Text} onChange={e => this.setState({ radiculapathyLeftT11Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftT12Choice === "1"} onChange={e => this.setState({ radiculapathyLeftT12Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T12 </span> <input type="text" value={this.state.radiculapathyLeftT12Text} onChange={e => this.setState({ radiculapathyLeftT12Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftL1Choice === "1"} onChange={e => this.setState({ radiculapathyLeftL1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L1 </span> <input type="text" value={this.state.radiculapathyLeftL1Text} onChange={e => this.setState({ radiculapathyLeftL1Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftL2Choice === "1"} onChange={e => this.setState({ radiculapathyLeftL2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L2 </span> <input type="text" value={this.state.radiculapathyLeftL2Text} onChange={e => this.setState({ radiculapathyLeftL2Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftL3Choice === "1"} onChange={e => this.setState({ radiculapathyLeftL3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L3 </span> <input type="text" value={this.state.radiculapathyLeftL3Text} onChange={e => this.setState({ radiculapathyLeftL3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftL4Choice === "1"} onChange={e => this.setState({ radiculapathyLeftL4Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L4 </span> <input type="text" value={this.state.radiculapathyLeftL4Text} onChange={e => this.setState({ radiculapathyLeftL4Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftL5Choice === "1"} onChange={e => this.setState({ radiculapathyLeftL5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L5 </span> <input type="text" value={this.state.radiculapathyLeftL5Text} onChange={e => this.setState({ radiculapathyLeftL5Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftS1Choice === "1"} onChange={e => this.setState({ radiculapathyLeftS1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S1 </span> <input type="text" value={this.state.radiculapathyLeftS1Text} onChange={e => this.setState({ radiculapathyLeftS1Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftS3Choice === "1"} onChange={e => this.setState({ radiculapathyLeftS3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S3 </span> <input type="text" value={this.state.radiculapathyLeftS3Text} onChange={e => this.setState({ radiculapathyLeftS3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyLeftS45Choice === "1"} onChange={e => this.setState({ radiculapathyLeftS45Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S4/5 </span> <input type="text" value={this.state.radiculapathyLeftS45Text} onChange={e => this.setState({ radiculapathyLeftS45Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightChoice === "1"} onChange={e => this.setState({ radiculapathyRightChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Right </span>  <input type="text" value={this.state.radiculapathyRightText} onChange={e => this.setState({ radiculapathyRightText: e.target.value })} className="form-control" /></div></div>
                                                    </div>
                                                    <div class="card border-warning" style={{ display: this.state.radiculapathyRightChoice === "1" ? 'block' : 'none' }} >
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightC2Choice === "1"} onChange={e => this.setState({ radiculapathyRightC2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C2 </span> <input type="text" value={this.state.radiculapathyRightC2Text} onChange={e => this.setState({ radiculapathyRightC2Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightC3Choice === "1"} onChange={e => this.setState({ radiculapathyRightC3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C3 </span> <input type="text" value={this.state.radiculapathyRightC3Text} onChange={e => this.setState({ radiculapathyRightC3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightC4Choice === "1"} onChange={e => this.setState({ radiculapathyRightC4Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C4 </span> <input type="text" value={this.state.radiculapathyRightC4Text} onChange={e => this.setState({ radiculapathyRightC4Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightC5Choice === "1"} onChange={e => this.setState({ radiculapathyRightC5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C5 </span> <input type="text" value={this.state.radiculapathyRightC5Text} onChange={e => this.setState({ radiculapathyRightC5Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightC6Choice === "1"} onChange={e => this.setState({ radiculapathyRightC6Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C6 </span> <input type="text" value={this.state.radiculapathyRightC6Text} onChange={e => this.setState({ radiculapathyRightC6Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightC7Choice === "1"} onChange={e => this.setState({ radiculapathyRightC7Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C7 </span> <input type="text" value={this.state.radiculapathyRightC7Text} onChange={e => this.setState({ radiculapathyRightC7Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightC8Choice === "1"} onChange={e => this.setState({ radiculapathyRightC8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">C8 </span> <input type="text" value={this.state.radiculapathyRightC8Text} onChange={e => this.setState({ radiculapathyRightC8Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT1Choice === "1"} onChange={e => this.setState({ radiculapathyRightT1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T1 </span> <input type="text" value={this.state.radiculapathyRightT1Text} onChange={e => this.setState({ radiculapathyRightT1Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT2Choice === "1"} onChange={e => this.setState({ radiculapathyRightT2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T2 </span> <input type="text" value={this.state.radiculapathyRightT2Text} onChange={e => this.setState({ radiculapathyRightT2Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT3Choice === "1"} onChange={e => this.setState({ radiculapathyRightT3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T3 </span> <input type="text" value={this.state.radiculapathyRightT3Text} onChange={e => this.setState({ radiculapathyRightT3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT4Choice === "1"} onChange={e => this.setState({ radiculapathyRightT4Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T4 </span> <input type="text" value={this.state.radiculapathyRightT4Text} onChange={e => this.setState({ radiculapathyRightT4Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT5Choice === "1"} onChange={e => this.setState({ radiculapathyRightT5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T5 </span> <input type="text" value={this.state.radiculapathyRightT5Text} onChange={e => this.setState({ radiculapathyRightT5Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT6Choice === "1"} onChange={e => this.setState({ radiculapathyRightT6Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T6 </span> <input type="text" value={this.state.radiculapathyRightT6Text} onChange={e => this.setState({ radiculapathyRightT6Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT7Choice === "1"} onChange={e => this.setState({ radiculapathyRightT7Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T7 </span> <input type="text" value={this.state.radiculapathyRightT7Text} onChange={e => this.setState({ radiculapathyRightT7Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT8Choice === "1"} onChange={e => this.setState({ radiculapathyRightT8Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T8 </span> <input type="text" value={this.state.radiculapathyRightT8Text} onChange={e => this.setState({ radiculapathyRightT8Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT9Choice === "1"} onChange={e => this.setState({ radiculapathyRightT9Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T9 </span> <input type="text" value={this.state.radiculapathyRightT9Text} onChange={e => this.setState({ radiculapathyRightT9Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT10Choice === "1"} onChange={e => this.setState({ radiculapathyRightT10Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T10 </span> <input type="text" value={this.state.radiculapathyRightT10Text} onChange={e => this.setState({ radiculapathyRightT10Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT11Choice === "1"} onChange={e => this.setState({ radiculapathyRightT11Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T11 </span> <input type="text" value={this.state.radiculapathyRightT11Text} onChange={e => this.setState({ radiculapathyRightT11Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightT12Choice === "1"} onChange={e => this.setState({ radiculapathyRightT12Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">T12 </span> <input type="text" value={this.state.radiculapathyRightT12Text} onChange={e => this.setState({ radiculapathyRightT12Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightL1Choice === "1"} onChange={e => this.setState({ radiculapathyRightL1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L1 </span> <input type="text" value={this.state.radiculapathyRightL1Text} onChange={e => this.setState({ radiculapathyRightL1Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightL2Choice === "1"} onChange={e => this.setState({ radiculapathyRightL2Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L2 </span> <input type="text" value={this.state.radiculapathyRightL2Text} onChange={e => this.setState({ radiculapathyRightL2Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightL3Choice === "1"} onChange={e => this.setState({ radiculapathyRightL3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L3 </span> <input type="text" value={this.state.radiculapathyRightL3Text} onChange={e => this.setState({ radiculapathyRightL3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightL4Choice === "1"} onChange={e => this.setState({ radiculapathyRightL4Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L4 </span> <input type="text" value={this.state.radiculapathyRightL4Text} onChange={e => this.setState({ radiculapathyRightL4Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightL5Choice === "1"} onChange={e => this.setState({ radiculapathyRightL5Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">L5 </span> <input type="text" value={this.state.radiculapathyRightL5Text} onChange={e => this.setState({ radiculapathyRightL5Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                        <div class="row">
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightS1Choice === "1"} onChange={e => this.setState({ radiculapathyRightS1Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S1 </span> <input type="text" value={this.state.radiculapathyRightS1Text} onChange={e => this.setState({ radiculapathyRightS1Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightS3Choice === "1"} onChange={e => this.setState({ radiculapathyRightS3Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S3 </span> <input type="text" value={this.state.radiculapathyRightS3Text} onChange={e => this.setState({ radiculapathyRightS3Text: e.target.value })} className="form-control" /></div></div>
                                                            <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.radiculapathyRightS45Choice === "1"} onChange={e => this.setState({ radiculapathyRightS45Choice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">S4/5 </span> <input type="text" value={this.state.radiculapathyRightS45Text} onChange={e => this.setState({ radiculapathyRightS45Text: e.target.value })} className="form-control" /></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.allergyHistoryT ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group">
                                                        <textarea className="form-control" value={this.state.allergyHistory} onChange={e => { this.setState({ allergyHistory: e.target.value }) }} ></textarea> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.respiratory ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.coughChoice === "1"} onChange={e => this.setState({ coughChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Cough </span><input type="text" value={this.state.coughText} onChange={e => this.setState({ coughText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.coryzaChoice === "1"} onChange={e => this.setState({ coryzaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Coryza </span> <input type="text" value={this.state.coryzaText} onChange={e => this.setState({ coryzaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sobChoice === "1"} onChange={e => this.setState({ sobChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> SOB </span> <input type="text" value={this.state.sobText} onChange={e => this.setState({ sobText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sneazingChoice === "1"} onChange={e => this.setState({ sneazingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Sneezing </span><input type="text" value={this.state.sneazingText} onChange={e => this.setState({ sneazingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.phelgmChoice === "1"} onChange={e => this.setState({ phelgmChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Phelgm</span> <input type="text" value={this.state.phelgmText} onChange={e => this.setState({ phelgmText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sputumChoice === "1"} onChange={e => this.setState({ sputumChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Sputum </span><input type="text" value={this.state.sputumText} onChange={e => this.setState({ sputumText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.haemoptysisChoice === "1"} onChange={e => this.setState({ haemoptysisChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Haemoptysis </span> <input type="text" value={this.state.haemoptysisText} onChange={e => this.setState({ haemoptysisText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.wheezingChoice === "1"} onChange={e => this.setState({ wheezingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Wheezing </span><input type="text" value={this.state.wheezingText} onChange={e => this.setState({ wheezingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.nasalChoice === "1"} onChange={e => this.setState({ nasalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Nasal Congestion</span>  <input type="text" value={this.state.nasalText} onChange={e => this.setState({ nasalText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.soreChoice === "1"} onChange={e => this.setState({ soreChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Sore Throat</span> <input type="text" value={this.state.soreText} onChange={e => this.setState({ soreText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.chestPainChoice === "1"} onChange={e => this.setState({ chestPainChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Chest Pain</span> <input type="text" value={this.state.chestPainText} onChange={e => this.setState({ chestPainText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other</span> <textarea value={this.state.respitoryNotes} onChange={e => this.setState({ respitoryNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.git ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.nauseaChoice === "1"} onChange={e => this.setState({ nauseaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Nausea </span> <input type="text" value={this.state.nauseaText} onChange={e => this.setState({ nauseaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.vomitingChoice === "1"} onChange={e => this.setState({ vomitingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Vomiting  </span> <input type="text" value={this.state.vomitingText} onChange={e => this.setState({ vomitingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.diarrhoeaChoice === "1"} onChange={e => this.setState({ diarrhoeaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Diarrhoea</span>  <input type="text" value={this.state.diarrhoeaText} onChange={e => this.setState({ diarrhoeaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.abdominialChoice === "1"} onChange={e => this.setState({ abdominialChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Abdominial Pain </span>  <input type="text" value={this.state.abdominialText} onChange={e => this.setState({ abdominialText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.flatulanceChoice === "1"} onChange={e => this.setState({ flatulanceChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Flatulance Lower </span> <input type="text" value={this.state.flatulanceText} onChange={e => this.setState({ flatulanceText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.flatulentChoice === "1"} onChange={e => this.setState({ flatulentChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Flatulent Dyspepsia</span>  <input type="text" value={this.state.flatulentText} onChange={e => this.setState({ flatulentText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.waterChoice === "1"} onChange={e => this.setState({ waterChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Water Brush</span>  <input type="text" value={this.state.waterText} onChange={e => this.setState({ waterText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.constipationChoice === "1"} onChange={e => this.setState({ constipationChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Constipation</span>   <input type="text" value={this.state.constipationText} onChange={e => this.setState({ constipationText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.prChoice === "1"} onChange={e => this.setState({ prChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Pr Bleeding </span> <input type="text" value={this.state.prText} onChange={e => this.setState({ prText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.wtChoice === "1"} onChange={e => this.setState({ wtChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Wt Gain </span> <input type="text" value={this.state.wtText} onChange={e => this.setState({ wtText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.melenaChoice === "1"} onChange={e => this.setState({ melenaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Melena </span>  <input type="text" value={this.state.melenaText} onChange={e => this.setState({ melenaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.jaundiceChoice === "1"} onChange={e => this.setState({ jaundiceChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Jaundice</span>  <input type="text" value={this.state.jaundiceText} onChange={e => this.setState({ jaundiceText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.loaChoice === "1"} onChange={e => this.setState({ loaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> LOA</span>  <input type="text" value={this.state.loaText} onChange={e => this.setState({ loaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lowChoice === "1"} onChange={e => this.setState({ lowChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> LOW</span>  <input type="text" value={this.state.lowText} onChange={e => this.setState({ lowText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span> <textarea value={this.state.gitNotes} onChange={e => this.setState({ gitNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.gus ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.dysuriaChoice === "1"} onChange={e => this.setState({ dysuriaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Dysuria </span><input type="text" value={this.state.dysuriaText} onChange={e => this.setState({ dysuriaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.frequencyChoice === "1"} onChange={e => this.setState({ frequencyChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Frequency </span> <input type="text" value={this.state.frequencyText} onChange={e => this.setState({ frequencyText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.haematuriaChoice === "1"} onChange={e => this.setState({ haematuriaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Haematuria </span> <input type="text" value={this.state.haematuriaText} onChange={e => this.setState({ haematuriaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.weakChoice === "1"} onChange={e => this.setState({ weakChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Weak Stream </span>  <input type="text" value={this.state.weakText} onChange={e => this.setState({ weakText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.strainingChoice === "1"} onChange={e => this.setState({ strainingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Straining </span> <input type="text" value={this.state.strainingText} onChange={e => this.setState({ strainingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.urineChoice === "1"} onChange={e => this.setState({ urineChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Urine Retention </span>  <input type="text" value={this.state.urineText} onChange={e => this.setState({ urineText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.urethalChoice === "1"} onChange={e => this.setState({ urethalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Urethral D/C  </span> <input type="text" value={this.state.urethalText} onChange={e => this.setState({ urethalText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.pvChoice === "1"} onChange={e => this.setState({ pvChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">PV D/C  </span><input type="text" value={this.state.pvText} onChange={e => this.setState({ pvText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.nocturiaChoice === "1"} onChange={e => this.setState({ nocturiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Nocturia  </span> <input type="text" value={this.state.nocturiaText} onChange={e => this.setState({ nocturiaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.genitalChoice === "1"} onChange={e => this.setState({ genitalChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Genital Sores </span> <input type="text" value={this.state.genitalText} onChange={e => this.setState({ genitalText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lossChoice === "1"} onChange={e => this.setState({ lossChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Loss Of Libido </span>  <input type="text" value={this.state.lossText} onChange={e => this.setState({ lossText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.erectileChoice === "1"} onChange={e => this.setState({ erectileChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Erectille Dysfunction </span> <input type="text" value={this.state.erectileText} onChange={e => this.setState({ erectileText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other  </span><textarea value={this.state.gusNotes} onChange={e => this.setState({ gusNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.gynae ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.dysaruniaChoice === "1"} onChange={e => this.setState({ dysaruniaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Dysarunia </span><input type="text" value={this.state.dysaruniaText} onChange={e => this.setState({ dysaruniaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.dysmemorrhoeaChoice === "1"} onChange={e => this.setState({ dysmemorrhoeaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Dysmemorrhoea </span><input type="text" value={this.state.dysmemorrhoeaText} onChange={e => this.setState({ dysmemorrhoeaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.imbChoice === "1"} onChange={e => this.setState({ imbChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">IMB  </span><input type="text" value={this.state.imbText} onChange={e => this.setState({ imbText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.contactChoice === "1"} onChange={e => this.setState({ contactChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Contact Bleeding  </span><input type="text" value={this.state.contactText} onChange={e => this.setState({ contactText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.menorrhagiaChoice === "1"} onChange={e => this.setState({ menorrhagiaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Menorrhagia </span> <input type="text" value={this.state.menorrhagiaText} onChange={e => this.setState({ menorrhagiaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lapChoice === "1"} onChange={e => this.setState({ lapChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> LAP  </span> <input type="text" value={this.state.lapText} onChange={e => this.setState({ lapText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.backacheChoice === "1"} onChange={e => this.setState({ backacheChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Backache  </span> <input type="text" value={this.state.backackeText} onChange={e => this.setState({ backackeText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.subfertilityChoice === "1"} onChange={e => this.setState({ subfertilityChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Subfertility </span> <input type="text" value={this.state.subfertilityText} onChange={e => this.setState({ subfertilityText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hotChoice === "1"} onChange={e => this.setState({ hotChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Hot Flashes  </span><input type="text" value={this.state.hotText} onChange={e => this.setState({ hotText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lnmpChoice === "1"} onChange={e => this.setState({ lnmpChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> LNMP </span>  <input type="text" value={this.state.lnmpText} onChange={e => this.setState({ lnmpText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.gynaeNotes} onChange={e => this.setState({ gynaeNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.cvs ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.orthopneaChoice === "1"} onChange={e => this.setState({ orthopneaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Orthopnea</span> <input type="text" value={this.state.orthopneaText} onChange={e => this.setState({ orthopneaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.pndChoice === "1"} onChange={e => this.setState({ pndChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">PND </span><input type="text" value={this.state.pndText} onChange={e => this.setState({ pndText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.easyChoice === "1"} onChange={e => this.setState({ easyChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Easy Fatigability</span> <input type="text" value={this.state.easyText} onChange={e => this.setState({ easyText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.palpitationsChoice === "1"} onChange={e => this.setState({ palpitationsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Palpitations</span>  <input type="text" value={this.state.palpitationsText} onChange={e => this.setState({ palpitationsText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.synacopeChoice === "1"} onChange={e => this.setState({ synacopeChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Syncope</span>  <input type="text" value={this.state.synacopeText} onChange={e => this.setState({ synacopeText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.legSwellingChoice === "1"} onChange={e => this.setState({ legSwellingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Leg Swelling</span> <input type="text" value={this.state.legSwellingText} onChange={e => this.setState({ legSwellingText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.chestPaincvsChoice === "1"} onChange={e => this.setState({ chestPaincvsChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Central Chest Pain </span><input type="text" value={this.state.chestPaincvsText} onChange={e => this.setState({ chestPaincvsText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.calfSwellingChoice === "1"} onChange={e => this.setState({ calfSwellingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Calf Swelling</span> <input type="text" value={this.state.calfSwellingText} onChange={e => this.setState({ calfSwellingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.pinkFrothChoice === "1"} onChange={e => this.setState({ pinkFrothChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Pink Froth</span> <input type="text" value={this.state.pinkFrothText} onChange={e => this.setState({ pinkFrothText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.gelatinousChoice === "1"} onChange={e => this.setState({ gelatinousChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Gelatinous Sputum</span> <input type="text" value={this.state.gelatinousText} onChange={e => this.setState({ gelatinousText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other</span>  <textarea value={this.state.cvsNotes} onChange={e => this.setState({ cvsNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.cns ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.headacheChoice === "1"} onChange={e => this.setState({ headacheChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Headache</span><input type="text" value={this.state.headacheText} onChange={e => this.setState({ headacheText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paraesthesiaChoice === "1"} onChange={e => this.setState({ paraesthesiaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Paraesthesia</span><input type="text" value={this.state.paraesthesiaText} onChange={e => this.setState({ paraesthesiaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.dizzinessChoice === "1"} onChange={e => this.setState({ dizzinessChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Dizziness</span><input type="text" value={this.state.dizzinessText} onChange={e => this.setState({ dizzinessText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.backackeChoice === "1"} onChange={e => this.setState({ backackeChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Backache</span><input type="text" value={this.state.backacheText} onChange={e => this.setState({ backacheText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.photophibiaChoice === "1"} onChange={e => this.setState({ photophibiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Photophobia</span><input type="text" value={this.state.photophibiaText} onChange={e => this.setState({ photophibiaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.neckstiffnessChoice === "1"} onChange={e => this.setState({ neckstiffnessChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Neck Stiffness</span>  <input type="text" value={this.state.neckstiffnessText} onChange={e => this.setState({ neckstiffnessText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.fittingChoice === "1"} onChange={e => this.setState({ fittingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Fitting</span><input type="text" value={this.state.fittingText} onChange={e => this.setState({ fittingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paresisChoice === "1"} onChange={e => this.setState({ paresisChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Paresis </span><input type="text" value={this.state.paresisText} onChange={e => this.setState({ paresisText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.paralysisChoice === "1"} onChange={e => this.setState({ paralysisChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Paralysis </span><input type="text" value={this.state.paralysisText} onChange={e => this.setState({ paralysisText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.insomniaChoice === "1"} onChange={e => this.setState({ insomniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Insomnia</span> <input type="text" value={this.state.insomniaText} onChange={e => this.setState({ insomniaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.hypersomniaChoice === "1"} onChange={e => this.setState({ hypersomniaChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Hypersomnia</span><input type="text" value={this.state.hypersomniaText} onChange={e => this.setState({ hypersomniaText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.abnChoice === "1"} onChange={e => this.setState({ abnChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> AbN Gait</span> <input type="text" value={this.state.abnText} onChange={e => this.setState({ abnText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.cnsNotes} onChange={e => this.setState({ cnsNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.constitutional ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.feverChoice === "1"} onChange={e => this.setState({ feverChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Fever</span><input type="text" value={this.state.feverText} onChange={e => this.setState({ feverText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.sweatingChoice === "1"} onChange={e => this.setState({ sweatingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Sweating</span><input type="text" value={this.state.sweatingText} onChange={e => this.setState({ sweatingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.chillsChoice === "1"} onChange={e => this.setState({ chillsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Chills</span><input type="text" value={this.state.chillsText} onChange={e => this.setState({ chillsText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.rigorsChoice === "1"} onChange={e => this.setState({ rigorsChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Rigors </span> <input type="text" value={this.state.rigorsText} onChange={e => this.setState({ rigorsText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.fatigueChoice === "1"} onChange={e => this.setState({ fatigueChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Fatigue </span><input type="text" value={this.state.fatigueText} onChange={e => this.setState({ fatigueText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.myalgiaChoice === "1"} onChange={e => this.setState({ myalgiaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Myalgia</span> <input type="text" value={this.state.myalgiaText} onChange={e => this.setState({ myalgiaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.constitutionalNotes} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.mss ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.bleedingChoice === "1"} onChange={e => this.setState({ bleedingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Bleeding</span> <input type="text" value={this.state.bleedingText} onChange={e => this.setState({ bleedingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.bruisingChoice === "1"} onChange={e => this.setState({ bruisingChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Bruising</span> <input type="text" value={this.state.bruisingText} onChange={e => this.setState({ bruisingText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.swellingChoice === "1"} onChange={e => this.setState({ swellingChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Swelling</span> <input type="text" value={this.state.swellingText} onChange={e => this.setState({ swellingText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.deformityChoice === "1"} onChange={e => this.setState({ deformityChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Deformity</span><input type="text" value={this.state.deformityText} onChange={e => this.setState({ deformityText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span> <textarea value={this.state.mssNotes} onChange={e => this.setState({ mssNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                            <div className={this.state.dermatology ? "tab-pane fade show active" : "tab-pane fade"}>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.rashChoice === "1"} onChange={e => this.setState({ rashChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Rash </span><input type="text" value={this.state.rashText} onChange={e => this.setState({ rashText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.itchynessChoice === "1"} onChange={e => this.setState({ itchynessChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Itchyness</span> <input type="text" value={this.state.itchynessText} onChange={e => this.setState({ itchynessText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.blistersChoice === "1"} onChange={e => this.setState({ blistersChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Blisters</span> <input type="text" value={this.state.blistersText} onChange={e => this.setState({ blistersText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.lumpsChoice === "1"} onChange={e => this.setState({ lumpsChoice: e.target.checked === true ? "1" : "0" })} /><span className="input-group-text"> Lumps</span>  <input type="text" value={this.state.lumpsText} onChange={e => this.setState({ lumpsText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.ulcersChoice === "1"} onChange={e => this.setState({ ulcersChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Ulcers </span><input type="text" value={this.state.ulcersText} onChange={e => this.setState({ ulcersText: e.target.value })} className="form-control" /></div></div>
                                                    <div className="col"><div className="input-group"><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width: 2.0 + 'em' }} checked={this.state.swellingdemaChoice === "1"} onChange={e => this.setState({ swellingdemaChoice: e.target.checked === true ? "1" : "0" })} /> <span className="input-group-text">Swelling</span> <input type="text" value={this.state.swellingdemaText} onChange={e => this.setState({ swellingdemaText: e.target.value })} className="form-control" /></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other </span><textarea value={this.state.dematologyNotes} onChange={e => this.setState({ dematologyNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={this.state.examination ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                    General and Extra-Neurological Systems Examination
                                    </div>
                                    <div className="card-body">
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">General </span><textarea value={this.state.generalNotes} onChange={e => this.setState({ generalNotes: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">CVS </span><textarea value={this.state.cvsExam} onChange={e => this.setState({ cvsExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Resp </span><textarea value={this.state.respExam} onChange={e => this.setState({ respExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                    </div>                                       
                                    <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Abdomen </span><textarea value={this.state.abdomenExam} onChange={e => this.setState({ abdomenExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Skin </span><textarea value={this.state.skinExam} onChange={e => this.setState({ skinExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                              
                                    <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Joints </span><textarea value={this.state.jointsExam} onChange={e => this.setState({ jointsExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Body mass index </span><textarea value={this.state.bodyMassIndexExam} onChange={e => this.setState({ bodyMassIndexExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                         
                                    <div className="card card-green">
                                        <div className="card-header">Neurological examination</div>
                                        <div className="card-body">                                           
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Meningism </span><textarea value={this.state.meningismExam} onChange={e => this.setState({ meningismExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                             
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Higher Function </span><textarea value={this.state.higherFunctionsExam} onChange={e => this.setState({ higherFunctionsExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                                 
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Spine </span><textarea value={this.state.spineExam} onChange={e => this.setState({ spineExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                               
                                    <br />
                                    <div className="card card-green">
                                        <div className="card-header">Cranial Nerves</div>
                                        <div className="card-body">
                                        <table className="table table-striped">
                                            <tr>
                                                <td></td>
                                                <th>RIGHT</th>
                                                <th>LEFT</th>
                                            </tr>
                                            <tbody>
                                                <tr>
                                                    <th>I</th>
                                                    <td><input type="text" value={this.state.cranialNerve1Right} onChange={e => this.setState({ cranialNerve1Right: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.cranialNerve1Left} onChange={e => this.setState({ cranialNerve1Left: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>II</th>
                                                    <td><input type="text" value={this.state.cranialNerve2Right} onChange={e => this.setState({ cranialNerve2Right: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.cranialNerve2Left} onChange={e => this.setState({ cranialNerve2Left: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>III,IV,VI</th>
                                                    <td><input type="text" value={this.state.cranialNerve3Right} onChange={e => this.setState({ cranialNerve3Right: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.cranialNerve3Left} onChange={e => this.setState({ cranialNerve3Left: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>V</th>
                                                    <td><input type="text" value={this.state.cranialNerve5Right} onChange={e => this.setState({ cranialNerve5Right: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.cranialNerve5Left} onChange={e => this.setState({ cranialNerve5Left: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>VII</th>
                                                    <td><input type="text" value={this.state.cranialNerve7Right} onChange={e => this.setState({ cranialNerve7Right: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.cranialNerve7Left} onChange={e => this.setState({ cranialNerve7Left: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>VIII</th>
                                                    <td><input type="text" value={this.state.cranialNerve8Right} onChange={e => this.setState({ cranialNerve8Right: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.cranialNerve8Left} onChange={e => this.setState({ cranialNerve8Left: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>IX</th>
                                                    <td><input type="text" value={this.state.cranialNerve9Right} onChange={e => this.setState({ cranialNerve9Right: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.cranialNerve9Left} onChange={e => this.setState({ cranialNerve9Left: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>X</th>
                                                    <td><input type="text" value={this.state.cranialNerve10Right} onChange={e => this.setState({ cranialNerve10Right: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.cranialNerve10Left} onChange={e => this.setState({ cranialNerve10Left: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>XI</th>
                                                    <td><input type="text" value={this.state.cranialNerve11Right} onChange={e => this.setState({ cranialNerve11Right: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.cranialNerve11Left} onChange={e => this.setState({ cranialNerve11Left: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>XII</th>
                                                    <td><input type="text" value={this.state.cranialNerve12Right} onChange={e => this.setState({ cranialNerve12Right: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.cranialNerve12Left} onChange={e => this.setState({ cranialNerve12Left: e.target.value })} className="form-control" /></td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                    </div><br />
                                    <div className="card card-green">
                                        <div className="card-header">Pupils</div>
                                        <div className="card-body">
                                        <table className="table table-striped">
                                            <tr>
                                                <td></td>
                                                <th>RIGHT</th>
                                                <th>LEFT</th>
                                            </tr>
                                            <tbody>
                                                <tr>
                                                    <th>Shape</th>
                                                    <td><input type="text" value={this.state.shapeRight} onChange={e => this.setState({ shapeRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.shapeLeft} onChange={e => this.setState({ shapeLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>Size</th>
                                                    <td><input type="text" value={this.state.sizeRight} onChange={e => this.setState({ sizeRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.sizeLeft} onChange={e => this.setState({ sizeLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>Direct pupillary reflex</th>
                                                    <td><input type="text" value={this.state.pupillaryRight} onChange={e => this.setState({ pupillaryRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.pupillaryLeft} onChange={e => this.setState({ pupillaryLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>Consensual reflex</th>
                                                    <td><input type="text" value={this.state.consensualReflexRight} onChange={e => this.setState({ consensualReflexRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.consensualReflexLeft} onChange={e => this.setState({ consensualReflexLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>RAPD</th>
                                                    <td><input type="text" value={this.state.rapdRight} onChange={e => this.setState({ rapdRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.rapdLeft} onChange={e => this.setState({ rapdLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>Funduscopy</th>
                                                    <td><input type="text" value={this.state.funduscopyRight} onChange={e => this.setState({ funduscopyRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.funduscopyLeft} onChange={e => this.setState({ funduscopyLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                                <br />
                                     <div className="card card-green">
                                        <div className="card-header">Motor Examination</div>
                                        <div className="card-body">
                                        <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Inspection</span><textarea value={this.state.inspectionExam} onChange={e => this.setState({ inspectionExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Involuntary movements</span><textarea value={this.state.involuntaryExam} onChange={e => this.setState({ involuntaryExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Tone</span><textarea value={this.state.toneExam} onChange={e => this.setState({ toneExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Rigidity</span><textarea value={this.state.rigidityExam} onChange={e => this.setState({ rigidityExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Bradykinesia</span><textarea value={this.state.bradykinesiaExam} onChange={e => this.setState({ bradykinesiaExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>
                                               <h3>Reflexes</h3>
                                           <table className="table table-striped">
                                            <tr>
                                                <td></td>
                                                <th>RIGHT</th>
                                                <th>LEFT</th>
                                            </tr>
                                            <tbody>
                                                <tr>
                                                    <th>Hoffman’s </th>
                                                    <td><input type="text" value={this.state.hoffmanRight} onChange={e => this.setState({ hoffmanRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.hoffmanLeft} onChange={e => this.setState({ hoffmanLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>Biceps jerk</th>
                                                    <td><input type="text" value={this.state.bicepsJerkRight} onChange={e => this.setState({ bicepsJerkRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.bicepsJerkLeft} onChange={e => this.setState({ bicepsJerkLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>Brachioradialis Jerk</th>
                                                    <td><input type="text" value={this.state.brachioradialisJerkRight} onChange={e => this.setState({ brachioradialisJerkRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.brachioradialisJerkLeft} onChange={e => this.setState({ brachioradialisJerkLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>Triceps Jerk</th>
                                                    <td><input type="text" value={this.state.tricepsJerkRight} onChange={e => this.setState({ tricepsJerkRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.tricepsJerkLeft} onChange={e => this.setState({ tricepsJerkLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>Knee Jerk</th>
                                                    <td><input type="text" value={this.state.kneeJerkRight} onChange={e => this.setState({ kneeJerkRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.kneeJerkLeft} onChange={e => this.setState({ kneeJerkLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>Ankle Jerk</th>
                                                    <td><input type="text" value={this.state.ankleJerkRight} onChange={e => this.setState({ ankleJerkRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.ankleJerkLeft} onChange={e => this.setState({ ankleJerkLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>Plantar Response</th>
                                                    <td><input type="text" value={this.state.plantarResponseRight} onChange={e => this.setState({ plantarResponseRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.plantarResponseLeft} onChange={e => this.setState({ plantarResponseLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>Abdominal Reflexes</th>
                                                    <td><input type="text" value={this.state.abdominalReflexesRight} onChange={e => this.setState({ abdominalReflexesRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.abdominalReflexesLeft} onChange={e => this.setState({ abdominalReflexesLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <th>Other Reflexes</th>
                                                    <td><input type="text" value={this.state.otherReflexesRight} onChange={e => this.setState({ otherReflexesRight: e.target.value })} className="form-control" /></td>
                                                    <td><input type="text" value={this.state.otherReflexesLeft} onChange={e => this.setState({ otherReflexesLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            <h3>Power</h3>       <table className="table table-striped">
                                            <tr>
                                                <th>RIGHT</th>
                                                <td></td>
                                                <th>LEFT</th>
                                            </tr>
                                            <tbody>
                                                <tr>
                                                <td><input type="text" value={this.state.shoulderAbductionRight} onChange={e => this.setState({ shoulderAbductionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Shoulder abduction</th>
                                                    <td><input type="text" value={this.state.shoulderAbductionLeft} onChange={e => this.setState({ shoulderAbductionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.shoulderAdductionRight} onChange={e => this.setState({ shoulderAdductionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Shoulder adduction</th>
                                                    <td><input type="text" value={this.state.shoulderAdductionLeft} onChange={e => this.setState({ shoulderAdductionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.elbowFlexionRight} onChange={e => this.setState({ elbowFlexionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Elbow flexion</th>
                                                    <td><input type="text" value={this.state.elbowFlexionLeft} onChange={e => this.setState({ elbowFlexionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.elbowExtensionRight} onChange={e => this.setState({ elbowExtensionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Elbow extension</th>
                                                    <td><input type="text" value={this.state.elbowExtensionLeft} onChange={e => this.setState({ elbowExtensionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td><input type="text" value={this.state.wristFlexionRight} onChange={e => this.setState({ wristFlexionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Wrist flexion</th>
                                                    <td><input type="text" value={this.state.wristFlexionLeft} onChange={e => this.setState({ wristFlexionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                    <td><input type="text" value={this.state.wristExtensionRight} onChange={e => this.setState({ wristExtensionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Wrist extension</th>
                                                    <td><input type="text" value={this.state.wristExtensionLeft} onChange={e => this.setState({ wristExtensionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.fingerFlexionRight} onChange={e => this.setState({ fingerFlexionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Finger flexion </th>
                                                    <td><input type="text" value={this.state.fingerFlexionLeft} onChange={e => this.setState({ fingerFlexionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.fingerExtensionRight} onChange={e => this.setState({ fingerExtensionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Finger extension </th>
                                                    <td><input type="text" value={this.state.fingerExtensionLeft} onChange={e => this.setState({ fingerExtensionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.fingerAbductionRight} onChange={e => this.setState({ fingerAbductionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Finger abduction </th>
                                                    <td><input type="text" value={this.state.fingerAbductionLeft} onChange={e => this.setState({ fingerAbductionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.hipFlexionRight} onChange={e => this.setState({ hipFlexionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Hip flexion</th>
                                                    <td><input type="text" value={this.state.hipFlexionLeft} onChange={e => this.setState({ hipFlexionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.hipExtensionRight} onChange={e => this.setState({ hipExtensionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Hip extension</th>
                                                    <td><input type="text" value={this.state.hipExtensionLeft} onChange={e => this.setState({ hipExtensionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.hipAbductionRight} onChange={e => this.setState({ hipAbductionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Hip abduction</th>
                                                    <td><input type="text" value={this.state.hipAbductionLeft} onChange={e => this.setState({ hipAbductionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.kneeFlexionRight} onChange={e => this.setState({ kneeFlexionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Knee flexion</th>
                                                    <td><input type="text" value={this.state.kneeFlexionLeft} onChange={e => this.setState({ kneeFlexionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.kneeExtensionRight} onChange={e => this.setState({ kneeExtensionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Knee extension</th>
                                                    <td><input type="text" value={this.state.kneeExtensionLeft} onChange={e => this.setState({ kneeExtensionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.ankleDorsiflexionRight} onChange={e => this.setState({ ankleDorsiflexionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Ankle dorsiflexion</th>
                                                    <td><input type="text" value={this.state.ankleDorsiflexionLeft} onChange={e => this.setState({ ankleDorsiflexionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.anklePlantarFlexionRight} onChange={e => this.setState({ anklePlantarFlexionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Ankle plantar flexion</th>
                                                    <td><input type="text" value={this.state.anklePlantarFlexionLeft} onChange={e => this.setState({ anklePlantarFlexionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.ankleEversionRight} onChange={e => this.setState({ ankleEversionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Ankle eversion</th>
                                                    <td><input type="text" value={this.state.ankleEversionLeft} onChange={e => this.setState({ ankleEversionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.ankleInversionRight} onChange={e => this.setState({ ankleInversionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Ankle inversion</th>
                                                    <td><input type="text" value={this.state.ankleInversionLeft} onChange={e => this.setState({ ankleInversionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.toeFlexionRight} onChange={e => this.setState({ toeFlexionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Toe flexion</th>
                                                    <td><input type="text" value={this.state.toeFlexionLeft} onChange={e => this.setState({ toeFlexionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                                <tr>
                                                <td><input type="text" value={this.state.toeExtensionRight} onChange={e => this.setState({ toeExtensionRight: e.target.value })} className="form-control" /></td>
                                                    <th>Toe extension</th>
                                                    <td><input type="text" value={this.state.toeExtensionLeft} onChange={e => this.setState({ toeExtensionLeft: e.target.value })} className="form-control" /></td>
                                                </tr>
                                            </tbody>
                                            </table>
                                               </div>                                        
                                        </div>   
                                        <br />
                                        <div className="card card-green">
                                        <div className="card-header">Sensation</div>
                                        <div className="card-body">                                         
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Fine touch</span><textarea value={this.state.fineTouchExam} onChange={e => this.setState({ fineTouchExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                         
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Pin prick</span><textarea value={this.state.pinPrickExam} onChange={e => this.setState({ pinPrickExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                          
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Joint position sense</span><textarea value={this.state.jointPositionSenseExam} onChange={e => this.setState({ jointPositionSenseExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>   
                                                                             
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Sensory loss pattern</span><textarea value={this.state.sensoryLossPatternExam} onChange={e => this.setState({ sensoryLossPatternExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                             
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Rhomberg test</span><textarea value={this.state.rhombergTestExam} onChange={e => this.setState({ rhombergTestExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                 

                                        </div></div>     
                                        <br />
                                        <div className="card card-green">
                                        <div className="card-header">Cerebellar exam</div>
                                        <div className="card-body">                                            
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Nystagmus</span><textarea value={this.state.nystagmusExam} onChange={e => this.setState({ nystagmusExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                                    
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Speech</span><textarea value={this.state.speechExam} onChange={e => this.setState({ speechExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                         
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Rapid alternating movements</span><textarea value={this.state.rapidAlternatingMovementsExam} onChange={e => this.setState({ rapidAlternatingMovementsExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                          
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Finger to nose test</span><textarea value={this.state.fingerToNoseTestExam} onChange={e => this.setState({ fingerToNoseTestExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>   
                                                                             
                                                <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Heel knee shin test</span><textarea value={this.state.heelKneeShinTestExam} onChange={e => this.setState({ heelKneeShinTestExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                             
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Tandem gait</span><textarea value={this.state.tandemGaitExam} onChange={e => this.setState({ tandemGaitExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                 

                                        </div></div>  
                                        </div></div>   
                                                                                    
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Gait</span><textarea value={this.state.gaitExam} onChange={e => this.setState({ gaitExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                                                                      
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Other examination findings</span><textarea value={this.state.otherExamFindings} onChange={e => this.setState({ otherExamFindings: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                                 
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Assessment</span><textarea value={this.state.assessmentExam} onChange={e => this.setState({ assessmentExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                                               
                                    <div className="row">
                                                    <div className="col"><div className="input-group"><span className="input-group-text">Plan</span><textarea value={this.state.planExam} onChange={e => this.setState({ planExam: e.target.value })} className="form-control"   ></textarea></div></div>
                                                </div>                      
                                        </div>
                                        
                                    </div>
                            </div>
                            <div className={this.state.feedbackLetter ? "tab-pane fade show active" : "tab-pane fade"} >
  <br />
    <div class="card card-blue"> 
        <div class="card-header  text-dark"> 
            Feedback Letter
        </div> 
        <div class="card-body"> 
           <div class="row">
                        <div class="col-lg-4 form-inline"><label>Dr Name </label><input type="text"  class="form-control" value={this.state.feedbackDoctor} onChange={e => { this.setState({feedbackDoctor: e.target.value}) }}  /></div>
                    </div>
            <table class="table table-striped">
                <tr>
                    <th>Date</th>
                    <th>Select</th>
                </tr>
                <tbody>
                {this.state.dateLists.map((item, index) =>  (
                <tr key={index}>
                    <td>{item.date}</td>
                    <td><input type="checkbox"  class="form-check-input" checked={item.checked === "1"} onChange={e => { this.setFeedbackItem(index, e.target.value)  }} value="1" /></td>
                </tr>
                  ))}
                  </tbody>
            </table>
            <div class="row">
                        <div class="col-lg-4 form-inline"><label>History </label><input type="checkbox"  class="form-check-input" checked={this.state.feedbackHistory === "1"} onChange={e => { this.setState({feedbackHistory: e.target.value})  }} value="1" /></div>
                        <div class="col-lg-4 form-inline"><label>Examination </label> <input type="checkbox"  class="form-check-input" checked={this.state.feedbackExamination === "1"} onChange={e => { this.setState({feedbackExamination: e.target.value})}}  value="1" /></div>
                        <div class="col-lg-4 form-inline"><label>Review Consult </label> <input type="checkbox"  class="form-check-input" checked={this.state.feedbackReviewConsult === "1"} onChange={e => { this.setState({feedbackReviewConsult: e.target.value}) }} value="1" /></div>
                    </div> 
            <div class="row">
                        <div class="col-lg-4 form-inline"><label>Investigations </label><input type="checkbox"  class="form-check-input" checked={this.state.feedbackInvestigations === "1"} onChange={e => { this.setState({feedbackInvestigations: e.target.value}) }} value="1" /></div>
                        <div class="col-lg-4 form-inline"><label>Diagnosis </label> <input type="checkbox"  class="form-check-input" checked={this.state.feedbackDiagnosis === "1"} onChange={e => { this.setState({feedbackDiagnosis: e.target.value})}} value="1" /></div>
                        <div class="col-lg-4 form-inline"><label>Prescriptions </label> <input type="checkbox"  class="form-check-input" checked={this.state.feedbackPrescriptions === "1"} onChange={e => { this.setState({feedbackPrescriptions: e.target.value}) }} value="1" /></div>
                    </div>
        </div>
    </div>
    
</div> 
                            <div className={this.state.review ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Review</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Comments</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.reviews.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                       
                                                    <td>{item.reviewDate}</td>
                                                    <td> {this.getReviewTime(item.reviewTime)} </td>
                                                        <td><textarea className="form-control" value={item.remarks} onChange={e => { this.setReview(index, 'remarks', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReview(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.props.showCalenderReview()} className="btn btn-primary" >Insert New Review Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.medicalCertificate ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Medical Certificate</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.medicalCertificates.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><select className="form-control" value={item.name} onChange={e => { this.setMedicalCertificate(index, 'name', e.target.value); }} >
                                                            <option value="">Choose Type</option>
                                                            {this.state.medicalCertificateTypes.map((m, index) => (
                                                                <option key={index} value={m} >{m}</option>
                                                            ))}
                                                        </select></td>
                                                        <td><DatePicker className="form-control" selected={new Date(item.startDate)} onChange={d => { this.setMedicalCertificate(index, "startDate", this.processDate(d)) }} dateFormat="yyyy-MM-dd" /></td>
                                                        <td><DatePicker className="form-control" selected={new Date(item.endDate)} onChange={d => { this.setMedicalCertificate(index, "endDate", this.processDate(d)) }} dateFormat="yyyy-MM-dd" /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteMedicalCertificate(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addMedicalCertificateTable()} className="btn btn-primary" >Insert New Medical Certificate Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.rehabilitation ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Rehabilitation</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Remarks</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.rehabilitations.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><select className="form-control" value={item.type} onChange={e => { this.setRehabilitation(index, 'type', e.target.value); }} >
                                                            <option value="">Choose Type</option>
                                                            <option value="PHYSIO THERAPY">PHYSIO_THERAPY</option>
                                                            <option value="OCCUPATIONAL THERAPY">OCCUPATIONAL_THERAPY</option>
                                                        </select></td>
                                                        <td><textarea className="form-control" value={item.remarks} onChange={e => { this.setRehabilitation(index, 'remarks', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteRehabilitation(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addRehabilitationTable()} className="btn btn-primary" >Insert New Rehabilitation Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.referral ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Referral</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Type</th>
                                                <th>Remarks</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.referrals.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><input type="text" className="form-control" value={item.consultant} onChange={e => { this.setReferral(index, 'consultant', e.target.value); }} /></td>
                                                        <td><textarea className="form-control" value={item.referralNotes} onChange={e => { this.setReferral(index, 'referralNotes', e.target.value); }} ></textarea></td>
                                                        <td><span className="link-red" onClick={() => this.deleteReferral(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addReferralTable()} className="btn btn-primary" >Insert New Referral Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.treatment ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Treatment</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Drug</th>
                                                <th>Dosage</th>
                                                <th>Frequency</th>
                                                <th>Route</th>
                                                <th>Duration</th>
                                                <th>Formulation</th>
                                                <th>Repeats</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.treatments.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><input type="text" list={'drugList' + index} className="form-control" value={item.drugName} onChange={e => { this.setTreatment(index, 'drugName', e.target.value); }} />
                                                            <datalist id={'drugList' + index}>
                                                                {this.state.drugs.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" className="form-control" value={item.dosage} onChange={e => { this.setTreatment(index, 'dosage', e.target.value); }} /></td>
                                                        <td><input type="text" list={'frequencyList' + index} className="form-control" value={item.frequencyName} onChange={e => { this.setTreatment(index, 'frequencyName', e.target.value); }} />
                                                            <datalist id={'frequencyList' + index}>
                                                                {this.state.frequencys.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'routeList' + index} className="form-control" value={item.routeName} onChange={e => { this.setTreatment(index, 'routeName', e.target.value); }} />
                                                            <datalist id={'routeList' + index}>
                                                                {this.state.routes.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'durationList' + index} className="form-control" value={item.durationName} onChange={e => { this.setTreatment(index, 'durationName', e.target.value); }} />
                                                            <datalist id={'durationList' + index}>
                                                                {this.state.durations.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" list={'formulationList' + index} className="form-control" value={item.formulationName} onChange={e => { this.setTreatment(index, 'formulationName', e.target.value); }} />
                                                            <datalist id={'formulationList' + index}>
                                                                {this.state.formulations.map((m, index) => (
                                                                    <option key={index} value={m}>{m}</option>
                                                                ))}
                                                            </datalist> </td>
                                                        <td><input type="text" className="form-control" value={item.repeats} onChange={e => { this.setTreatment(index, 'repeats', e.target.value); }} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteTreatment(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addTreatmentTable()} className="btn btn-primary" >Insert New Treatment Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.investigation ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card  card-blue">
                                    <div className="card-header">Investigation</div>
                                    <div className="card-body">
                                    <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Name</th>
                                                <th>Action</th>
                                            </tr>
                                            <tbody>
                                                {this.state.investigations.map((item, index) => (item.activeStatus === 1 && (
                                                    <tr key={index}>
                                                        <td><Select className="form-control" value={this.state.services.filter(({ value }) => value === item.name)} onChange={e => { this.setInvestigation(index, e.value); }} options={this.state.services} /></td>
                                                        <td><span className="link-red" onClick={() => this.deleteInvestigation(index)} ><i className="bi bi-x-square-fill"></i></span></td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <button onClick={() => this.addInvestigationTable()} className="btn btn-primary" >Insert New Investigation Line</button>
                                    </div></div>
                            </div>
                            <div className={this.state.graphical ? "tab-pane fade show active" : "tab-pane fade"}>
                                <br />
                                <div className="card card-blue">
                                    <div className="card-header  text-dark">
                                        Graphical
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">HBA1C</span><input type="number" step="any" value={this.state.hBA1C} onChange={e => this.setState({ hBA1C: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Cholesterol</span><input type="number" step="any" value={this.state.cholesterol} onChange={e => this.setState({ cholesterol: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                        <div className="row">
                                            <div className="col"><div className="input-group"><span className="input-group-text">Other Name</span><input type="text" step="any" value={this.state.otherName} onChange={e => this.setState({ otherName: e.target.value })} className="form-control" /></div></div>
                                            <div className="col"><div className="input-group"><span className="input-group-text">Other Value</span><input type="number" step="any" value={this.state.otherValue} onChange={e => this.setState({ otherValue: e.target.value })} className="form-control" /></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-2 col-md-2">
                        <div className="card border-success">
                            <div className="card-body">
                                <div><span className="fw-bold">Weight (Kg):</span> {this.state.weight}</div>
                                <div><span className="fw-bold">Height (cm):</span> {this.state.height}</div>
                                <div><span className="fw-bold">Waist (cm):</span> {this.state.waist}</div>
                                <div><span className="fw-bold">BMI:</span> {this.state.bmi}</div>
                                <div><span className="fw-bold">Waist Height Ratio:</span> {this.state.waistHeightRatio}</div>
                                <div><span className="fw-bold">Notes:</span> <span >{this.state.nurseNotes}</span></div>
                            </div>
                        </div>

                        {this.state.allergies && (<div className="card card-red">
                            <div className="card-header text-dark">
                                Allergies
                            </div>
                            <div className="card-body">
                                <span>{this.state.allergies}</span>
                            </div>
                        </div>)}
                        {this.state.id > 0 && (<div className="card card-green">
                            <div className="card-header text-dark">Current</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewHistory(this.state.visitId)} >Current</span></li>
                                </ul>
                            </div>
                        </div>)}
                        <div className="card card-green">
                            <div className="card-header  text-dark">Consolidated</div>
                            <div className="card-body">
                                <ul>
                                    <li ><span className="link" onClick={() => this.viewConsolidated(this.state.visitId)}>Consolidated</span></li>
                                </ul>
                            </div>
                        </div>
                        {this.state.historyVisits.length > 0 && (<div className="card card-green" >
                            <div className="card-header  text-dark">History</div>
                            <div className="card-body">
                                <ul>
                                    {this.state.historyVisits.map((item, index) => (
                                        <li key={index}><span className="link" onClick={() => this.viewHistory(item.id)} >{item.name}</span></li>
                                    ))}
                                </ul>
                            </div>
                        </div>)}
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Notes</div>
                            <div className="card-body">
                                <label>Upload Notes [15MB]</label>
                                <input type="file" accept=".pdf" className="form-control" onChange={e => this.uploadFile('NOTES', e.target.files[0])} />
                            </div>
                        </div>
                        {this.state.notes.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Notes</div>
                            <div className="card-body">
                                <table >
                                    {this.state.notes.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'NOTES')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                        {this.state.referralLetters.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Referral Letters</div>
                            <div className="card-body">
                                <table >
                                    {this.state.referralLetters.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'REFERRAL_LETTERS')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                    </div>
                </div>


            </div >
        </div >
        </div >)

    }
}      