import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import '../../App.css';
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
export default class PatientAction extends React.Component {
    constructor(props) {
        super(props);
        this.getPatient = this.getPatient.bind(this);
    }
    state = { showSubmit: false, msg: '', error: '', action: 'SAVEONLY', existPatients: [],
        patientDetails: true, contactDetails: false, mobileDetails: false, nextOfKinDetails: false, insuranceDetails: false, dependentDetails: false, statisticalDetails: false, fileNumberDetails: false,
        firstname: '', firstnameError: '', salutationId: '', salutationError: '', idNumber: '', idNumberError: '', middlename: '', email: '',
        surname: '', surnameError: '', passport: '', gender: '', genderError: '', dateOfBirth: null, dateOfBirthError: '', employer: '', employerError: '', patientFileNumbers: [], salutations: [], genders: [],
        address1: '', address1Error: '', address2: '', address2Error: '', town: '', townError: '', landline: '', towns: [],
        patientMobiles: [{ id: 0, countryCodeId: '', mobileNumber: '', activeStatus: 1 }], countrys: [], count: 0,
        nextOfKinName: '', nextOfKinNameError: '', nextOfKinRelationshipId: '', nextOfKinRelationshipIdError: '', nextOfKinMobile: '', nextOfKinMobileError: '', relationships: [],
        patientInsurances: [], insurances: [], suffixs: [], dependents: [], sourceofVisits: [], sourceofVisit: '', remarks: '', canEditInsurance: false
    }
    componentDidMount() {
      //  this.getPatient() 
    }
    toggleTabs(name) {
        switch (name) {
            case "contactDetails":
                this.setState({ patientDetails: false, contactDetails: true, mobileDetails: false, nextOfKinDetails: false, insuranceDetails: false, dependentDetails: false, statisticalDetails: false , fileNumberDetails: false})
                break
            case "mobileDetails":
                this.setState({ patientDetails: false, contactDetails: false, mobileDetails: true, nextOfKinDetails: false, insuranceDetails: false, dependentDetails: false, statisticalDetails: false , fileNumberDetails: false })
                break
            case "nextOfKinDetails":
                this.setState({ patientDetails: false, contactDetails: false, mobileDetails: false, nextOfKinDetails: true, insuranceDetails: false, dependentDetails: false, statisticalDetails: false , fileNumberDetails: false })
                break
            case "insuranceDetails":
                this.setState({ patientDetails: false, contactDetails: false, mobileDetails: false, nextOfKinDetails: false, insuranceDetails: true, dependentDetails: false, statisticalDetails: false , fileNumberDetails: false })
                break
            case "dependentDetails":
                this.setState({ patientDetails: false, contactDetails: false, mobileDetails: false, nextOfKinDetails: false, insuranceDetails: false, dependentDetails: true, statisticalDetails: false , fileNumberDetails: false })
                break
            case "statisticalDetails":
                this.setState({ patientDetails: false, contactDetails: false, mobileDetails: false, nextOfKinDetails: false, insuranceDetails: false, dependentDetails: false, statisticalDetails: true , fileNumberDetails: false })
                break
            case "fileNumberDetails":
                this.setState({ patientDetails: false, contactDetails: false, mobileDetails: false, nextOfKinDetails: false, insuranceDetails: false, dependentDetails: false, statisticalDetails: false , fileNumberDetails: true })
                break
            default:
                this.setState({ patientDetails: true, contactDetails: false, mobileDetails: false, nextOfKinDetails: false, insuranceDetails: false, dependentDetails: false, statisticalDetails: false  , fileNumberDetails: false})
                break

        }
    }
    closePopup() {
        document.querySelector('#action').style = 'display:none'
    }
    setPatientValue(name, value) {
        switch (name) {
            case "salutationId":
                this.setState({ salutationId: value })
                console.log(value)
                break;
            case "firstname":
                this.setState({ firstname: (value).toLocaleUpperCase() })
                break;
            case "idNumber":
                this.setState({ idNumber: (value).toLocaleUpperCase() })
                break;
            case "middlename":
                this.setState({ middlename: (value).toLocaleUpperCase() })
                break;
            case "surname":
                this.setState({ surname: (value).toLocaleUpperCase() })
                break;
            case "passport":
                this.setState({ passport: (value).toLocaleUpperCase() })
                break;
            case "gender":
                this.setState({ gender: value })
                break;
            case "dateOfBirth": 
                this.setState({ dateOfBirth: this.processDate(value)})
                break;
            case "employer":
                this.setState({ employer: (value).toLocaleUpperCase() })
                break;
            case "address1":
                this.setState({ address1: (value).toLocaleUpperCase() })
                break;
            case "address2":
                this.setState({ address2: (value).toLocaleUpperCase() })
                break;
            case "town":
                this.setState({ town: (value).toLocaleUpperCase() })
                break;
            case "email":
                this.setState({ email: value })
                break;
            case "landline":
                this.setState({ landline: value })
                break;
            case "nextOfKinName":
                this.setState({ nextOfKinName: (value).toLocaleUpperCase() })
                break;
            case "nextOfKinRelationshipId":
                this.setState({ nextOfKinRelationshipId: value })
                break;
            case "nextOfKinMobile":
                this.setState({ nextOfKinMobile: value })
                break;
                default:
                    console.log("..............")
                    break

        }
        this.doValidate()
    }
    doValidate() {
        setTimeout(() => {
            this.validatePatient();
        }, 1000);
    }
    validatePatient() {
       
        var error = false;
        var patientDetails = 0;
        var contactDetails = 0;
        var mobileDetails = 0;
        var nextOfKinDetails = 0;
        var insuranceDetails = 0;
        if (this.state.firstname === null) {
            this.setState({ firstnameError: "Firstname cannot be empty" });
            patientDetails++;
            error = true
        }
        else if (this.state.firstname === "") {
            this.setState({ firstnameError: "Firstname cannot be empty" });
            patientDetails++;
            error = true
        }
        else if(!(/^(?!\s*$)[-a-zA-Z`]*$/.test(this.state.firstname)))
            {
            this.setState({ firstnameError: "Firstname should contain characters only and no spaces"})
            patientDetails++;
            error = true
            }
        else {
            this.setState({ firstnameError: "" })
        }

        if (this.state.surname === null) {
            this.setState({ surnameError: "Surname cannot be empty" })
            patientDetails++;
            error = true
        }
        else if (this.state.surname === "") {
            this.setState({ surnameError: "Surname cannot be empty" })
            patientDetails++;
            error = true
        }
        else if(!(/^(?!\s*$)[-a-zA-Z`]*$/.test(this.state.surname)))
            {
            this.setState({ surnameError: "Surname should contain characters only and no spaces"})
            patientDetails++;
            error = true
            }
        else {
            this.setState({ surnameError: "" })
        }

        if (this.state.salutationId === 0) {
            this.setState({ salutationError: "Salutation cannot be empty" })
            patientDetails++;
            error = true
        }
        else if (this.state.salutationId === "") {
            this.setState({ salutationError: "Salutation cannot be empty" })
            patientDetails++;
            error = true
        }
        else {
            this.setState({ salutationError: "" })
        } 
        if(localStorage.getItem('speciality') === 'OPD'){
            if (this.state.idNumber === null) {
                this.setState({ idNumberError: "National ID cannot be empty" })
                patientDetails++;
                error = true
            }
            else if (this.state.idNumber === "") {
                this.setState({ idNumberError: "National ID cannot be empty" })
                patientDetails++;
                error = true
            }
            else {
                this.setState({ idNumberError: "" })
            } 
           }
        if (this.state.gender === null) {
            this.setState({ genderError: "Gender cannot be empty" })
            patientDetails++;
            error = true
        }
        else if (this.state.gender === "") {
            this.setState({ genderError: "Gender cannot be empty" })
            patientDetails++;
            error = true
        }
        else {
            this.setState({ genderError: "" })
        } console.log(this.state.dateOfBirth)
        if (this.state.dateOfBirth === null) {
            this.setState({ dateOfBirthError: "Date Of Birth cannot be empty" })
            patientDetails++;
            error = true
        }
        else if (this.state.dateOfBirth === "") {
            this.setState({ dateOfBirthError: "Date Of Birth cannot be empty" })
            patientDetails++;
            error = true
        }
        else if(!(/^(\d{4})-(\d{2})-(\d{2})$/.test(this.state.dateOfBirth)))
            {
            this.setState({ dateOfBirthError: "Date Of Birth wrong format"})
            patientDetails++;
            error = true
            }
        else {
            this.setState({ dateOfBirthError: "" })
        }
        if (this.state.employer === null) {
            this.setState({ employerError: "Employer cannot be empty" })
            patientDetails++;
            error = true
        }
        else if (this.state.employer === "") {
            this.setState({ employerError: "Employer cannot be empty" })
            patientDetails++;
            error = true
        }
        else {
            this.setState({ employerError: "" })
        }
        if (this.state.address1 === null) {
            this.setState({ address1Error: "Street / Flat / Building Number cannot be empty" })
            contactDetails++;
            error = true
        }
        else if (this.state.address1 === "") {
            this.setState({ address1Error: "Street / Flat / Building Number cannot be empty" })
            contactDetails++;
            error = true
        }
        else {
            this.setState({ address1Error: "" })
        }
        if (this.state.address2 === null) {
            this.setState({ address2Error: "Surburb cannot be empty" })
            contactDetails++;
            error = true
        }
        else if (this.state.address2 === "") {
            this.setState({ address2Error: "Surburb cannot be empty" })
            contactDetails++;
            error = true
        }
        else {
            this.setState({ address2Error: "" })
        }
        if (this.state.town === null) {
            this.setState({ townError: "Town cannot be empty" })
            contactDetails++;
            error = true
        }
        else if (this.state.town === "") {
            this.setState({ townError: "Town cannot be empty" })
            contactDetails++;
            error = true
        }
        else {
            this.setState({ townError: "" })
        }
        console.log(" Details error"+error)
        var pms = this.state.patientMobiles;
        console.log(".......... "+JSON.stringify(pms))
        for (var i in pms) {
            if (pms[i].countryCodeId === "") {
                pms[i].countryCodeIdError = "Country Code cannot be empty"
                mobileDetails++;
                error = true
            }
            else {
                pms[i].countryCodeIdError = "";
            } console.log(pms[i].mobileNumber)
            if (pms[i].mobileNumber === "") {
                pms[i].mobileNumberError = "Mobile Number cannot be empty"
                mobileDetails++;
                error = true
            } //pattern-custom="^[7]\d{8}$"  data-pattern-error="Please numbers only should not start with zero like 772747211"
            else if(!(/^[7]\d{8}$/.test(pms[i].mobileNumber)) && !(/^[9]\d{8}$/.test(pms[i].mobileNumber)))
                {
                pms[i].mobileNumberError = "Please numbers only should not start with zero like 772747211"
                mobileDetails++;
                error = true
                }
            else {
                pms[i].mobileNumberError = "";
            }
            if (pms[i].whatsappNumber === "0" || pms[i].whatsappNumber === 0 || pms[i].whatsappNumber === null || pms[i].whatsappNumber === "null") {
                pms[i].whatsappNumber = ""
            }
            if (pms[i].whatsappCodeId === "" && pms[i].whatsappNumber !== "") {
                pms[i].whatsappCodeIdError = "Whtasapp Country Code cannot be empty"
                mobileDetails++;
                error = true
            }
            else {
                pms[i].whatsappCodeIdError = "";
            } 
         if(pms[i].whatsappNumber !== "" && !(/^[7]\d{8}$/.test(pms[i].whatsappNumber)) && !(/^[9]\d{8}$/.test(pms[i].mobileNumber)))
                {
                pms[i].whatsappNumberError = "Please numbers only should not start with zero like 772747211"
                mobileDetails++;
                error = true
                }
            else {
                pms[i].whatsappNumberError = "";
            }   
        }// console.log(".......... "+JSON.stringify(pms))
        console.log(" Mobile error"+error)
        this.setState({ patientMobiles: pms })
        if (this.state.nextOfKinName === null) {
            this.setState({ nextOfKinNameError: "Next of Kin Name cannot be empty" })
            nextOfKinDetails++
            error = true
        }
        else if (this.state.nextOfKinName === "") {
            this.setState({ nextOfKinNameError: "Next of Kin Name cannot be empty" })
            nextOfKinDetails++
            error = true
        }
        else {
            this.setState({ nextOfKinNameError: "" })
        }
        if (this.state.nextOfKinRelationshipId === 0) {
            this.setState({ nextOfKinRelationshipIdError: "Relationship to Patient cannot be empty" })
            nextOfKinDetails++
            error = true
        }
        else if (this.state.nextOfKinRelationshipId === "") {
            this.setState({ nextOfKinRelationshipIdError: "Relationship to Patient cannot be empty" })
            nextOfKinDetails++
            error = true
        }
        else {
            this.setState({ nextOfKinRelationshipIdError: "" })
        }
        if (this.state.nextOfKinMobile === null) {
            this.setState({ nextOfKinMobileError: "Next of Kin Mobile cannot be empty" })
            nextOfKinDetails++
            error = true
        }
        else if (this.state.nextOfKinMobile === "") {
            this.setState({ nextOfKinMobileError: "Next of Kin Mobile cannot be empty" })
            nextOfKinDetails++
            error = true
        }
        else {
            this.setState({ nextOfKinMobileError: "" })
        }

        console.log(" next error"+error)
        var patientInsurances = this.state.patientInsurances;
        for (var index in patientInsurances) {
            if (patientInsurances[index].insuranceId === "") {
                patientInsurances[index].insuranceIdError = "Insurance cannot be empty"
                insuranceDetails++
                error = true
            }
            else {
                patientInsurances[index].insuranceIdError = "";
            }
            if (patientInsurances[index].relationshipId === "" || patientInsurances[index].relationshipId === "0" || patientInsurances[index].relationshipId === 0) {
                patientInsurances[index].relationshipIdError = "Relationship to Member cannot be empty"
                insuranceDetails++
                error = true
            } //pattern-custom="^[7]\d{8}$"  data-pattern-error="Please numbers only should not start with zero like 772747211"
            else {
                patientInsurances[index].relationshipIdError = "";
            }
            if (patientInsurances[index].suffixId === "" || patientInsurances[index].suffixId === "0" || patientInsurances[index].suffixId === 0) {
                patientInsurances[index].suffixIdError = "Suffix cannot be empty"
                insuranceDetails++
                error = true
            }
            else {
                patientInsurances[index].suffixIdError = "";
            }
            if (patientInsurances[index].memberName === "") {
                patientInsurances[index].memberNameError = "Member Number cannot be empty"
                insuranceDetails++
                error = true
            }
            else {
                patientInsurances[index].memberNameError = "";
            }
            if (patientInsurances[index].memberNumber === "") {
                patientInsurances[index].memberNumberError = "Member Name cannot be empty"
                insuranceDetails++
                error = true
            }
            else {
                patientInsurances[index].memberNumberError = "";
            }
        }
        console.log(" Patient Insurance error"+error)
        this.setState({ patientInsurances: patientInsurances })
        var dependents = this.state.dependents;
        for ( index in dependents) {
            if(dependents[index].activeStatus === 1){
            if (dependents[index].salutationId === "") {
                dependents[index].salutationIdError = "Salutation cannot be empty"
                error = true
            }
            else {
                dependents[index].salutationIdError = "";
            }
            if (dependents[index].surname === "") {
                dependents[index].surnameError = "Surname to Member cannot be empty"
                error = true
            } //pattern-custom="^[7]\d{8}$"  data-pattern-error="Please numbers only should not start with zero like 772747211"
            else if(!(/^(?!\s*$)[-a-zA-Z`]*$/.test(dependents[index].surname)))
                {
                dependents[index].surnameError = "Surname should contain characters only and no spaces"
                error = true
                }
            else {
                dependents[index].surnameError = "";
            }
            if (dependents[index].firstname === "") {
                dependents[index].firstnameError = "Firstname cannot be empty"
                error = true
            }
            else if(!(/^(?!\s*$)[-a-zA-Z`]*$/.test(dependents[index].firstname)))
                {
                dependents[index].firstnameError= "Firstname should contain characters only and no spaces"
                error = true
                }
            else {
                dependents[index].firstnameError = "";
            }
            if (dependents[index].gender === "") {
                dependents[index].genderError = "Gender cannot be empty"
                error = true
            }
            else {
                dependents[index].genderError = "";
            }
            if (dependents[index].dateOfBirth === "") {
                dependents[index].dateOfBirthError = "Date Of Birth cannot be empty"
                error = true
            }
            else if(!(/^(\d{4})-(\d{2})-(\d{2})$/.test(dependents[index].dateOfBirth)))
                {
                dependents[index].dateOfBirthError = "Date Of Birth wrong format"
                error = true
                }
            else {
                dependents[index].dateOfBirthError = "";
            }
            if (dependents[index].relationshipId === "") {
                dependents[index].relationshipIdError = "Relationship cannot be empty"
                error = true
            }
            else {
                dependents[index].relationshipIdError = "";
            }
            if (dependents[index].insuranceId === "") {
                dependents[index].insuranceIdError = "Insurance cannot be empty"
                error = true
            }
            else {
                dependents[index].insuranceIdError = "";
            }
            if (dependents[index].memberNumber === "") {
                dependents[index].memberNumberError = "Member Number cannot be empty"
                error = true
            }
            else {
                dependents[index].memberNumberError = "";
            }
            if (dependents[index].suffixId === "") {
                dependents[index].suffixIdError = "Suffix cannot be empty"
                error = true
            }
            else {
                dependents[index].suffixIdError = "";
            }
        }
        }
        console.log(" Dependents error"+error)
        this.setState({ dependents: dependents })
        if(patientDetails === 0 && this.state.id === 0 && this.state.count === 0)
          {
            this.checkIfExists()
          }
        if(error === true)
           {
            var where =  (patientDetails > 0?" [Patient] ": "")
            +""+(contactDetails > 0?" [Contact] ": "")
            +""+(mobileDetails > 0?" [Mobile] ": "")
            +""+(nextOfKinDetails > 0?" [Next Of Kin] ": "")
            +""+(insuranceDetails > 0?" [Insurance] ": "")
            this.setState({ error: "One or more of the mandatory field(s) is not filled in "+where, showSubmit: false}) 
           }
        else
           {
            this.setState({error: "", showSubmit: true}) 
           }   
    }
    setPatientmobileValue(index, name, value) {
        let list = this.state.patientMobiles.slice();

        switch (name) {
            case "countryCodeId":
                list[index].countryCodeId = value;
                break;
            case "mobileNumber":
                list[index].mobileNumber = value;
                break;
            case "whatsappCodeId":
                list[index].whatsappCodeId = value;
                break;
            case "whatsappNumber":
                list[index].whatsappNumber = value;
                break;
            case "activeStatus":
                list[index].activeStatus = value;
                break;
            default:
                console.log('....')
                break;
        }
        this.setState({ patientMobiles: list });
        this.validatePatient()        
    }
    setPatientInsuranceValue(index, name, value) {
        let list = this.state.patientInsurances.slice();

        switch (name) {
            case "insuranceId":
                list[index].insuranceId = value;
                break;
            case "relationshipId":
                list[index].relationshipId = value;
                break;
            case "suffixId":
                list[index].suffixId = value;
                break;
            case "memberName":
                list[index].memberName = (value).toLocaleUpperCase();
                break;
            case "memberNumber":
                list[index].memberNumber = value;
                break;
            case "activeStatus":
                list[index].activeStatus = value;
                break;
            default:
                console.log('....')
                break;
        }
        this.setState({ patientInsurances: list });
        this.validatePatient()        
    }
    setDependentValue(index, name, value) {
        let list = this.state.dependents.slice();

        switch (name) {
            case "salutationId":
                list[index].salutationId = value;
                break;
            case "surname":
                list[index].surname = (value).toLocaleUpperCase();
                break;
            case "firstname":
                list[index].firstname = (value).toLocaleUpperCase();
                break;
            case "gender":
                list[index].gender = value;
                break;
            case "dateOfBirth":
                list[index].dateOfBirth = this.processDate(value);
                break;
            case "relationshipId":
                list[index].relationshipId = value;
                break;
            case "insuranceId":
                list[index].insuranceId = value;
                break;
            case "memberNumber":
                list[index].memberNumber = value;
                break;
            case "suffixId":
                list[index].suffixId = value;
                break;
            default:
                console.log('....')
                break;
        }
        this.setState({ dependents: list });
        this.validatePatient()        
    }
addMobileTable()
{   console.log("....")
    let list = this.state.patientMobiles.slice();
    list.push({ id: 0, countryCodeId: '', countryCodeIdError: "Country Code cannot be empty", mobileNumber: '', mobileNumberError: "Mobile Number cannot be empty", whatsappCodeId: '', whatsappCodeIdError: '', whatsappNumber:'', whatsappNumberError: '', activeStatus: 1 });
    this.setState({ patientMobiles: list });
}
addInsuranceTable()
{
    let list = this.state.patientInsurances.slice();
    list.push({ id: 0, insuranceId: '', insuranceIdError: "Insurance cannot be empty", relationshipId: '', relationshipIdError: "Relationship to Member cannot be empty", suffixId: '', suffixIdError: "Suffix cannot be empty", memberNumber: '', memberNumberError: "Member Number cannot be empty", memberName: '', memberNameError: "Member Name cannot be empty", activeStatus: 1 });
    this.setState({ patientInsurances: list });
    //console.log('....... LIST '+JSON.stringify(list))
    //console.log('....... STATE LIST '+JSON.stringify(this.state.patientInsurances))
}
insertDependantLine()
{
    let list = this.state.dependents.slice();
    list.push({ id: 0, salutationId: '', salutationIdError: "Salutation cannot be empty", surname: '', surnameError: "Surname to Member cannot be empty", firstname: '', firstnameError: "Firstname cannot be empty", gender: '', genderError: "Gender cannot be empty", dateOfBirth: '', dateOfBirthError: "Date Of Birth cannot be empty", relationshipId: '', relationshipIdError: "Relationship cannot be empty", insuranceId: '', insuranceIdError: "Insurance cannot be empty", suffixId: '', suffixIdError: "Suffix cannot be empty", memberNumber: '', memberNumberError: "Member Name cannot be empty", activeStatus: 1 });
    this.setState({ dependents: list });
}
removePatientInsuranceEntry(index)
{
    let list = this.state.patientInsurances.slice();
    list.splice(index, 1)
    this.setState({ patientInsurances: list });
    this.doValidate()
}
removePatientDependentEntry(index)
{
    let list = this.state.dependents.slice();
    list.splice(index, 1)
    this.setState({ dependents: list });
    this.doValidate()
}
getPatientApp(id, canEditInsurance){
    this.setState({id: id, firstname: '', firstnameError: '', salutationId: '', salutationError: '', idNumber: '', middlename: '',
    surname: '', surnameError: '', passport: '', gender: '', genderError: '', dateOfBirth: null, dateOfBirthError: '', employer: '', employerError: '', patientFileNumbers: [], salutations: [], genders: [],
    address1: '', address1Error: '', address2: '', address2Error: '', town: '', townError: '', landline: '', towns: [],
    patientMobiles: [{ id: 0, countryCodeId: '', mobileNumber: '', activeStatus: 1 }], countrys: [],
    nextOfKinName: '', nextOfKinNameError: '', nextOfKinRelationshipId: '', nextOfKinRelationshipIdError: '', nextOfKinMobile: '', nextOfKinMobileError: '', relationships: [],
    patientInsurances: [], insurances: [], suffixs: [], dependents: [], sourceofVisits: [], sourceofVisit: '', remarks: '', existPatients: [],
    patientDetails: true, contactDetails: false, mobileDetails: false, nextOfKinDetails: false, insuranceDetails: false, dependentDetails: false, statisticalDetails: false  , fileNumberDetails: false, canEditInsurance: canEditInsurance
})
    axios.get(localStorage.getItem('host') + '/patient/getpatientapp/'+id+"/"+localStorage.getItem("companyId"), {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.setState({id: res.data.id, firstname: res.data.firstname, salutationId: res.data.salutationId,  idNumber: res.data.idNumber, middlename: res.data.middlename,
            surname: res.data.surname, passport: res.data.passport, gender: res.data.gender, dateOfBirth: res.data.dateOfBirth, employer: res.data.employer, patientFileNumbers: res.data.patientFileNumbers, salutations: res.data.salutations, genders: res.data.genders,
            address1: res.data.address1, address2: res.data.address2, town: res.data.town, landline: res.data.landline, towns: res.data.towns,
            patientMobiles: res.data.patientMobiles, countrys: res.data.countrys, email: res.data.email,
            nextOfKinName: res.data.nextOfKinName, nextOfKinRelationshipId: res.data.nextOfKinRelationshipId, nextOfKinMobile: res.data.nextOfKinMobile, relationships: res.data.relationships,
            patientInsurances: res.data.patientInsurances, insurances: res.data.insurances, suffixs: res.data.suffixs, dependents: res.data.dependents, sourceofVisits: res.data.sourceofVisits, sourceofVisit: res.data.sourceofVisit, remarks: res.data.remarks});
            
            this.props.setLoader(false)
                if(id === 0)
                {
                    this.addMobileTable()   
                } 
            this.validatePatient();
        }, err => {
             this.setState({error: err+""})
                this.props.setLoader(false)
        }
    ) 
}
getPatient(id, canEditInsurance) {
    this.setState({id: id, firstname: '', firstnameError: '', salutationId: '', salutationError: '', idNumber: '', middlename: '',
    surname: '', surnameError: '', passport: '', gender: '', genderError: '', dateOfBirth: null, dateOfBirthError: '', employer: '', employerError: '', patientFileNumbers: [], salutations: [], genders: [],
    address1: '', address1Error: '', address2: '', address2Error: '', town: '', townError: '', landline: '', towns: [],
    patientMobiles: [{ id: 0, countryCodeId: '', mobileNumber: '', activeStatus: 1 }], countrys: [], count: 0, 
    nextOfKinName: '', nextOfKinNameError: '', nextOfKinRelationshipId: '', nextOfKinRelationshipIdError: '', nextOfKinMobile: '', nextOfKinMobileError: '', relationships: [],
    patientInsurances: [], insurances: [], suffixs: [], dependents: [], sourceofVisits: [], sourceofVisit: '', remarks: '',
    patientDetails: true, contactDetails: false, mobileDetails: false, nextOfKinDetails: false, insuranceDetails: false, dependentDetails: false, statisticalDetails: false  , fileNumberDetails: false, canEditInsurance: canEditInsurance
})
    axios.get(localStorage.getItem('host') + '/patient/getpatient/'+id+"/"+localStorage.getItem("companyId"), {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.setState({id: res.data.id, firstname: res.data.firstname, salutationId: res.data.salutationId,  idNumber: res.data.idNumber, middlename: res.data.middlename,
            surname: res.data.surname, passport: res.data.passport, gender: res.data.gender, dateOfBirth: res.data.dateOfBirth, employer: res.data.employer, patientFileNumbers: res.data.patientFileNumbers, salutations: res.data.salutations, genders: res.data.genders,
            address1: res.data.address1, address2: res.data.address2, town: res.data.town, landline: res.data.landline, towns: res.data.towns,
            patientMobiles: res.data.patientMobiles, countrys: res.data.countrys, email: res.data.email,
            nextOfKinName: res.data.nextOfKinName, nextOfKinRelationshipId: res.data.nextOfKinRelationshipId, nextOfKinMobile: res.data.nextOfKinMobile, relationships: res.data.relationships,
            patientInsurances: res.data.patientInsurances, insurances: res.data.insurances, suffixs: res.data.suffixs, dependents: res.data.dependents, sourceofVisits: res.data.sourceofVisits, sourceofVisit: res.data.sourceofVisit, remarks: res.data.remarks});
            
            this.props.setLoader(false)
                if(id === 0)
                {
                    this.addMobileTable()   
                } 
            this.validatePatient();
        }, err => {
             this.setState({error: err+""})
                this.props.setLoader(false)
        }
    ) 
}
save() {
    this.props.setLoader(true)
    var data = {id: this.state.id, firstname: this.state.firstname, salutationId: this.state.salutationId,  idNumber: this.state.idNumber, middlename: this.state.middlename,
        surname: this.state.surname, passport: this.state.passport, gender: this.state.gender, dateOfBirth: this.state.dateOfBirth, employer: this.state.employer, patientFileNumbers: this.state.patientFileNumbers, 
        address1: this.state.address1, address2: this.state.address2, town: this.state.town, landline: this.state.landline, email: this.state.email, 
        patientMobiles: this.state.patientMobiles, 
        nextOfKinName: this.state.nextOfKinName, nextOfKinRelationshipId: this.state.nextOfKinRelationshipId, nextOfKinMobile: this.state.nextOfKinMobile,
        patientInsurances: this.state.patientInsurances, dependents: this.state.dependents, sourceofVisit: this.state.sourceofVisit, remarks: this.state.remarks}
    axios.post(localStorage.getItem('host') + '/patient/patientsave', data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.props.setLoader(false)
            console.log(res.data)
            if(res.data.error)
              {
                this.setState({ error: res.data.error });  
              }
              else
              {
                this.props.actionSavePatient(res.data.id, res.data.msg, this.state.action)  
                this.closePopup();
              }
        }, err => {
             this.setState({error: err+""})
            this.props.setLoader(false)
        }
    )
}
processDate(date)
{
  const now = new Date(date);
  const todayY = now.getFullYear();
  const todayM = checkZero(now.getMonth() + 1);
  const todayD = checkZero(now.getDate());
  var date1 = todayY + '-' + todayM + '-' + todayD;
  console.log(date1)
 return date1;
  

}
setFileNumber(index, value)
{
    var list  = this.state.patientFileNumbers
    list[index].fileNumber = value
    this.setState({patientFileNumbers: list})
} 
checkIfExists()
 {
    var data = { firstname: this.state.firstname, surname: this.state.surname, dateOfBirth: this.state.dateOfBirth, gender: this.state.gender }
    axios.post(localStorage.getItem('host') + '/patient/checkexist', data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.setState({ existPatients: res.data , count: this.state.count + 1});
        }, err => {
            console.log(".....")
        }
    )
 }
render() { //<input value={firstname}  onChange={this.handleChange} />
    let divMsg = ''
    if (this.state.msg) {
        divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
    } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
    if (this.state.error) {
        divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
    }
    return (<div className="page-popup page-popup-overlay" id="action">
            <div className="card">
                <div className="card-header bg-info">
                    Action
                </div>
                <div className="card-body">
                    {divMsg}
                    <div className="row">
                        <div className="col-md-9">
                            <div className="tab-content pt-2 pl-1" id="pills-tabContent" v-align="top">
                                <div className={this.state.patientDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                    <div className="card card-green">
                                        <div className="card-header  text-dark">
                                            Personal Details
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-4"><div className="input-group has-validation">
                                                    <span className="input-group-text">Salutation</span>
                                                    <select className={this.state.salutationError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.salutationId} onChange={e => { this.setPatientValue('salutationId', e.target.value); }} >
                                                        <option value="">Choose Salutation</option>
                                                        {this.state.salutations.map((salutation, index) => (
                                                            <option key={index} value={salutation.id} >{salutation.name}</option>
                                                        ))}
                                                    </select><div className="invalid-feedback">{this.state.salutationError}</div>
                                                </div></div>
                                                <div className="col-lg-4"><div className="input-group has-validation">
                                                    <span className="input-group-text">Firstname</span>
                                                    <input type="text" className={this.state.firstnameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.firstname} onChange={e => { this.setPatientValue('firstname', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.firstnameError}</div>
                                                </div></div>
                                                <div className="col-lg-4"><div className="input-group">
                                                    <span className="input-group-text">National ID</span>
                                                    <input type="text" className={this.state.idNumberError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.idNumber} onChange={e => { this.setPatientValue('idNumber', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.idNumberError}</div>
                                                </div></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4"><div className="input-group">
                                                    <span className="input-group-text"> Middlenames</span>
                                                    <input type="text" className="form-control" value={this.state.middlename} onChange={e => { this.setPatientValue('middlename', e.target.value); }} />
                                                </div></div>
                                                <div className="col-lg-4"><div className="input-group has-validation">
                                                    <span className="input-group-text">Surname</span>
                                                    <input type="text" className={this.state.surnameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.surname} onChange={e => { this.setPatientValue('surname', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.surnameError}</div>
                                                </div></div>
                                                <div className="col-lg-4"><div className="input-group">
                                                    <span className="input-group-text">Passport</span>
                                                    <input type="text" className="form-control" value={this.state.passport} onChange={e => { this.setPatientValue('passport', e.target.value); }} />
                                                </div></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4"><div className="input-group has-validation">
                                                    <span className="input-group-text">Gender</span>
                                                    <select className={this.state.genderError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.gender} onChange={e => { this.setPatientValue('gender', e.target.value); }} >
                                                        <option value="">Choose Gender</option>
                                                        {this.state.genders.map((gender, index) => (
                                                            <option key={index} value={gender} >{gender}</option>
                                                        ))}
                                                    </select>
                                                    <div className="invalid-feedback">{this.state.genderError}</div>
                                                </div></div>
                                                <div className="col-lg-4"><div className="input-group has-validation">
                                                    <span className="input-group-text">Date of Birth (yyyy-mm-dd)</span>
                                                    <DatePicker className={this.state.dateOfBirthError !== "" ? "form-control is-invalid" : "form-control"} selected={this.state.dateOfBirth === null?null:new Date(this.state.dateOfBirth)} maxDate={new Date()} onChange={d => { this.setPatientValue('dateOfBirth',d); }} dateFormat="yyyy-MM-dd" />
                                                    <input type="hidden" className={this.state.dateOfBirthError !== "" ? "form-control is-invalid" : "form-control"} /><div className="invalid-feedback">{this.state.dateOfBirthError}</div>
                                                </div></div>
                                                <div className="col-lg-4"><div className="input-group has-validation">
                                                    <span className="input-group-text">Employer</span>
                                                    <input type="text" className={this.state.employerError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.employer} onChange={e => { this.setPatientValue('employer', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.employerError}</div>
                                                </div></div>
                                            </div>
                                        </div></div></div>
                                 <div className={this.state.fileNumberDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                    
                                 <div className="card card-green">
                                        <div className="card-header  text-dark">
                                            File Number
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-striped">
                                                <tr>
                                                    <th>Company Name</th>
                                                    <th>File Number</th>
                                                </tr>
                                                <tbody>
                                                    {this.state.patientFileNumbers.map((patientFileNumber, index) => (
                                                        <tr key={index}>
                                                            <td>{patientFileNumber.companyName}</td>
                                                            <td><div className="input-group"><input type="text" className="form-control" value={patientFileNumber.fileNumber} onChange={e => { this.setFileNumber(index, e.target.value); }} />
                                                               </div></td>                                                           
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table></div></div>
                                    </div>       
                                <div className={this.state.contactDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                    <div className="card card-green">
                                        <div className="card-header  text-dark">
                                            Contact Details
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col"><div className="input-group has-validation">
                                                    <span className="input-group-text">Street / Flat / Building Number</span>
                                                    <input type="text" className={this.state.address1Error !== "" ? "form-control is-invalid" : "form-control"} value={this.state.address1} onChange={e => { this.setPatientValue('address1', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.address1Error}</div>
                                                </div></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6"><div className="input-group has-validation">
                                                    <span className="input-group-text">Surburb</span>
                                                    <input type="text" className={this.state.address2Error !== "" ? "form-control is-invalid" : "form-control"} value={this.state.address2} onChange={e => { this.setPatientValue('address2', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.address2Error}</div>
                                                </div></div>
                                                <div className="col-lg-6"><div className="input-group has-validation">
                                                    <span className="input-group-text">Town</span>
                                                    <input type="text" autoComplete="off" className={this.state.townError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.town} onChange={e => { this.setPatientValue('town', e.target.value); }} list="townList" />
                                                    <datalist id="townList">
                                                        {this.state.towns.map((town, index) => (
                                                            <option key={index} value={town} >{town}</option>
                                                        ))}
                                                    </datalist>
                                                    <div className="invalid-feedback">{this.state.townError}</div>
                                                </div></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6"><div className="input-group has-validation">
                                                    <span className="input-group-text">Email</span>
                                                    <input type="email" className="form-control" value={this.state.email} onChange={e => { this.setPatientValue('email', e.target.value); }} />
                                                </div></div>
                                                <div className="col-lg-6"><div className="input-group has-validation">
                                                    <span className="input-group-text">Landline Numbers (Home / Work)</span>
                                                    <input type="text" className="form-control" value={this.state.landline} onChange={e => { this.setPatientValue('landline', e.target.value); }} />
                                                </div></div>
                                                <div className="col-lg-4"></div>
                                            </div>
                                        </div>
                                    </div></div>
                                <div className={this.state.mobileDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                    <div className="card card-green">
                                        <div className="card-header  text-dark">
                                            Mobile Details
                                        </div>
                                        <div className="card-body">
                                            <div align="center" id="tableMsg"></div>
                                            <div className="alert alert-info">Enter the whatsapp number that the patient would want to receive notifications on like appointments, reviews and birthday messages</div>
                                            <button onClick={() => this.addMobileTable()} className="btn btn-success" ><i className="bi bi-plus-square"></i></button>
                                            <div className="table-responsive-lg"> 
                                            <table className="table table-striped">
                                                <tr>
                                                    <th>Country Code</th>
                                                    <th>Mobile Number</th>
                                                    <th>Whatsapp Country Code</th>
                                                    <th>Whatsapp Number</th>
                                                    <th>Active</th>
                                                </tr>
                                                <tbody>
                                                    {this.state.patientMobiles.map((patientMobile, index) => (
                                                        <tr key={index}>
                                                            <td><div className="input-group has-validation"><select className={patientMobile.countryCodeIdError !== "" ? "form-control is-invalid" : "form-control"} value={patientMobile.countryCodeId} onChange={e => { this.setPatientmobileValue(index, 'countryCodeId', e.target.value); }} >
                                                            <option value="">Choose</option>
                                                                {this.state.countrys.map((country, index) => (
                                                                    <option key={index} value={country.id} >{country.name}</option>
                                                                ))}
                                                            </select>
                                                                <div className="invalid-feedback">{patientMobile.countryCodeIdError}</div></div></td>
                                                            <td><div className="input-group has-validation"><input type="text" className={patientMobile.mobileNumberError !== "" ? "form-control is-invalid" : "form-control"} value={patientMobile.mobileNumber} onChange={e => { this.setPatientmobileValue(index, 'mobileNumber', e.target.value); }} />
                                                                <div className="invalid-feedback">{patientMobile.mobileNumberError}</div></div></td>
                                                                <td><div className="input-group has-validation"><select className={patientMobile.whatsappCodeIdError !== "" ? "form-control is-invalid" : "form-control"} value={patientMobile.whatsappCodeId} onChange={e => { this.setPatientmobileValue(index, 'whatsappCodeId', e.target.value); }} >
                                                            <option value="">Choose</option>
                                                                {this.state.countrys.map((country, index) => (
                                                                    <option key={index} value={country.id} >{country.name}</option>
                                                                ))}
                                                            </select>
                                                                <div className="invalid-feedback">{patientMobile.whatsappCodeIdError}</div></div></td>
                                                            <td><div className="input-group has-validation"><input type="text" className={patientMobile.whatsappNumberError !== "" ? "form-control is-invalid" : "form-control"} value={patientMobile.whatsappNumber} onChange={e => { this.setPatientmobileValue(index, 'whatsappNumber', e.target.value); }} />
                                                                <div className="invalid-feedback">{patientMobile.whatsappNumberError}</div></div></td>
                                                            <td><select className="form-control" value={patientMobile.activeStatus}  onChange={e => { this.setPatientmobileValue(index, 'activeStatus', e.target.value); }}>
                                                                <option value="0">No</option>
                                                                <option value="1">Yes</option>
                                                            </select></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div></div>
                                <div className={this.state.nextOfKinDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                    <div className="card card-green">
                                        <div className="card-header  text-dark">
                                            Next Of Kin Details
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6"><div className="input-group has-validation">
                                                    <span className="input-group-text">Next Of Kin Name</span>
                                                    <input type="text" className={this.state.nextOfKinNameError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.nextOfKinName} onChange={e => { this.setPatientValue('nextOfKinName', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.nextOfKinNameError}</div> </div></div>
                                                <div className="col-lg-6"><div className="input-group has-validation">
                                                    <span className="input-group-text">Relationship to Patient</span>
                                                    <select className={this.state.nextOfKinRelationshipIdError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.nextOfKinRelationshipId} onChange={e => { this.setPatientValue('nextOfKinRelationshipId', e.target.value); }}>
                                                    <option value="">Choose</option>
                                                        {this.state.relationships.map((relationship, index) => (
                                                            <option key={index} value={relationship.id} >{relationship.name}</option>
                                                        ))}
                                                    </select>
                                                    <div className="invalid-feedback">{this.state.nextOfKinRelationshipIdError}</div></div></div>
                                                    </div>
                                            <div className="row">
                                                <div className="col-lg-6"><div className="input-group has-validation">
                                                    <span className="input-group-text">Next of Kin Mobile</span>
                                                    <input type="text" className={this.state.nextOfKinMobileError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.nextOfKinMobile} onChange={e => { this.setPatientValue('nextOfKinMobile', e.target.value); }} />
                                                    <div className="invalid-feedback">{this.state.nextOfKinMobileError}</div></div></div>
                                            </div>
                                        </div>
                                    </div>       </div>
                                <div className={this.state.insuranceDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                    <div className="card card-green">
                                        <div className="card-header  text-dark">
                                            Insurance Details
                                        </div>
                                        <div className="card-body">
                                            <button onClick={() => this.addInsuranceTable()} className="btn btn-success" ><i className="bi bi-plus-square"></i></button>
                                            <div className="table-responsive-lg"> 
                                            <table className="table table-striped">
                                                <tr>
                                                    <th>Insurance</th>
                                                    <th>Relationship To Member</th>
                                                    <th>Suffix</th>
                                                    <th>Member Number</th>
                                                    <th>Member Name</th>
                                                    <th>Active</th>
                                                    <th>Action</th>
                                                </tr>
                                                <tbody>
                                                    {this.state.patientInsurances.map((patientInsurance, index) => (patientInsurance.activeStatus === 1 && (
                                                        <tr v-for="(patientInsurance, index) in patientInsurances">
                                                            <td><div className="input-group has-validation"><select className={patientInsurance.insuranceIdError !== "" ? "form-control is-invalid" : "form-control"} value={patientInsurance.insuranceId} onChange={e => { this.setPatientInsuranceValue(index, 'insuranceId', e.target.value); }} disabled={patientInsurance.id > 0 && !this.state.canEditInsurance} >
                                                                <option value="" >Choose Insurance</option>
                                                                {this.state.insurances.map((insurances, index) => (
                                                                    <option key={index} value={insurances.id} >{insurances.name}</option>
                                                                ))}
                                                            </select>
                                                                <div className="invalid-feedback">{patientInsurance.insuranceIdError}</div></div></td>
                                                            <td><div className="input-group has-validation"><select className={patientInsurance.relationshipIdError !== "" ? "form-control is-invalid" : "form-control"} value={patientInsurance.relationshipId} onChange={e => { this.setPatientInsuranceValue(index, 'relationshipId', e.target.value); }}  >
                                                            <option value="" >Choose Relationship</option>
                                                                {this.state.relationships.map((relationship, index) => (
                                                                    <option key={index} value={relationship.id} >{relationship.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{patientInsurance.relationshipIdError}</div></div></td>
                                                            <td><div className="input-group has-validation"><select className={patientInsurance.suffixIdError !== "" ? "form-control is-invalid" : "form-control"} value={patientInsurance.suffixId} onChange={e => { this.setPatientInsuranceValue(index, 'suffixId', e.target.value); }}  >
                                                            <option value="" >Choose Suffix</option>
                                                                {this.state.suffixs.map((suffix, index) => (
                                                                    <option key={index} value={suffix.id} >{suffix.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback"></div></div></td>
                                                            <td><div className="input-group has-validation"><input type="text" className={patientInsurance.memberNumberError !== "" ? "form-control is-invalid" : "form-control"} value={patientInsurance.memberNumber} onChange={e => { this.setPatientInsuranceValue(index, 'memberNumber', e.target.value); }} disabled={patientInsurance.id > 0 && !this.state.canEditInsurance}  />
                                                                <div className="invalid-feedback">{patientInsurance.memberNumberError}</div></div></td>
                                                            <td><div className="input-group has-validation"><input type="text" className={patientInsurance.memberNameError !== "" ? "form-control is-invalid" : "form-control"} value={patientInsurance.memberName} onChange={e => { this.setPatientInsuranceValue(index, 'memberName', e.target.value); }}   />
                                                                <div className="invalid-feedback">{patientInsurance.memberNameError}</div></div></td>
                                                            <td><select className="form-control" value={patientInsurance.activeStatus} onChange={e => { this.setPatientInsuranceValue(index, 'activeStatus', e.target.value); }} >
                                                                <option value="0">No</option>
                                                                <option value="1">Yes</option>
                                                            </select></td>
                                                            {patientInsurance.id === 0 ? (<td><input type="button" className="btn btn-danger" onClick={() => this.removePatientInsuranceEntry(index)} value="Remove" /></td>) : (<td></td>)}
                                                        </tr>
                                                    )))}
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={this.state.dependentDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                    <div className="card card-green">
                                        <div className="card-header  text-dark">
                                            Dependents
                                        </div>
                                        <div className="card-body">
                                            <button onClick={() => this.insertDependantLine()} className="btn btn-success" ><i className="bi bi-plus-square"></i></button>
                                            <div className="table-responsive-lg"> 
                                            <table className="table">
                                                <tr>
                                                    <th>Salutation</th>
                                                    <th>Surname</th>
                                                    <th>Firstname</th>
                                                    <th>Gender</th>
                                                    <th>Date Of Birth</th>
                                                    <th>Relationship To Member</th>
                                                    <th>Insurance</th>
                                                    <th>Member No</th>
                                                    <th>Suffix</th>
                                                    <th>Active</th>
                                                    <th>Action</th>
                                                </tr>
                                                <tbody>
                                                    {this.state.dependents.map((dependent, index) => (dependent.activeStatus === 1 && (
                                                        <tr >
                                                            <td><div className="input-group has-validation">
                                                                <select className={dependent.salutationIdError !== "" ? "form-control is-invalid" : "form-control"} value={dependent.salutationId} onChange={e => { this.setDependentValue(index, 'salutationId', e.target.value); }} >
                                                                    <option value="">Choose Salutation</option>
                                                                    {this.state.salutations.map((salutation, index) => (
                                                                        <option key={index} value={salutation.id} >{salutation.name}</option>
                                                                    ))}
                                                                </select><div className="invalid-feedback">{dependent.salutationIdError}</div></div></td>
                                                            <td><div className="input-group has-validation">
                                                                <input type="text" className={dependent.surnameError !== "" ? "form-control is-invalid" : "form-control"} value={dependent.surname} onChange={e => { this.setDependentValue(index, 'surname', e.target.value); }} />
                                                                <div className="invalid-feedback">{dependent.surnameError}</div>
                                                            </div></td>
                                                            <td><div className="input-group has-validation">
                                                                <input type="text" className={dependent.firstnameError !== "" ? "form-control is-invalid" : "form-control"} value={dependent.firstname} onChange={e => { this.setDependentValue(index, 'firstname', e.target.value); }} />
                                                                <div className="invalid-feedback">{dependent.firstnameError}</div>
                                                            </div></td>
                                                            <td><div className="input-group has-validation">
                                                                <select className={dependent.genderError !== "" ? "form-control is-invalid" : "form-control"} value={dependent.gender} onChange={e => { this.setDependentValue(index, 'gender', e.target.value); }} >
                                                                    <option value="">Choose Gender</option>
                                                                    {this.state.genders.map((gender, index) => (
                                                                        <option key={index} value={gender} >{gender}</option>
                                                                    ))}
                                                                </select><div className="invalid-feedback">{dependent.genderError}</div>
                                                            </div></td>
                                                            <td><div className="input-group has-validation">
                                                                <DatePicker className={dependent.dateOfBirthError !== "" ? "form-control is-invalid" : "form-control"} value={dependent.dateOfBirth} onChange={e => { this.setDependentValue(index, 'dateOfBirth', e); }} />
                                                                <div className="invalid-feedback">{dependent.dateOfBirthError}</div>
                                                            </div></td>
                                                            <td><div className="input-group has-validation">
                                                                <select className={dependent.relationshipIdError !== "" ? "form-control is-invalid" : "form-control"} value={dependent.relationshipId} onChange={e => { this.setDependentValue(index, 'relationshipId', e.target.value); }} >
                                                                    <option value="">Choose Relationship</option>
                                                                    {this.state.relationships.map((relationship, index) => (
                                                                        <option key={index} value={relationship.id} >{relationship.name}</option>
                                                                    ))}
                                                                </select><div className="invalid-feedback">{dependent.relationshipIdError}</div>
                                                            </div></td>
                                                            <td><div className="input-group has-validation"><select className={dependent.insuranceIdError !== "" ? "form-control is-invalid" : "form-control"} value={dependent.insuranceId} onChange={e => { this.setDependentValue(index, 'insuranceId', e.target.value); }} >
                                                                <option value="" >Choose Insurance</option>
                                                                {this.state.insurances.map((insurances, index) => (
                                                                    <option key={index} value={insurances.id} >{insurances.name}</option>
                                                                ))}  </select>
                                                                <div className="invalid-feedback">{dependent.insuranceIdError}</div></div></td>
                                                            <td><div className="input-group has-validation">
                                                                <input type="text" className={dependent.memberNumberError !== "" ? "form-control is-invalid" : "form-control"} value={dependent.memberNumber} onChange={e => { this.setDependentValue(index, 'memberNumber', e.target.value); }} />
                                                                <div className="invalid-feedback">{dependent.memberNumberError}</div>
                                                            </div></td>
                                                            <td><div className="input-group has-validation"><select className={dependent.suffixIdError !== "" ? "form-control is-invalid" : "form-control"} value={dependent.suffixId}  onChange={e => { this.setDependentValue(index, 'suffixId', e.target.value); }} >
                                                                <option value="">Choose Suffix</option>
                                                                {this.state.suffixs.map((suffix, index) => (
                                                                    <option key={index} value={suffix.id} >{suffix.name}</option>
                                                                ))}
                                                            </select><div className="invalid-feedback">{dependent.suffixIdError}</div>
                                                            </div></td>
                                                            <td ><select className="form-control" value={dependent.activeStatus} >
                                                                <option value="0">No</option>
                                                                <option value="1">Yes</option>
                                                            </select></td>
                                                            {dependent.id === 0 ? (<td><input type="button" className="btn btn-danger" onClick={() => this.removePatientDependentEntry(index)} value="Remove" /></td>) : (<td></td>)}
                                                        </tr>
                                                    )))}
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div> </div>
                                <div className={this.state.statisticalDetails ? "tab-pane fade show active" : "tab-pane fade"}>
                                    <div className="card card-green">
                                        <div className="card-header  text-dark">
                                            Statistical Data
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6"><div className="input-group">
                                                    <span className="input-group-text">How did you hear about us?</span>
                                                    <select className="form-control" value={this.state.sourceofVisit} onChange={e => { this.setPatientValue('sourceofVisit', e.target.value); }}>
                                                        {this.state.sourceofVisits.map((sourceofVisit, index) => (
                                                            <option key={index} value={sourceofVisit} >{sourceofVisit}</option>
                                                        ))}
                                                    </select>
                                                </div></div>
                                                <div className="col-lg-6"><div className="input-group">
                                                    <span className="input-group-text">Remarks</span>
                                                    <textarea className="form-control" value={this.state.remarks} onChange={e => { this.setPatientValue('remarks', e.target.value); }}  ></textarea>
                                                </div></div>
                                            </div>
                                        </div>
                                    </div></div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                    <button className={this.state.patientDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("patientDetails") }} data-bs-target="#patientDetails" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Patient Details</button>
                                </li>
                                <li className="nav-item">
                                    <button className={this.state.contactDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("contactDetails") }} data-bs-target="#contactDetails" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Contact Details</button>
                                </li>
                                <li className="nav-item">
                                    <button className={this.state.mobileDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("mobileDetails") }} data-bs-target="#mobileDetails" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Mobile Details</button>
                                </li>
                                <li className="nav-item">
                                    <button className={this.state.nextOfKinDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("nextOfKinDetails") }} data-bs-target="#nextOfKinDetails" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Next Of Kin Details</button>
                                </li>
                                <li className="nav-item">
                                    <button className={this.state.insuranceDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("insuranceDetails") }} data-bs-target="#insuranceDetails" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Insurance Details</button>
                                </li>
                                <li className="nav-item">
                                    <button className={this.state.fileNumberDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("fileNumberDetails") }} data-bs-target="#fileNumberDetails" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">File Number Details</button>
                                </li>
                                <li className="nav-item">
                                    <button className={this.state.statisticalDetails ? "nav-link active" : "nav-link"} onClick={() => { this.toggleTabs("statisticalDetails") }} data-bs-target="#statisticalDetails" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Statistical Details</button>
                                </li>
                            </ul>
                            { this.state.id === 0 && this.state.existPatients.length > 0 &&
                            <div className='card card-blue'>
                                <div className='card-header'>Matched Records</div>
                                <div className='card-body'>
                            <table>
                               <tr>
                                <th></th>
                                <th>Name</th>
                               </tr>
                               {this.state.existPatients.map((item, index) => (
                               <tr key={index}> 
                                  <td><span className="link" title="Edit" id={item.id} onClick={e => this.getPatient(e.currentTarget.id, false)}><i className="bi bi-pencil-square"></i></span></td>
                                  <td>{item.fullname}</td>
                               </tr>
                               ))}
                            </table>
                            </div>
                            </div> }
                            { this.state.id > 0 && this.state.patientInsurances.length > 0 && (
                            <div className="row">
                                            <div className="col"><input type="button" onClick={() => this.props.getMemberLookuPi(this.state.id, 0)} className="btn btn-success" value="Member Lookup" /></div>
                                            <div className="col"><input type="button" onClick={() => this.props.getEnrol(this.state.id, 0)} className="btn btn-success" value="Enrol" /></div>
                                            <div className="col"><input type="button" value="Presign Bio" className="btn btn-success" onClick={() => this.props.getPresignPi(this.state.id, 0)} /></div>
                                        </div>
                            )}
                        </div>
                    </div>
                    <div className="card card-blue">
                        <div className="card-header">Action</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col"><div className="input-group"> <span className="input-group-text">Save Only</span><input type="radio"  value="SAVEONLY" checked={this.state.action === "SAVEONLY"} onChange={e => { this.setState({action: e.target.value}); }} /></div></div>
                                <div className="col"><div className="input-group"> <span className="input-group-text">Visit</span><input type="radio"  value="VISIT" checked={this.state.action === "VISIT"} onChange={e => { this.setState({action: e.target.value}); }} /></div></div>
                                <div className="col"><div className="input-group"> <span className="input-group-text">Appointment</span><input type="radio"  value="APPOINTMENT" checked={this.state.action === "APPOINTMENT"} onChange={e => { this.setState({action: e.target.value}); }} /></div></div>
                                <div className="col"><div className="input-group"> <span className="input-group-text">Advanced Receipt</span><input type="radio"  value="ADVANCEDRECEIPT" checked={this.state.action === "ADVANCEDRECEIPT"} onChange={e => { this.setState({action: e.target.value}); }} /></div></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col" align="right">{this.state.showSubmit === true ?(<input type="button" className="btn btn-primary" onClick={() => { this.save() }} value="Save" />):(<span></span>)}</div>
                        <div className="col"><input type="button" className="btn btn-primary"  onClick={() => this.closePopup()} value="Close" /></div>
                    </div>
                </div></div>
    </div >
    );
}
}