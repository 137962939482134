import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class DuplicateRecords extends Component {
     state = { id: 0, duplicateRecords: [], patientRecords: [], patientRecord: '', list: [], patientId: 0, reloadPatientList: false, duplicateCount: 0 }
    getDuplicateRecords()
      {
        this.setState({patientRecord: '', patientRecords: [], msg: '', error: ''})
        axios.get(localStorage.getItem('host') + '/patient/getduplicaterecords', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ duplicateRecords: res.data });
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )  
      }
    getPatientRecords(item)
    {
        this.props.setLoader(true)
        this.setState({patientRecords: []})
        axios.post(localStorage.getItem('host') + '/patient/getpatientrecords', JSON.parse(item), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                this.setState({ patientRecords: res.data });
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    setRecordType(index, e)
    {
        let list = this.state.patientRecords.slice();
        list[index].recordType = e.target.value;
        this.setState({ patientRecords: list })
    }
    save()
    {
        this.props.setLoader(true)
          
            axios.post(localStorage.getItem('host') + '/patient/savepatientrecords', this.state.patientRecords, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.props.setLoader(false)
                    console.log(res.data)
                    this.setState({ msg: res.data });    
                    this.props.getPatientRecordsCount()                
                    setTimeout(() => {
                        this.closePopup();
                    }, 3000);
                }, err => {
                    console.log(".....")
                     this.setState({error: err+""})
                this.props.setLoader(false)
                }
            )

    }
    closePopup()
    {
        document.getElementById('duplicateRecordAction').style = 'display:none'  
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        return (<div id="duplicateRecordAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header bg-info">Merge Duplicate Records</div>
            <div id="duplicateRecordMsg"></div>
            <div className="card-body" >
                {divMsg}
                <div className="row">
                    <div className="col"><div className="input-group">
                        <span className="input-group-text">Patient Record to Merge</span>
                        <select className="form-control" value={this.state.patientRecord} onChange={(e)=>this.getPatientRecords(e.target.value)}>
                        <option value="">Choose Patient Record</option>
                        {this.state.duplicateRecords.map((item, index) => (
                        <option value={JSON.stringify(item)}>{item.firstname } {item.surname } { item.dateOfBirth } { item.gender }</option>
                        ))}
                    </select></div></div>
                    <div className="col"></div>
                </div>
                <div >
                    <table className="table table-striped">
                        <tr>
                            <th>Record Type</th>
                            <th>Id</th>
                            <th>Firstname</th>
                            <th>Surname</th>
                            <th>Date of Birth</th>
                            <th>Gender</th>
                        </tr>
                        {this.state.patientRecords.map((item, index) => (
                          <tbody> 
                        <tr >
                            <td><select className="form-control" value={item.recordType} onChange={e =>this.setRecordType(index, e)} >
                            <option value="">Choose</option>
                                <option value="PRIMARY">PRIMARY</option>
                                <option value="DUPLICATE">DUPLICATE</option>
                            </select></td>
                            <td>{ item.id }</td>
                            <td>{ item.firstname }</td>
                            <td>{ item.surname }</td>
                            <td>{ item.dateOfBirth }</td>
                            <td>{ item.gender }</td>
                        </tr>
                        </tbody> 
                         ))}
                    </table>
                </div>
                <div className="row">
                    <div className="col-lg-6" align="center"><button onClick={()=>this.save()} className="btn btn-primary">Merge Records</button></div>
                    <div className="col-lg-6" align="center"><button onClick={()=>this.closePopup()} className="btn btn-primary">Close Window (Esc)</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}      