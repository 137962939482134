import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
var date1 = todayY + '-' + todayM + '-' + todayD;
console.log(date1)
function checkZero(num) {
    console.log((num + "").length)
    return (num + "").length === 1 ? "0" + num : num;
}
// disabled={this.state.appointmentTimeId > 0 && item.id !==  this.state.appointmentTimeId? true:null}
export default class TaskAction extends Component {
 
    state = {  taskStatus: "PENDING", companyId: 0, nurseTasks: [], nurse: false, patientId: 0 }
   
    taskAction(nurse, patientId) {
      
        this.setState({ nurse: nurse, patientId: patientId})
       this.getList()
    }
    getList()
      {
        this.props.setLoader(true)
        this.setState({  nurseTasks: [] })
        axios.post(localStorage.getItem('host') + '/patientmanagement/getnursetasklist/' , {taskStatus: this.state.taskStatus, companyId: localStorage.getItem('companyId')}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => { 
                this.setState({ nurseTasks: res.data, msg: ''});
                this.props.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
      }
    save() {
        this.props.setLoader(true)
        axios.post(localStorage.getItem('host') + '/patientmanagement/tasksave',this.state.nurseTasks, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                  this.setState({ msg: res.data }); 
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('taskViewAction').style = 'display:none'
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
        

      }
      addTable() {
        let list = this.state.nurseTasks.slice();
        list.push({ id: 0, remarks: '', taskDate: date1, nurseResponse: '', taskStatus: 'PENDING',  companyId: localStorage.getItem('companyId'), patientId: this.state.patientId });
        this.setState({ nurseTasks: list });
    }
    setItem(index, name, value) {
        let list = this.state.nurseTasks.slice();
        switch (name) {
            case "remarks":
                list[index].remarks = value
                break;
            case "taskDate":
                list[index].taskDate = value
                break;
            case "nurseResponse":
                list[index].nurseResponse = value
                break;
            default:
                if(list[index].id === 0)
                  {
                    list[index].taskStatus = "PENDING"
                  }
                else if(this.state.nurse && value === "COMPLETED")  
                  {
                    list[index].taskStatus = "COMPLETED NURSE"
                  }
                else
                  {
                    list[index].taskStatus = value
                  }  
                
                break;
        }
        this.setState({ nurseTasks: list });
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="taskViewAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header  bg-info">Task Action</div>
            <div className="card-body" >
                {divMsg}
                <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                             <div className="col"><div className="input-group"><span className="input-group-text">Status</span><select className="form-control" onChange={(e) => {this.setState({ taskStatus: e.target.value });  }} value={this.state.taskStatus}  >
                                             <option value="PENDING">PENDING</option>
                                                    <option value="WORK IN PROGRESS">WORK IN PROGRESS</option>
                                                    <option value="COMPLETED NURSE">COMPLETED NURSE</option>
                                                    <option value="COMPLETED">COMPLETED</option>
                                            </select>  </div></div> 
                                         <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getList()} value="Search" /></div>
                                        </div></div></div>
                            </div>
                        </div>     
                <div className="table-responsive-lg"> 
                                        <table className="table" id="table">
                                            <tr>
                                                <th>Date</th>
                                                <th>Patient Name</th>
                                                <th>Doctor Instructions</th>
                                                <th>Nurse Remarks</th>
                                                <th>Status</th>
                                            </tr>
                                            <tbody>
                                                {this.state.nurseTasks.map((item, index) => (
                                                    <tr key={index}>
                                                     { this.state.nurse === false ?(<td><DatePicker className="form-control" selected={new Date(item.taskDate)} onChange={d => { this.setItem(index, "taskDate", this.processDate(d)) }} dateFormat="yyyy-MM-dd" /></td>):(<td>{item.taskDate}</td>)}
                                                     <td>{item.fullname}</td>
                                                     { this.state.nurse === false ? (  <td><textarea className="form-control" value={item.remarks} onChange={e => { this.setItem(index, 'remarks', e.target.value); }} ></textarea></td>):(<td>{item.remarks}</td>)}
                                                     { this.state.nurse === true ? (    <td><textarea className="form-control" value={item.nurseResponse} onChange={e => { this.setItem(index, 'nurseResponse', e.target.value); }} ></textarea></td>):(<td>{item.nurseResponse}</td>)}
                                                     <td><select className="form-control" value={item.taskStatus} onChange={e =>  this.setItem(index, "taskStatus",  e.target.value )}  >
                                                    <option value="PENDING">PENDING</option>
                                                    <option value="WORK IN PROGRESS">WORK IN PROGRESS</option>
                                                    <option value="COMPLETED NURSE">COMPLETED NURSE</option>
                                                    <option value="COMPLETED">COMPLETED</option>
                                                </select></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                        { this.state.nurse === false && (<button onClick={() => this.addTable()} className="btn btn-primary" >Insert New Line</button>)}
                <div className="row">
                    <div className="col-lg-6" align="center"><button onClick={() => this.save()} className="btn btn-primary">Save</button></div>
                    <div className="col-lg-6" align="center"><button onClick={() => this.closePopup()} className="btn btn-primary">Close Window (Esc)</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}      