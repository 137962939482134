import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default class RisReport extends Component {
    constructor(props) {
        super(props);
        //this.setPatientValue = this.setPatientValue.bind(this);
        this.editorRef = React.createRef()
        this.progressRef = React.createRef();
    }
    state = {id: 0, visitId: 0, contentType: '', name: '', report: '', risStatus: '', rejectReason: '', rejectedBy: '', risTemplates: [], fullname: '', gender: '', dateOfBirth: '', age: '', employer: '', images: [], msg: '', error: '', showButtons: false, showProgress: false, clinicalData: '', template: ''}
    componentDidMount() {
       
    }
    openRisReportAction(ris) {
        this.setState({id: ris.id, visitId: ris.visitId, name: '', contentType: '', report: '', risStatus: '', rejectReason: '', rejectedBy: '', risTemplates: [], fullname: ris.fullname, gender: ris.gender, dateOfBirth: ris.dateOfBirth, age: ris.stringAge, employer: ris.employer, images: [], msg: '', error: '', showButtons: true, showProgress: false, clinicalData: ''})
        axios.get(localStorage.getItem('host') + '/is/ris/' + ris.id+'/'+localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({
                   name: res.data.name, report: res.data.report, risStatus: res.data.risStatus, rejectReason: res.data.rejectReason, rejectedBy: res.data.rejectedBy, risTemplates: res.data.risTemplates, images: res.data.images, clinicalData: res.data.clinicalData});
                this.props.setLoader(false)
                window.scrollTo(0, 0);
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )
    }
    save() {
        this.props.setLoader(true)
        this.setState({showButtons: false})
        var data = {id: this.state.id, report: this.state.report, risStatus: 'REPORTED' , reportedBy: localStorage.getItem('fullname')}
        axios.post(localStorage.getItem('host') + '/is/saveris', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                this.setState({ msg: res.data });
                window.scrollTo(0, 0);
                this.props.getList()
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )

    }
    closePopup() {
        document.getElementById('risReport').style = 'display:none';
        window.scrollTo(0, 0);
    }
    uploadFile(fileType, file) {
        if (file !== null || file !== '') {
            this.setState({showProgress: true})
            var data = new FormData();
            data.append("file", file)
            data.append("fileType", fileType)
            data.append("visitId", this.state.visitId)
            data.append("refId", this.state.id)
            axios.post(localStorage.getItem('host') + '/file/filesave', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data'

                },
                onUploadProgress: progressEvent => {
                    let percentComplete = progressEvent.loaded / progressEvent.total
                    percentComplete = parseInt(percentComplete * 100);
                    console.log(percentComplete);
                    //updateProgress(percentComplete);
                    this.progressRef.current.value = percentComplete;
                  }
            }).then(
                res => {
                    console.log(res.data)
                        this.setState({showProgress: false, images: res.data, msg: 'Successfully uploaded Image' });
                        window.scrollTo(0, 0);
                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }, err => {
                    this.setState({showProgress: false})
                    console.log(".....")
                }
            )

        }
    }
    deleteFile(id, fileExtension, fileType) {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/deletefile/' + id + '/' + fileType + '/' + fileExtension + '/' + this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                    this.setState({ images: res.data, msg: 'Successfully deleted Image' });
                    window.scrollTo(0, 0);
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )
    }
    showFile(id, fileExtension, contentType) {
        this.setState({ fileData: '', contentType: contentType });
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/getfile/' + id + '/' + fileExtension, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ fileData: res.data });
                document.getElementById('showFileNurseAction').style = 'display:block'
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )

    }
    fileNurseClosePopup() {
        this.setState({ fileData: '' });
        window.scrollTo(0, 0);
        document.getElementById('showFileNurseAction').style = 'display:none'
    }
    setReview(index, name, value) {
        let list = this.state.reviews.slice();
        switch (name) {
            case "reviewDate":
                list[index].reviewDate = value
                break;
            default:
                list[index].remarks = value
                break;
        }
        this.setState({ reviews: list });
    }
    setTemplate()
      {
        this.setState({report: this.state.template})
      }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } //v-bind:style="'height: '+heightDuplicateRecords+'px;max-height: '+heightDuplicateRecords+'px;overflow: auto;overflow-x: hidden;'"
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="risReport" className="page-popup page-popup-overlay"   >
            <div className="card" >
            <div className="card-header  bg-info">Report Action</div>
            <div className="card-body" >
                {divMsg}
                <div id="showFileNurseAction" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                            <img title="File View" alt={this.state.contentType} src={'data:'+this.state.contentType+';base64,' + this.state.fileData}  style={{ width: 100 + '%' }} />
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="card  card-green"  >
                            <div className="card-header  text-dark">Upload Images [15MB]</div>
                            <div className="card-body">
                                <div className="row">
                                <input type="file" accept=".png, .jpg, .jpeg, .pdf" className="form-control" onChange={e => this.uploadFile('RADIOLOGYIMAGE', e.target.files[0])} /></div>
                                <div className="row">
                                    {this.state.showProgress && <progress ref={this.progressRef} value="0" max="100" />}
                                </div>
                            </div>
                        </div>
                        {this.state.images.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Images</div>
                            <div className="card-body">
                                <table >
                                    {this.state.images.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension, item.contentType)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'RADIOLOGYIMAGE')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className="card border-success">
                            <div className="card-body">
                            <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                 </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                    <div className="col"><span className="fw-bold">Employer:</span> {this.state.employer}</div>
                                    <div className="col"></div>
                                 </div>
                                 <div className="row">   
                                    <div className="col"><span className="fw-bold">Rejected By:</span> {this.state.rejectedBy}</div>
                                    <div className="col"><span className="fw-bold">Reject Reason:</span> {this.state.rejectReason}</div>
                                    <div className="col"></div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Name:</span> {this.state.name}</div>
                                    <div className="col"><span className="fw-bold">Status:</span> {this.state.risStatus}</div>
                                 </div>
                                 <div className="row">
                                    <div className="col"><span className="fw-bold">Clinical Data:</span> {this.state.clinicalData}</div>
                                 </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" align="right">{ this.state.showButtons === true &&<button onClick={() => this.save()} className="btn btn-primary">Save</button>}</div>
                            <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                        </div>
                        <br />
                        
                            
                                <div className="card  card-blue">
                                    <div className="card-header">Report</div>
                                    <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                        <div className="input-group">  <select className="form-control" value={this.state.template} onChange={(e)=> this.setState({template: e.target.value})}>
                                           <option value="" >Choose</option>
                                           {this.state.risTemplates.map((item, index) => (
                                              <option value={item.template} key={index}>{item.name}</option>
                                           ))}
                                           </select><input type="button" className="btn btn-success" value="Insert" onClick={(e)=> this.setTemplate()} /></div>
                                        </div>
                                        <div className="col"></div>
                                    </div>
                                    <ReactQuill theme="snow" value={this.state.report} onChange={(data)=> this.setState({report: data})}/>
                </div>
             
                </div>
            </div >  </div>
            </div >
        </div >
        </div >)

    }
}      