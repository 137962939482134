import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


export default class Ward extends Component {
    state = { list: [], id: 0, name: '', wardName: '', roleId: 0, roles: [], beds: [], wardLocations: [], locations: [], services: [], order: '', activeStatus: 1, msg: '', error: '' }
    componentDidMount() {
        this.getList()

    }
    menuAction(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, name: '', wardName: '', roleId: 0, roles: [], beds: [], wardLocations: [], locations: [], services: [], order: '', activeStatus: 1, msg: '' })
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/master/wardaction/' + id+'/'+localStorage.getItem("companyId")+'/'+localStorage.getItem("speciality"), {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ id: res.data.id, name: res.data.name, wardName: res.data.wardName, roleId: res.data.roleId, activeStatus: res.data.activeStatus, roles: res.data.roles, beds: res.data.beds, wardLocations: res.data.wardLocations, services: res.data.services, locations: res.data.locations });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#menuAction').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#menuAction').style = 'display:none'
        console.log('......close');
    }
    addBed() {
        let list = this.state.beds.slice();
        list.push({ id: 0, name: '', serviceId: 0, floatBed: 0, bedStatus: 'AVAILABLE', activeStatus: 1 });
        this.setState({ beds: list })
    }
    setRowValue(index, e, name) {
        let list = this.state.beds.slice();
        switch (name) {
            case "name":
                list[index].name = e.target.value;
                break;
            case "serviceId":
                list[index].serviceId = e.target.value;
                break;
            case "floatBed":
                list[index].floatBed = e.target.value;
                break;
            case "bedStatus":
                list[index].bedStatus = e.target.value;
                break;
            case "activeStatus":
                list[index].activeStatus = e.target.value;
                break;
            default:
                console.log("default")
                break;
        }
        this.setState({ beds: list })
    }
    addLocation() {
        let list = this.state.wardLocations.slice();
        list.push({ id: 0, name: '', locationId: '', activeStatus: 1 });
        this.setState({ wardLocations: list })
    }
    setRowValueLocation(index, e, name) {
        let list = this.state.wardLocations.slice();
        switch (name) {
            case "locationId":
                list[index].locationId = e.target.value;
                break;
            case "activeStatus":
                list[index].activeStatus = e.target.value;
                break;
            default:
                console.log("default")
                break;
        }
        this.setState({ wardLocations: list })
    }
    save() {
        var data = { id: this.state.id, name: this.state.name, wardName: this.state.wardName, roleId: this.state.roleId, activeStatus: this.state.activeStatus, beds: this.state.beds, wardLocations: this.state.wardLocations }
        axios.post(localStorage.getItem('host') + '/master/wardform/'+localStorage.getItem("companyId"), data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    getList() {
        axios.get(localStorage.getItem('host') + '/master/ward/'+localStorage.getItem("companyId"), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit Menu" id={res.data[index].id} onClick={e => this.menuAction(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }
    render() {
        const data = {
            columns: [
                
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Ward Name',
                    field: 'wardName',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Status',
                    field: 'stringStatus',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: this.state.list
        };
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        <button className="btn btn-primary" onClick={() => this.showForm(0)} title="Add Menu" ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                        <MDBDataTable
                            button="true"
                            striped
                            bordered
                            small
                            data={data}
                        />
                        <div className="page-popup page-popup-overlay" id="menuAction">
                          
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Ward Action
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Name</span>
                                                <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e => { this.setState({ activeStatus: e.target.value }) }}  >
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Disable</option>
                                                </select></div>
                                            </div></div>
                                            <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Ward Name</span>
                                                <input type="text" className="form-control" value={this.state.wardName} onChange={e => { this.setState({ wardName: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Role</span>
                                                <select className="form-control" value={this.state.roleId} onChange={e => { this.setState({ roleId: e.target.value }) }}  >
                                                <option value="0">Choose Role</option>
                                                                {this.state.roles.map((item, index) => (
                                                                    <option key={index} value={item.id} >{item.name}</option>
                                                                ))}
                                                </select></div>
                                            </div></div>
                                        <div className="card card-green">
                                            <div className="card-header">Beds</div>
                                            <div className="card-body">
                                                <table className="table table-striped"  >
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Role</th>
                                                        <th>Float</th>
                                                        <th>Bed Status</th>
                                                        <th>Status</th>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4"> </td>
                                                        <td><button type="button" className="btn btn-success" title="Insert Bed" onClick={() => { this.addBed() }}  ><i className="bi bi-plus-square"></i></button></td>
                                                    </tr>
                                                <tbody>
                                                    {this.state.beds.map((item, index) => (
                                                        <tr>
                                                            <td><input type="text" className="form-control" value={item.name} onChange={e => { this.setRowValue(index, e, "name") }} /></td>
                                                            <td><select className="form-control" value={item.serviceId} onChange={e => { this.setRowValue(index, e, "serviceId") }}  >
                                                            <option value="0">Choose Service</option>
                                                                {this.state.services.map((item, index) => (
                                                                    <option key={index} value={item.id} >{item.name}</option>
                                                                ))}
                                                            </select></td>
                                                            <td><select className="form-control" value={item.floatBed} onChange={e => { this.setRowValue(index, e, "floatBed") }}  >
                                                                <option value="1" >Yes</option>
                                                                <option value="0" >No</option>
                                                            </select></td>
                                                            <td><select className="form-control" value={item.bedStatus} onChange={e => { this.setRowValue(index, e, "bedStatus") }}  >
                                                                <option value="AVAILABLE" >AVAILABLE</option>
                                                                <option value="OCCUPIED" >OCCUPIED</option>
                                                                <option value="RESERVED" >RESERVED</option>
                                                                <option value="UNAVAILABLE" >UNAVAILABLE</option>
                                                            </select></td>
                                                            <td><select className="form-control" value={item.activeStatus} onChange={e => { this.setRowValue(index, e, "activeStatus") }}  >
                                                                <option value="1" >Active</option>
                                                                <option value="0" >Disable</option>
                                                            </select></td>
                                                        </tr>
                                                    ))} </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card card-green">
                                            <div className="card-header">Locations</div>
                                            <div className="card-body">
                                                <table className="table table-striped"  >
                                                    <tr>
                                                        <th>Location</th>
                                                        <th>Status</th>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4"> </td>
                                                        <td><button type="button" className="btn btn-success" title="Insert Bed" onClick={() => { this.addBed() }}  ><i className="bi bi-plus-square"></i></button></td>
                                                    </tr>
                                                <tbody>
                                                    {this.state.wardLocations.map((item, index) => (
                                                        <tr>
                                                            <td><select className="form-control" value={item.locationId} onChange={e => { this.setRowValueLocation(index, e, "locationId") }}  >
                                                            <option value="0">Choose Location</option>
                                                                {this.state.locations.map((item, index) => (
                                                                    <option key={index} value={item.id} >{item.name}</option>
                                                                ))}
                                                            </select></td>
                                                            <td><select className="form-control" value={item.activeStatus} onChange={e => { this.setRowValueLocation(index, e, "activeStatus") }}  >
                                                                <option value="1" >Active</option>
                                                                <option value="0" >Disable</option>
                                                            </select></td>
                                                        </tr>
                                                    ))} </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary"  onClick={() => this.save()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary"  onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                           
                        </div>
                    </div>
                </div></div>
        )

    }
}    