import React, { Component } from "react";
import axios from "axios";
import '../../App.css';

export default class CancelReceipt extends Component {
    state = { id: 0, remarks: '', msg: '', error: '', fullname: '', number: '', paymentType: '', paidAmount: 0.00, convertedAmount: 0.00, refundedAmount: 0.00 }

    getCancelReceipt(advancedReceipt) {
        this.props.setLoader(false)
        this.setState({ id: advancedReceipt.id, remarks: '', fullname: advancedReceipt.fullname, number: advancedReceipt.number, paymentType: advancedReceipt.paymentType, paidAmount: advancedReceipt.paidAmount, convertedAmount: advancedReceipt.convertedAmount, refundedAmount: advancedReceipt.refundedAmount })
    }
    save() {
        var error = "";
        if (error === "") {
            this.props.setLoader(true)

            axios.get(localStorage.getItem('host') + "/bill/cancelreceipt/"+this.state.id+"?remarks="+this.state.remarks, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then(res => {
                    this.props.setMsg(res.data);
                    this.closePopup();
                    this.props.getList();
                    this.props.setLoader(false)
                })
        } else {
            this.setState({ error: error })
        }
    }
    setRate(index, value) {
        var list = this.state.allocationAdvancedReceipts;
        list[index].rate = value
        this.setState({ allocationAdvancedReceipts: list })
    }
    currencyFormat(amount) {
        let val = (amount / 1).toFixed(2)
        return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
    }
    closePopup() {
        document.getElementById('receiptCancelAction').style = 'display:none'
    }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="receiptCancelAction" className="page-popup page-popup-overlay"   ><div className="card" >
            <div className="card-header bg-info">Cancel Receipt</div>
            <div className="card-body" >
                <div className="card border-success">
                    <div className="card-body">
                        {divMsg}
                        <div className="row">
                            <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                            <div className="col"><span className="fw-bold">Number:</span> {this.state.number}</div>
                            <div className="col"><span className="fw-bold">Payment Type:</span> {this.state.paymentType}</div>
                        </div>
                        <div className="row">
                            <div className="col"><span className="fw-bold">Paid Amount:</span> {this.currencyFormat(this.state.paidAmount)}</div>
                            <div className="col"><span className="fw-bold">Converted Amount:</span> {this.currencyFormat(this.state.convertedAmount)}</div>
                            <div className="col"><span className="fw-bold">Change Amount:</span> {this.currencyFormat(this.state.refundedAmount)}</div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col" align="center"><div className="input-group"><span className="input-group-text">Cancel Reason:</span><textarea cols="5" value={this.state.remarks} onChange={e => this.setState({ remarks: e.target.value })} className="form-control" ></textarea></div> </div>

                </div>
                <div className="row">
                    <div className="col" align="center">{this.state.remarks !== '' && this.state.remarks.length > 4 ? (<button onClick={() => this.save()} id="submitButton2" className="btn btn-primary" >Submit</button>) : (<span></span>)}</div>
                    <div className="col" align="center"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                </div>
            </div>
        </div>
        </div>)

    }
}    