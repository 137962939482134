import React, { Component } from "react";
import axios from "axios";
/*
 <DuplicateRecords getPatientRecordsCount={this.getPatientRecordsCount} ref={this.duplicateRecordsRef} setLoader={this.setLoader} />
                         <MemberLookup ref={this.memberLookupRef} setLoader={this.setLoader} updateVisitAction={this.updateVisitAction} />
                        <MemberLookupQuick ref={this.memberLookupQuickRef} setLoader={this.setLoader} />
                        <Presignbio ref={this.presignbioRef} setLoader={this.setLoader} updateVisitAction={this.updateVisitAction} />
                        <Enrol ref={this.enrolRef} setLoader={this.setLoader} updateVisitAction={this.updateVisitAction} />
                        <ClaimResponse ref={this.claimResponseRef} setLoader={this.setLoader} />*/

export default class PatientList extends Component {

    state = { id: 0, firstname: '', surname: '', dateOfBirth: '', list: [], patientId: 0, reloadPatientList: false }
    openPatientList(firstname, surname, dateOfBirth)
     {
      this.setState({firstname: firstname, surname: surname, dateOfBirth: dateOfBirth})
      setTimeout(() => { this.getPatientList() }, 1000);
     }
    getPatientList() {

        this.props.setLoader(true)
        var data = { firstname: this.state.firstname, surname: this.state.surname, dateOfBirth: this.state.dateOfBirth }
        axios.post(localStorage.getItem('host') + '/patient/patientlist/'+localStorage.getItem("companyId")+'/'+localStorage.getItem("speciality"), data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {

                this.props.setLoader(false)
                for (var index in res.data) {
                    res.data[index].action = <button className="btn btn-our" style={{ backgroundColor: '#36BEEE', color: '#FFF' }} id={res.data[index].id} onClick={e => this.action(e)}>Edit</button>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    openPatient(id)
     {
      this.props.openPatient(id)
     }
     closePopup() {
        document.getElementById('patientList').style = 'display:none'
     }
     setSellPatient(index) 
     {
        this.props.setLoader(true)
        this.props.setSellPatient( this.state.list[index].id) 
     }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (<div className="page-popup page-popup-overlay" id="patientList">
        <div className="card">
            <div className="card-header bg-info">
                Action
            </div>
            <div className="card-body">
                {divMsg}
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Firstname</span> <input type="text" className="form-control" value={this.state.firstname} onChange={e => { this.setState({ firstname: e.target.value }) }} /></div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Surname</span> <input type="text" className="form-control" value={this.state.surname} onChange={e => { this.setState({ surname: e.target.value }) }} /></div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Date Of Birth</span> <input type="text" className="form-control" value={this.state.dateOfBirth} onChange={e => { this.setState({ dateOfBirth: e.target.value }) }} /></div></div>
                                    <div className="col"><button className="btn btn-success" onClick={() => this.getPatientList()} >Search</button></div>
                                </div>

                            </div></div>
                        <table className="table table-striped">
                            <tr>
                                <th>Action</th>
                                <th>Fullname</th>
                                <th>Number</th>
                                <th>Gender</th>
                                <th>Date Of Birth</th>
                                <th>File Numbers</th>
                            </tr>
                            <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr key={index}>
                                        <td><span className="link" title="Edit" id={item.id} onClick={e => this.openPatient(e.currentTarget.id)}><i className="bi bi-pencil-square"></i></span>
                                            <span className="link" title="New Sell" id={index} onClick={e => this.setSellPatient(e.currentTarget.id)}><i className="bi bi-file-earmark-fill"></i></span>
                                            <span className="link" title="Advanced Receipt" id={item.id} onClick={e => this.advancedReceiptAction(e.currentTarget.id)}><i className="bi bi-cash-coin"></i></span>
                                        </td>
                                        <td>{item.fullname}</td>
                                        <td>{item.number}</td>
                                        <td>{item.gender}</td>
                                        <td>{item.dateOfBirth}</td>
                                        <td>{item.fileNumber}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                       
                        <div className="row">
                            <div className="col-lg-12" align="center"><button onClick={() => this.closePopup()} className="btn btn-primary">Close Window (Esc)</button></div>
                        </div>
                        </div>
                        </div>  
                    </div>
        )

    }
}    