import React, { Component } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import PrintOut from '../patient/printout'
import SampleCreate from './samplecreate'

const now = new Date();
const todayY = now.getFullYear();
const todayM = checkZero(now.getMonth() + 1);
const todayD = checkZero(now.getDate());
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}


export default class SampleList extends Component {
    state = { loader: false, id: 0, firstname: '', surname: '', startDate: todayY + '-' + todayM + '-' + todayD, endDate: todayY + '-' + todayM + '-' + todayD, number: '', sampleStatus: 'CREATED', companyId: localStorage.getItem('companyId'), list: [], clusterCompanys: [], updateSampleStatus: '',  rejectReasonText: '', showSubmit: false, updateSampleStatusError: '', error: '', rejectReasonTextError: ''}
    constructor(props) {
        super(props);
        this.setLoader = this.setLoader.bind(this)
        this.getPrintOut = this.getPrintOut.bind(this)
        this.sendCancelRequest = this.sendCancelRequest.bind(this)
        this.getList = this.getList.bind(this)
        this.setMsg = this.setMsg.bind(this)
        this.printOutRef = React.createRef()
        this.sampleCreateRef = React.createRef()
        this.cancelRef = React.createRef()
    }

    componentDidMount() {
        axios.get(localStorage.getItem('host') + '/auth/getclustercompanys/'+localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({clusterCompanys: res.data});
                this.getList() 
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.setLoader(false)
            }
        )
    }
    getList() {
        this.setLoader(true)
        var data = { number: this.state.number, sampleStatus: this.state.sampleStatus, firstname: this.state.firstname, surname: this.state.surname, startDate: this.state.startDate, endDate: this.state.endDate, companyId:  this.state.companyId, updateSampleStatus: '',  rejectReasonText: '' , showSubmit: false, updateSampleStatusError: '', error: '', rejectReasonTextError: ''}
        axios.post(localStorage.getItem('host') + '/is/samplelist', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ list: res.data });
                this.setLoader(false)
            }, err => {
                console.log(".....")
                 this.setState({ error: err.Error })
                this.setLoader(false)
            }
        )
    } 

   
    openGrvAction(id)
      {
       this.close()
       this.props.getForm(id, '')   
      }
      setLoader(loader) {
          this.setState({ loader: loader })
      }
      currencyFormat(amount) {
          let val = (amount / 1).toFixed(2)
          return val.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ").replace("-,", "-");
      }
      getPrintOut(id, type, printouts, companyId) {
          this.setState({ loader: true })
          this.printOutRef.current.getPrintOut(id, type, printouts, companyId)
          document.getElementById('printOutAction').style = 'display:block'
      }
      openReceiptAction(grvId) {
          this.setState({ loader: true })
          this.receiptRef.current.openReceiptAction(grvId)
          document.getElementById('receiptAction').style = 'display:block'
      }
      setMsg(msg)
       {
        this.setState({msg:msg})
       }
      processDate(date)
        {
          const now = new Date(date);
          const todayY = now.getFullYear();
          const todayM = checkZero(now.getMonth() + 1);
          const todayD = checkZero(now.getDate());
          var date1 = todayY + '-' + todayM + '-' + todayD;
          console.log(date1)
         return date1;
        }
        sendCancelRequest(id, reason)
         {
            this.setLoader(true)
            axios.get(localStorage.getItem('host') + '/stockmanagement/cancelgrv/'+id+'/'+reason, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({msg: res.data, loader: false});
                    document.getElementById('cancelAction').style = 'display:none'
                    this.getList()
                }, err => {
                    console.log(".....")
                     this.setState({error: err+""})
                    this.setLoader(false)
                }
            )
         } 
         setItem(index, value)
           {
           var list = this.state.list;
           list[index].checked = value
           this.setState({list: list})
           this.doValidate()
           }
           setChecked(value)
            {
             var list = this.state.list
             for(var i in list)
                 {
                  list[i].checked = value
                 }
              this.setState({list: list, checked: value})   
              this.doValidate()
            } 
            doValidate() {
                setTimeout(() => {
                    this.validate(this.state.list.slice());
                }, 1000);
            }
            validate(list) {
                var error = false;
                if (this.state.updateSampleStatus === "") {
                    this.setState({updateSampleStatusError: "Sample Status cannot be empty"})
                    error = true
                }
                else {
                    this.setState({updateSampleStatusError : ""})
                }
                if (this.state.locationId === "") {
                    this.setState({locationIdError: "Location cannot be empty"})
                    error = true
                }
                else {
                    this.setState({locationIdError : ""})
                }
                var checked = false;
                for (var index in list) {
                    if(list[index].checked === 1){
                        checked = true
                }
                }
                if(checked === false)
                   {
                    this.setState({ error: "Please select at least one Item to update Sample", showSubmit: false })
                   }
               else if (error === true) {
                    this.setState({ error: "One or more of the mandatory field(s) is not filled in", showSubmit: false })
                }
                else {
                    this.setState({ error: "", showSubmit: true })
                }
            }
            save() {
                this.setLoader(true)
                this.setState({showSubmit: false})
                var list = []
                for(var i in this.state.list)
                   {
                    if(this.state.list[i].checked === 1)
                        {
                        let item = this.state.list[i]
                        item.sampleStatus = this.state.updateSampleStatus
                        item.rejectReasonText = this.state.rejectReasonText
                        list.push(item)
                        }
                   }
                var data = { list: this.state.list, userId: localStorage.getItem("userId")}
                axios.post(localStorage.getItem('host') + '/is/updatesample',data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }).then(
                    res => {
                        this.setLoader(false)
                          this.getList();
                          this.setState({ msg: res.data });  
                    }, err => {
                        console.log(".....")
                         this.setState({error: err+""})
                        this.setLoader(false)
                    }
                )
        
            }
    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        let loaderDiv = ''
        if(this.state.loader)
           {
            loaderDiv = (  <div id="loader" className="loader-overlay"><div align="center" className="loader"></div></div>)
           }
        return (
           
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid" id="topDiv">
                        <div className="row">
                            <div className="col"><input type="button" className="btn btn-success" onClick={() => this.sampleCreateRef.current.getInvestigationList(0)} value="Create New" /></div>
                        </div>
                        {divMsg}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card border-primary">
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col"><div className="input-group"><span className="input-group-text">Number</span> <input type="text" className="form-control" value={this.state.number} onChange={d => { this.setState({ number: d.target.value });} }  /></div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Firstname</span> <input type="text" className="form-control" value={this.state.firstname} onChange={d => { this.setState({ firstname: d.target.value });} }  /></div></div>    
                                    <div className="col"><div className="input-group"><span className="input-group-text">Surname</span> <input type="text" className="form-control" value={this.state.surname} onChange={d => { this.setState({ surname: d.target.value });} }  /></div></div>                                        
                                    <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">End Date</span> <DatePicker className="form-control" selected={new Date(this.state.endDate)}  minDate={new Date(this.state.startDate)} maxDate={new Date()}  onChange={d => { this.setState({ endDate: this.processDate(d) }); } } dateFormat="yyyy-MM-dd" /></div></div>
                                    <div className="col"><div className="input-group"><span className="input-group-text">Status</span><select className="form-control" value={this.state.sampleStatus} onChange={(e) => this.setState({ sampleStatus: e.target.value })}  >
                                                                                           <option value="CREATED" >CREATED</option>
                                                                                           <option value="COLLECTED" >COLLECTED</option>
                                                                                           <option value="DISTRIBUTED" >DISTRIBUTED</option>
                                                                                           <option value="REJECTED" >REJECTED</option>
                                        </select>  </div></div>
                                     <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getList()} value="Search" /></div>
                                    </div></div></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card border-primary">
                                <div className="card-header">Update Samples</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col"><div className="input-group"><span className="input-group-text">Status</span><select className={this.state.updateSampleStatusError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.updateSampleStatus} onChange={(e) => { this.setState({ updateSampleStatus: e.target.value }); this.doValidate()}}  >
                                                                                           <option value="" >Choose</option>
                                                                                           {this.state.sampleStatus === "CREATED" && <option value="COLLECTED" >COLLECTED</option>}
                                                                                           {this.state.sampleStatus === "COLLECTED" && <option value="DISTRIBUTED" >DISTRIBUTED</option>}
                                                                                           {(this.state.sampleStatus === "DISTRIBUTED" || this.state.sampleStatus === "COLLECTED") && <option value="REJECTED" >REJECTED</option>}
                                        </select><div className="invalid-feedback">{this.state.updateSampleStatusError}</div>  </div></div>
                                        <div className="col">{ this.state.updateSampleStatus === "REJECTED" &&<div className="input-group"><span className="input-group-text">Reject Reason</span> <textarea className={this.state.rejectReasonTextError !== "" ? "form-control is-invalid" : "form-control"} value={this.state.rejectReasonText} onChange={d => { this.setState({ rejectReasonText: d.target.value }); this.doValidate()} }  ></textarea><div className="invalid-feedback">{this.state.rejectReasonTextError}</div></div>}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col">{this.state.showSubmit === true && <input type="button" className="btn btn-primary" onClick={() => this.save()} value="Save" />}</div>
                                        <div className="col"></div>
                                    </div>
                                </div>
                         </div>
                         </div>
                    </div>              
                    <div>
                        <table className="table table-striped table-hover">
                            <tr> 
                                <th><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={this.state.checked === 1} onChange={e => this.setChecked(e.target.checked === true ? 1 : 0)} /></th>
                                <th>Action</th>
                                <th>Number</th>
                                <th>Name</th>
                                <th>Department</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Date</th>
                                <th>Rejection Reason</th>
                            </tr>
                            <tbody>
                                {this.state.list.map((item, index) => (
                                    <tr style={{ backgroundColor: item.color }} key={index}>
                                        <td><input type="checkbox" className="form-check-input" style={{ height: 2.0 + 'em', width:  2.0 + 'em' }} checked={item.checked === 1} onChange={e => this.setItem(index, e.target.checked === true ? 1 : 0 )} /></td>
                                       <td >
                                            <span className="link" onClick={() => this.getPrintOut(item.id, 'SAMPLE', [], item.companyId)} title="Print" ><i className="bi bi-printer-fill"></i></span>
                                            </td>
                                        <td>{item.number}</td>
                                        <td>{item.fullname}</td>
                                        <td>{item.departmentName}</td>
                                        <td>{item.sampleTypeName}</td>
                                        <td>{item.sampleStatus}</td>
                                        <td>{item.creationDateTime}</td>
                                        <td>{item.rejectReasonText}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
       
            {loaderDiv}
                        <SampleCreate ref={this.sampleCreateRef} setLoader={this.setLoader} setMsg={this.setMsg} getPrintOut={this.getPrintOut} getList={this.getList} />
                        <PrintOut ref={this.printOutRef} setLoader={this.setLoader} />
                    </div>
        )

    }



}