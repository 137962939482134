import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default class RisVerification extends Component {
    constructor(props) {
        super(props);
        //this.setPatientValue = this.setPatientValue.bind(this);
        this.editorRef = React.createRef()
    }
    state = {id: 0, visitId: 0, requestVisitId: 0, contentType: '', name: '', report: '', risStatus: '', rejectReason: '', rejectedBy: '', risTemplates: [], fullname: '', gender: '', dateOfBirth: '', age: '', employer: '', images: [], msg: '', error: '', showButtons: false, clinicalData: ''}
    componentDidMount() {

    }
    openRisVerificationAction(ris) {
        this.setState({id: ris.id, contentType: '', name: '', verification: '', risStatus: '', rejectReason: '', rejectedBy: '', risTemplates: [], fullname: ris.fullname, gender: ris.gender, dateOfBirth: ris.dateOfBirth, age: ris.stringAge, employer: ris.employer, images: [], msg: '', error: '', showButtons: true, clinicalData: ''})
        axios.get(localStorage.getItem('host') + '/is/ris/' + ris.id+'/'+localStorage.getItem('companyId'), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({
                    requestVisitId: res.data.requestVisitId, visitId: res.data.visitId, name: res.data.name, report: res.data.report, risStatus: res.data.risStatus, rejectReason: res.data.rejectReason, rejectedBy: res.data.rejectedBy, risTemplates: res.data.risTemplates, images: res.data.images, clinicalData: res.data.clinicalData});
                this.props.setLoader(false)
                window.scrollTo(0, 0);
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                this.props.setLoader(false)
                window.scrollTo(0, 0);
            }
        )
    }
    approve() {
        this.props.setLoader(true)
        this.setState({showButtons: false})
        var data = {id: this.state.id, visitId: this.state.visitId, requestVisitId: this.state.requestVisitId, approvedById: localStorage.getItem('userId'), fullname: this.state.fullname, gender: this.state.gender,report: this.state.report, risStatus: 'APPROVED' , approvedBy: localStorage.getItem('fullname'), speciality: localStorage.getItem('speciality')}
        axios.post(localStorage.getItem('host') + '/is/approveris', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                this.setState({ msg: res.data });
                window.scrollTo(0, 0);
                this.props.getList()
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )

    }
    reject() {
        var error = "";
        this.setState({error: error, showButtons: false})   
        if(error === "" && (this.state.rejectReason === "" || this.state.rejectReason.length < 3))
        {
        error = "Rejection Reason cannot be empty";
        }
        if(error === "")
          {
        this.props.setLoader(true)
        var data = {id: this.state.id, report: this.state.report, risStatus: 'REJECTED', rejectReason: this.state.rejectReason , rejectedBy: localStorage.getItem('fullname')}
        axios.post(localStorage.getItem('host') + '/is/rejectris', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.props.setLoader(false)
                this.setState({ msg: res.data });
                window.scrollTo(0, 0);
                this.props.getList()
            }, err => {
                console.log(".....")
                this.setState({ error: err })
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )
          }
       else
          {
           this.setState({error: error})   
          }   
    }
    closePopup() {
        document.getElementById('risVerification').style = 'display:none'
    }
    uploadFile(fileType, file) {
        if (file !== null || file !== '') {
            var data = new FormData();
            data.append("file", file)
            data.append("fileType", fileType)
            data.append("visitId", 0)
            data.append("refId", this.state.id)
            axios.post(localStorage.getItem('host') + '/file/filesave', data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'multipart/form-data'

                }
            }).then(
                res => {
                    console.log(res.data)
                        this.setState({ images: res.data, msg: 'Successfully uploaded Image' });
                        window.scrollTo(0, 0);
                    setTimeout(() => {
                        this.setState({ msg: '' });
                    }, 3000);
                }, err => {
                    console.log(".....")
                }
            )

        }
    }
    deleteFile(id, fileExtension, fileType) {
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/deletefile/' + id + '/' + fileType + '/' + fileExtension + '/' + this.state.visitId, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                    this.setState({ images: res.data, msg: 'Successfully deleted Image' });
                    window.scrollTo(0, 0);
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )
    }
    showFile(id, fileExtension, contentType) {
        this.setState({ fileData: '', contentType: contentType });
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/file/getfile/' + id + '/' + fileExtension, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ fileData: res.data });
                document.getElementById('showFileNurseAction').style = 'display:block'
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                window.scrollTo(0, 0);
                this.props.setLoader(false)
            }
        )

    }
    fileNurseClosePopup() {
        this.setState({ fileData: '' });
        window.scrollTo(0, 0);
        document.getElementById('showFileNurseAction').style = 'display:none'
    }

    render() {
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        } 
        if (this.state.error) {
            divMsg = (<div className='alert alert-danger'>{this.state.error}</div>)
        }
        return (<div id="risVerification" className="page-popup page-popup-overlay"   >
            <div className="card" >
            <div className="card-header  bg-info">Verification Action</div>
            <div className="card-body" >
                {divMsg}
                <div id="showFileNurseAction" className="page-popup page-popup-overlay" >
                    <div className="card" >
                        <div className="card-header  bg-info">File View</div>
                        <div className="card-body" >
                        <img title="File View" alt={this.state.contentType} src={'data:'+this.state.contentType+';base64,' + this.state.fileData}  style={{ width: 100 + '%' }} />
                            <div className="row">
                                <div className="col" align="center" ><button onClick={() => this.fileNurseClosePopup()} className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {this.state.images.length > 0 && (<div className="card card-green"  >
                            <div className="card-header  text-dark">Images</div>
                            <div className="card-body">
                                <table >
                                    {this.state.images.map((item, index) => (
                                        <tr key={index} ><td width="90%"><span className="link" onClick={() => this.showFile(item.id, item.fileExtension, item.contentType)} >{item.fileName}</span></td><td> <span className="link-red" onClick={() => this.deleteFile(item.id, item.fileExtension, 'NOTES')}><i className="bi bi-x-square-fill"></i></span></td></tr>
                                    ))}
                                </table>

                            </div>
                        </div>)}
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10">
                        <div className="card border-success">
                            <div className="card-body">
                            <div className="row">
                                    <div className="col"><span className="fw-bold">Fullname:</span> {this.state.fullname}</div>
                                    <div className="col"><span className="fw-bold">Gender:</span> {this.state.gender}</div>
                                    <div className="col"><span className="fw-bold">DOB:</span> {this.state.dateOfBirth}</div>
                                 </div>
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Age:</span> <span dangerouslySetInnerHTML={{ __html: this.state.age }}></span></div>
                                    <div className="col"><span className="fw-bold">Employer:</span> {this.state.employer}</div>
                                    <div className="col"></div>
                                 </div>
                                 <div className="row">   
                                    <div className="col"><span className="fw-bold">Rejected By:</span> {this.state.rejectedBy}</div>
                                    <div className="col"><span className="fw-bold">Reject Reason:</span> {this.state.rejectReason}</div>
                                    <div className="col"></div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-success">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col"><span className="fw-bold">Name:</span> {this.state.name}</div>
                                    <div className="col"><span className="fw-bold">Status:</span> {this.state.risStatus}</div>
                                 </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" align="right">{ this.state.showButtons === true &&<button onClick={() => this.approve()} className="btn btn-primary">Approve</button>}</div>
                            <div className="col" align="center">{ this.state.showButtons === true &&<button onClick={() => this.reject()} className="btn btn-primary">Reject</button>}</div>
                            <div className="col" align="left"><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col"><div className="input-group">
                                <span className="input-group-text">Rejection Reason</span>
                                <textarea className="form-control" value={this.state.rejectReason} onChange={e => { this.setState({ rejectReason: e.target.value });} } ></textarea>
                            </div></div>
                            <div className="col"></div>
                        </div>                            
                                <div className="card  card-blue">
                                    <div className="card-header">Report</div>
                                    <div className="card-body">
                                    <ReactQuill theme="snow" value={this.state.report} onChange={(data)=> this.setState({verification: data})}/>
                </div>
             
                </div>
            </div >  </div>
            </div >
        </div >
        </div >)

    }
}      