import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import Top from '../../includes/top'
import Sidebar from '../../includes/sidebar'
import { MDBDataTable } from 'mdbreact';


export default class MenuGroups extends Component {
    state = { list: [], id: 0, name: '', menus: [], order: '', activeStatus: 1, msg: '', error: '' }
    componentDidMount() {
        this.getList()

    }
    menuAction(e) {
        var id = e.currentTarget.id;
        console.log("......." + id + " .... " + e.currentTarget.id)
        this.showForm(id);
    }
    showForm(id) {
        this.setState({ id: 0, name: '', menus: [], order: '', activeStatus: 1, msg: '' })
        if (id > 0) {
            axios.get(localStorage.getItem('host') + '/auth/menugroupaction/' + id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(
                res => {
                    this.setState({ id: res.data.id, name: res.data.name, activeStatus: res.data.activeStatus, menus: res.data.menus });
                }, err => {
                    console.log(".....")
                }
            )
        }
        document.querySelector('#menuAction').style = 'display:block'
    }
    closePopup() {
        document.querySelector('#menuAction').style = 'display:none'
        console.log('......close');
    }
    addMenu() {
        let list = this.state.menus.slice();
        list.push({ id: 0, name: '', menuFunction: '', activeStatus: 1 });
        this.setState({ menus: list })
    }
    setRowValue(index, e, name) {
        let list = this.state.menus.slice();
        switch (name) {
            case "name":
                list[index].name = e.target.value;
                break;
            case "menuFunction":
                list[index].menuFunction = e.target.value;
                break;
            case "activeStatus":
                list[index].activeStatus = e.target.value;
                break;
            default:
                console.log("default")
                break;
        }
        this.setState({ menus: list })
    }
    saveMenu() {
        var data = { id: this.state.id, name: this.state.name, activeStatus: this.state.activeStatus, menus: this.state.menus }
        axios.post(localStorage.getItem('host') + '/auth/menugroupform', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                console.log(res.data)
                this.setState({ msg: res.data });
                this.getList();
                this.closePopup();
                setTimeout(() => {
                    this.setState({ msg: '' });
                }, 3000);
            }, err => {
                console.log(".....")
            }
        )
    }
    delete(e) {
        axios.get(localStorage.getItem('host') + '/auth/menudelete/' + e.currentTarget.id, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                 this.setState({ msg: res.data});
                 document.querySelector('#menuAction').style = 'display:none'
                 }, err => {
                console.log(".....")
            }
        )
}
    getList() {
        axios.get(localStorage.getItem('host') + '/auth/menugroup', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                for (var index in res.data) {
                    res.data[index].action = <span className="link" title="Edit Menu" id={res.data[index].id} onClick={e => this.menuAction(e)}><i className="bi bi-pencil-square"></i></span>
                }
                this.setState({ list: res.data });
            }, err => {
                console.log(".....")
            }
        )
    }
    render() {
        const data = {
            columns: [
                
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Status',
                    field: 'stringStatus',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: this.state.list
        };
        let divMsg = ''
        if (this.state.msg) {
            divMsg = (<div className='alert alert-success'>{this.state.msg}</div>)
        }
        return (
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Top />

                    <div className="container-fluid">
                        <button className="btn btn-primary" onClick={() => this.showForm(0)} title="Add Menu" ><i className="bi bi-plus-square"></i></button>
                        {divMsg}
                        <MDBDataTable
                            button="true"
                            striped
                            bordered
                            small
                            data={data}
                        />
                        <div className="page-popup page-popup-overlay" id="menuAction">
                          
                                <div className="card">
                                    <div className="card-header bg-info">
                                        Menu Group Action
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Name</span>
                                                <input type="text" className="form-control" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} /></div>
                                            </div>
                                            <div className="col">
                                            <div className="input-group"><span className="input-group-text">Status</span>
                                                <select className="form-control" value={this.state.activeStatus} onChange={e => { this.setState({ activeStatus: e.target.value }) }}  >
                                                    <option value="1" >Active</option>
                                                    <option value="0" >Disable</option>
                                                </select></div>
                                            </div></div>
                                        <div className="card card-green">
                                            <div className="card-header">Menus</div>
                                            <div className="card-body">
                                                <table className="table table-striped"  >
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Menu Function</th>
                                                        <th>Status</th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4"> </td>
                                                        <td><button type="button" className="btn btn-success" title="Insert Menu" onClick={() => { this.addMenu() }}  ><i className="bi bi-plus-square"></i></button></td>
                                                    </tr>
                                                <tbody>
                                                    {this.state.menus.map((menu, index) => (
                                                        <tr>
                                                            <td><input type="text" className="form-control" value={menu.name} onChange={e => { this.setRowValue(index, e, "name") }} /></td>
                                                            <td><input type="text" className="form-control" value={menu.menuFunction} onChange={e => { this.setRowValue(index, e, "menuFunction") }} /></td>
                                                            <td><select className="form-control" value={menu.activeStatus} onChange={e => { this.setRowValue(index, e, "activeStatus") }}  >
                                                                <option value="1" >Active</option>
                                                                <option value="0" >Disable</option>
                                                            </select></td>
                                                            <td><span className="link-red" id={menu.id} onClick={e => this.delete(e)} title="Cancel"><i className="bi bi-x-square-fill"></i></span></td>
                                                        </tr>
                                                    ))} </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" align="right"><input type="button" className="btn btn-primary"  onClick={() => this.saveMenu()} value="Save" /></div>
                                            <div className="col"><input type="button" className="btn btn-primary"  onClick={() => this.closePopup()} value="Close" /></div>
                                        </div>
                                    </div></div>
                           
                        </div>
                    </div>
                </div></div>
        )

    }
}    