import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const now = new Date();
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}
export default class RemainderView extends Component {
  
     state={number: '', id : '', reason: '', titles:[], remainderList: [], dates: [], clusterCompanys:[], startDate: now.getFullYear() + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()), companyId: localStorage.getItem('companyId')}
     remainder()
    {    
        this.setState({ consultants: [], consultantId: 0})
     document.getElementById('remainderViewAction').style = 'display:block'
     this.props.setLoader(true)
     axios.get(localStorage.getItem('host') + '/auth/getclustercompanywithconsultants/' + localStorage.getItem('companyId'), {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.setState({ clusterCompanys: res.data });
            this.getRemainderList()
        }, err => {
            this.setState({ error: err + "" })
            this.props.setLoader(false)
        }
    )
    }  
    getRemainderList() {
        this.setState({ list: [], msg: '' });
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patientmanagement/getremainderview/'+this.state.companyId+'/'+this.state.startDate,  {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ titles: res.data.titles, dates: res.data.dates, remainderList: res.data.remainderList });
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    closePopup() {
        document.getElementById('remainderViewAction').style = 'display:none'
    }
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
    render() {
        return (<div id="remainderViewAction" className="page-popup page-popup-overlay" >
        <div className="card" >
            <div className="card-header bg-info">Remainder View</div>
            <div className="card-body" >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                             <div className="col"><div className="input-group"><span className="input-group-text">Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                                             <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" onChange={(e) => {this.setState({ companyId: e.target.value }); this.setConsultantList(e.target.value) }} value={this.state.companyId}  >
                                                {this.state.clusterCompanys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div> 
                                         <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getRemainderList()} value="Search" /></div>
                                        </div></div></div>
                            </div>
                        </div>     
                 <table className="table table-striped" >
                    <thead>
                        <tr>
                            <th>Time</th>
                            {this.state.titles.map((item, index) => (
                            <th key={index}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                {this.state.remainderList.map((item, index) => (
                        <tr key={index}>
                            <th>{index}</th>
                           <td dangerouslySetInnerHTML={{ __html: item.entry1 }}></td>
                           <td style={{ backgroundColor: '#cccccc' }} dangerouslySetInnerHTML={{ __html: item.entry2 }}></td>
                           <td dangerouslySetInnerHTML={{ __html: item.entry3 }}></td>
                           <td style={{ backgroundColor: '#cccccc' }} dangerouslySetInnerHTML={{ __html: item.entry4 }}></td>
                           <td dangerouslySetInnerHTML={{ __html: item.entry5 }}></td>
                           <td style={{ backgroundColor: '#cccccc' }} dangerouslySetInnerHTML={{ __html: item.entry6 }}></td>
                           <td dangerouslySetInnerHTML={{ __html: item.entry7 }}></td>
                        </tr>
                         ))}
                    </tbody>
                 </table>
                <div className="row">
                <div className="col" align="center" ><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div> 
                </div>
            </div>
        </div>
    </div>)

    }
}    